import React from 'react'
import PropTypes from 'prop-types'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const considerations = ({ viewBox, color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="considerations"
          d="M16.189 16.76c-1.66 0-2.18-4.817-2.18-6.021a2.18 2.18 0 1 1 4.36 0c0 1.204-.52 6.02-2.18 6.02zm0 5.97a2.18 2.18 0 1 1 0-4.36 2.18 2.18 0 0 1 0 4.36zm5.758 4.903l.02-.01A13.126 13.126 0 0 1 16.098 29C8.866 29 3 23.182 3 16S8.866 3 16.097 3s13.097 5.818 13.097 13a12.87 12.87 0 0 1-1.51 6.067c0 .104.02.26.06.458.077.379.198.805.441 1.583l.033.104c.328 1.051.438 1.43.539 1.913.2.97.184 1.626-.383 2.186-.827.816-1.307.726-4.232-.136l-.033-.01c-.868-.255-1.275-.366-1.67-.446a3.198 3.198 0 0 0-.504-.069c-.021 0-.02-.002.012-.017zm2.872-1.758c.77.227 1.18.342 1.516.418a30.76 30.76 0 0 0-.406-1.366l-.033-.105c-.691-2.216-.839-2.946-.376-3.796A10.473 10.473 0 0 0 26.796 16c0-5.853-4.788-10.602-10.699-10.602S5.398 10.147 5.398 16c0 5.853 4.788 10.602 10.699 10.602a10.73 10.73 0 0 0 4.839-1.143c.835-.42 1.488-.29 3.85.406l.033.01z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#considerations" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#considerations"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

considerations.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ...propTypesSvg,
}

considerations.defaultProps = {
  size: 'auto',
  ...defaultPropTypesSvg,
}

export default considerations
