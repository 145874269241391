import React from 'react'
import PropTypes from 'prop-types'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const graphs = ({ viewBox, color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="graphs"
          d="M14.3 25.2h4.8a.1.1 0 0 0 .1-.1V7.3a.1.1 0 0 0-.1-.1h-4.8a.1.1 0 0 0-.1.1v17.8a.1.1 0 0 0 .1.1zm7.1-8.192c.066-.005.133-.008.2-.008h4.6a2.4 2.4 0 0 1 2.4 2.4v5.5c0 .102-.006.202-.018.3h.718a1.1 1.1 0 0 1 0 2.2H3.1a1.1 1.1 0 0 1 0-2.2h1.708A2.432 2.432 0 0 1 4.8 25V14.4A2.4 2.4 0 0 1 7.2 12h4.6c.067 0 .134.003.2.008V7.3A2.3 2.3 0 0 1 14.3 5h4.8a2.3 2.3 0 0 1 2.3 2.3v9.708zM12 25V14.4a.2.2 0 0 0-.2-.2H7.2a.2.2 0 0 0-.2.2V25c0 .11.09.2.2.2h4.6a.2.2 0 0 0 .2-.2zm9.4-.1a.3.3 0 0 0 .3.3h4.4a.3.3 0 0 0 .3-.3v-5.5a.2.2 0 0 0-.2-.2h-4.6a.2.2 0 0 0-.2.2v5.5z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#graphs" />
        </mask>
        <use className={styles[color]} fillRule="nonzero" xlinkHref="#graphs" />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

graphs.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ...propTypesSvg,
}

graphs.defaultProps = {
  size: 'auto',
  ...defaultPropTypesSvg,
}

export default graphs
