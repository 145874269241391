/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import { ReactComponent as AberturaUnidades } from 'assets/images/ProvidersPage/svgs/aberturaUnidades.svg'
import { ReactComponent as Carpintaria } from 'assets/images/ProvidersPage/svgs/carpintaria.svg'
import { ReactComponent as Chaveiros } from 'assets/images/ProvidersPage/svgs/chaveiros.svg'
import { ReactComponent as Eletrica } from 'assets/images/ProvidersPage/svgs/eletrica.svg'
import { ReactComponent as Fotos } from 'assets/images/ProvidersPage/svgs/fotos.svg'
import { ReactComponent as Hidraulica } from 'assets/images/ProvidersPage/svgs/hidraulica.svg'
import { ReactComponent as Marcenaria } from 'assets/images/ProvidersPage/svgs/marcenaria.svg'
import { ReactComponent as Placas } from 'assets/images/ProvidersPage/svgs/placas.svg'
import { ReactComponent as Limpeza } from 'assets/images/ProvidersPage/svgs/limpeza.svg'
import { ReactComponent as PequenosReparos } from 'assets/images/ProvidersPage/svgs/pequenosReparos.svg'
import { ReactComponent as Pinturas } from 'assets/images/ProvidersPage/svgs/pintura.svg'
import { ReactComponent as Diligencias } from 'assets/images/ProvidersPage/svgs/diligencia.svg'
import { ReactComponent as Jardinagem } from 'assets/images/ProvidersPage/svgs/jardinagem.svg'
import { ReactComponent as Serralherias } from 'assets/images/ProvidersPage/svgs/serralheria.svg'

const content = [
  { title: 'Abertura de unidades', icon: () => <AberturaUnidades /> },
  { title: 'Diligências', icon: () => <Diligencias /> },
  { title: 'Fotos', icon: () => <Fotos /> },
  { title: 'Pequenos reparos e reformas', icon: () => <PequenosReparos /> },
  { title: 'Jardinagem', icon: () => <Jardinagem /> },
  { title: 'Chaveiros', icon: () => <Chaveiros /> },
  { title: 'Elétrica', icon: () => <Eletrica /> },
  { title: 'Hidráulica', icon: () => <Hidraulica /> },
  { title: 'Limpeza', icon: () => <Limpeza /> },
  { title: 'Carpintaria', icon: () => <Carpintaria /> },
  { title: 'Marcenaria', icon: () => <Marcenaria /> },
  { title: 'Instalação de placas e banners', icon: () => <Placas /> },
  { title: 'Pinturas', icon: () => <Pinturas /> },
  { title: 'Serralherias', icon: () => <Serralherias /> },
]

export default content
