import React from 'react'

import { Button, SelectSorting, AmountResults } from 'components'
import { isMobile } from 'utils/deviceDetector'
import { useList, useFilter, useAlert } from 'store'

import { TagSetContainer } from 'containers'
import styles from './EstateHeaderContainer.module.scss'

const EstateHeaderContainer = () => {
  const { isListLoading, getListData } = useList()
  const {
    $filter: {
      filters: { stringFilters },
    },
    setStringFilter,
    updateQueryString,
  } = useFilter()

  const { setOpenAlertModal } = useAlert()
  const { pagination } = getListData()
  const { total_items } = pagination

  const hasItems = !isListLoading() && !!total_items

  const setOrderBy = e => {
    setStringFilter('orderBy', e.target.value)
    updateQueryString()
  }

  return (
    <section className={styles.estateHeader}>
      {!isListLoading() && (
        <AmountResults
          amountResults={total_items || 0}
          profileType="iniciante"
          afterSearch={false}
        />
      )}

      {isMobile && hasItems && <TagSetContainer />}

      {hasItems && (
        <div className={styles.headerButtons}>
          <SelectSorting
            onChange={setOrderBy}
            value={stringFilters.orderBy.value || ''}
            type="outline"
            color="dark"
            iconPosition="right"
            fontSize="small"
            options={stringFilters.orderBy.options}
          />
          <Button
            onClick={() => setOpenAlertModal()}
            type="outline"
            iconPosition="left"
            darkColor
            textColor="darkColor"
            backgroundColor="darkColor"
            className={styles.alertButton}
          >
            Criar alerta
          </Button>
        </div>
      )}
    </section>
  )
}

export default EstateHeaderContainer
