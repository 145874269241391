export const featureIconDictionary = {
  dormitorios: 'bed',
  suites: 'suite',
  vagas_garagem: 'vacancies',
  banheiros: 'bathroom',
  varanda: 'balcony',
  copas: 'bed',
  cozinhas: 'kitchen',
  salas: 'livingroom',
  piscinas: 'pool',
  lavabos: 'bathsink',
  despensas: 'storeroom',
  banheiro_empregada: 'servicebath',
  dormitorio_empregada: 'serviceroom',
  areas_servico: 'servicearea',
  escritorio: 'desk',
  area_comum: 'footage',
  area_construida: 'footage',
  area_privativa: 'footage',
  area_terreno: 'footage',
  area_total: 'footage',
  area_util: 'footage',
}

export const featureNameDictionary = {
  dormitorios: 'Dormitório(s)',
  suites: 'Suíte(s)',
  vagas_garagem: 'Vaga(s) na Garagem',
  banheiros: 'Banheiro(s)',
  varanda: 'Varanda(s)',
  copas: 'Copa(s)',
  cozinhas: 'Cozinha(s)',
  salas: 'Sala(s)',
  lavabos: 'Lavabo(s)',
  despensas: 'Despensa(s)',
  banheiro_empregada: 'Banheiro de empregado',
  dormitorio_empregada: 'Dormitório de empregado',
  areas_servico: 'Área de serviço',
  piscinas: 'Piscina',
  escritorio: 'Escritório(s)',
  area_comum: 'Área comum',
  area_construida: 'Área construida',
  area_privativa: 'Área privativa',
  area_terreno: 'Área do Terreno',
  area_total: 'Área total',
  area_util: 'Área útil',
}

export const pendingTexts = {
  averbacao_leiloes_negativos_pc1:
    'Averbação dos leilões negativos da Lei 9.514/97',
  regularizacoes_reserva_legal_pc2:
    'Regularizações diversas relacionadas à Reserva Legal',
  regularizacoes_app_pc3:
    'Regularizações diversas relacionadas à Área de Preservação Permanente (APP)',
  regularizacoes_georreferenciamento_pc4:
    'Regularizações diversas relacionadas ao Georreferenciamento',
  regularizacoes_levantamento_topografico_pc5:
    'Regularizações diversas relacionadas ao Levantamento Topográfico',
  regularizacoes_car_pc6:
    'Regularizações diversas relacionadas ao cadastro do imóvel no CAR (Cadastro Ambiental Rural)',
  regularizacoes_sicar_pc7:
    'Regularizações diversas relacionadas ao cadastro do imóvel no Sistema Nacional de Cadastro Rural (SICAR)',
  regularizacoes_cnir_pc8:
    'Regularizações diversas relacionadas ao número no Cadastro Nacional de Imóveis Rurais (CNIR)',
  regularizacoes_cafir_pc9:
    'Regularizações diversas relacionadas ao Cadastro de Imóveis Rurais (CAFIR)',
  regularizacoes_emissao_ccir_pc10:
    'Regularizações diversas relacionadas à emissão do CCIR (Certificado de Cadastro de Imóvel Rural), incluindo regularização de área divergente em seus cadastros, se necessário',
  regularizacoes_receita_federal_itr_pc11:
    'Regularizações diversas relacionadas ao Receita Federal, inclusive com a eventual necessidade de regularização do ITR e suas pendências',
  averbacao_area_pc12: 'Averbação de Área',
  averbacao_benfeitorias_construcoes_pc13:
    'Averbação de Benfeitorias e Construções',
  averbacao_demolicao_pc14: 'Averbação de Demolição',
  constituicao_condominio_pc15: 'Constituição de Condomínio',
  demarcacoes_pc16: 'Demarcações',
  desmembramento_matricula_pc17: 'Desmembramento de Matrícula',
  levantamento_hipotecas_penhoras_pc18: 'Levantamento de hipotecas e penhoras',
}
