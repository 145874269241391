import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'components'

import styles from './Address.module.scss'

const Address = ({ address }) => {
  return (
    <address className={styles.wrapper}>
      <Tooltip>{address}</Tooltip>
    </address>
  )
}

Address.propTypes = {
  address: PropTypes.string.isRequired,
}

export default React.memo(Address)
