import React from 'react'
import PropTypes from 'prop-types'
import { CategoryTitle, Text } from 'components'

const Expenses = ({ expenses }) => {
  if (!expenses.length) return null

  return (
    <section>
      <CategoryTitle icon="expenses" title="Despesas" />
      {expenses.map((expense, index) => (
        <div key={index.toString()}>
          <Text fontSize="medium" color="lightTextColor">
            {`${expense.type}: ${
              expense.isento
                ? 'Isento'
                : `R$ ${expense.value.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`
            }`}
          </Text>
        </div>
      ))}
    </section>
  )
}

Expenses.propTypes = {
  expenses: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      isento: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
}

export default Expenses
