import normalizeString from 'utils/normalizeString'

export const getModalImages = ({ bannersOrder, campaignsBanners = [] }) => {
  const banners = []

  if (campaignsBanners.length) {
    campaignsBanners.forEach(banner => {
      banners.unshift({
        src: banner.image,
        srcMobile: banner.image,
        link: banner.url || '',
        name: banner.name || '',
      })
    })
  }

  const imagesModalCampaign = banners.sort(
    (a, b) =>
      bannersOrder.indexOf(normalizeString(a.name)) -
      bannersOrder.indexOf(normalizeString(b.name))
  )

  return { imagesModalCampaign }
}
