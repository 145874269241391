import React from 'react'
import PropTypes from 'prop-types'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const Arrow = ({ viewBox, color, rotate }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
      transform={`rotate(${rotate})`}
    >
      <defs>
        <path
          id="arrow"
          d="M20.08 18.08V8.5a1.5 1.5 0 0 1 3 0v11c0 .435-.184.826-.48 1.1-.274.296-.665.48-1.1.48h-11a1.5 1.5 0 1 1 0-3h9.58z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#arrow" />
        </mask>
        <use
          className={styles[color]}
          transform="rotate(45 16.04 14.04)"
          xlinkHref="#arrow"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Arrow.propTypes = {
  ...propTypesSvg,
  rotate: PropTypes.number,
}

Arrow.defaultProps = {
  ...defaultPropTypesSvg,
  rotate: 90,
}

export default Arrow
