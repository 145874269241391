import { useRoot } from 'store'

export const useCampaigns = () => {
  const {
    $root: { campaigns, campaignsLoading },
  } = useRoot()

  const hasActiveCampaign = () => {
    return Object.entries(campaigns).some(([, campaign]) => campaign.isActive)
  }

  const isAnActiveCampaign = campaign => {
    return Object.entries(campaigns)
      .map(([key, item]) => item.isActive && campaign?.test(key))
      .some(isActive => isActive)
  }

  const campaignConfigurations = campaign => campaigns[campaign]

  const hasCampaignFilter = () =>
    Object.values(campaigns).some(
      campaign => hasActiveCampaign() && campaign.filter
    )

  return {
    campaigns,
    hasActiveCampaign,
    isAnActiveCampaign,
    campaignConfigurations,
    hasCampaignFilter,
    campaignsLoading,
  }
}
