import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Text } from 'components'
import styles from './Financing.module.scss'

const Financing = ({ show, property }) => {
  const {
    mainValue,
    propertySmallId,
    category,
    purpose,
    salesman,
    isFinancing,
  } = property
  const ambiente = process.env.REACT_APP_STAGE === 'prod' ? 'PROD' : 'DEV'
  const salesmanName = salesman.name.replace(/ /g, '')
  if (!show) return null
  return (
    <span className={styles.financing}>
      <span className={styles.indicator}>
        <Icon type="hand" size={26} color="darkColor" />{' '}
        <Text>Quer financiar o imóvel?</Text>
      </span>
      <button type="button" className={styles.button} onClick={null}>
        <a
          href={
            isFinancing
              ? `https://simulador.credihome.com.br/resale/?partnerId=3417`
              : `https://resale.typeform.com/credito-002#source=emgea&valor_imovel=${mainValue}&codigo_imovel=${propertySmallId}&tipo_imovel=${category}&finalidade_imovel=${purpose}&ambiente=${ambiente}&vendedor=${salesmanName}`
          }
          target="_blank"
          rel="noreferrer"
        >
          Aprove aqui seu crédito
        </a>
      </button>
    </span>
  )
}

Financing.propTypes = {
  show: PropTypes.bool,
  property: PropTypes.shape({
    mainValue: PropTypes.string.isRequired,
    propertySmallId: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    purpose: PropTypes.string.isRequired,
    salesman: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    isFinancing: PropTypes.bool,
  }).isRequired,
}

Financing.defaultProps = {
  show: false,
}
export default React.memo(Financing)
