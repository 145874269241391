import api from 'service/api'
import { to } from 'nh-utils'
import { origin_device } from 'utils/deviceDetector'

export const sendForm = ({
  propertyId,
  form,
  send_mail_emgea,
  send_mail_resale,
}) => {
  const payload = { ...form, send_mail_emgea, send_mail_resale, origin_device }
  return to(api.post(`/${propertyId}/schedule`, payload))
}
