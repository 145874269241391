/* eslint-disable no-param-reassign */
import React, { useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isPreRendering from 'utils/isPreRendering'
import { isMobile } from 'utils/deviceDetector'
import {
  IdSearchField,
  Title,
  Toggle,
  Localities,
  ValueFilter,
  Icon,
  CheckboxFiltersOptions,
  SelectLocalityInput,
} from 'components'
import { replaceCampaignToShow } from 'service/campaigns'
import { useFilter, useRoot } from 'store'
import { RenderComplexCampaign } from './ComplexCampaignsFilters'
import styles from './Filter.module.scss'
import { reverseToInitialFilterValue } from './utils'

const Filter = ({ type, className, customFilters, children }) => {
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)

  const {
    setStringFilter,
    setBooleanFilter,
    setNumberFilter,
    setArrayFilter,
    removeArrayFilterPosition,
    initialState,
    $filter,
    setComplexCampaigns,
    setFilterCampaignOptions,
  } = useFilter()

  const {
    $root: { campaignsFilters },
  } = useRoot()

  const {
    booleanFilters,
    stringFilters,
    numberFilters,
    arrayFilters,
  } = $filter.filters

  const campaignFilterOptions = booleanFilters.campaign.options
  const hasCampaignFilter = () => !!campaignFilterOptions?.length
  useLayoutEffect(() => {
    const { options, complexOptions } = campaignsFilters
    if (!campaignFilterOptions?.length && options?.length) {
      const newOptions = reverseToInitialFilterValue(options)
      const newComplexOptions = reverseToInitialFilterValue(complexOptions)
      setFilterCampaignOptions(newOptions, newComplexOptions)
    }
    // eslint-disable-next-line
  }, [campaignFilterOptions])

  const simpleFilters = [
    arrayFilters.localities.key,
    'selectedLocalities',
    booleanFilters.searchRegion.key,
    'campaign',
    booleanFilters.purpose.key,
    booleanFilters.propertyType.key,
    'value',
  ]

  const handleComplexCampaign = (campaign, setFilter = () => null) => {
    setComplexCampaigns(campaign)
    setFilter()
  }
  const hasComplexCampaignsActive = !!booleanFilters.campaign.complexCampaigns
    .length

  const onChangeCampaign = (key, name) => {
    const isPreActive = /Pr[eé] Ativo/i.test(name) || /Em Breve/gi.test(name)

    if (isPreActive)
      return setStringFilter(
        stringFilters.preActive.key,
        !stringFilters.preActive.value ? 'true' : ''
      )

    return setBooleanFilter(key, name)
  }

  const hasCheckedCampaign = (name, checked) => {
    if (/Pr[eé] Ativo/i.test(name) || /Em Breve/gi.test(name))
      return stringFilters.preActive.value === 'true'

    return checked
  }

  const renderAllFilters = [
    {
      key: arrayFilters.localities.key,
      title: arrayFilters.localities.name,
      component: (
        <SelectLocalityInput
          onChange={value => setArrayFilter(arrayFilters.localities.key, value)}
          selectedLocalities={arrayFilters.localities.value}
          options={arrayFilters.localities.options}
          type={type}
          invertIconOnX
        />
      ),
      wrapperClass: '',
      hideSeparator: true,
    },
    {
      key: booleanFilters.searchRegion.key,
      component: (
        <Toggle
          checked={booleanFilters.searchRegion.options[0].checked}
          onChange={() =>
            setBooleanFilter(
              booleanFilters.searchRegion.key,
              booleanFilters.searchRegion.options[0].name
            )
          }
          option={booleanFilters.searchRegion.options[0].name}
          optionPosition="right-aligned"
          id={`surroundings-toggle-${type}`}
        />
      ),
      wrapperClass: '',
      hideSeparator: true,
      hide: false,
    },
    {
      key: 'selectedLocalities',
      title: 'Localidades selecionadas',
      component: (
        <Localities
          localities={arrayFilters.localities.value}
          removeLocality={removeArrayFilterPosition}
          carrouselMode={isMobile}
        />
      ),
      hide:
        !arrayFilters.localities.value || !arrayFilters.localities.value.length,
      wrapperClass: '',
      hideSeparator: true,
    },
    {
      title: booleanFilters.campaign.name,
      key: booleanFilters.campaign.key,
      component: (
        <>
          {campaignFilterOptions.map(
            campaign =>
              hasCampaignFilter() && (
                <Toggle
                  checked={hasCheckedCampaign(campaign.name, campaign.checked)}
                  onChange={() =>
                    onChangeCampaign(booleanFilters.campaign.key, campaign.name)
                  }
                  option={replaceCampaignToShow(campaign.name)}
                  optionPosition="right-aligned"
                  id={`campaign-toggle-${campaign.name}`}
                />
              )
          )}
          {booleanFilters.campaign.complexCampaigns.map(option =>
            RenderComplexCampaign({
              optionFilter: option,
              filterCampaign: booleanFilters.campaign,
              onChange: () =>
                handleComplexCampaign(option.name, () =>
                  setStringFilter('coupon', option.value)
                ),
            })
          )}
        </>
      ),
      hide: !hasCampaignFilter() && !hasComplexCampaignsActive,
      // hideSeparator: type === 'filterModal',
      hideSeparator: false,
    },
    {
      key: booleanFilters.purpose.key,
      title: booleanFilters.purpose.name,
      component: (
        <CheckboxFiltersOptions
          type={type}
          filterKey={booleanFilters.purpose.key}
          options={booleanFilters.purpose.options}
          setBooleanFilter={setBooleanFilter}
        />
      ),
      hideSeparator:
        type === 'filterModal' &&
        !hasCampaignFilter() &&
        !hasComplexCampaignsActive,
    },
    {
      key: booleanFilters.propertyType.key,
      title: booleanFilters.propertyType.name,
      component: (
        <CheckboxFiltersOptions
          type={type}
          filterKey={booleanFilters.propertyType.key}
          options={booleanFilters.propertyType.options}
          setBooleanFilter={setBooleanFilter}
          justOneColumn
        />
      ),
    },
    {
      key: 'value',
      title: 'Valor',
      component: (
        <ValueFilter
          setNumberFilter={setNumberFilter}
          step={1000}
          limit={{
            min: initialState.filters.numberFilters.minValue.value,
            max: initialState.filters.numberFilters.maxValue.value,
          }}
          stateMinValue={numberFilters.minValue.value}
          stateMaxValue={numberFilters.maxValue.value}
          type={type}
        />
      ),
    },
    {
      key: booleanFilters.saleCategory.key,
      title: booleanFilters.saleCategory.name,
      component: (
        <CheckboxFiltersOptions
          type={type}
          filterKey={booleanFilters.saleCategory.key}
          options={booleanFilters.saleCategory.options}
          setBooleanFilter={setBooleanFilter}
        />
      ),
    },
    {
      key: booleanFilters.saleType.key,
      title: booleanFilters.saleType.name,
      component: (
        <CheckboxFiltersOptions
          type={type}
          filterKey={booleanFilters.saleType.key}
          options={booleanFilters.saleType.options}
          setBooleanFilter={setBooleanFilter}
        />
      ),
    },
    {
      key: booleanFilters.situation.key,
      title: booleanFilters.situation.name,
      component: (
        <CheckboxFiltersOptions
          type={type}
          filterKey={booleanFilters.situation.key}
          options={booleanFilters.situation.options}
          setBooleanFilter={setBooleanFilter}
        />
      ),
    },
    {
      key: booleanFilters.paymentType.key,
      title: booleanFilters.paymentType.name,
      component: (
        <CheckboxFiltersOptions
          type={type}
          filterKey={booleanFilters.paymentType.key}
          options={booleanFilters.paymentType.options}
          setBooleanFilter={setBooleanFilter}
        />
      ),
    },
    {
      key: booleanFilters.purchaseType.key,
      title: booleanFilters.purchaseType.name,
      component: (
        <CheckboxFiltersOptions
          type={type}
          filterKey={booleanFilters.purchaseType.key}
          options={booleanFilters.purchaseType.options}
          setBooleanFilter={setBooleanFilter}
        />
      ),
    },
    {
      key: booleanFilters.legalActions.key,
      title: booleanFilters.legalActions.name,
      component: (
        <Toggle
          checked={booleanFilters.legalActions.options[0].checked}
          onChange={() =>
            setBooleanFilter(
              booleanFilters.legalActions.key,
              booleanFilters.legalActions.options[0].name
            )
          }
          option={booleanFilters.legalActions.options[0].name}
          id={`judicial-toggle-${type}`}
        />
      ),
    },
    {
      key: booleanFilters.debts.key,
      title: booleanFilters.debts.name,
      component: (
        <Toggle
          checked={booleanFilters.debts.options[0].checked}
          onChange={() =>
            setBooleanFilter(
              booleanFilters.debts.key,
              booleanFilters.debts.options[0].name
            )
          }
          option={booleanFilters.debts.options[0].name}
          id={`debt-toggle-${type}`}
        />
      ),
    },
    {
      key: booleanFilters.visits.key,
      title: booleanFilters.visits.name,
      component: booleanFilters.visits.options.map(option => (
        <Toggle
          checked={option.checked}
          onChange={() =>
            setBooleanFilter(booleanFilters.visits.key, option.name)
          }
          option={option.name}
          id={`visit-toggle-${type}`}
        />
      )),
    },
    {
      key: booleanFilters.discount.key,
      title: booleanFilters.discount.name,
      component: (
        <CheckboxFiltersOptions
          type={type}
          filterKey={booleanFilters.discount.key}
          options={booleanFilters.discount.options}
          setBooleanFilter={setBooleanFilter}
        />
      ),
    },
    {
      key: stringFilters.searchId.key,
      title: stringFilters.searchId.name,
      component: (
        <IdSearchField
          onChange={value => setStringFilter(stringFilters.searchId.key, value)}
          value={stringFilters.searchId.value}
          id={`find-by-id-${type}`}
        />
      ),
      hide: type === 'modal',
    },
  ]

  const renderMobileFilters = showAdvancedFilters
    ? renderAllFilters
    : renderAllFilters.filter(item => simpleFilters.includes(item.key))

  const renderCustomFilters =
    customFilters &&
    customFilters.map(filter => renderAllFilters.find(f => f.key === filter))

  const renderFilterComponent = filter =>
    !filter.hide && (
      <section key={filter.key} className={filter.wrapperClass}>
        {!filter.hideSeparator && <hr id={`filter-separator-${filter.key}`} />}

        <Title
          type="h3"
          font="small"
          color="darkBlueColor"
          className={styles.title}
        >
          {filter.title}
        </Title>
        {filter.component}
      </section>
    )

  return (
    !isPreRendering() && (
      <form autoComplete="false" className={classNames(styles.form, className)}>
        {children}

        {!customFilters && (
          <>
            <div className={styles.desktopFilters}>
              {renderAllFilters.map(filter => renderFilterComponent(filter))}
            </div>

            <div className={styles.mobileFilters}>
              {renderMobileFilters.map(filter => renderFilterComponent(filter))}
            </div>
          </>
        )}

        {customFilters && (
          <div className={styles.customFilters}>
            {renderCustomFilters.map(filter => renderFilterComponent(filter))}
          </div>
        )}

        {type === 'filterModal' && (
          <button
            type="button"
            className={styles.showAdvancedFiltersButton}
            onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
          >
            {showAdvancedFilters
              ? 'Recolher filtros avançados'
              : 'Ver filtros avançados'}
            <Icon
              type="arrow"
              size={20}
              color="redColor"
              className={
                showAdvancedFilters ? styles.arrowOpen : styles.arrowClose
              }
            />
          </button>
        )}
      </form>
    )
  )
}

Filter.propTypes = {
  filters: PropTypes.shape({
    booleanFilters: PropTypes.shape({
      purpose: PropTypes.shape({
        name: PropTypes.string,
        queryStringKey: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string,
            name: PropTypes.string,
            checked: PropTypes.bool,
          })
        ),
      }),
      propertyType: PropTypes.shape({
        name: PropTypes.string,
        queryStringKey: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string,
            name: PropTypes.string,
            checked: PropTypes.bool,
          })
        ),
      }),
      situation: PropTypes.shape({
        name: PropTypes.string,
        queryStringKey: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string,
            name: PropTypes.string,
            checked: PropTypes.bool,
          })
        ),
      }),
      saleType: PropTypes.shape({
        name: PropTypes.string,
        queryStringKey: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string,
            name: PropTypes.string,
            checked: PropTypes.bool,
          })
        ),
      }),
      purchaseType: PropTypes.shape({
        name: PropTypes.string,
        queryStringKey: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string,
            name: PropTypes.string,
            checked: PropTypes.bool,
          })
        ),
      }),
      discount: PropTypes.shape({
        name: PropTypes.string,
        queryStringKey: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string,
            name: PropTypes.string,
            checked: PropTypes.bool,
          })
        ),
      }),
      legalActions: PropTypes.shape({
        name: PropTypes.string,
        queryStringKey: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string,
            name: PropTypes.string,
            checked: PropTypes.bool,
          })
        ),
      }),
      debts: PropTypes.shape({
        name: PropTypes.string,
        queryStringKey: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string,
            name: PropTypes.string,
            checked: PropTypes.bool,
          })
        ),
      }),
      searchRegion: PropTypes.shape({
        name: PropTypes.string,
        queryStringKey: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string,
            name: PropTypes.string,
            checked: PropTypes.bool,
          })
        ),
      }),
      visits: PropTypes.shape({
        name: PropTypes.string,
        queryStringKey: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string,
            name: PropTypes.string,
            checked: PropTypes.bool,
          })
        ),
      }),
      campaign: PropTypes.shape({
        name: PropTypes.string,
        queryStringKey: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string,
            name: PropTypes.string,
            checked: PropTypes.bool,
          })
        ),
      }),
    }),
    stringFilters: PropTypes.shape({
      searchId: PropTypes.shape({
        name: PropTypes.string,
        queryStringKey: PropTypes.string,
        value: PropTypes.string,
      }),
      localities: PropTypes.shape({
        name: PropTypes.string,
        queryStringKey: PropTypes.string,
        value: PropTypes.arrayOf(PropTypes.string),
        options: PropTypes.arrayOf(PropTypes.string),
      }),
      orderBy: PropTypes.shape({
        name: PropTypes.string,
        queryStringKey: PropTypes.string,
        value: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
    numberFilters: PropTypes.shape({
      minValue: PropTypes.shape({
        name: PropTypes.string,
        queryStringKey: PropTypes.string,
        value: PropTypes.number,
      }),
      maxValue: PropTypes.shape({
        name: PropTypes.string,
        queryStringKey: PropTypes.string,
        value: PropTypes.number,
      }),
      page: PropTypes.shape({
        queryStringKey: PropTypes.string,
        value: PropTypes.number,
      }),
    }),
  }).isRequired,

  className: PropTypes.string,
  type: PropTypes.oneOf(['filterModal', 'alertModal', 'listing']).isRequired,
  customFilters: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

Filter.defaultProps = {
  className: '',
  customFilters: false,
  children: <></>,
}

export default Filter
