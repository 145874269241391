export default {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1500 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1500, min: 1025 },
    items: 3,
    partialVisibilityGutter: 70,
  },
  tabletPro: {
    breakpoint: { max: 1025, min: 1024 },
    items: 2,
    partialVisibilityGutter: 100,
  },
  tablet: {
    breakpoint: { max: 1023, min: 464 },
    items: 2,
    partialVisibilityGutter: -30,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 40,
  },
}
