import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './SelectSorting.module.scss'

const SelectSorting = ({
  type,
  style,
  iconPosition,
  fontSize,
  value,
  onChange,
  options,
  color,
  id,
  onBlur,
}) => {
  return (
    <span>
      <select
        id={id}
        value={value}
        onChange={onChange}
        className={classNames(
          styles[type],
          styles[`direction-${iconPosition}`],
          styles[`font-${fontSize}`],
          {
            [styles[`${color}Background`]]: type === 'filled',
            [styles[`${color}Border`]]: type === 'outline',
            [styles.paddingButton]: iconPosition,
          }
        )}
        style={style}
        onBlur={onBlur}
      >
        {options.map((option, index) => (
          <option key={index.toString()} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
    </span>
  )
}

SelectSorting.propTypes = {
  color: PropTypes.string,
  type: PropTypes.oneOf(['outline', 'filled']),
  style: PropTypes.objectOf(PropTypes.shape({})),
  iconPosition: PropTypes.oneOf(['right', 'left', '']),
  fontSize: PropTypes.oneOf(['xsmall', 'small', 'medium']),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  id: PropTypes.string,
  onBlur: PropTypes.func,
}

SelectSorting.defaultProps = {
  color: 'primary',
  style: {},
  type: 'outline',
  iconPosition: 'right',
  fontSize: 'small',
  id: 'select',
  onBlur: () => {},
}

export default React.memo(SelectSorting)
