import React from 'react'
import {
  toastSuccess,
  toastError,
} from 'components/ToastComponent/ToastComponent'
import { MessageForm } from 'components'
import { useMessageForm } from 'store'
import { getUtms } from 'utils/Utms'
import {
  setCustomerInfoLocalStorage,
  getCustomerInfoLocalStorage,
} from 'utils/customerInfoLocalStorage'

const MessageFormContainer = () => {
  const { getForm, sendForm, setField, clearForm } = useMessageForm()
  const { form, sendFormLoading, sendFormError } = getForm()

  const handleConfirm = async () => {
    const utms = getUtms()
    const buyPartnership = /Compra de imóvel/i.test(form.subject.trim())

    const payload = { ...utms }
    payload.email = form.email?.trim()
    payload.message = form.message?.trim()
    payload.name = form.name?.trim()
    payload.subject = buyPartnership
      ? `${form.subject?.trim()} - ${form.property_locality?.trim()}`
      : form.subject?.trim()
    payload.category = form.category?.trim()
    payload.phone = form.phone.replace(/[^0-9]/g, '')

    setCustomerInfoLocalStorage({
      email: payload.email,
      name: payload.name,
      ...getCustomerInfoLocalStorage(),
    })

    await sendForm(payload)

    if (sendFormError) {
      clearForm()
      return toastError('Houve um erro no envio da mensagem.')
    }

    return toastSuccess('Contato recebido com sucesso!')
  }

  return (
    <MessageForm
      sendForm={handleConfirm}
      setField={setField}
      values={form}
      isLoading={sendFormLoading}
    />
  )
}

export default MessageFormContainer
