import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './SaleValue.module.scss'

const SaleValue = ({ value, asterisk, className, isCampaign }) => {
  return (
    <strong
      className={classNames(styles.span, className, {
        [styles.campaign]: isCampaign,
      })}
    >
      {value
        ? `R$ ${parseInt(value, 10).toLocaleString('pt-br')}`
        : 'Sob consulta'}
      {asterisk && value ? '*' : ''}
    </strong>
  )
}

SaleValue.propTypes = {
  value: PropTypes.number.isRequired,
  asterisk: PropTypes.bool,
  className: PropTypes.string,
  isCampaign: PropTypes.bool.isRequired,
}

SaleValue.defaultProps = {
  asterisk: false,
  className: '',
}

export default React.memo(SaleValue)
