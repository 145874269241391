import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const Balcony = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="balcony"
          d="M25.625 17.969v-6.344C25.625 6.318 21.307 2 16 2s-9.625 4.318-9.625 9.625v6.344H2v8.75A3.285 3.285 0 0 0 5.281 30H26.72A3.285 3.285 0 0 0 30 26.719v-8.75h-4.375zm-12.797 2.187h2.078v7.657h-2.078v-7.657zm-2.187 7.657H8.562v-7.657h2.079v7.657zm6.453-7.657h2.078v7.657h-2.078v-7.657zm4.265 0h2.078v7.657H21.36v-7.657zm-4.265-2.187v-4.922h6.343v4.922h-6.343zm6.304-7.11h-6.304V4.268a7.453 7.453 0 0 1 6.304 6.591zm-8.492-6.591v6.591H8.602a7.453 7.453 0 0 1 6.304-6.591zm-6.344 8.779h6.344v4.922H8.562v-4.922zM4.189 26.719v-6.563h2.187v7.657H5.281a1.095 1.095 0 0 1-1.093-1.094zm23.625 0c0 .603-.491 1.093-1.094 1.093h-1.094v-7.656h2.188v6.563z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#balcony" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#balcony"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Balcony.propTypes = {
  ...propTypesSvg,
}

Balcony.defaultProps = {
  ...defaultPropTypesSvg,
}

export default Balcony
