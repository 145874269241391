import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const EatArea = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="eatArea"
          d="M28.66 21.833c0 .625-.501 1.134-1.12 1.134H4.515c-.62 0-1.12-.509-1.12-1.134 0-6.095 4.214-11.28 9.963-12.519a2.822 2.822 0 0 1-.12-.814c0-1.543 1.237-2.8 2.761-2.8s2.76 1.257 2.76 2.8c0 .279-.04.552-.119.814 5.785 1.245 10.018 6.447 10.018 12.52zM15.48 8.5c0 .295.235.533.52.533.285 0 .52-.238.52-.533a.528.528 0 0 0-.52-.533.528.528 0 0 0-.52.533zm.52 2.8c-5.31 0-9.744 4.095-10.305 9.4H26.36c-.563-5.305-5.021-9.4-10.359-9.4zm13.18 13.2c.62 0 1.12.508 1.12 1.133s-.5 1.134-1.12 1.134H2.82c-.62 0-1.12-.509-1.12-1.134 0-.625.5-1.133 1.12-1.133h26.36z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#eatArea" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#eatArea"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

EatArea.propTypes = {
  ...propTypesSvg,
}

EatArea.defaultProps = {
  ...defaultPropTypesSvg,
}

export default EatArea
