export default $messageForm => {
  const getForm = () => {
    const { sendFormLoading, sendFormError, form } = $messageForm

    return {
      sendFormLoading,
      sendFormError,
      form,
    }
  }

  return { getForm }
}
