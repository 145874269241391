import { toastError } from 'components/ToastComponent/ToastComponent'
import { alternativeFormater } from 'utils/formatNumber'
import { setCustomerInfoLocalStorage } from 'utils/customerInfoLocalStorage'
import * as providers from './provider'

export default (setSimulatorForm, initialState) => {
  const setOpenModal = async () => {
    setSimulatorForm(prev => ({ ...prev, openModal: !prev.openModal }))
  }

  const setField = (field, value, formField = false) => {
    if (formField) {
      setSimulatorForm(prev => ({
        ...prev,
        [field]: value,
      }))
    }
    setSimulatorForm(prev => ({
      ...prev,
      form: { ...prev.form, [field]: value },
    }))
  }

  const sendForm = async form => {
    setSimulatorForm(prev => ({
      ...prev,
      loading: !prev.loading,
    }))

    setCustomerInfoLocalStorage({
      email: form.email,
      name: form.name,
      phone: form.phone,
    })

    const formatedFormValues = {
      ...form,
      entrance_value: alternativeFormater(form.entrance_value),
      balance_amount: alternativeFormater(form.balance_amount),
    }

    const [err, res] = await providers.sendForm(formatedFormValues)

    if (!res || err) {
      toastError(
        'Não foi possível enviar sua simulação de financiamento. Verifique os dados adicionados novamente'
      )
      setSimulatorForm(prev => ({
        ...prev,
        loading: !prev.loading,
      }))

      return false
    }

    setSimulatorForm(prev => ({
      ...prev,
      form: { ...initialState.form },
      succeeded: true,
      loading: !prev.loading,
    }))

    return res.data
  }

  const resetForm = async () => {
    setSimulatorForm(() => ({
      ...initialState,
    }))
  }

  return {
    setOpenModal,
    setField,
    sendForm,
    resetForm,
  }
}
