import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'components'
import commaNumber from 'comma-number'
import Icon, { ICONS_TYPES } from 'components/Icon/Icon'
import styles from './MoreFeatures.module.scss'

const MoreFeatures = ({ number, feature, icon }) => {
  if (!number || number === '00' || number === '0') {
    return null
  }

  const numberReplace = number.toString().replace('.', ',')
  const formattedNumber = commaNumber(numberReplace, '.', ',')

  return (
    <div className={styles.container}>
      <span className={styles.icon}>
        <Icon type={icon} color="primaryColor" />
      </span>
      <span className={styles.text}>
        <Text fontSize="small">
          {`${formattedNumber}${icon === 'footage' ? ' m²' : ''}`}
        </Text>
        <Text className={styles.label}>{feature}</Text>
      </span>
    </div>
  )
}

MoreFeatures.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  feature: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(ICONS_TYPES).isRequired,
}

export default React.memo(MoreFeatures)
