export const activeCountDown = (configurations, countDownType) => {
  const campaigns = {}

  Object.entries(configurations).forEach(
    ([key, { countDowns, isActive, isPreLaunch }]) => {
      if (!countDowns || !countDowns.length) return

      countDowns.forEach(countDown => {
        if (countDown.location !== countDownType || (!isActive && !isPreLaunch))
          return

        campaigns[key] =
          (isActive || isPreLaunch) && countDown.location === countDownType
      })
    }
  )
  return campaigns
}

export const countDownsConfigurations = campaignConfigurations => {
  const timers = []

  Object.entries(campaignConfigurations).forEach(([key, { countDowns }]) => {
    countDowns.forEach(countDown => {
      if (!countDown?.location) return

      const shouldRender = (
        state,
        typeCountDown,
        nameCampaign,
        campaignsData
      ) => {
        const generalValidate =
          state[key] && countDown.location === typeCountDown

        if (typeCountDown === 'detalhe_imovel') {
          return (
            generalValidate &&
            campaignsData &&
            campaignsData.some(camp => new RegExp(key, 'i').test(camp.codigo))
          )
        }

        return generalValidate && new RegExp(key, 'i').test(nameCampaign)
      }

      timers.push({
        name: key,
        show: (state, typeCountDown, nameCampaign, campaignsData) =>
          shouldRender(state, typeCountDown, nameCampaign, campaignsData),
        title: countDown.text,
        timer: countDown.dateHour,
      })
    })
  })

  return timers
}
