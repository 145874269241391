import {
  toastError,
  toastSuccess,
} from 'components/ToastComponent/ToastComponent'
import { gaEvent } from 'utils/ga'
import * as providers from './provider'
import { serializeProperty } from './selectors'

export default ({ setProperty, setError }) => {
  const setCurrentProperty = (newId, preview, walletId) =>
    setProperty(prev => ({ ...prev, id: newId, preview, walletId }))

  const setPropertyData = data => setProperty(prev => ({ ...prev, data }))

  const setPropertyLoading = value =>
    setProperty(prev => ({ ...prev, loadingProperty: value }))

  const clearPropertyData = () => {
    setProperty(prev => ({
      ...prev,
      id: null,
      data: null,
      loadingProperty: false,
    }))
  }

  const fetchProperty = async (id, preview, walletId) => {
    const data = await providers.fetchProperty(id, preview, walletId)

    const serializedData = data && !data.error && serializeProperty(data)

    if (!serializedData && data && data.error) {
      toastError('Erro ao buscar informações.')
      return setError(data.code)
    }

    return setPropertyData(serializedData)
  }

  const sendAuctionForm = async (
    propertyId,
    email,
    propertySmallId,
    checkOffers,
    checkOffersResale
  ) => {
    const [err, res] = await providers.sendAuctionForm({
      propertyId,
      email,
      checkOffers,
      checkOffersResale,
    })
    if (!res || err) {
      toastError('Houve um erro no envio da mensagem.')
      return false
    }
    gaEvent({
      category: 'L_leilao',
      action: 'submit',
      label: propertySmallId,
    })
    toastSuccess('Recebemos seu contato com sucesso!')
    return res
  }

  const setPropertyIsPreActive = value => {
    setProperty(prev => ({ ...prev, propertyIsPreActive: value }))
  }

  return {
    setCurrentProperty,
    fetchProperty,
    setPropertyLoading,
    clearPropertyData,
    sendAuctionForm,
    setPropertyIsPreActive,
  }
}
