/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Swiper from 'react-id-swiper'
import { Lazy } from 'swiper/dist/js/swiper'
import classNames from 'classnames'
import Arrow from './Arrow'
import Slide from './Slide'
import styles from './Carousel.module.scss'

const Carousel = props => {
  return (
    <>
      <DesktopCarousel {...props} />
      <MobileCarousel {...props} />
    </>
  )
}

const DesktopCarousel = ({ images, onChange, active, noPhoto }) => {
  const previousSlide = () => {
    const lastIndex = images.length - 1
    const shouldResetIndex = active === 0
    const index = shouldResetIndex ? lastIndex : active - 1
    onChange(index)
  }

  const nextSlide = () => {
    const lastIndex = images.length - 1
    const shouldResetIndex = active === lastIndex
    const index = shouldResetIndex ? 0 : active + 1
    onChange(index)
  }

  const { alt, url } = images[active]
  const multiImages = images.length > 1

  return (
    <div className={styles.carousel}>
      {multiImages && <Arrow arrowDirection="left" onClick={previousSlide} />}
      <Slide url={url} alt={alt} noPhoto={noPhoto} />
      {multiImages && <Arrow arrowDirection="right" onClick={nextSlide} />}
    </div>
  )
}

const MobileCarousel = ({ images, noPhoto }) => {
  const [swiper, updateSwiper] = useState(null)
  const [currentIndex, updateCurrentIndex] = useState(0)

  const params = {
    modules: [Lazy],
    preloadImages: false,
    lazy: true,
    wrapperClass: styles.swiper,
    slideClass: styles.slide,
    containerClass: styles.containerSlide,
    slidesPerView: 1,
    loop: false,
    getSwiper: updateSwiper,
  }

  const updateIndex = useCallback(() => updateCurrentIndex(swiper.realIndex), [
    swiper,
  ])

  useEffect(() => {
    if (swiper !== null) {
      swiper.on('slideChange', updateIndex)
    }

    return () => {
      if (swiper !== null) {
        swiper.off('slideChange', updateIndex)
      }
    }
  }, [swiper, updateIndex, currentIndex])

  return (
    <div className={styles.carouselMobile}>
      <div className={styles.wrapper}>
        <Swiper {...params}>
          {images.map((img, index) => (
            <img
              key={index.toString()}
              loading="lazy"
              src="data/image"
              data-src={img.url}
              alt={img.alt}
              noPhoto={noPhoto}
              className={classNames(styles.slide, 'swiper-lazy')}
            />
          ))}
        </Swiper>
      </div>
      <div className="swiper-pagination" />

      {images.length > 1 && (
        <div className={classNames(styles.pagination, styles.p6)}>
          <ul
            className="paginationInner"
            style={{
              position: 'absolute',
              left: currentIndex === 0 ? 30 : 30 + -(currentIndex * 15),
            }}
          >
            {images.map((_, index) => (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                key={index.toString()}
                href="#"
                className={classNames({
                  [styles['is-active']]: index === currentIndex,
                })}
              >
                <li />
              </a>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

MobileCarousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ).isRequired,
  noPhoto: PropTypes.string.isRequired,
}

DesktopCarousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  active: PropTypes.number.isRequired,
  noPhoto: PropTypes.string.isRequired,
}

export default React.memo(Carousel)
