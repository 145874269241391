import React from 'react'
import { Link } from '@reach/router'
import logo from 'assets/images/logo-resale.png'
import pagimovelLogo from 'assets/images/logo-pagimovel.png'
import { Title, Text, Icon } from 'components'
import classNames from 'classnames'
import { getCNPJ } from '../../lib/resaleInfos'
import { onPath } from '../../utils/onPath'
import styles from './Footer.module.scss'
import { socialNetworks, links } from './FooterData'

function handleLinks(pageLink) {
  if (pageLink.link.includes('blog') || pageLink.link.includes('pagimovel')) {
    return (
      <a href={pageLink.link} target="_blank" rel="noopener noreferrer">
        {pageLink.name}
      </a>
    )
  }
  if (pageLink.name.includes('funciona')) {
    return <a href={`/${pageLink.link}`}>{pageLink.name}</a>
  }
  if (pageLink.name.includes('parcerias')) {
    return <a href={`/${pageLink.link}`}>{pageLink.name}</a>
  }
  if (pageLink.link.includes('/termos')) {
    return <Link to={pageLink.link}>{pageLink.name}</Link>
  }
  if (pageLink.name.includes('Canal de Ética')) {
    return (
      <a href={pageLink.link} target="_blank" rel="noreferrer">
        {pageLink.name}
      </a>
    )
  }

  return <a href={pageLink.link}>{pageLink.name}</a>
}

const Footer = () => {
  const currentYear = new Date().getFullYear()

  const isSimuladorPath = onPath('/simulador')

  return (
    <footer
      className={classNames({
        [styles.footer]: !isSimuladorPath,
        [styles.footerSimulator]: isSimuladorPath,
      })}
    >
      <div className={styles.top}>
        <figure className={styles.container}>
          <div className={styles.logoContainer}>
            <img
              loading="lazy"
              src={logo}
              alt="Logo Portal Resale"
              title="Logo Portal Resale"
              className={styles.logo}
            />
          </div>
          <figcaption className={styles.companyInformation}>
            <div>
              <Text color="darkBlueColor" fontSize="medium">
                A Resale, o maior outlet imobiliário do Brasil, é a empresa
                responsável pelo site emgeaimoveis.com.br. Os imóveis para venda
                são de propriedade da Emgea (Empresa Gestora de Ativos) do
                Governo Federal, disponíveis para compra direta pelo consumidor
                final.
              </Text>
            </div>
          </figcaption>
        </figure>
        <ul className={styles.majorList}>
          {links.map(item => (
            <li key={item.title}>
              <Title type="h3" fontSize="large">
                {item.title}
              </Title>
              <ul className={styles.linksList}>
                {item.links.map(pageLink => (
                  <li key={pageLink.name}>{handleLinks(pageLink)}</li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.bottom}>
        <Text color="darkBlueColor" fontSize="medium">
          {`Resale.com.br - Copyright `}
          {currentYear}
          <br />
          <span className={styles.cnpj}>{getCNPJ()}</span>
        </Text>

        <div className={styles.rightContainer}>
          <figure className={styles.pagimovel}>
            <figcaption>
              <Text color="darkBlueColor" fontSize="medium">
                Compre com
              </Text>
            </figcaption>
            <button
              type="button"
              onClick={() => {
                window.location.href = 'https://clientes.pagimovel.com.br'
              }}
            >
              <img
                loading="lazy"
                src={pagimovelLogo}
                alt="pagimovel logo"
                className={styles.pagimovelLogo}
              />
            </button>
          </figure>

          <div className={styles.socialNetworks}>
            {socialNetworks.map(({ link, icon }) => (
              <a
                href={link}
                className={styles.linkNetwork}
                key={link}
                target="_blank"
                rel="noopener noreferrer"
                title={`Link para o ${icon} da Resale`}
              >
                <Icon type={icon} size={32} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default React.memo(Footer)
