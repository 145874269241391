import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const Facebook = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="facebook"
          d="M18.428 16.123h-1.677v6.146h-2.542v-6.146H13v-2.16h1.209v-1.398c0-1 .475-2.565 2.564-2.565l1.883.008v2.097H17.29c-.224 0-.54.112-.54.588v1.272h1.9l-.222 2.158zM16 30C8.268 30 2 23.732 2 16S8.268 2 16 2s14 6.268 14 14-6.268 14-14 14zm0-2.2c6.517 0 11.8-5.283 11.8-11.8S22.517 4.2 16 4.2 4.2 9.483 4.2 16 9.483 27.8 16 27.8z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#facebook" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#facebook"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Facebook.propTypes = {
  ...propTypesSvg,
}

Facebook.defaultProps = {
  ...defaultPropTypesSvg,
}

export default Facebook
