import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const livingRoom = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="livingRoom"
          d="M26.773 12.896V7.469A5.475 5.475 0 0 0 21.305 2h-10.61a5.475 5.475 0 0 0-5.468 5.469v5.427A4.328 4.328 0 0 0 2 17.076v6.453a4.328 4.328 0 0 0 3.227 4.18v1.197a1.094 1.094 0 1 0 2.187 0V27.85h17.172v1.057a1.094 1.094 0 1 0 2.187 0v-1.198A4.328 4.328 0 0 0 30 23.528v-6.453a4.328 4.328 0 0 0-3.227-4.18zM10.695 4.187h10.61a3.285 3.285 0 0 1 3.28 3.282v5.427a4.328 4.328 0 0 0-3.225 4.161H10.64a4.328 4.328 0 0 0-3.226-4.161V7.469a3.285 3.285 0 0 1 3.281-3.282zm-.054 15.058h10.718v6.416H10.641v-6.416zm-6.454 4.284v-6.453c0-1.177.957-2.133 2.133-2.133 1.176 0 2.133.956 2.133 2.133v8.586H6.32a2.135 2.135 0 0 1-2.133-2.133zm23.625 0a2.135 2.135 0 0 1-2.132 2.133h-2.133v-8.586c0-1.177.957-2.133 2.133-2.133 1.176 0 2.133.956 2.133 2.133v6.453z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#livingRoom" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#livingRoom"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

livingRoom.propTypes = {
  ...propTypesSvg,
}

livingRoom.defaultProps = {
  ...defaultPropTypesSvg,
}

export default livingRoom
