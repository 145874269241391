import { useState } from 'react'
import { getCustomerInfoLocalStorage } from 'utils/customerInfoLocalStorage'
import useCreateStore from '../../lib/useCreateStore'
import simulatorFormActions from './actions'
import simulatorFormSelectors from './selectors'

const initialState = {
  form: {
    property_id: '',
    name: getCustomerInfoLocalStorage().name || '',
    email: getCustomerInfoLocalStorage().email || '',
    phone: getCustomerInfoLocalStorage().phone || '',
    entrance_value: '',
    balance_amount: '',
    parcel_quantity: '',
    first_parcel_amount: '',
    last_parcel_amount: '',
  },
  loading: false,
  openModal: false,
  succeeded: false,
}

const SimulatorFormStore = useCreateStore(() => {
  const [$simulatorForm, setSimulatorForm] = useState(initialState)

  const actions = simulatorFormActions(setSimulatorForm, initialState)
  const selectors = simulatorFormSelectors($simulatorForm)

  return { $simulatorForm, ...actions, ...selectors }
})

export const useSimulatorForm = () => SimulatorFormStore()
export const SimulatorFormContext = SimulatorFormStore.Context
export const SimulatorFormProvider = SimulatorFormStore.Provider
