import React from 'react'
import PropTypes from 'prop-types'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const Clear = ({ viewBox, color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="clear"
          d="M14.5 14.5v-7a1.5 1.5 0 0 1 3 0v7h7a1.5 1.5 0 0 1 0 3h-7v7a1.5 1.5 0 0 1-3 0v-7h-7a1.5 1.5 0 0 1 0-3h7z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#clear" />
        </mask>
        <use
          className={styles[color]}
          transform="rotate(45 16 16)"
          xlinkHref="#clear"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Clear.propTypes = {
  ...propTypesSvg,
  size: PropTypes.number,
}

Clear.defaultProps = {
  ...defaultPropTypesSvg,
  size: 'auto',
}

export default Clear
