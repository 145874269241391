import {
  deviceDetect,
  isTablet,
  isSmartTV,
  isAndroid,
  isWinPhone,
  isMacOs,
  osVersion,
  osName,
  browserVersion,
  browserName,
  isIPad13,
  isMobile,
  isIOS,
} from 'react-device-detect'

const checkDeviceType = () => {
  if (isMobile) return 'mobile'
  if (isTablet) return 'tablet'
  if (isSmartTV) return 'smartTv'
  return 'desktop'
}

const checkDevice = () => {
  if (isAndroid) return 'android'
  if (isMacOs) return 'macOs'
  if (isWinPhone) return 'windowsPhone'
  if (isIPad13) return 'Ipad'
  if (isIOS) return 'IOS'
  return 'desktop'
}

const fullDeviceAndOsInfo = {
  deviceType: checkDeviceType(),
  device: checkDevice(),
  osName,
  osVersion,
  browserName,
  browserVersion,
  moreInfo: deviceDetect(),
}

const origin_device = checkDeviceType()

export { fullDeviceAndOsInfo, origin_device, isMobile }
