import React, { useState } from 'react'
import WebFont from 'webfontloader'
import { Router, Redirect } from '@reach/router'
import { Helmet } from 'react-helmet'
import StateProviders from 'lib/StateProviders'
import { SimulatorFormProvider, ProvidersProvider } from 'store'
import isPreRendering from 'utils/isPreRendering'
import {
  SearchPage,
  PropertyPage,
  HomePage,
  PartnershipPage,
  FAQPage,
  NotFoundPage,
  ContactEmailPage,
  TermsPage,
  MaintenancePage,
  SimulatorPage,
  ProvidersPage,
} from 'pages'
import { ModalCampaign } from 'components'
import { LeadModalContainer } from 'containers'
import { useCleanBotHistory } from './hooks/useCleanBotHistory'
import { useClearPropertyReferenceStorage } from './hooks/useClearPropertyReferenceStorage'

const RedirectSantander = () => {
  window.location.href = 'https://santander.resale.com.br'

  return null
}

export default () => {
  const [openModal, setOpenModal] = useState(true)
  const [openLeadModal, setOpenLeadModal] = useState(false)
  const currentPath = window.location.pathname
  // eslint-disable-next-line
  console.info(
    `%cBuild version: ${process.env.REACT_APP_VERSION}`,
    'background: #222; color: #fff; padding: 10px 10vw;'
  )

  WebFont.load({
    google: {
      families: [
        'Titillium Web:200,200i,300,300i,400,400i,600,600i,700,700i,900',
      ],
    },
  })

  useCleanBotHistory()
  useClearPropertyReferenceStorage()

  return (
    <>
      <Helmet>
        <title>Compre direto do Banco com 70% de desconto</title>
        <meta
          property="og:title"
          content="Compre direto do Banco com 70% de desconto"
        />
        <meta property="og:site_name" content="Imóveis Emgea" />
        <meta property="og:url" content="https://seuimovelbb.com.br" />
        <meta
          property="og:description"
          content="Descubra o que são os imóveis com desconto do Banco do Brasil!"
        />
      </Helmet>
      {process.env.REACT_APP_MAINTENANCE ? (
        <MaintenancePage />
      ) : (
        <StateProviders>
          <SimulatorFormProvider>
            <ProvidersProvider>
              <Router>
                <HomePage
                  path="/"
                  opened={openLeadModal}
                  setOpened={setOpenLeadModal}
                />
                <Redirect from="/home" to="/" noThrow />
                <Redirect from="/pre-lancamento" to="/" noThrow />

                <FAQPage path="/perguntas-frequentes" />

                <SearchPage path="/busca/:slug/:discount" />
                <SearchPage path="/busca/:slug/" />
                <SearchPage path="/busca/" />

                <SearchPage path="/busca/*" />

                <PropertyPage path="/imovel/:id" />
                <PropertyPage path="/imovel/:id/:walletId" />
                <PropertyPage path="/imovel/:id/preview" preview />
                <PropertyPage path="/imovel/:state/:city/:slug/:id" />
                <PropertyPage
                  path="/imovel/:state/:city/:slug/:id/preview"
                  preview
                />
                <PropertyPage path="/imovel/:state/:city/:id" />
                <PropertyPage path="/imovel/:state/:city/:id/preview" preview />
                <PropertyPage path="/imovel/:state/:id" />
                <PropertyPage path="/imovel/:state/:id/preview" preview />

                <PartnershipPage path="/seja-um-parceiro" />
                <PartnershipPage path="/seja-um-parceiro" preview />

                <ProvidersPage path="/fornecedores" />
                <ProvidersPage path="/fornecedores" preview />

                <ContactEmailPage path="/fale-conosco" />
                <TermsPage path="/termos" />
                <TermsPage path="/termos/:term" />
                <SimulatorPage path="/simulador" noModal />
                <NotFoundPage default />
                <RedirectSantander path="/santander" />
              </Router>
              {!isPreRendering() && currentPath === '/' && (
                <>
                  <ModalCampaign
                    opened={openModal}
                    closeModal={setOpenModal}
                    onClick={() => setOpenLeadModal(true)}
                  />
                  {openLeadModal && (
                    <LeadModalContainer
                      opened={openLeadModal}
                      closeModal={() => setOpenLeadModal(false)}
                      bannerBlackout
                    />
                  )}
                </>
              )}
            </ProvidersProvider>
          </SimulatorFormProvider>
        </StateProviders>
      )}
    </>
  )
}
