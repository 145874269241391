export const CAIXA_KEY = 'f0bad832-8ec9-aba2-b72d-5909efb6e044'

export const salesTypeDictionary = {
  'Venda Direta': {
    labelValor: 'venda',
    valorPrincipal: 'valor_venda',
  },
  'Leilão - Santander': {
    labelValor: 'venda',
    valorPrincipal: 'valor_venda',
  },
  'Leilão - Caixa': {},
  'Concorrência Direta': {},
  'Terreno Sob Consulta': {},
}

export const discountText = value =>
  `* Imóvel tem ${value}% de desconto para pagamento à vista;`

export const formatDate = (
  competitionDate,
  competitionHour,
  isPreActive = false
) => {
  const [day, month, year] = competitionDate.split('/')
  if (isPreActive) {
    return `${year}-${month}-${day}T${competitionHour}.00-0300`
  }
  return `${year}-${month}-${day}T${competitionHour}:00.00-0300`
}
