export const formatBRL = (value, showDecimal = true) => {
  const formater = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })

  const formatedNumber = formater.format(Number(value))
  if (!showDecimal) {
    return formatedNumber.substring(0, formatedNumber.length - 3)
  }

  return formatedNumber
}

export const alternativeFormater = num => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
    .format(num)
    .split('R$')[1]
    .trim()
}
