import PropTypes from 'prop-types'

export const propTypesSvg = {
  viewBox: PropTypes.string,
  color: PropTypes.oneOf([
    'primaryColor',
    'secondColor',
    'darkColor',
    'whiteColor',
    'greenColor',
    'grayColor',
  ]),
}

export const defaultPropTypesSvg = {
  viewBox: '0 0 32 32',
  color: 'primaryColor',
}
