import React from 'react'
import PropTypes from 'prop-types'
import { Title } from 'components'
import { useFilter, useList } from 'store'
import Filter from './Filter/Filter'
import styles from './FilterContainer.module.scss'

const FilterContainer = ({ type, customFilters, showHeader, className }) => {
  const { isListLoading } = useList()
  const { clearFilters, updateQueryString } = useFilter()

  const handleClearFilters = () => {
    clearFilters()
    updateQueryString()
  }

  return (
    <div>
      <Filter
        className={className || styles[`wrapper-${type}`]}
        blocked={isListLoading()}
        type={type}
        customFilters={customFilters}
      >
        {showHeader && (
          <header className={styles.header}>
            <Title type="h3" font="small">
              Refinar minha busca
            </Title>
            <button type="button" onClick={handleClearFilters}>
              Limpar filtros
            </button>
          </header>
        )}
      </Filter>
    </div>
  )
}

FilterContainer.propTypes = {
  type: PropTypes.oneOf(['filterModal', 'alertModal', 'listing']).isRequired,
  customFilters: PropTypes.arrayOf(PropTypes.string),
  showHeader: PropTypes.bool,
  className: PropTypes.string,
}

FilterContainer.defaultProps = {
  customFilters: false,
  showHeader: false,
  className: '',
}

export default FilterContainer
