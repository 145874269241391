import React from 'react'
import { Button, Icon, Text } from 'components'
import PropTypes from 'prop-types'
import isIos from 'utils/isIos'
import styles from './Collapse.module.scss'

const Collapse = ({ onClick, checked, isDetails }) => (
  <Button
    color="primaryColor"
    fontSize="regular"
    bold
    type="text"
    onClick={onClick}
  >
    <Text color="primaryColor" fontSize="medium" bold>
      {isDetails
        ? 'Entenda como funciona'
        : `Ver ${checked ? 'menos' : 'mais'} características`}
    </Text>
    <Icon
      id="icon"
      type="arrow"
      size={24}
      color="primaryColor"
      className={
        isIos()
          ? styles[checked ? 'upArrowIphone' : 'downArrowIphone']
          : styles[checked ? 'upArrow' : 'downArrow']
      }
    />
  </Button>
)

Collapse.propTypes = {
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  isDetails: PropTypes.bool,
}

Collapse.defaultProps = {
  isDetails: false,
}

export default React.memo(Collapse)
