import React from 'react'
import { Button, Modal, Text } from 'components'
import PropTypes from 'prop-types'
import styles from './CookiesMobile.module.scss'

const CookiesMobile = ({ acceptCookies, cookies, setCookies, hideCookies }) => {
  if (!cookies || hideCookies) return null

  return (
    <div className={styles.cookiesMobile}>
      <Modal
        opened={cookies}
        closeModal={() => setCookies(false)}
        backdropClassName={styles.cookiesBackdrop}
        className={styles.cookiesModal}
        hideCloseBtn
      >
        <div className={styles.container}>
          <Text fontSize="regular" color="lightTextColor">
            A Resale utiliza cookies e outras tecnologias para otimizar as
            funcionalidades do site e melhorar sua experiência de compra.
          </Text>
          <Text fontSize="regular" color="lightTextColor">
            Ao continuar navegando você concorda com nossa política de
            privacidade.
            <a href="/termos">Saiba mais.</a>
          </Text>
          <Button onClick={acceptCookies} className={styles.button}>
            CONCORDAR E FECHAR
          </Button>
          <Button
            onClick={() => setCookies(false)}
            type="text"
            className={styles.ghostButton}
          >
            Deixar para depois
          </Button>
        </div>
      </Modal>
    </div>
  )
}

CookiesMobile.propTypes = {
  acceptCookies: PropTypes.func.isRequired,
  setCookies: PropTypes.func.isRequired,
  cookies: PropTypes.bool.isRequired,
  hideCookies: PropTypes.bool.isRequired,
}

export default React.memo(CookiesMobile)
