import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'components'
import classNames from 'classnames'
import Switch from 'react-switch'
import styles from './Toggle.module.scss'

const Toggle = ({ option, optionPosition, checked, onChange, id }) => (
  // eslint-disable-next-line jsx-a11y/label-has-for
  <label
    htmlFor={id}
    className={classNames(styles.container, styles[`option-${optionPosition}`])}
  >
    <Text bold={checked}>{option}</Text>
    <Switch
      id={id}
      onChange={onChange}
      checked={checked}
      uncheckedIcon={false}
      checkedIcon={false}
      onColor="#00af93"
      height={22}
      width={40}
    />
  </label>
)

Toggle.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  option: PropTypes.string.isRequired,
  optionPosition: PropTypes.oneOf(['left', 'right', 'right-aligned']),
  id: PropTypes.string,
}
Toggle.defaultProps = {
  optionPosition: 'left',
  checked: false,
  id: '',
}

export default React.memo(Toggle)
