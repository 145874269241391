/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import PropTypes from 'prop-types'
import applyMask from 'utils/applyMask'
import mask from 'utils/masks'
import capitalize from 'utils/capitalize'

import { Text } from 'components'

import styles from './RangeValueInput.module.scss'

const enterClick = (e, onBlur) => {
  if (e.keyCode === 13) {
    onBlur(e.target.value)
  }
}

const reverseString = value => {
  return value
    .toString()
    .split('')
    .reverse()
    .join('')
}

const applyMaskNumber = value => {
  const reverseArray = applyMask(reverseString(value), mask.number)
  return reverseString(reverseArray)
}

/**
 * Receives a value and applies mask and adds ,00 to it's end
 * @param {string} value Value do be parsed for display
 * @returns string
 * @author Leandro Parisi
 */
const parseDisplayValue = value => `${applyMaskNumber(value)},00`

const RangeValuesInput = ({
  id,
  value,
  min,
  max,
  step,
  onChange,
  onBlur,
  dataTestId,
}) => {
  const inputType = id.split('-')[0]
  const limitValue = inputType === 'min' ? min : max
  return (
    <div className={styles.wrapper}>
      <Text color="grayColor" fontSize="xsmall">
        {`${capitalize(inputType)}. R$ ${parseDisplayValue(limitValue)}`}
      </Text>
      <div className={styles.wrapper}>
        <label id={id} htmlFor={id} className={styles.label}>
          R$
        </label>
        <input
          type="text"
          id={id}
          className={styles.input}
          onKeyUp={e => enterClick(e, onBlur)}
          onBlur={() => onBlur(value)}
          onChange={e => onChange(e.target.value)}
          value={`${applyMaskNumber(value)}${max === value ? '+' : ''}`}
          minLength={6}
          maxLength={10}
          step={step}
          data-testid={dataTestId}
        />
      </div>
    </div>
  )
}

RangeValuesInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  step: PropTypes.number,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  dataTestId: PropTypes.string,
}

RangeValuesInput.defaultProps = {
  step: 1,
  onBlur: () => {},
  dataTestId: '',
}

export default React.memo(RangeValuesInput)
