import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { BaseLayout } from 'templates'
import { Title } from 'components'
import { setUtms } from 'utils/Utms'
import PropTypes from 'prop-types'

import { usePageView } from 'hooks/useGAPageView'
import styles from './FAQ.module.scss'
import data from './faqData'
import FAQCard from './FAQCard.jsx/FAQCard'

export default function FAQ({ location: { search } }) {
  const [toggleStates, setToggle] = React.useState(
    data.questions.map(() => false)
  )
  const toggleFaq = index => {
    const newState = toggleStates.map((t, i) => (index === i ? !t : t))
    setToggle(newState)
  }

  useEffect(() => {
    setUtms(search)
  }, [search])

  usePageView()

  return (
    <>
      <Helmet>
        <title>Imóveis Emgea | Perguntas Frequentes</title>
        <meta
          property="og:title"
          content="Imóveis Emgea | Perguntas Frequentes"
        />
        <meta
          property="og:description"
          content="Como funciona a Plataforma Imóveis Emgea?"
        />
        <meta
          property="og:url"
          content="https://seuimovelbb.com.br/perguntas-frequentes"
        />
      </Helmet>
      <BaseLayout backgroundColor="white">
        <main>
          <section className={styles.faqContainer}>
            <Title color="secondColor" type="h1" className={styles.mainTitle}>
              {data.title}
            </Title>
            {data.questions.map((q, i) => (
              <FAQCard
                key={q.title}
                {...q}
                onClick={() => toggleFaq(i)}
                isActive={toggleStates[i]}
              />
            ))}
          </section>
        </main>
      </BaseLayout>
    </>
  )
}

FAQ.propTypes = {
  location: {
    search: PropTypes.string,
  },
}

FAQ.defaultProps = {
  location: {
    search: '',
  },
}
