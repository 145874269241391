import React from 'react'

import ErrorPage, { errorLinks } from './ErrorPage'

const notFoundInfo = {
  code: 404,
  title: 'Link indisponível',
  body: `
    Ops, essa página não está mais disponível, mas você pode encontrar outras opções clicando no botão abaixo.
  `,
  links: errorLinks,
}

export default () => <ErrorPage {...notFoundInfo} />
