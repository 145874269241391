import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Text } from 'components'
import styles from './AuctionDate.module.scss'

const AuctionDate = ({ label, date, hour }) => (
  <span className={styles.auctionDate}>
    <Icon type="auction" size={24} />
    <Text color="lightTextColor">{`${label} ${date} - Horário: ${hour}`}</Text>
  </span>
)

AuctionDate.propTypes = {
  label: PropTypes.string.isRequired,
  hour: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
}

export default React.memo(AuctionDate)
