import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'
import isPreRendering from 'utils/isPreRendering'
import { isMobile } from 'utils/deviceDetector'
import { getCustomerInfoLocalStorage } from 'utils/customerInfoLocalStorage'
import {
  EstateListContainer,
  EstateHeaderContainer,
  FilterContainer,
  LeadModalContainer,
} from 'containers'
import { Button, ListBanner } from 'components'
import { useRoot, useFilter } from 'store'
import { usePageView } from 'hooks/useGAPageView'
import { InternalErrorPage } from 'pages'
import { BaseLayout } from 'templates'
import { setUtms } from 'utils/Utms'

import useAlertModal from 'hooks/useAlertModal'

import { useLoadLocalities } from 'hooks/useLoadLocalities'
import styles from './Search.module.scss'

const SearchPage = ({ location: { search } }) => {
  const {
    getError,
    setError,
    setLeadModal,
    $root: { cookies, leadModal },
  } = useRoot()

  const { setField } = useFilter()
  useLoadLocalities()

  useEffect(() => {
    setUtms(search)
  }, [search])

  useEffect(() => {
    window.scrollTo(0, 0)

    setUtms(search)

    return () => setError(null)
  }, []) //eslint-disable-line

  usePageView()
  useAlertModal()

  const emailResale = getCustomerInfoLocalStorage().email || ''

  useEffect(() => {
    if (!emailResale) {
      setTimeout(
        () => setLeadModal({ openModal: true, wasOpened: false }),
        90000
      )
    }
  }, [emailResale, setLeadModal])

  if (getError()) return <InternalErrorPage errorCode={getError()} />
  return (
    <>
      <Helmet>
        <title>Imóveis Emgea | Busca</title>
        <meta property="og:title" content="Imóveis Emgea | Busca" />
        <meta
          property="og:description"
          content="Descubra o que são os imóveis com desconto do Banco do Brasil!"
        />
        <meta property="og:url" content="https://seuimovelbb.com.br/busca" />
      </Helmet>
      <BaseLayout
        backgroundColor="gray"
        enableSearch={isMobile}
        urlParams={search}
      >
        <ListBanner />
        {!isPreRendering() && (
          <div
            className={classNames(styles.buttonContainer, {
              [styles.hide]: cookies,
            })}
          >
            <Button
              name="filterButton"
              onClick={() => setField('openFilterModal', true)}
              iconPosition="left"
              icon="filter"
              darkColor
              className={styles.filterButton}
            >
              Refinar Busca
            </Button>
          </div>
        )}
        <main className={styles.mainSearch} id="list">
          <div className={styles.container}>
            <EstateHeaderContainer />
            <div className={styles.sectionSearch}>
              <div className={styles.filters}>
                <FilterContainer showHeader type="listing" />
              </div>
              <div className={styles.result}>
                <EstateListContainer urlParams={search} />
              </div>
            </div>
          </div>
        </main>
        {!isPreRendering() && (
          <LeadModalContainer
            closeModal={() =>
              setLeadModal({ openModal: false, wasOpened: true })
            }
            opened={leadModal.openModal && !leadModal.wasOpened}
          />
        )}
      </BaseLayout>
    </>
  )
}

SearchPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
}

SearchPage.defaultProps = {
  location: {
    search: '',
  },
}

export default SearchPage
