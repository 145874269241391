import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const HeartFilled = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="heartFilled"
          d="M15.072 26.657c-.97-.834-1.904-1.617-2.729-2.308l-.004-.004c-2.418-2.026-4.506-3.777-5.959-5.5C4.756 16.917 4 15.09 4 13.094c0-1.94.676-3.73 1.904-5.039C7.146 6.73 8.85 6 10.704 6c1.386 0 2.655.43 3.771 1.28A7.645 7.645 0 0 1 16 8.846c.45-.612.96-1.137 1.525-1.566A6.102 6.102 0 0 1 21.295 6c1.854 0 3.559.73 4.801 2.055 1.228 1.31 1.904 3.1 1.904 5.039 0 1.996-.756 3.823-2.38 5.75-1.453 1.724-3.54 3.474-5.958 5.5-.826.693-1.763 1.478-2.734 2.313a1.422 1.422 0 0 1-1.856 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#heartFilled" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#heartFilled"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

HeartFilled.propTypes = {
  ...propTypesSvg,
}

HeartFilled.defaultProps = {
  ...defaultPropTypesSvg,
}

export default HeartFilled
