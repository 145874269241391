import { apiWithoutPropertySuffix } from 'service/api'
import { to } from 'nh-utils'

export const sendForm = (
  form,
  send_mail_emgea = true,
  send_mail_resale = true
) => {
  const payload = { ...form, send_mail_emgea, send_mail_resale }
  return to(apiWithoutPropertySuffix.post(`/installment-simulation`, payload))
}
