import React from 'react'
import PropTypes from 'prop-types'
import { CategoryTitle } from 'components'

const AuctionRegistration = ({ auctionRegistration }) => {
  if (!auctionRegistration) {
    return null
  }

  return (
    <section>
      <CategoryTitle
        icon="considerations"
        title="Averbação dos leilões negativos relativos à Lei 9.514/97"
      />
    </section>
  )
}

AuctionRegistration.propTypes = {
  auctionRegistration: PropTypes.bool.isRequired,
}

export default AuctionRegistration
