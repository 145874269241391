import React from 'react'
import { useRoot } from 'store'
import CookiesMobile from './CookiesMobile/CookiesMobile'
import CookiesDesktop from './CookiesDesktop/CookiesDesktop'

const Cookies = () => {
  const {
    $root: { cookies },
    setCookies,
  } = useRoot()

  const acceptCookies = () => {
    window.localStorage.setItem('cookiesResaleEmgea', 'accepted')
    setCookies(false)
  }

  const hideCookies = () => {
    const isSimuladorPath = window.location.pathname.includes('/simulador')
    return isSimuladorPath
  }

  return (
    <>
      <CookiesDesktop
        acceptCookies={acceptCookies}
        cookies={cookies}
        hideCookies={hideCookies()}
      />
      <CookiesMobile
        acceptCookies={acceptCookies}
        cookies={cookies}
        setCookies={setCookies}
        hideCookies={hideCookies()}
      />
    </>
  )
}

export default React.memo(Cookies)
