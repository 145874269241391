export const generateFilterCampaignOptions = campaigns => {
  const options = []
  const complexOptions = []

  Object.entries(campaigns).forEach(([, campaign]) => {
    const isAnCouponCampaign = /cupom/i.test(campaign?.campaignModel)
    const isPattern = /(\b)ativado(\b)/i.test(campaign.filter?.pattern)

    if (!campaign.isActive) return

    if (isPattern && !isAnCouponCampaign && campaign.filter?.name) {
      options.push({
        name: campaign.filter?.name,
        checked: false,
        value: campaign.filter?.url,
      })
    }
    if (isPattern && isAnCouponCampaign)
      complexOptions.push({
        name: campaign.filter?.name,
        checked: false,
        value: campaign.couponCode,
      })
  })

  return { options, complexOptions }
}
