/* eslint-disable prefer-destructuring */
import { SpreadsheetApiEsquentaBlackout } from '../spreadSheet'

export const SaveEsquentaBlackout = async ({
  email,
  optinResale,
  optinWhiteLabel,
}) => {
  let sheet
  await SpreadsheetApiEsquentaBlackout().then(doc => {
    sheet = doc.sheetsByIndex[0]
    sheet.addRow({
      'Data/Hora': new Date().toLocaleString('pt-BR'),
      email,
      'optin resale': optinResale,
      'optin whitelabel': optinWhiteLabel,
      'portal de origem': 'emgea',
      ambiente: process.env.REACT_APP_STAGE === 'prod' ? 'PROD' : 'DEV',
    })
  })
}
