import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'components'
import styles from './Optins.scss'

const Optins = ({
  location,
  checkOffers,
  setCheckOffers,
  checkOffersResale,
  setCheckOffersResale,
  checkOffersPgi,
  setCheckOffersPgi,
}) => {
  const handleCheckEmailOffers = () => {
    setCheckOffers(!checkOffers)
  }

  const handleCheckResaleEmailOffers = () => {
    setCheckOffersResale(!checkOffersResale)
  }

  const handleCheckPgiEmailOffers = () => {
    setCheckOffersPgi(!checkOffersPgi)
  }

  return (
    <div className={styles.container}>
      {setCheckOffers && (
        <Checkbox
          option="Aceito receber ofertas do emgeaimoveis.com.br"
          optionPosition="terms"
          checked={checkOffers}
          onChange={handleCheckEmailOffers}
          id={`checkOffers-${location}`}
        />
      )}
      {setCheckOffersResale && (
        <Checkbox
          option="Aceito receber ofertas da resale.com.br"
          optionPosition="terms"
          checked={checkOffersResale}
          onChange={handleCheckResaleEmailOffers}
          id={`checkOffersResale-${location}`}
        />
      )}

      {setCheckOffersPgi && (
        <Checkbox
          option="Aceito receber mensagem da Pagimovel®️"
          optionPosition="terms"
          checked={checkOffersPgi}
          onChange={handleCheckPgiEmailOffers}
          id={`checkOffersPgi-${location}`}
        />
      )}
    </div>
  )
}

Optins.propTypes = {
  checkOffers: PropTypes.bool,
  setCheckOffers: PropTypes.func,
  checkOffersResale: PropTypes.bool,
  setCheckOffersResale: PropTypes.func,
  checkOffersPgi: PropTypes.bool,
  setCheckOffersPgi: PropTypes.func,
  location: PropTypes.string,
}

Optins.defaultProps = {
  checkOffers: true,
  setCheckOffers: undefined,
  checkOffersResale: true,
  setCheckOffersResale: undefined,
  checkOffersPgi: true,
  setCheckOffersPgi: undefined,
  location: '',
}

export default React.memo(Optins)
