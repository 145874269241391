import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg } from './propTypes'

const Photos = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="photos"
          d="M26 17.223V9a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v12.66l5.63-3.686a1.5 1.5 0 0 1 1.465-.1l3.378 1.605 5.134-4.036a1.5 1.5 0 0 1 1.789-.048L26 17.223zm0 2.444l-3.45-2.422-5.084 3.998a1.5 1.5 0 0 1-1.571.175l-3.405-1.617-6.098 3.993c.168.13.38.206.608.206h18a1 1 0 0 0 1-1v-3.333zM6 6h20a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2zm10 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#photos" />
        </mask>
        <use
          className={styles[color]}
          // transform="rotate(45 16.04 14.04)"
          xlinkHref="#photos"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Photos.propTypes = {
  ...propTypesSvg,
}

Photos.defaultProps = {
  viewBox: '0 0 32 32',
  color: 'grayColor',
}

export default Photos
