/* eslint-disable no-param-reassign */
import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'components'
import { ReactComponent as ClockIcon } from 'assets/svgs/clock.svg'
import classNames from 'classnames'
import {
  getCampaignByContent,
  SALE_CAMPAIGNS,
  replaceCampaignToShow,
} from 'service/campaigns'
import { useCampaignTags, useCampaigns } from 'hooks'
import styles from './Tag.module.scss'
import { getImagesCampaignMap } from './utils/imagesCampaignMap'

const Tag = ({ children, onClick, campaign = false }) => {
  const dontRender = ['Novembro Mágico']
  const { isAnActiveCampaign } = useCampaigns()
  const { tagConfig } = useCampaignTags()
  const { isAnCampaign, tagProps } = tagConfig(children, styles)

  // const isBlackout = children === 'BLACKOUT'
  const isBlackoutDay = SALE_CAMPAIGNS.blackoutDay.test(children)
  const isPreActive = children === 'Em breve'
  const currentCampaign = getCampaignByContent(children)

  // if (isBlackout || isBlackoutDay) {
  //   children = ''
  // }

  if (
    dontRender.includes(children) ||
    (!isAnActiveCampaign(SALE_CAMPAIGNS.blackout) && /blackout/i.test(children))
  )
    return null

  children = replaceCampaignToShow(children)
  if (/anivers[áa]rio/i.test(children)) return null

  const imagesCampaignMap = getImagesCampaignMap({
    styles,
    // isBlackout,
    isBlackoutDay,
  })

  return (
    <span
      style={tagProps.styles}
      role="button"
      tabIndex={0}
      className={classNames({
        [styles.greenTag]: ['Novidade'].includes(children),
        [styles.deletable]: onClick,
        [styles.primaryTag]: !onClick && campaign,
        [styles.tag]: !campaign && !onClick,
        [styles.feirao]: SALE_CAMPAIGNS.feirao.test(children),
        // [styles.blackout]: isBlackout,
        [styles.blackoutDay]: isBlackoutDay,
        [styles.preActive]: isPreActive,
        [styles.campaignTag]: isAnCampaign,
      })}
    >
      {isAnCampaign && <span className={styles.icon}>{tagProps.icon()}</span>}

      {isPreActive && (
        <span className={styles.icon}>
          <ClockIcon />
        </span>
      )}

      {currentCampaign && imagesCampaignMap[currentCampaign]}
      {!campaign && children}
      {campaign && (
        <>
          {children.substr(0, 9)} <b>{campaign && children.substr(10)}</b>
        </>
      )}

      {onClick && (
        <button type="button" onClick={onClick} className={styles.closeButton}>
          <Icon
            color={
              isAnCampaign && tagProps.closeIconColor
                ? tagProps.closeIconColor
                : 'darkColor'
            }
            type="clear"
            size={16}
          />
        </button>
      )}
    </span>
  )
}

Tag.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  campaign: PropTypes.bool,
}

Tag.defaultProps = {
  onClick: null,
  campaign: false,
}

export default React.memo(Tag)
