/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import deburr from 'lodash.deburr'
import { Input } from 'components'
import OutsideAlerter from 'hooks/useOutsideAlerter'
import { useFilter } from 'store'
import { navigate } from '@reach/router'
import styles from './SelectLocalityInput.module.scss'

const SelectInput = ({
  selectedLocalities,
  onChange,
  options,
  placeholderText,
  className,
  disabled,
  type,
  onFocus,
  invertIconOnX,
  ...rest
}) => {
  const [localityFocused, setLocalityFocused] = React.useState(false)
  const [localityValue, setLocalityValue] = React.useState('')
  const [line, setLine] = React.useState(0)

  const { updateQueryString } = useFilter()

  // <initial> navigation of options by keyboard arrow
  useEffect(() => {
    document.addEventListener('keydown', changeRow)
    return () => {
      document.removeEventListener('keydown', changeRow)
    }
  })

  const getRowIncrement = e => {
    if (e.keyCode === 38) return -1
    if (e.keyCode === 40) return 1
    return 0
  }

  const changeRow = e => {
    const rowIncrement = getRowIncrement(e)
    if (!rowIncrement) return

    e.stopPropagation()
    e.preventDefault()
    const nextLine = (line + rowIncrement + results.length) % results.length

    document
      .getElementById(
        `locality${type +
          (nextLine - 1 === -1 ? results.length - 1 : nextLine - 1)}`
      )
      .focus()
    setLine(nextLine)
  }
  // <end> navigation of options by keyboard arrow

  const clearInput = () => {
    setLocalityFocused(false)
    setLocalityValue('')
    setLine(0)
  }

  const selectLocality = ({ name, slug }) => {
    onChange([...selectedLocalities, { name, slug }])
    navigate(`/busca`)
    updateQueryString()
    clearInput()
  }

  const loadingLocalities = !options || !options.length

  const deburredSearch = deburr(localityValue.toLowerCase())
  const searchTooShort = deburredSearch.length < 2
  const localities = searchTooShort ? options.slice(0, 27) : options

  const results = localities.filter(({ deburredName }) =>
    deburredName.includes(deburredSearch)
  )

  results.sort(
    searchTooShort
      ? (first, second) => {
          if (first.slug < second.slug) {
            return -1
          }

          if (first.slug < second.slug) {
            return 1
          }

          return 0
        }
      : (first, second) => {
          const firstIsState = first.value.length === 2
          if (firstIsState) {
            return -1
          }

          const secondIsState = second.value.length === 2
          if (secondIsState) {
            return 1
          }

          const firstIsDirectMatch = first.deburredName.startsWith(
            deburredSearch
          )
          if (firstIsDirectMatch) {
            return -1
          }

          const secondIsDirectMatch = second.deburredName.startsWith(
            deburredSearch
          )
          if (secondIsDirectMatch) {
            return 1
          }

          return 0
        }
  )

  return (
    <OutsideAlerter
      action={() => {
        setLocalityFocused(false)
        setLine(0)
      }}
      className={styles.alerter}
    >
      <Input
        placeholder={loadingLocalities ? 'Carregando...' : placeholderText}
        {...rest}
        onChange={e => setLocalityValue(e.target.value)}
        value={localityValue}
        icon="search"
        invertIconOnX
        type="text"
        onFocus={() => {
          setLocalityFocused(true)
          onFocus()
        }}
        onClear={clearInput}
        round
        className={classNames(className, styles.input)}
        disabled={disabled}
        tabIndex="0"
        aria-autocomplete="list"
        autoComplete="nope"
        aria-haspopup="true"
        role="combobox"
      />

      {options && (
        <ul
          role="listbox"
          className={classNames(styles.options, {
            [styles.open]: localityFocused,
            [styles.tooManyItems]: results.length > 8,
          })}
        >
          {results.slice(0, 27).map(({ name, value, slug }, index) => (
            <li
              role="option"
              id={`locality${type + index}`}
              aria-selected={
                localityValue === value || line === index ? 'true' : 'false'
              }
              tabIndex={index}
              className={classNames(styles.option, {
                [styles.focus]: line === index,
              })}
              key={slug}
              onClick={() => selectLocality({ name, slug })}
              onKeyPress={() => {
                selectLocality({ name, slug })
              }}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  selectLocality({ name, slug })
                  setLine(0)
                }
              }}
            >
              {name}
            </li>
          ))}
        </ul>
      )}
    </OutsideAlerter>
  )
}

SelectInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedLocalities: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  placeholderTop: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  onFocus: PropTypes.func,
  invertIconOnX: PropTypes.bool,
  placeholderText: PropTypes.string,
}

SelectInput.defaultProps = {
  placeholderTop: undefined,
  placeholderText: 'Busca por cidade ou estado',
  className: '',
  disabled: false,
  type: 'page',
  invertIconOnX: false,
  onFocus: () => {},
}

export default React.memo(SelectInput)
