const getRooms = estate => {
  const [roomsEstate, suitesEstate] = estate.features || []

  const rooms = roomsEstate && roomsEstate.amount && Number(roomsEstate.amount)

  const suites =
    suitesEstate && suitesEstate.amount && Number(suitesEstate.amount)

  if (rooms && suites) {
    const allRooms = rooms + suites
    const slug = allRooms <= 1 ? 'Dormitorio' : 'Dormitorios'

    return `${allRooms}-${slug}`
  }

  if (rooms) {
    const slug = rooms <= 1 ? 'Dormitorio' : 'Dormitorios'

    return `${rooms}-${slug}`
  }

  return false
}

const extractEstateKeys = estate => {
  const city = estate ? estate.city : ''
  const state = estate ? estate.state : ''
  const rooms = getRooms(estate)

  return { city, rooms, state }
}

const parseUrl = url => {
  return url
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/ /g, '-')
}

export const setListPropertySlug = estate => {
  const { city, rooms, state } = extractEstateKeys(estate)

  const url = rooms
    ? `${state}/${city}/${rooms}/${estate.propertySmallId}`
    : `${state}/${city}/${estate.propertySmallId}`

  return parseUrl(url)
}

export const setPropertySlug = (estate, id) => {
  const { city, rooms } = extractEstateKeys(estate)

  const url = rooms ? `${city}/${rooms}/${id}` : `${city}/${id}`

  return parseUrl(url)
}

export default setPropertySlug
