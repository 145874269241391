import { useMemo, useState } from 'react'
import { getCustomerInfoLocalStorage } from 'utils/customerInfoLocalStorage'
import useCreateStore from '../../lib/useCreateStore'
import alertActions from './actions'
import alertSelectors from './selectors'

const initialState = {
  form: {
    email: getCustomerInfoLocalStorage().email || '',
    phone: getCustomerInfoLocalStorage().phone || '',
    periodicity: {
      daily: false,
      weekly: false,
      avaiable: false,
    },
    whatsapp: false,
    purpose: {
      live: false,
      invest: false,
    },
    orderBy: 'data',
    situation: '',
  },
  openModal: false,
  openExitModal: false,
}

const AlertStore = useCreateStore(() => {
  const [$alert, setAlert] = useState(initialState)

  const actions = useMemo(() => alertActions(setAlert), [])
  const selectors = alertSelectors($alert)

  return { $alert, ...actions, ...selectors }
})

export const useAlert = () => AlertStore()
export const AlertContext = AlertStore.Context
export const AlertProvider = AlertStore.Provider
