import React from 'react'
import { Title, Text, Button } from 'components'
import PropTypes from 'prop-types'
import content from './content'
import styles from './HowToBecomeSupplierContainer.module.scss'

const HowToBecomeSupplierContainer = ({ onClick }) => {
  return (
    <section className={styles.howToBecomeSupplier}>
      <Title
        type="h1"
        fontSize="xlarge"
        className={styles.mainTitle}
        color="darkBlueColor"
      >
        Como se tornar um fornecedor credenciado
      </Title>
      <div className={styles.container}>
        {content.map(item => (
          <div className={styles.card} key={item.title}>
            <figure>
              <img src={item.image} alt={item.description} title={item.title} />
            </figure>
            <Title fontSize="medium" color="darkBlueColor" type="h3">
              {item.title}
            </Title>
            <Text fontSize="medium" color="lightBlueColor" type="h4">
              {item.subtitle}
            </Text>
          </div>
        ))}
      </div>
      <Button className={styles.toBecomeButton} onClick={onClick}>
        Quero me tornar um fornecedor
      </Button>
    </section>
  )
}

HowToBecomeSupplierContainer.propTypes = {
  onClick: PropTypes.func.isRequired,
}
export default HowToBecomeSupplierContainer
