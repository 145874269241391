export default setLocalityModal => {
  const setOpenModal = async () => {
    setLocalityModal(prev => ({ ...prev, openModal: !prev.openModal }))
  }

  const setClickedBy = value =>
    setLocalityModal(prev => ({ ...prev, clickedBy: value }))

  return {
    setOpenModal,
    setClickedBy,
  }
}
