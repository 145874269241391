export default {
  AREA_UTIL: 'Área útil',
  AREA_PRIVADA: 'Área privativa',
  AREA_CONSTRUIDA: 'Área construida',
  AREA_DO_TERRENO: 'Área do Terreno',
  DORMITORIOS: 'Dormitórios',
  SUITES: 'Suítes',
  BANHEIROS: 'Banheiros',
  SALAS: 'Salas',
  VAGAS_NA_GARAGEM: 'Vagas na Garagem',
  IMOVEL_FEIRAO: 'Imóvel Feirão',
  DENIED_PAGES: [
    '/',
    '/busca',
    '/fale-conosco',
    '/perguntas-frequentes',
    '/manutencao',
  ],
  CONCORRENCIA: 'Imóvel em Disputa',
}
