import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const Pool = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="pool"
          d="M3.867 21.812a4.996 4.996 0 0 1 7.058 0 7.174 7.174 0 0 0 10.15 0h.001a4.996 4.996 0 0 1 7.057 0 1.094 1.094 0 0 0 1.547-1.546c-2.238-2.238-5.597-2.686-8.284-1.346V5.245a1.058 1.058 0 0 1 2.115 0 1.094 1.094 0 1 0 2.187 0A3.248 3.248 0 0 0 22.453 2a3.249 3.249 0 0 0-3.245 3.245v3.208h-6.416V5.245a1.058 1.058 0 0 1 2.114 0 1.094 1.094 0 0 0 2.188 0A3.248 3.248 0 0 0 13.849 2a3.248 3.248 0 0 0-3.245 3.245V18.92c-2.687-1.34-6.046-.891-8.284 1.346a1.094 1.094 0 1 0 1.547 1.546zm8.925-1.254v-3.465h6.416v3.465a4.986 4.986 0 0 1-6.416 0zm6.416-9.918v4.265h-6.416V10.64h6.416zM29.68 26.35a1.094 1.094 0 1 1-1.547 1.546 4.996 4.996 0 0 0-7.057 0 7.174 7.174 0 0 1-10.151 0 4.997 4.997 0 0 0-7.058 0 1.093 1.093 0 1 1-1.547-1.547c2.8-2.798 7.353-2.798 10.151 0a4.988 4.988 0 0 0 7.058 0c2.798-2.798 7.352-2.798 10.15 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#pool" />
        </mask>
        <use className={styles[color]} fillRule="nonzero" xlinkHref="#pool" />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Pool.propTypes = {
  ...propTypesSvg,
}

Pool.defaultProps = {
  ...defaultPropTypesSvg,
}

export default Pool
