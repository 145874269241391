import { validateEmail } from 'utils/validations'

export const getNotificationBoxTexts = exitModal => {
  const title = exitModal ? 'Não encontrou o que procurava?' : 'Crie seu alerta'
  const message = exitModal
    ? 'Antes de sair crie um cadastro baseado na sua última busca ou com filtros diferentes'
    : 'e receba por e-mail, as melhores oportunidades de imóveis de acordo com as características que você procura.'

  return { title, message }
}

export const checkIsDisabled = ({ form: { email }, filters, maxValue }) => {
  const hasFilterChecked =
    filters.finalidade.length ||
    filters.localidade.length ||
    filters['tipo-imovel'].length ||
    filters['buscar-regiao'] ||
    filters.valor_min ||
    filters.valor_max !== maxValue ||
    filters.situacao.length ||
    (filters.order !== 'data' && filters.order !== '')

  return !validateEmail(email) || !hasFilterChecked
}

export const transformFiltersToSendToApi = filters => {
  const { booleanFilters, numberFilters, arrayFilters, stringFilters } = filters

  let purpose = booleanFilters.purpose.options.filter(item => item.checked)
  purpose = purpose.map(item => item.value)

  let propertyType = booleanFilters.propertyType.options.filter(
    item => item.checked
  )
  propertyType = propertyType.map(item => item.value)

  const searchRegion = booleanFilters.searchRegion.options.filter(
    item => item.name === 'Buscar na Região'
  )[0]

  const situation = booleanFilters.situation.options
    .filter(item => item.checked)
    .map(item => item.value)

  const localities = arrayFilters.localities.value.map(item => item.slug)

  const orderBy = stringFilters.orderBy.value

  return {
    finalidade: purpose,
    'tipo-imovel': propertyType,
    localidade: localities,
    'buscar-regiao': searchRegion.checked,
    valor_max: numberFilters.maxValue.value,
    valor_min: numberFilters.minValue.value,
    situacao: situation,
    order: orderBy,
  }
}
