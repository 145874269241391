import terms from 'pages/Terms/dict'

const { TERMS_OF_USE, PRIVACY_POLICY, COOKIES_POLICY, DELETION_POLICY } = terms

const socialNetworks = [
  {
    icon: 'facebook',
    link: 'https://www.facebook.com/resale.com.br/',
  },
  {
    icon: 'instagram',
    link: 'https://www.instagram.com/resalebr/',
  },
  {
    icon: 'linkedin',
    link: 'https://www.linkedin.com/company/resalebr',
  },
  {
    icon: 'youtube',
    link:
      'https://www.youtube.com/channel/UCOQPpSCHs1NPfeJYsOi1AnQ/featured?view_as=subscriber',
  },
]

const links = [
  {
    title: 'Institucional',
    links: [
      {
        name: 'Ajuda',
        link: '/perguntas-frequentes',
      },
      {
        name: 'Fale Conosco',
        link: '/fale-conosco',
      },
      {
        name: 'Seja um parceiro',
        link: '/seja-um-parceiro',
      },
      {
        name: 'Fornecedores',
        link: '/fornecedores',
      },
    ],
  },
  {
    title: 'Imóveis',
    links: [
      {
        name: 'Para morar',
        link: '/busca?order=relevante&situacao=desocupado',
      },
      {
        name: 'Para investir',
        link: '/busca?situacao=ocupado&order=relevante',
      },
      {
        name: 'Leilões',
        link: '/busca?order=relevante&tipo-venda=leilao',
      },
      {
        name: 'Venda direta',
        link: '/busca?order=relevante&tipo-venda=venda_direta',
      },
      {
        name: 'Fazendas',
        link: '/busca?order=relevante&tipo-imovel=fazenda',
      },
    ],
  },
  {
    title: 'Pagimovel®',
    links: [
      {
        name: 'Minhas Propostas',
        link: 'https://clientes.pagimovel.com.br/welcome',
      },
    ],
  },
  {
    title: 'Termos e Privacidade',
    links: [
      {
        name: 'Termos e Condições de Uso',
        link: '/termos',
        term: TERMS_OF_USE,
      },
      {
        name: 'Política de Privacidade',
        link: '/termos/privacidade',
        term: PRIVACY_POLICY,
      },

      {
        name: 'Política de Cookies',
        link: '/termos/cookies',
        term: COOKIES_POLICY,
      },
      {
        name: 'Política de Exclusão de Dados',
        link: '/termos/exclusao_de_dados',
        term: DELETION_POLICY,
      },
    ],
  },
]
export { socialNetworks, links }
