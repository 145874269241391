/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'

import classNames from 'classnames'

import { FaChevronDown } from 'react-icons/fa'

import PropTypes from 'prop-types'

import styles from './SectionCollapse.module.scss'

export default function SectionCollapse({ toggle, title, children, onClick }) {
  return (
    <div className={styles.info}>
      <div className={styles.title} onClick={onClick}>
        <span>{title}</span>
        <FaChevronDown
          className={classNames(styles.icon, {
            [styles.iconRotate]: toggle,
          })}
        />
      </div>

      {toggle && <div className={styles.text}>{children}</div>}
    </div>
  )
}

SectionCollapse.propTypes = {
  toggle: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
}
