import React from 'react'
import PropTypes from 'prop-types'
import { AlertContainer, FiltersModalContainer, SearchHeader } from 'containers'
import { Header, Footer, ToastComponent, Cookies } from 'components'
import { useAlert } from 'store'
import isPreRendering from 'utils/isPreRendering'
import { useWindowOut } from 'hooks/useWindowOut'

import styles from './BaseLayout.module.scss'

const BaseLayout = ({
  children,
  backgroundColor,
  showFooter,
  preview,
  enableSearch,
}) => {
  const { setOpenExitModal } = useAlert()

  useWindowOut(setOpenExitModal, 'exitModal')

  return (
    <div className={styles[`background-${backgroundColor}`]}>
      <AlertContainer />
      <AlertContainer exitModal />
      <ToastComponent />
      <FiltersModalContainer />
      <div className={styles.fixedTop}>
        <Header route="/imoveis" preview={preview} />
        {enableSearch && <SearchHeader />}
      </div>
      {!isPreRendering() && <Cookies />}
      {children}
      {showFooter && <Footer />}
    </div>
  )
}

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
  showFooter: PropTypes.bool,
  preview: PropTypes.bool,
  enableSearch: PropTypes.bool,
}

BaseLayout.defaultProps = {
  backgroundColor: 'white',
  showFooter: true,
  preview: false,
  enableSearch: false,
}

export default BaseLayout
