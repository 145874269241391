import React, { useEffect } from 'react'
import { getUtms } from 'utils/Utms'

import { ProposalModal } from 'components'
import { useProposalModal, useProperty } from 'store'
import { fakePropertyId, fakePaymentConditions } from 'utils/mockData'
import { navigate } from '@reach/router'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import {
  toastSuccess,
  toastError,
} from 'components/ToastComponent/ToastComponent'
import { setCustomerInfoLocalStorage } from 'utils/customerInfoLocalStorage'
import { normalizeStringAndChangeSpaces } from 'utils/normalizeString'

const ProposalModalContainer = () => {
  const [loadingFetch, setloadingFetch] = React.useState(false)
  const [fetchSuccess, setFetchSuccess] = React.useState(false)

  const [url, setUrl] = React.useState('')

  useEffect(() => {
    return () => {
      setFetchSuccess(false)
    }
  }, [])

  const {
    getForm,
    getOpenModal,
    sendForm,
    setField,
    setOpenModal,
  } = useProposalModal()

  const {
    getPropertyId,
    getPaymentConditions,
    getPropertySmallId,
    getPropertyData,
  } = useProperty()

  const property = getPropertyData()

  const propertyId = getPropertyId() || fakePropertyId

  const saleType = property && property.saleType

  const paymentConditions = getPaymentConditions() || fakePaymentConditions

  const values = getForm()

  const competitionProps = property && {
    limitDate:
      property && property.auctionInfo[0] ? property.auctionInfo[0].data : null,
    limitTime:
      property && property.auctionInfo[0] ? property.auctionInfo[0].hora : null,
    video:
      property && property.videos[0]
        ? property.videos[0].competition_tutorial
        : null,
    document:
      property && property.document[0] ? property.document[0].url : null,
  }

  const handleConfirm = async (
    checkOffers,
    checkOffersResale,
    checkOffersPgi,
    tokenCaptcha
  ) => {
    const utms = getUtms()

    const form = { ...utms }
    form.confirm_password = values.confirmPassword
    form.email = values.email.trim()
    form.name = values.name.trim()
    form.password = values.password
    form.phone = values.phone.replace(/[^0-9]/g, '')
    form.registered = values.registered

    setCustomerInfoLocalStorage({
      email: form.email,
      name: form.name,
      phone: form.phone,
    })

    const propertySmallId = getPropertySmallId()
    setloadingFetch(true)
    const responseUrl = await sendForm({
      propertyId,
      form,
      propertySmallId,
      send_mail_emgea: checkOffers,
      send_mail_resale: checkOffersResale,
      send_mail_pgi: checkOffersPgi,
      tokenCaptcha,
    })
    setloadingFetch(false)

    if (responseUrl.status === 'erro') {
      return toastError(responseUrl.message)
    }

    setFetchSuccess(true)
    setUrl(responseUrl.message)
    window.location.href = responseUrl.message
    return toastSuccess('Seu pré-cadastro foi registrado!')
  }

  const handleRecoverPassword = async () => {
    if (!values.email) {
      return toastError('Preencha o seu email para recuperar sua senha')
    }

    return navigate(
      `http://clientes.pagimovel.com.br/welcome/login/forgot_password?email=${values.email}`
    )
  }

  const checkSaleTypeAndReturnLabel = () => {
    const labels = {
      melhor_proposta: 'participe_da_melhor_proposta_enviado',
      venda_direta: 'btn_criar_proposta_venda_direta',
    }

    return (
      labels[normalizeStringAndChangeSpaces(saleType)] || 'btn_criar_proposta'
    )
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LdRkDEiAAAAAKpMzmMN42ddh5XXIuSX_gkAOk2y"
      scriptProps={{ async: true }}
    >
      <ProposalModal
        idButton={checkSaleTypeAndReturnLabel()}
        closeModal={() => setOpenModal()}
        isLoading={loadingFetch}
        opened={getOpenModal()}
        paymentConditions={paymentConditions}
        sendForm={handleConfirm}
        recoverPassword={handleRecoverPassword}
        setField={setField}
        values={values}
        success={fetchSuccess}
        url={url}
        competitionProps={
          property && property.isPublicCompetition ? competitionProps : null
        }
      />
    </GoogleReCaptchaProvider>
  )
}

export default ProposalModalContainer
