import React from 'react'
import { getUtms } from 'utils/Utms'

import { VisitScheduleModal } from 'components'
import { useVisitScheduleModal, useProperty } from 'store'
import { fakePropertyId, fakeContactInfo } from 'utils/mockData'
import { setCustomerInfoLocalStorage } from 'utils/customerInfoLocalStorage'

const VisitScheduleModalContainer = () => {
  const [loadingFetch, setloadingFetch] = React.useState(false)

  const {
    getForm,
    getOpenModal,
    sendForm,
    setField,
    setOpenModal,
  } = useVisitScheduleModal()

  const {
    getSaleType,
    getPropertyId,
    getContactInfo,
    getPropertySmallId,
  } = useProperty()

  const propertyId = getPropertyId() || fakePropertyId

  const contactInfo = getContactInfo() || fakeContactInfo

  const values = getForm()

  const handleConfirm = async (checkOffers, checkOffersResale) => {
    const formatOutput = (selectedDays, selectedPeriods) => {
      const formatedDays = []
      const formatedPeriods = []

      Object.keys(selectedDays).forEach(day => {
        if (selectedDays[day]) {
          formatedDays.push(day)
        }
      })

      Object.keys(selectedPeriods).forEach(period => {
        if (selectedPeriods[period]) {
          formatedPeriods.push(period)
        }
      })

      return [formatedDays, formatedPeriods]
    }

    const [formatedDays, formatedPeriods] = formatOutput(
      values.days,
      values.times
    )
    const utms = getUtms()

    const form = { ...utms }
    form.name = values.name.trim()
    form.email = values.email.trim()
    form.phone = values.phone.replace(/[^0-9]/g, '')
    form.days = formatedDays
    form.times = formatedPeriods
    form.canal_venda_codigo = values.canal_venda_codigo

    setCustomerInfoLocalStorage({
      email: form.email,
      name: form.name,
      phone: form.phone,
    })

    const propertySmallId = getPropertySmallId()
    const saleType = getSaleType()
    setloadingFetch(true)
    const success = await sendForm({
      propertyId,
      form,
      saleType,
      propertySmallId,
      send_mail_emgea: checkOffers,
      send_mail_resale: checkOffersResale,
    })
    setloadingFetch(false)

    if (success) {
      setOpenModal()
    }
  }

  return (
    <VisitScheduleModal
      closeModal={() => setOpenModal()}
      contactInfo={contactInfo}
      opened={getOpenModal()}
      sendForm={handleConfirm}
      setField={setField}
      values={values}
      isLoading={loadingFetch}
    />
  )
}

export default VisitScheduleModalContainer
