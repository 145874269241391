import { SALE_CAMPAIGNS } from './1_SaleCampaigns'

export const getCurrentBanner = (
  campaign,
  secondCampaign,
  currentBannerList,
  setCurrentBanner,
  setBannerIndex,
  campaignConfigurations,
  getSpecificBannerIndex,
  getRandomBannerIndex
) => {
  const hasCampaignBanner =
    campaign && campaignConfigurations(process.env.REACT_APP_CAMPAIGN).banner

  const hasSecondCampaignBanner =
    secondCampaign &&
    campaignConfigurations(process.env.REACT_APP_SECOND_CAMPAIGN).banner

  if (hasCampaignBanner) {
    setCurrentBanner(currentBannerList, true, process.env.REACT_APP_CAMPAIGN)
  }

  if (hasSecondCampaignBanner) {
    setCurrentBanner(
      currentBannerList,
      true,
      process.env.REACT_APP_SECOND_CAMPAIGN
    )
  }

  if (hasCampaignBanner || hasSecondCampaignBanner) {
    return setBannerIndex(
      getSpecificBannerIndex(currentBannerList[currentBannerList.length - 1])
    )
  }

  return setBannerIndex(getRandomBannerIndex())
}

export const extractNameCampaign = (property, campaigns) => {
  const campaignskeys = Object.keys(campaigns)
  const campaignsData =
    property &&
    property.campaignsData?.filter(
      campaign => campaigns[campaign.codigo]?.campaignModel === 'valor'
    )
  const nameCampaign = campaignsData
    ?.map(campaign => {
      if (campaignskeys.includes(campaign.codigo)) {
        return campaigns[campaign.codigo]?.campaignName
      }
      return null
    })
    ?.filter(value => value)
  const campaignValue =
    property && property.campaignInfo.values.campaignValue > 0
  const isPreVenda = property && property.saleCategory === 'pre_venda'

  if (campaignValue && !!nameCampaign?.length) {
    return nameCampaign[0]
  }
  if (campaignValue && isPreVenda) {
    return 'Pré-Venda'
  }
  return 'campanha'
}

export const getCampaignByContent = childrenText => {
  const [foundCampaign] = Object.entries(SALE_CAMPAIGNS)

    .map(([campaign, campaignRegex]) => {
      const isCurrentCampaign = campaignRegex.test(childrenText)

      return isCurrentCampaign ? campaign : null
    })

    .filter(campaign => !!campaign)

  return foundCampaign
}

export const setComplexCampaignsByUrl = ({
  filterKey,
  optionName,
  setComplexCampaigns,
}) => {
  if (filterKey === 'coupon') {
    setComplexCampaigns(optionName)
  }
}
