import { getBanners } from 'service/campaigns'
import * as providers from './provider'

export default ({ setList, setError, initialState }) => {
  const fetchBanner = async campaigns => {
    const { listBanners } = getBanners({ campaigns })

    return setList(prev => ({
      ...prev,
      banners: listBanners,
      isLoadingBanners: false,
    }))
  }
  const setField = (value, field) =>
    setList(prev => ({ ...prev, [field]: value }))

  const setListLoading = value =>
    setList(prev => ({ ...prev, loadingList: value }))

  const fetchList = async query => {
    setField(true, 'loadingList')

    const listData = await providers.getEstatesList(query)

    if (listData && listData.error) {
      return setError(listData.code)
    }

    const { list = [], pagination = {} } = listData || {}

    return setList(prev => ({
      ...prev,
      estatesList: list,
      pagination,
      loadingList: false,
    }))
  }

  const clearList = () => setList(() => ({ ...initialState }))

  return {
    fetchBanner,
    setField,
    fetchList,
    setListLoading,
    clearList,
  }
}
