/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { Button, Input, Text, Select } from 'components'
import { validateFullName, validateMandatoryPhone } from 'utils/validations'
import masks from 'utils/masks'
import styles from './MessageForm.module.scss'

const options = [
  { value: '', name: '' },
  { value: 'Compra de imóvel', name: 'Compra de imóvel' },
  { value: 'Parcerias', name: 'Parcerias' },
  { value: 'Financiamento', name: 'Financiamento' },
  { value: 'Ouvidoria', name: 'Ouvidoria' },
  { value: 'Visita', name: 'Visita' },
  { value: 'SAC', name: 'SAC' },
]

const optionsCategory = [
  { value: '', name: '' },
  {
    value: 'Quero ser um corretor parceiro',
    name: 'Quero ser um corretor parceiro',
  },
  {
    value: 'Já sou parceiro e preciso de suporte',
    name: 'Já sou parceiro e preciso de suporte',
  },
]

const MessageForm = ({ isLoading, sendForm, setField, values }) => {
  const [touched, setTouched] = React.useState({})

  const handleBlur = field => () => setTouched({ ...touched, [field]: true })

  const handleErrorMessage = (value, errorMessage) =>
    value === '' ? 'Campo obrigatório' : errorMessage

  const onChange = e => {
    setTouched({ ...touched, [e.target.id]: false })
    setField(e.target.id, e.target.value)
  }

  const { message, name, subject, category, phone, property_locality } = values
  const subjectIsPartnerships = /Parcerias/i.test(subject)
  const buyPartnership = /Compra de imóvel/i.test(subject)

  const isDisabled =
    !validateFullName(name) ||
    !validateMandatoryPhone(phone) ||
    message === '' ||
    subject === '' ||
    (subjectIsPartnerships && category === '')

  const callSendForm = async () => {
    if (isDisabled) {
      setTouched({ ...touched, name: true, subject: true, message: true })
      return null
    }

    await sendForm()
    setField('name', '')
    setField('message', '')
    setField('subject', '')
    setTouched({})

    return null
  }

  return (
    <div className={styles.messageForm}>
      <Text fontSize="medium" className={styles.text}>
        Qual o motivo do seu contato?
      </Text>
      <div className={styles.main}>
        <Input
          errorMessage="Telefone Inválido"
          onBlur={handleBlur('phone')}
          hasError={!validateMandatoryPhone(phone) && touched.phone}
          mask={masks.phone}
          id="phone"
          label="Telefone*"
          onChange={onChange}
          placeholder=""
          value={phone}
          className={styles.inputPhone}
          labelClassName={styles.modalLabel}
        />
        <Select
          errorMessage="Selecione um assunto"
          onBlur={handleBlur('subject')}
          hasError={subject === '' && touched.subject}
          id="subject"
          onChange={onChange}
          value={subject}
          options={options}
          className={styles.inputSubject}
          label="Assunto*"
        />
        {buyPartnership && (
          <Input
            label="Localidade do imóvel"
            id="property_locality"
            onChange={onChange}
            value={property_locality}
            className={styles.inputSubject}
          />
        )}
        {subjectIsPartnerships && (
          <Select
            errorMessage="Selecione uma categoria"
            onBlur={handleBlur('category')}
            hasError={category === '' && touched.category}
            id="category"
            onChange={onChange}
            value={category}
            options={optionsCategory}
            className={styles.inputSubject}
            label="Categoria*"
          />
        )}
        <Input
          errorMessage={handleErrorMessage(name, 'Nome completo inválido')}
          onBlur={handleBlur('name')}
          hasError={!validateFullName(name) && touched.name}
          id="name"
          label="Nome completo*"
          onChange={onChange}
          placeholder=""
          value={name}
          className={styles.inputName}
          labelClassName={styles.modalLabel}
        />
        <Input
          errorMessage={handleErrorMessage(message)}
          onBlur={handleBlur('message')}
          hasError={message === '' && touched.message}
          id="message"
          label="Mensagem*"
          onChange={onChange}
          placeholder=""
          value={message}
          className={styles.inputMessage}
          rows={4}
          labelClassName={styles.modalLabel}
        />
      </div>
      <div className={styles.buttonAlign}>
        <Button
          id="btn_criar_fale_conosco"
          onClick={() => callSendForm()}
          disabled={isLoading || isDisabled}
          isLoading={isLoading}
          type="filled"
          textColor="whiteColor"
          fontSize="medium"
          className={styles.button}
        >
          Enviar dúvida
        </Button>
      </div>
    </div>
  )
}

MessageForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  sendForm: PropTypes.func.isRequired,
  setField: PropTypes.func.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
  }).isRequired,
}

export default React.memo(MessageForm)
