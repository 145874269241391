import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'components'
import classNames from 'classnames'
import styles from './AprraisedValue.module.scss'

const AprraisedValue = ({ show, value, label, type, strike, isCampaign }) => {
  if (!value) {
    return null
  }

  return (
    show && (
      <div
        className={classNames(styles.row, {
          [styles[type]]: !isCampaign,
          [styles.campaignAppraise]: isCampaign,
        })}
      >
        <Text fontSize={type === 'column' ? 'small' : 'medium'}>{label}</Text>
        <Text
          className={classNames(styles.value, { [styles.strike]: strike })}
          fontSize={type === 'column' ? 'medium' : 'regular'}
        >
          {`R$ ${value.toLocaleString('pt-br', { maximumFractionDigits: 0 })}`}
        </Text>
      </div>
    )
  )
}

AprraisedValue.propTypes = {
  show: PropTypes.bool,
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['row', 'column']),
  strike: PropTypes.bool,
  isCampaign: PropTypes.bool,
}

AprraisedValue.defaultProps = {
  type: 'column',
  strike: false,
  isCampaign: false,
  show: true,
}

export default React.memo(AprraisedValue)
