import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from 'components'
import ClassName from 'classnames'
import isIos from 'utils/isIos'
import styles from './Arrow.module.scss'

const Arrow = ({ onClick, arrowDirection }) => (
  <Button
    type="text"
    onClick={onClick}
    className={ClassName(styles[`${arrowDirection}Button`], styles.arrow)}
  >
    <Icon
      type="arrow"
      size={60}
      color="whiteColor"
      className={
        styles[isIos() ? `${arrowDirection}IconIOS` : `${arrowDirection}Icon`]
      }
    />
  </Button>
)

Arrow.propTypes = {
  onClick: PropTypes.func.isRequired,
  arrowDirection: PropTypes.string.isRequired,
}

export default Arrow
