import React from 'react'
import PropTypes from 'prop-types'
import { Feature, MoreFeatures, Collapse } from 'components'
import classNames from 'classnames'
import styles from './PropertyFeatures.module.scss'

const HOUSE_FEATURE_PRIORITY = [
  'Dormitórios',
  'Suítes',
  'Banheiros',
  'Salas',
  'Vagas na Garagem',
]
const OTHER_FEATURE_PRIORITY = ['Vagas na Garagem']
const AREA_PRIORITY = [
  'Área útil',
  'Área privativa',
  'Área construida',
  'Área do Terreno',
]

const sortPrioritiesFn = priorityList => (firstFeature, secondFeature) => {
  const firstPriorityIndex = priorityList.findIndex(
    priority => firstFeature.feature.indexOf(priority) !== -1
  )
  const secondPriorityIndex = priorityList.findIndex(
    priority => secondFeature.feature.indexOf(priority) !== -1
  )

  if (firstPriorityIndex === -1 && secondPriorityIndex !== -1) {
    return 1
  }

  if (secondPriorityIndex === -1 && firstPriorityIndex !== -1) {
    return -1
  }

  return firstPriorityIndex < secondPriorityIndex ? -1 : 1
}

const PropertyFeatures = ({ allFeatures, isHouseApartment }) => {
  const [viewMore, setViewMore] = React.useState(false)

  if (!allFeatures) {
    return null
  }

  const areaFeatureList = allFeatures.filter(({ feature }) => {
    return (
      feature && feature.indexOf('Área') !== -1 && feature !== 'Área de Serviço'
    )
  })
  areaFeatureList.sort(sortPrioritiesFn(AREA_PRIORITY))
  const areaFeature = areaFeatureList[0]

  const filteredFeatures = allFeatures.filter(({ amount, feature }) => {
    return (
      feature &&
      amount &&
      amount !== '00' &&
      (feature.indexOf('Área') === -1 || feature === 'Área de Serviço')
    )
  })

  filteredFeatures.sort(
    sortPrioritiesFn(
      isHouseApartment ? HOUSE_FEATURE_PRIORITY : OTHER_FEATURE_PRIORITY
    )
  )

  if (areaFeature) {
    filteredFeatures.splice(5, 0, areaFeature)
  }

  const displayFeatures = filteredFeatures.slice(0, 6)

  return (
    <div className={styles.body}>
      <div
        className={classNames(styles.featuredFeatures, {
          [styles.featuresStart]: displayFeatures.length < 5,
        })}
      >
        {displayFeatures.map(({ id, feature, amount, icon }) => (
          <Feature
            key={id}
            icon={icon}
            number={amount < 10 ? `0${amount}` : amount}
            feature={feature}
            type="column"
          />
        ))}
      </div>
      <div className={styles.details}>
        <summary>
          <Collapse onClick={() => setViewMore(!viewMore)} checked={viewMore} />
        </summary>
        {viewMore && (
          <div className={styles.allFeatures}>
            {allFeatures.map(({ id, feature, amount, icon }) => (
              <MoreFeatures
                key={id}
                icon={icon}
                number={amount < 10 ? `0${amount}` : amount}
                feature={feature}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
PropertyFeatures.propTypes = {
  allFeatures: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isHouseApartment: PropTypes.bool,
}

PropertyFeatures.defaultProps = {
  isHouseApartment: false,
}

export default React.memo(PropertyFeatures)
