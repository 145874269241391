import React from 'react'
import PropTypes from 'prop-types'
import { Text, Icon } from 'components'
import styles from './NoticeInformation.module.scss'

const NoticeInformation = ({ show }) =>
  show && (
    <span className={styles.noticeInformation}>
      <Icon type="money" size={32} />
      <Text bold color="lightTextColor">
        As condições de pagamento e honorários de terceiros podem ser
        consultados diretamente no Edital.
      </Text>
    </span>
  )

NoticeInformation.propTypes = {
  show: PropTypes.bool,
}

NoticeInformation.defaultProps = {
  show: false,
}

export default React.memo(NoticeInformation)
