/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Input,
  Modal,
  Text,
  Title,
  Optins,
  NotificationBox,
} from 'components'
import { getUtms } from 'utils/Utms'
import { validateEmail } from 'utils/validations'
import { useRoot } from 'store'
import {
  toastError,
  toastSuccess,
} from 'components/ToastComponent/ToastComponent'
import { gaEvent } from 'utils/ga'
import isPreRendering from 'utils/isPreRendering'
import {
  setCustomerInfoLocalStorage,
  getCustomerInfoLocalStorage,
} from 'utils/customerInfoLocalStorage'
import { SaveEsquentaBlackout } from 'service/apiSpreadSheet/actions/saveEsquentaBlackout'
import styles from './LeadModalContainer.module.scss'

const LeadModalContainer = ({ closeModal, opened, bannerBlackout }) => {
  const [email, setEmail] = useState(getCustomerInfoLocalStorage().email || '')
  const [checkOffers, setCheckOffers] = useState(true)
  const [checkOffersResale, setCheckOffersResale] = useState(true)
  const [touched, setTouched] = useState({})
  const [loading, setLoading] = useState(false)

  const { sendEmail, getSendLeadEmail } = useRoot()
  const { sendLeadEmailLoading, sendLeadEmailError } = getSendLeadEmail()
  const isDisabled = !validateEmail(email) || !checkOffers

  const handleBlur = field => () => setTouched({ ...touched, [field]: true })

  const handleErrorMessage = (value, errorMessage) =>
    value === '' ? 'Campo obrigatório' : errorMessage

  const handleClose = () => {
    setTouched({})
    closeModal()
  }

  const onChange = e => {
    setTouched({ ...touched, [e.target.id]: false })
    setEmail(e.target.value)
  }

  const sendForm = async () => {
    setTouched({ ...touched, email: true })

    if (!validateEmail(email)) {
      return null
    }

    const utms = getUtms()
    const form = { ...utms }
    form.email = email.trim()

    setCustomerInfoLocalStorage({
      email: form.email,
      ...getCustomerInfoLocalStorage(),
    })

    try {
      if (!bannerBlackout) {
        await sendEmail(form, checkOffers, checkOffersResale)

        if (sendLeadEmailError) {
          return toastError('Não foi possível enviar seu email!')
        }
        if (!isPreRendering()) {
          gaEvent({
            category: 'lead_rosie_alerta',
            action: 'submit',
          })
        }
      } else {
        setLoading(true)
        await SaveEsquentaBlackout({
          email: form.email,
          optinResale: checkOffersResale,
          optinWhiteLabel: checkOffers,
        })
      }
      toastSuccess('Email enviado! Agora podemos te manter informado.')
    } finally {
      setLoading(false)
      closeModal()
    }
  }

  const notificationBoxProps = {
    renderTitle: () => {
      return (
        <Title type="h1" color="whiteColor" fontSize="large">
          <b className={styles.boldText}>Ei! Deixe o seu e-mail </b>
        </Title>
      )
    },
    renderMessage: () => {
      return (
        <Text color="whiteColor" fontSize="medium">
          {bannerBlackout
            ? 'Assim consigo te avisar em primeira mão de todas as ofertas Blackout'
            : 'Assim consigo te avisar sempre que tivermos novidades por aqui'}
        </Text>
      )
    },
  }

  const { renderTitle, renderMessage } = notificationBoxProps

  return (
    <Modal
      opened={opened}
      closeModal={handleClose}
      className={styles.modalLead}
    >
      <section className={styles.leadModal}>
        <div>
          <NotificationBox
            renderTitle={renderTitle}
            renderMessage={renderMessage}
          />
          <div className={styles.main}>
            <Title
              color="primaryColor"
              type="h2"
              fontSize="small"
              className={styles.question}
            >
              Onde gostaria de receber as ofertas?
            </Title>
            <Input
              errorMessage={handleErrorMessage(email, 'Email Inválido')}
              onBlur={handleBlur('email')}
              hasError={!validateEmail(email) && touched.email}
              id="email"
              label="Seu e-mail*"
              type="email"
              onChange={onChange}
              placeholder=""
              value={email}
              className={styles.inputEmail}
              labelClassName={styles.modalLabel}
            />
            <div className={styles.optins}>
              <Optins
                checkOffers={checkOffers}
                setCheckOffers={setCheckOffers}
                checkOffersResale={checkOffersResale}
                setCheckOffersResale={setCheckOffersResale}
              />
            </div>
          </div>
        </div>
        <footer className={styles.footer}>
          <Button
            onClick={sendForm}
            type="filled"
            className={styles.button}
            fontSize="medium"
            textColor="whiteColor"
            disabled={sendLeadEmailLoading || isDisabled || loading}
            isLoading={sendLeadEmailLoading}
          >
            Enviar
          </Button>
        </footer>
      </section>
    </Modal>
  )
}

LeadModalContainer.propTypes = {
  closeModal: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
}

export default React.memo(LeadModalContainer)
