import React from 'react'
import { Text, Title } from 'components'
import propTypes from 'prop-types'
import aspasIcon from 'assets/svgs/aspas.svg'
import styles from './CommentCard.module.scss'

const CommentCard = ({ depoiment, type, name, age }) => {
  return (
    <article className={styles.card}>
      <figure className={styles.icon}>
        <img loading="lazy" src={aspasIcon} alt="ícone de aspas" />
      </figure>
      <Text fontSize="large" color="darkBlueColor" type="h4">
        {depoiment}
      </Text>
      <footer>
        <div className={styles.typeContainer}>
          <hr />
          <Text fontSize="medium" color="lightBlueColor" alignCenter>
            {type}
          </Text>
          <hr />
        </div>
        <Title color="darkBlueColor" type="h2" alignCenter>
          {name}
        </Title>
        <Text fontSize="medium" color="lightBlueColor" alignCenter>
          {age}
        </Text>
      </footer>
    </article>
  )
}

CommentCard.propTypes = {
  depoiment: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  age: propTypes.string.isRequired,
}
export default React.memo(CommentCard)
