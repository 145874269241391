import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg } from './propTypes'

const Percent = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="percent"
          d="M29.524 14.438a18.96 18.96 0 0 1-2.857-6.896 2.8 2.8 0 0 0-2.209-2.21 18.967 18.967 0 0 1-6.896-2.856 2.799 2.799 0 0 0-3.124 0 18.967 18.967 0 0 1-6.896 2.857 2.8 2.8 0 0 0-2.21 2.209 18.96 18.96 0 0 1-2.856 6.896 2.8 2.8 0 0 0 0 3.124 18.96 18.96 0 0 1 2.857 6.896 2.799 2.799 0 0 0 2.209 2.209 18.964 18.964 0 0 1 6.896 2.857 2.799 2.799 0 0 0 3.124 0 18.964 18.964 0 0 1 6.896-2.857 2.8 2.8 0 0 0 2.21-2.209 18.96 18.96 0 0 1 2.856-6.896 2.8 2.8 0 0 0 0-3.124zm-1.818 1.907a21.134 21.134 0 0 0-3.184 7.688.62.62 0 0 1-.489.489 21.138 21.138 0 0 0-7.688 3.184.62.62 0 0 1-.69 0 21.137 21.137 0 0 0-7.688-3.184.619.619 0 0 1-.489-.489 21.141 21.141 0 0 0-3.184-7.688.62.62 0 0 1 0-.69 21.142 21.142 0 0 0 3.184-7.688.618.618 0 0 1 .489-.488 21.141 21.141 0 0 0 7.688-3.185.62.62 0 0 1 .69 0 21.142 21.142 0 0 0 7.688 3.185c.248.048.44.24.489.488a21.138 21.138 0 0 0 3.184 7.688c.14.21.14.48 0 .69zm-6.631-3.873l-8.603 8.603a1.094 1.094 0 1 1-1.547-1.547l8.603-8.603a1.094 1.094 0 0 1 1.547 1.547zm-6.132-.774a3.248 3.248 0 0 0-3.245-3.244 3.248 3.248 0 0 0-3.244 3.244 3.248 3.248 0 0 0 3.244 3.245 3.248 3.248 0 0 0 3.245-3.245zm-4.302 0a1.058 1.058 0 0 1 2.115 0 1.058 1.058 0 0 1-2.115 0zm9.66 5.36a3.248 3.248 0 0 0-3.244 3.243 3.248 3.248 0 0 0 3.245 3.245 3.248 3.248 0 0 0 3.244-3.245 3.248 3.248 0 0 0-3.244-3.244zm0 4.3a1.058 1.058 0 1 1 1.057-1.057c0 .583-.474 1.058-1.056 1.058z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#percent" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#percent"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Percent.propTypes = {
  ...propTypesSvg,
}

Percent.defaultProps = {
  viewBox: '0 0 32 32',
  color: 'darkColor',
}

export default Percent
