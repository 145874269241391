import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'components'
import classNames from 'classnames'
import styles from './Checkbox.module.scss'

const Checkbox = ({
  id,
  option,
  optionPosition,
  checked,
  onChange,
  className,
}) => (
  <label
    htmlFor={id}
    className={classNames(
      className,
      styles.container,
      styles[`option-${optionPosition}`]
    )}
  >
    <Text bold={checked}>{option}</Text>
    <input id={id} type="checkbox" checked={checked} onChange={onChange} />
    <span className={styles.checkmark} />
  </label>
)

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  option: PropTypes.string.isRequired,
  id: PropTypes.string,
  optionPosition: PropTypes.oneOf([
    'left',
    'right',
    'left-joined',
    'right-joined',
    'terms',
  ]),
  className: PropTypes.string,
}
Checkbox.defaultProps = {
  optionPosition: 'left',
  className: '',
  id: '',
}

export default React.memo(Checkbox)
