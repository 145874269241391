import React from 'react'
import PropTypes from 'prop-types'
import ClassName from 'classnames'
import { Button } from 'components'
import styles from './Selector.module.scss'

const Selector = ({
  active,
  onClick,
  options,
  className,
  tabbed,
  buttonClass,
}) => {
  return (
    <div className={ClassName(styles.selectorContainer, className)}>
      <div className={ClassName(styles.wrapper, tabbed && styles.tabs)}>
        {options.map(({ name, label }) => (
          <Button
            key={name}
            type="filled"
            onClick={() => onClick(name)}
            className={ClassName(
              buttonClass,
              styles.button,
              !active[name] && styles.disabled
            )}
            fontSize="xsmall"
          >
            <p>{label}</p>
          </Button>
        ))}
      </div>
    </div>
  )
}

Selector.propTypes = {
  active: PropTypes.objectOf(PropTypes.bool).isRequired,
  onClick: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ).isRequired,
  className: PropTypes.string,
  tabbed: PropTypes.bool,
  buttonClass: PropTypes.string,
}

Selector.defaultProps = {
  className: '',
  tabbed: false,
  buttonClass: '',
}

export default React.memo(Selector)
