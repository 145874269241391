import React from 'react'
import PropTypes from 'prop-types'
import { CategoryTitle, Text } from 'components'
import styles from './PreSale.module.scss'

const PreSale = ({ isPreSale }) => {
  if (!isPreSale) {
    return null
  }

  return (
    <section>
      <CategoryTitle icon="question" title="Entenda a Pré-Venda" />
      <Text fontSize="medium" className={styles.darkColor}>
        <p>
          A pré-venda de imóveis Resale é uma oportunidade única de você comprar
          imóveis que somente seriam disponibilizados para venda daqui a alguns
          meses. São imóveis que possuem pendências simples e que não impedem
          que sejam vendidos. A vantagem é que enquanto a pendência é resolvida,
          você já garante que ele vai ser seu e com o preço congelado.
        </p>

        <br />

        <p>
          <b>Como funciona</b>
        </p>

        <br />

        <p>
          1. Antes de realizar a proposta de compra veja abaixo qual a pendência
          existente sobre o imóvel e quem será o responsável por regularizá-la;
        </p>

        <p>
          2. Caso a sua proposta seja a vencedora e, após a assinatura do
          contrato de compra/venda, nosso time entrará em contato para lhe
          informar sobre os próximos passos da regularização;{' '}
        </p>

        <p>
          3. Somente após a regularização dessa pendência é que se iniciará o
          processo de escrituração do imóvel, portanto, você tem que levar isso
          em conta antes de iniciar a sua compra. O prazo pode variar de acordo
          com o tipo de pendência e a cidade onde o imóvel está localizado;{' '}
        </p>

        <p>
          4. Quando a regularização do imóvel for de responsabilidade do
          Vendedor os custos envolvidos ficarão por conta dele.
        </p>
      </Text>
    </section>
  )
}

PreSale.propTypes = {
  isPreSale: PropTypes.bool.isRequired,
}

export default PreSale
