import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const ServiceArea = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="serviceArea"
          d="M25.198 2H6.765C5.24 2 4 3.227 4 4.734v22.532C4 28.773 5.24 30 6.765 30h18.433c1.525 0 2.765-1.227 2.765-2.734V4.734c0-1.507-1.24-2.734-2.765-2.734zM6.765 4.188h18.433a.55.55 0 0 1 .553.546v5.87H6.212v-5.87a.55.55 0 0 1 .553-.546zm18.433 23.625H6.765a.55.55 0 0 1-.553-.547V12.792h19.54v14.474a.55.55 0 0 1-.554.547zm-9.216-12.907c-3.009 0-5.457 2.42-5.457 5.396 0 2.975 2.448 5.396 5.457 5.396 3.008 0 5.456-2.42 5.456-5.396 0-2.975-2.448-5.396-5.456-5.396zm0 8.604c-1.79 0-3.245-1.439-3.245-3.208 0-1.77 1.456-3.208 3.245-3.208 1.788 0 3.244 1.439 3.244 3.208 0 1.77-1.456 3.208-3.244 3.208zM17.05 7.396a1.1 1.1 0 0 1 1.106-1.094h4.35a1.1 1.1 0 0 1 1.106 1.094 1.1 1.1 0 0 1-1.106 1.094h-4.35a1.1 1.1 0 0 1-1.106-1.094zm-8.7 0a1.1 1.1 0 0 1 1.105-1.094h2.175a1.1 1.1 0 0 1 1.106 1.094A1.1 1.1 0 0 1 11.63 8.49H9.456A1.1 1.1 0 0 1 8.35 7.396z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#serviceArea" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#serviceArea"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

ServiceArea.propTypes = {
  ...propTypesSvg,
}

ServiceArea.defaultProps = {
  ...defaultPropTypesSvg,
}

export default ServiceArea
