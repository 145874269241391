import React from 'react'
import { SuccessIcon } from 'assets/svgs'
import PropTypes from 'prop-types'
import { Button, Text, Title } from 'components'
import classNames from 'classnames'
import styles from './SuccessCard.module.scss'

const SuccessCard = ({ handleClose, noModal }) => {
  return (
    <section
      className={classNames(styles.successContainer, {
        [styles.noModal]: noModal,
      })}
    >
      <figure>
        <SuccessIcon color="primaryColor" />
      </figure>
      <Title type="h2" fontSize="xlarge" color="primaryColor">
        Proposta enviada com sucesso!
      </Title>
      <Text fontSize="medium">
        Nossa equipe já está avaliando a sua solicitação. Assim que tivermos
        novidades entraremos em contato.
      </Text>
      <Button
        type="outline"
        className={styles.button}
        fontSize="medium"
        onClick={() => handleClose()}
      >
        Fechar
      </Button>
    </section>
  )
}
SuccessCard.propTypes = {
  handleClose: PropTypes.func.isRequired,
  noModal: PropTypes.bool,
}

SuccessCard.defaultProps = {
  noModal: false,
}

export default React.memo(SuccessCard)
