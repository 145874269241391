import React from 'react'
import { useFilter } from 'store'

import { SelectLocalityInput } from 'components'
import styles from './SearchHeader.module.scss'

const SearchHeader = () => {
  const {
    setArrayFilter,
    $filter: {
      filters: { arrayFilters },
    },
  } = useFilter()

  return (
    <div className={styles.searchHeader}>
      <span className={styles.inputContainer}>
        <SelectLocalityInput
          onChange={value => setArrayFilter(arrayFilters.localities.key, value)}
          selectedLocalities={arrayFilters.localities.value}
          options={arrayFilters.localities.options}
          invertIconOnX
        />
      </span>
    </div>
  )
}

SearchHeader.propTypes = {}

SearchHeader.defaultProps = {}

export default SearchHeader
