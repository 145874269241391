import { calcTimeDelta } from 'react-countdown'
import { formatDate } from 'components/CallToAction/utils'
import {
  isAuctionCheck,
  isDirectOrAgencySale,
  isCompetition,
  isLearnMore,
  isClosed,
  isSold,
  isUpFrontValue,
  isSaleValue,
  isReferenceValue,
  isSacre,
  isHideDisplay,
  paymentConditionSwitch,
  numberWithDots,
  checkIsActiveByDate,
  isAgencySale,
} from './general'
import { labelsDictionary } from '../dictionaries'

export const setAuctionAndCTALabels = ({
  tipo_de_venda,
  data_limite,
  datas_leilao,
  call_to_action_url,
  call_to_actions,
  status_da_venda,
}) => {
  const tipoVenda = tipo_de_venda.toLowerCase()
  let callToActionLabel
  let showContactUs = false
  let contactButtonLabel
  let showAuctionDate = false
  let showMultipleAuctionDate = false
  let auctionInfo = []
  let callToActionUrl
  let isAuction = false
  let remainingCouncurrenceTime = false
  let isConcurrenceOver = false
  let isPublicCompetition = false
  let activeContactUs = false

  if (isAgencySale(tipoVenda)) {
    callToActionLabel = labelsDictionary.directSale.CB
    showContactUs = false
  } else if (isDirectOrAgencySale(tipoVenda)) {
    callToActionLabel = labelsDictionary.directSale.CTA
    showContactUs = true
    contactButtonLabel = labelsDictionary.directSale.CB
  } else if (isAuctionCheck(tipoVenda)) {
    if (data_limite && !datas_leilao) {
      showAuctionDate = true
      auctionInfo.push({
        data: data_limite.split(' ', 2)[0],
        hora: data_limite.split(' ', 2)[1],
        label: labelsDictionary.auction.info,
      })
    }
    if (datas_leilao) {
      showMultipleAuctionDate = true
      auctionInfo = Object.values(datas_leilao)
      auctionInfo.forEach((_, index) => {
        auctionInfo[index].label = `${index + 1}º Leilão`
      })
    }

    const [isAuctionActive, expiration_date] = checkIsActiveByDate(data_limite)

    const actionLabel =
      data_limite && expiration_date ? 'Vendido' : labelsDictionary.auction.CTA

    activeContactUs = isAuctionActive
    showContactUs = true
    callToActionLabel = actionLabel
    callToActionUrl = call_to_action_url
    contactButtonLabel = labelsDictionary.auction.CB
    isAuction = isAuctionActive
  } else if (isCompetition(tipoVenda)) {
    showContactUs = true
    callToActionLabel = labelsDictionary.competition.CTA
    contactButtonLabel = labelsDictionary.competition.CB

    if (data_limite) {
      showAuctionDate = true
      auctionInfo.push({
        data: data_limite.split(' ', 2)[0],
        hora: data_limite.split(' ', 2)[1],
        label: labelsDictionary.competition.info,
      })

      remainingCouncurrenceTime =
        auctionInfo[0] &&
        calcTimeDelta(formatDate(auctionInfo[0].data, auctionInfo[0].hora))

      isConcurrenceOver =
        remainingCouncurrenceTime &&
        callToActionLabel === labelsDictionary.competition.CTA &&
        remainingCouncurrenceTime.completed

      if (isConcurrenceOver) {
        callToActionLabel = labelsDictionary.over.CTA
      }
    }

    const [isAuctionActive] = checkIsActiveByDate(data_limite)

    activeContactUs = isAuctionActive
    isPublicCompetition = true
  }

  if (isLearnMore(call_to_actions)) {
    callToActionLabel = labelsDictionary.learnMore.CTA
  }

  if (isClosed(status_da_venda)) {
    callToActionLabel = labelsDictionary.over.CTA
    callToActionUrl = ''
  } else if (isSold(status_da_venda)) {
    callToActionLabel = labelsDictionary.over.CTA
    callToActionUrl = ''
  }

  return {
    callToActionLabel,
    showContactUs,
    contactButtonLabel,
    showAuctionDate,
    showMultipleAuctionDate,
    auctionInfo,
    callToActionUrl,
    isAuction,
    isConcurrenceOver,
    isPublicCompetition,
    activeContactUs,
  }
}

export const setValuesAndConsiderations = (
  carteira,
  valores,
  callToActionConsiderations
) => {
  let { valor_exibido } = carteira
  const callToActionConsiderationsIn = [...callToActionConsiderations]
  if (!valor_exibido) {
    valor_exibido = ''
  }

  const { condicoes } = carteira
  let mainValue
  let showDiscount = true
  let hideValue = false
  let showProposal = true
  let hideConditions = false

  if (isHideDisplay(valor_exibido)) {
    hideConditions = true
  }
  if (isSaleValue(valor_exibido)) {
    mainValue = valores.valor_venda

    if (condicoes.a_vista) {
      const { desconto } = condicoes.a_vista
      if (desconto) {
        callToActionConsiderationsIn.unshift(
          `* Imóvel tem ${desconto}% de desconto para pagamento à vista;`
        )
      }
    }
  } else if (isUpFrontValue(valor_exibido)) {
    mainValue = valores.valor_a_vista

    callToActionConsiderationsIn.unshift(`* Valor para pagamento à vista;`)
  } else if (isReferenceValue(valor_exibido)) {
    mainValue = valores.valor_referencia
    callToActionConsiderationsIn.push(`*Valor mínimo para envio de propostas;`)
  } else if (isHideDisplay(valor_exibido)) {
    showDiscount = false
    hideValue = true
    showProposal = false
  }

  return {
    hideConditions,
    showDiscount,
    hideValue,
    showProposal,
    mainValue,
    callToActionConsiderationsIn,
  }
}

export const setPaymentMethod = ({
  valores,
  condicoes,
  formas_pagamento_card,
}) => {
  const formsInstallment = [...formas_pagamento_card]
  let isDirectInstallment = false

  const { parcelamento_direto } = condicoes

  if (parcelamento_direto) {
    isDirectInstallment = true

    formsInstallment.push({
      title: 'Parcelamento Direto',
      content: [
        {
          label: 'Entrada mínima',
          value: parcelamento_direto.entrada_minima,
        },
      ],
    })

    const position = formsInstallment
      .map(element => element.title)
      .indexOf('Parcelamento Direto')

    const {
      valor_prestacao,
      indexador,
      prestacoes,
      juros,
      juros_periodo,
      correcao,
      correcao_periodo,
    } = condicoes.parcelamento_direto

    const indexText = indexador ? ` + ${indexador}` : ''
    const jurosText = juros > 0 ? ` + juros de ${juros}% ${juros_periodo}` : ''
    const correcaoText =
      correcao > 0 ? ` + correção de ${correcao}% ${correcao_periodo}` : ''

    let labelText = `Saldo em ${prestacoes}x${jurosText}${correcaoText}${indexText} de`
    const valueText = `R$ ${numberWithDots(valor_prestacao)}`

    if (isSacre(condicoes)) {
      labelText = `Saldo em até 120x + Amortização PRICE + Correção TR + juros de 8% a.a.`
    }

    formsInstallment[position].content.push({
      label: labelText,
      value: valueText,
    })

    if (isSacre(condicoes)) {
      formsInstallment[position].content.push({
        label: 'Valor total (sem honorários)',
        value: Number(valores.valor_sem_comissao.toFixed(0)),
      })
    } else {
      formsInstallment[position].content.push({
        label: 'Valor total',
        value: valores.valor_a_prazo,
      })
    }
  }
  return { formsInstallment, isDirectInstallment }
}

export const setFinancingConditions = (condicoes, formas_pagamento_card) => {
  const { financiamento_imobiliario } = condicoes
  let showFinanceEstate = false
  let financialText
  const formasPagamentoIn = [...formas_pagamento_card]

  if (financiamento_imobiliario) {
    showFinanceEstate = true
    formasPagamentoIn.push({
      title: 'Financiamento Imobiliário',
      content: [
        {
          label: 'Entrada mínima',
          value: condicoes.financiamento_imobiliario.entrada_minima,
        },
        {
          label: 'Saldo total',
          value: condicoes.financiamento_imobiliario.saldo_total,
        },
      ],
    })

    const { banco, escolha_banco } = financiamento_imobiliario

    financialText = paymentConditionSwitch(escolha_banco, banco)
  }
  return {
    showFinanceEstate,
    formasPagamentoIn,
    financialText,
  }
}
