import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Loading, Icon, Text } from 'components'
import styles from './Button.module.scss'

const colorIcon = (darkColor, type, classIcon, disabled, textColor) => {
  if (disabled) {
    return 'whiteColor'
  }

  if (classIcon) {
    return classIcon
  }

  if (type === 'outline') {
    if (darkColor) {
      return 'darkColor'
    }
    return 'primaryColor'
  }

  if (textColor) {
    return textColor
  }

  return 'whiteColor'
}

const Button = ({
  showButton,
  children,
  onClick,
  disabled,
  type,
  darkColor,
  textColor,
  redColor,
  whiteColor,
  backgroundColor,
  iconPosition,
  icon,
  classIcon,
  fontSize,
  className,
  isLoading,
  textLoading,
  name,
  id,
  bold,
}) => {
  return (
    showButton && (
      <button
        name={name}
        type="button"
        onClick={onClick}
        className={classNames(
          className,
          styles[type],
          styles[`direction-${iconPosition}`],
          styles[`font-${fontSize}`],
          [className],
          {
            [styles[`background-${backgroundColor}`]]:
              type === 'filled' && !disabled,
            [styles[`border-${backgroundColor}`]]:
              type === 'outline' && !disabled,
            [styles[redColor ? 'redBackground' : 'primaryBackground']]:
              type === 'filled' && !disabled,
            [styles[whiteColor ? 'whiteBackground' : 'primaryBackground']]:
              type === 'filled' && !disabled,
            [styles.paddingButton]: iconPosition,
            [styles.disabled]: disabled,
            [styles.bold]: bold,
            [styles[`text-${textColor}`]]: textColor && !disabled,
          }
        )}
        disabled={disabled}
        id={id}
      >
        {isLoading ? (
          <span className={styles.containerLoading}>
            <Loading size="small" />
            {textLoading && (
              <Text className={styles.textLoading}>{textLoading}</Text>
            )}
          </span>
        ) : (
          <>
            {iconPosition && (
              <div className={styles[iconPosition]}>
                <Icon
                  color={colorIcon(
                    darkColor,
                    type,
                    classIcon,
                    disabled,
                    textColor
                  )}
                  type={icon}
                  size={16}
                />
              </div>
            )}
            {children}
          </>
        )}
      </button>
    )
  )
}

Button.propTypes = {
  showButton: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['outline', 'filled', 'text']),
  darkColor: PropTypes.bool,
  redColor: PropTypes.bool,
  whiteColor: PropTypes.bool,
  backgroundColor: PropTypes.oneOf([
    'primaryColor',
    'secondColor',
    'darkColor',
    'whiteColor',
    'redColor',
    '',
  ]),
  textColor: PropTypes.oneOf([
    'primaryColor',
    'secondColor',
    'darkColor',
    'whiteColor',
    'goldenColor',
    '',
  ]),
  onClick: PropTypes.func.isRequired,
  iconPosition: PropTypes.oneOf(['right', 'left', '']),
  icon: PropTypes.string,
  classIcon: PropTypes.oneOf(['primaryColor', 'darkColor', 'whiteColor', '']),
  fontSize: PropTypes.oneOf(['xsmall', 'small', 'medium', 'regular']),
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  textLoading: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  bold: PropTypes.bool,
}

Button.defaultProps = {
  showButton: true,
  children: '',
  darkColor: false,
  redColor: false,
  whiteColor: false,
  textColor: 'whiteColor',
  backgroundColor: 'primaryColor',
  disabled: false,
  type: 'filled',
  iconPosition: '',
  classIcon: '',
  fontSize: 'small',
  className: '',
  isLoading: false,
  icon: 'bell',
  name: undefined,
  textLoading: '',
  id: '',
  bold: false,
}

export default React.memo(Button)
