import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'components'
import commaNumber from 'comma-number'
import Icon, { ICONS_TYPES } from 'components/Icon/Icon'
import classNames from 'classnames'
import styles from './Feature.module.scss'

const Feature = ({ number, feature, icon, type, propertyCard }) => {
  if (!number || number === '00' || number === '0') {
    return null
  }

  const numberReplace = number.toString().replace('.', ',')
  const formattedNumber = commaNumber(numberReplace, '.', ',')
  return (
    <figure className={styles[`feature-${type}`]}>
      <div
        className={classNames(styles.icon, {
          [styles.propertyCard]: propertyCard,
        })}
      >
        <Icon type={icon} color="primaryColor" />
      </div>
      <figcaption
        className={styles[`${type === 'rowWithoutName' ? 'row' : type}-text`]}
      >
        {type === 'column' && (
          <Text title={feature} className={styles.label}>
            {feature}
          </Text>
        )}
        <Text
          fontSize={type === 'column' ? 'large' : 'small'}
          className={styles.number}
        >
          {`${formattedNumber}${icon === 'footage' ? ' m²' : ''}`}
        </Text>
        {type === 'row' && <Text className={styles.label}>{feature}</Text>}
      </figcaption>
    </figure>
  )
}

Feature.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  feature: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(ICONS_TYPES).isRequired,
  type: PropTypes.oneOf(['row', 'column', 'rowWithoutName']).isRequired,
  propertyCard: PropTypes.bool,
}

Feature.defaultProps = {
  propertyCard: false,
}

export default React.memo(Feature)
