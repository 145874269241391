import React from 'react'

import { FaStar } from 'react-icons/fa'

import PropTypes from 'prop-types'

import styles from './Score.module.scss'

export default function Score({ score, updatedAt }) {
  return (
    <div className={styles.container}>
      <div className={styles.scoreContainer}>
        <div className={styles.left}>
          <span>Score do imóvel</span>
        </div>
        <div className={styles.right}>
          <FaStar color="#F2C94C" />
          <b>{score}</b>/1000
        </div>
      </div>
      <span className={styles.updatedAt}>Atualizado em {updatedAt}</span>
    </div>
  )
}

Score.propTypes = {
  score: PropTypes.number.isRequired,
  updatedAt: PropTypes.string.isRequired,
}
