import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'components'
import styles from './FiltersCategory.module.scss'

const renderOptions = options => {
  return options.map(({ id, name, onChange, checked }) => {
    return (
      <Checkbox
        id={id}
        option={name}
        optionPosition="right-joined"
        onChange={onChange}
        checked={checked}
        key={id}
      />
    )
  })
}

const FiltersCategory = ({ options, forceSingleRow }) => {
  const hasLongNames = options.filter(({ name }) => {
    return name && name.length > 20
  })

  const className =
    hasLongNames.length > 0 || forceSingleRow ? 'one-column' : 'two-columns'
  return <div className={styles[className]}>{renderOptions(options)}</div>
}

FiltersCategory.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
      onChange: PropTypes.func.isRequired,
    })
  ).isRequired,
  forceSingleRow: PropTypes.bool.isRequired,
}

export default React.memo(FiltersCategory)
