import { useEffect } from 'react'
import { useFilter } from 'store'

export const useLoadLocalities = () => {
  const {
    fetchLocalities,
    $filter: {
      filters: { arrayFilters },
    },
  } = useFilter()

  const localitiesOptions = arrayFilters.localities.options

  useEffect(() => {
    if (!localitiesOptions.length) fetchLocalities()
    // eslint-disable-next-line
  }, [localitiesOptions])
}
