import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const ServiceBath = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="serviceBath"
          d="M20.594 28.21h1.471a.98.98 0 0 1 .99.97.98.98 0 0 1-.99.97H10.926a.98.98 0 0 1-.99-.97.98.98 0 0 1 .99-.97h1.471a4.192 4.192 0 0 0 .337-2.947C9.842 23.944 7.847 21.2 7.557 18.05a2.61 2.61 0 0 1-1.707-2.434A2.61 2.61 0 0 1 7.512 13.2V5.253c0-1.878 1.562-3.403 3.481-3.403h11.005c1.919 0 3.481 1.525 3.481 3.403v7.944a2.61 2.61 0 0 1 1.671 2.42 2.61 2.61 0 0 1-1.707 2.434c-.29 3.153-2.29 5.9-5.186 7.216-.25.99-.13 2.03.337 2.942zM7.83 15.616a.67.67 0 0 0 .672.664h15.996a.669.669 0 0 0 .672-.664.67.67 0 0 0-.672-.664H8.502a.669.669 0 0 0-.672.664zM23.5 5.253c0-.806-.673-1.462-1.502-1.462H10.993c-.828 0-1.5.656-1.5 1.462v7.76h14.006v-7.76zm-7 18.883a7.058 7.058 0 0 0 2.616-.498c2.306-.918 3.963-2.985 4.314-5.416H9.57c.484 3.364 3.432 5.914 6.93 5.914zm1.959 4.073a6.085 6.085 0 0 1-.299-2.283 9.141 9.141 0 0 1-3.329-.002 6.087 6.087 0 0 1-.299 2.285h3.927z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#serviceBath" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#serviceBath"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

ServiceBath.propTypes = {
  ...propTypesSvg,
}

ServiceBath.defaultProps = {
  ...defaultPropTypesSvg,
}

export default ServiceBath
