import React from 'react'
import PropTypes from 'prop-types'
import { CommentCard } from 'components'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import responsive from './responsive'
import styles from './CarouselDepoiments.module.scss'

const CarouselDepoiments = ({ data }) => {
  if (!data) {
    return null
  }

  return (
    <Carousel
      additionalTransfrom={0}
      responsive={responsive}
      swipeable
      partialVisible
      arrows
      removeArrowOnDeviceType={['mobile']}
      containerClass={styles.list}
    >
      {!!data.length &&
        data.map(item => (
          <CommentCard
            depoiment={item.depoiment}
            type={item.type}
            name={item.name}
            age={item.subtitle}
          />
        ))}
    </Carousel>
  )
}

CarouselDepoiments.propTypes = {
  data: PropTypes.arrayOf([]).isRequired,
}

export default CarouselDepoiments
