import React from 'react'
import PropTypes from 'prop-types'
import { useCountDown } from 'hooks'
import styles from './ActionCampaigns.module.scss'

const ActionCampaigns = ({ titleCountDown, campaignsData }) => {
  const { renderCountDown } = useCountDown({
    titleCountDown,
    styles,
    callOn: 'AuctionCampaigns',
  })
  return renderCountDown({ campaignsData })
}

ActionCampaigns.propTypes = {
  campaignsData: PropTypes.arrayOf([]).isRequired,
  titleCountDown: PropTypes.string.isRequired,
  displayCountdownFirst: PropTypes.bool.isRequired,
  displayCountdownSecond: PropTypes.bool.isRequired,
}

export default React.memo(ActionCampaigns)
