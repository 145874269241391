/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { navigate, Link } from '@reach/router'
import { Helmet } from 'react-helmet'
import isPreRendering from 'utils/isPreRendering'

import { BaseLayout } from 'templates'
import { BannerImageContainer, BestSellingPropertyContainer } from 'containers'
import { setUtms } from 'utils/Utms'
import {
  BannerCarousel,
  // BannerCarousel,
  BannerPropertyInfo,
  Button,
  DidYouKnowNotification,
  MaintenanceNotification,
} from 'components'
import { usePageView } from 'hooks/useGAPageView'
import partnershipDesktop from 'assets/images/partnership-desktop2.png'
import partnershipMobile from 'assets/images/partnership-mobile.png'
import partnershipXLarge from 'assets/images/partnership-xLarge.png'
import { useLoadLocalities } from 'hooks/useLoadLocalities'
import styles from './HomeStyles.module.scss'

const Home = ({ location: { search } }) => {
  usePageView()
  useLoadLocalities()

  React.useEffect(() => {
    setUtms(search)
    const source = JSON.parse(window.localStorage.getItem('utms'))

    if (source) {
      const alreadyAccessed =
        window.localStorage.getItem(source.utm_source) === 'true'

      if (!alreadyAccessed) {
        window.localStorage.setItem(source.utm_source, false)
      }
    }
  }, [search])
  const isMaintenance = Boolean(process.env.REACT_APP_MAINTENANCE_NOTICE)

  return (
    <>
      <Helmet>
        <title>Imóveis Emgea | Outlet Imobiliário</title>
        <meta
          property="og:title"
          content="Imóveis Emgea | Outlet Imobiliário"
        />
        <meta
          property="og:description"
          content="Descubra o que são os imóveis com desconto do Emgea!"
        />
        <meta property="og:url" content="" />
      </Helmet>
      <BaseLayout backgroundColor="white" enableSearch>
        <main className={styles.home}>
          {isMaintenance && !isPreRendering() && <MaintenanceNotification />}
          <div className={styles.bannerContainer}>
            <BannerCarousel />
          </div>
          <BannerPropertyInfo />
          <BannerImageContainer
            bannerImageMobile={partnershipMobile}
            bannerImageDesk={partnershipDesktop}
            bannerImageDeskLarge={partnershipXLarge}
            link="/perguntas-frequentes"
            newTab
          />
          <BestSellingPropertyContainer />
          <div className={styles.buttonContainer}>
            <Link to="/busca" className={styles.linkOpportunity}>
              <Button
                onClick={() => {
                  window.scrollTo(0, 0)
                  navigate(`/busca`)
                }}
                fontSize="medium"
                textColor="whiteColor"
                className={styles.buttonOpportunity}
              >
                Ver oportunidades
              </Button>
            </Link>
          </div>
          {!isMaintenance && <DidYouKnowNotification />}
        </main>
      </BaseLayout>
    </>
  )
}
export default Home

Home.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
}

Home.defaultProps = {
  location: {
    search: '',
  },
}
