/* eslint-disable react/prop-types */
import classNames from 'classnames'
import { Image } from 'components'
import { useCountDown } from 'hooks'
import React, { useState } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import styles from './Carousel.module.scss'

export const CarouselHome = ({
  bannersList,
  autoPlay,
  hasCountDown = true,
}) => {
  const { renderCountDown } = useCountDown({ styles })
  const [selected, setSelected] = useState({ index: 0, active: true })

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  const CustomDot = ({ onClick, ...rest }) => {
    const { index, active } = rest
    if (active && selected.index !== index) setSelected({ index, active })
    return (
      <button
        type="button"
        onClick={() => {
          onClick(index)
          if (!active && selected.index !== index)
            setSelected({ index, active: !active })
        }}
        className={styles.button}
      >
        <div
          key={index}
          className={classNames(styles.dot, {
            [styles.dotActive]: index === selected.index && selected.active,
          })}
        />
      </button>
    )
  }
  return (
    <div className={styles.container}>
      <Carousel
        responsive={responsive}
        showDots
        autoPlay={autoPlay}
        infinite={bannersList.length > 1}
        autoPlaySpeed={9000}
        containerClass={styles.slide}
        dotListClass={styles.dotList}
        customDot={<CustomDot />}
      >
        {bannersList.map(
          (
            { link, sourceMobile, sourceNormal, sourceLarge, alt, name },
            index
          ) => (
            <>
              <Image
                key={index.toString()}
                className={styles.slide}
                link={link}
                alt={`Link para a pagina ${alt}`}
                sourceMobile={sourceMobile}
                source={sourceNormal}
                sourceLarge={sourceLarge}
                sourceXLarge={sourceLarge}
                name={name}
                stylesCountDown={styles}
                countdown
                bannersHome
              />
              {hasCountDown && renderCountDown({ nameCampaign: name })}
            </>
          )
        )}
      </Carousel>
    </div>
  )
}
