import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const Auction = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="auction"
          d="M18.14 23.519H7.41a5.39 5.39 0 0 0-5.384 5.384 1.09 1.09 0 0 0 1.09 1.091h19.319a1.09 1.09 0 0 0 1.091-1.09 5.39 5.39 0 0 0-5.384-5.385zM4.4 27.812a3.207 3.207 0 0 1 3.01-2.11h10.73c1.382 0 2.563.88 3.01 2.11H4.399zm25.246-6.12l-7.814-7.814 3.52-3.52a1.092 1.092 0 0 0 0-1.545l-6.438-6.439a1.091 1.091 0 0 0-1.544 0L8.783 10.96a1.091 1.091 0 0 0 0 1.543l6.44 6.44a1.091 1.091 0 0 0 1.543 0l3.521-3.521 7.814 7.814a1.092 1.092 0 0 0 1.544-1.544zm-13.65-5.065L11.1 11.732l7.04-7.042 4.896 4.895-7.043 7.042z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#auction" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#auction"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Auction.propTypes = {
  ...propTypesSvg,
}

Auction.defaultProps = {
  ...defaultPropTypesSvg,
}

export default Auction
