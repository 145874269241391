export const getHoursAfterNow = ({ date }) => {
  const now = new Date()
  const past = new Date(date)
  const diff = Math.abs(now.getTime() - past.getTime())
  const days = Math.ceil(diff / (1000 * 60 * 60))

  return days
}

export const isExpiredCache = ({ key }) => {
  const store = JSON.parse(window.localStorage.getItem(key))

  if (!store) return false

  return getHoursAfterNow(store.last_update) > Number(store.expiration)
}

export const setCache = ({ key, data, expiration }) => {
  window.localStorage.setItem(
    key,
    JSON.stringify({ expiration, last_update: new Date(), data })
  )
}

export const getCache = ({ key }) => {
  const store = JSON.parse(window.localStorage.getItem(key))

  if (!store) return null

  if (isExpiredCache({ key })) return null

  return store.data
}
