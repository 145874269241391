import React from 'react'
import PropTypes from 'prop-types'
import { AlertSuccessModal, Text } from 'components'
import { useAlert, useFilter } from 'store'
import {
  setCustomerInfoLocalStorage,
  getCustomerInfoLocalStorage,
} from 'utils/customerInfoLocalStorage'
import { getUtms } from 'utils/Utms'
import { origin_device } from 'utils/deviceDetector'
import AlertModal from './AlertModal/AlertModal'
import {
  getNotificationBoxTexts,
  checkIsDisabled,
  transformFiltersToSendToApi,
} from './utils'

const AlertContainer = ({ exitModal, urlParams }) => {
  const [openAlertSuccess, setOpenAlertSuccess] = React.useState(false)
  const [loadingFetch, setloadingFetch] = React.useState(false)

  const {
    setField,
    createAlert,
    clearStateAlert,
    getForm,
    getOpenModal,
    setOpenAlertModal,
    getOpenExitModal,
    setOpenExitModal,
    setPurpose,
  } = useAlert()

  const {
    $filter: { filters },
    initialState,
    clearFilters,
  } = useFilter()

  const alertModalParamsByType = exitAlertModal => {
    const type = exitAlertModal ? 'exitAlert' : 'defaultAlert'
    const transformedFilters = transformFiltersToSendToApi(filters)

    const params = {
      exitAlert: {
        opened: getOpenExitModal(),
        closeModal: setOpenExitModal,
        isDisabled: checkIsDisabled({
          form: getForm(),
          filters: transformedFilters,
          maxValue: initialState.filters.numberFilters.maxValue.value,
        }),
      },
      defaultAlert: {
        opened: getOpenModal(),
        closeModal: setOpenAlertModal,
        isDisabled: checkIsDisabled({
          form: getForm(),
          filters: transformedFilters,
          maxValue: initialState.filters.numberFilters.maxValue.value,
        }),
      },
    }

    return params[type]
  }

  const { opened, closeModal, isDisabled } = alertModalParamsByType(exitModal)

  const handleConfirm = async values => {
    setloadingFetch(true)

    const payload = {
      send_mail_emgea: true,
      origin_device,
      email: values.email,
      phone: values.phone,
      filters: transformFiltersToSendToApi(filters),
      ...getUtms(),
    }

    payload.filters.purpose = values.purpose

    const success = await createAlert(payload)
    setloadingFetch(false)

    setCustomerInfoLocalStorage({
      ...getCustomerInfoLocalStorage(),
      phone: values.phone,
      email: values.email.trim(),
    })

    if (success) {
      closeModal()
      setOpenAlertSuccess(!openAlertSuccess)
      clearStateAlert()
      clearFilters()
    }
  }

  const handleNewAlert = () => {
    setOpenAlertSuccess(!openAlertSuccess)
    setOpenAlertModal()
  }

  const { title, message } = getNotificationBoxTexts(exitModal)
  const notificationBoxProps = {
    renderTitle: () => {
      return (
        <Text color="whiteColor" fontSize="large">
          <b>{title}</b>
        </Text>
      )
    },
    renderMessage: () => {
      return (
        <Text color="whiteColor" fontSize="medium">
          {message}
        </Text>
      )
    },
  }

  return (
    <>
      {opened && (
        <AlertModal
          opened={opened}
          values={getForm()}
          closeModal={closeModal}
          createAlert={handleConfirm}
          setField={setField}
          isLoading={loadingFetch}
          isDisabled={isDisabled}
          notificationBoxProps={notificationBoxProps}
          urlParams={urlParams}
          setPurpose={setPurpose}
        />
      )}
      {openAlertSuccess && (
        <AlertSuccessModal
          opened={openAlertSuccess}
          closeModal={() => setOpenAlertSuccess(!openAlertSuccess)}
          openAlertModal={handleNewAlert}
        />
      )}
    </>
  )
}

AlertContainer.propTypes = {
  exitModal: PropTypes.bool,
  urlParams: PropTypes.string.isRequired,
}

AlertContainer.defaultProps = {
  exitModal: false,
}

export default React.memo(AlertContainer)
