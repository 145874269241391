import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lib/noop'
import classNames from 'classnames'

import { Button } from 'components'

import styles from './ActionContactCompany.module.scss'

const ActionContactCompany = ({
  show,
  preview,
  showVisit,
  scheduleAction,
  contactUsAction,
  showContactUs,
  fullContactButton,
  callToActionLabel,
  contactButtonLabel,
  activeContactUs,
  idButton,
}) => {
  const disableScheduleVisit =
    callToActionLabel === 'Vendido' || callToActionLabel === 'Encerrado'
  const disableContactButtonLabel = activeContactUs || disableScheduleVisit

  return (
    show && (
      <div className={styles.horizontalContainer}>
        <Button
          id={idButton}
          showButton={showContactUs}
          name="possibleFixedButton"
          className={classNames(
            { [styles.largeButton]: fullContactButton },
            { [styles.halfButton]: !fullContactButton }
          )}
          disabled={disableContactButtonLabel}
          type="filled"
          onClick={contactUsAction}
          fontSize="medium"
          backgroundColor="secondColor"
        >
          {contactButtonLabel}
        </Button>

        {preview}

        <Button
          showButton={showVisit}
          name="possibleFixedButton"
          className={classNames(
            { [styles.largeButton]: !showContactUs },
            { [styles.halfButton]: showContactUs }
          )}
          type="filled"
          onClick={scheduleAction}
          fontSize="medium"
          disabled={disableScheduleVisit}
          backgroundColor="secondColor"
        >
          Agendar visita
        </Button>
      </div>
    )
  )
}

ActionContactCompany.propTypes = {
  callToActionLabel: PropTypes.string.isRequired,
  contactButtonLabel: PropTypes.string.isRequired,
  showVisit: PropTypes.bool.isRequired,
  showContactUs: PropTypes.bool.isRequired,
  fullContactButton: PropTypes.bool.isRequired,
  contactUsAction: PropTypes.func,
  show: PropTypes.bool,
  scheduleAction: PropTypes.func,
  preview: PropTypes.bool,
  activeContactUs: PropTypes.bool,
  idButton: PropTypes.string,
}

ActionContactCompany.defaultProps = {
  contactUsAction: noop,
  scheduleAction: noop,
  preview: false,
  show: true,
  activeContactUs: false,
  idButton: '',
}

export default React.memo(ActionContactCompany)
