import React from 'react'
import PropTypes from 'prop-types'

import styles from './BannerImage.module.scss'

const BannerImage = ({
  bannerImageMobile,
  bannerImageDesk,
  bannerImageDeskLarge,
  link,
  alt,
  linktitle,
}) => {
  return (
    bannerImageMobile && (
      <a href={link} title={linktitle}>
        <picture>
          <source
            className={styles.banner}
            media="(max-width: 767px)"
            srcSet={`${bannerImageMobile} 750w`}
          />
          <source
            className={styles.banner}
            media="(max-width: 1920px)"
            srcSet={`${bannerImageDesk} 1280w`}
          />
          <source
            className={styles.banner}
            media="(max-width: 1920px)"
            srcSet={`${bannerImageDeskLarge} 1560w`}
          />
          <img
            loading="lazy"
            className={styles.banner}
            srcSet={`${bannerImageMobile} 750w, ${bannerImageDesk} 1280w, ${bannerImageDeskLarge} 1560w`}
            sizes="100vw"
            src={bannerImageMobile}
            alt={alt}
          />
        </picture>
      </a>
    )
  )
}

BannerImage.propTypes = {
  link: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  linktitle: PropTypes.string.isRequired,
  bannerImageMobile: PropTypes.string.isRequired,
  bannerImageDesk: PropTypes.string.isRequired,
  bannerImageDeskLarge: PropTypes.string.isRequired,
}

export default React.memo(BannerImage)
