import React from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'
import { Icon } from 'components'
import classNames from 'classnames'
import isIos from 'utils/isIos'
import styles from './Pagination.module.scss'

const Pagination = ({ handleClick, totalPages, forcePage }) => {
  const width = window.innerWidth
  return (
    <div className={styles.container}>
      <ReactPaginate
        previousLabel={
          <Icon
            type="arrow"
            size={20}
            className={classNames({ [styles.prevButtonIphone]: isIos() })}
          />
        }
        nextLabel={
          <Icon
            type="arrow"
            size={20}
            rotate={270}
            className={classNames({ [styles.nextButtonIphone]: isIos() })}
          />
        }
        breakLabel="..."
        breakClassName="break-me"
        pageCount={totalPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={width < 590 ? 3 : 7}
        onPageChange={handleClick}
        containerClassName={styles.reactPaginate}
        activeClassName={styles.active}
        forcePage={forcePage}
      />
    </div>
  )
}

Pagination.propTypes = {
  handleClick: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  forcePage: PropTypes.number.isRequired,
}

export default React.memo(Pagination)
