import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg } from './propTypes'

const Youtube = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="youtube"
          d="M19.619 13.553a2.318 2.318 0 0 1 1.048 1.947c0 .79-.39 1.514-1.048 1.947l-4.624 3.47a2.33 2.33 0 0 1-2.45.13A2.332 2.332 0 0 1 11.334 19v-7a2.332 2.332 0 0 1 3.662-1.916l4.624 3.469zm-1.367 2.003a1.13 1.13 0 0 1 .081-.056 1.08 1.08 0 0 1-.082-.057l-4.584-3.439v6.992l4.585-3.44zM24.167 5A5.84 5.84 0 0 1 30 10.833v9.334A5.84 5.84 0 0 1 24.167 26H7.833A5.84 5.84 0 0 1 2 20.167v-9.334A5.84 5.84 0 0 1 7.833 5h16.334zm3.5 15.167v-9.334c0-1.93-1.57-3.5-3.5-3.5H7.833c-1.93 0-3.5 1.57-3.5 3.5v9.334c0 1.93 1.57 3.5 3.5 3.5h16.334c1.93 0 3.5-1.57 3.5-3.5z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#youtube" />
        </mask>
        <use className={styles[color]} xlinkHref="#youtube" />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Youtube.propTypes = {
  ...propTypesSvg,
}

Youtube.defaultProps = {
  viewBox: '0 0 32 32',
  color: 'grayColor',
}

export default Youtube
