export const depoiments = [
  {
    id: 1,
    partner: 'Cláudio Desidério Silva',
    partnership: 'Corretor',
    testimonial:
      'A parceria com a empresa Resale é excelente, conta com suporte técnico e plataforma digital de ótima qualidade, com imóveis atualizados a todo momento. É um trabalho de qualidade e expertise, para entregar para o parceiro, um serviço de qualidade e segurança. O foco é servir o cliente em todas as etapas, e por isso eu agradeço a empresa Resale.',
  },
  {
    id: 2,
    partner: 'Anderson dos Santos da Costa Potulski',
    partnership: 'Corretor',
    testimonial:
      'Estou gostando muito da parceria, do sistema oferecido, método de trabalho e comissionamento. Além disso, temos inúmeras opções de imóveis para vendermos.',
  },
  {
    id: 3,
    partner: 'Silvio Íris Messias dos Santos',
    partnership: 'Corretor',
    testimonial:
      'A parceria com a Resale tem sido surpreendente! As ofertas são colocadas de maneira personalizada por região, para priorizar nossa assertividade de atuação. Recomendo!',
  },
  {
    id: 4,
    partner: 'Meire Regina de Jesus',
    partnership: 'BJFlores Imóveis LTDA',
    testimonial:
      'A parceria com a Resale está sendo uma excelente experiência! Em seu portal temos uma variedade de oportunidades incríveis e descomplicadas para oferecer aos clientes!',
  },
  {
    id: 5,
    partner: 'Reginaldo Barros',
    partnership: 'Portal Imobiliária',
    testimonial:
      'Estou muito satisfeito com o retorno e suporte dado pela plataforma e parceiros. 🙏',
  },
  {
    id: 6,
    partner: 'José Eduardo Ramos',
    partnership: 'Corretor',
    testimonial:
      'A Júlia tem sido muito prestativa e bem dinâmica na cooperação.',
  },
  {
    id: 7,
    partner: 'Rock Hudson Santiago dos Santos',
    partnership: 'Corretor',
    testimonial:
      'Considero positiva a aliança entre a Resale, Pagimovel e Corretores. Pois, com profissionalismo e bom empenho, atingiremos nossas metas e os clientes suas realizações.',
  },
  {
    id: 8,
    partner: 'Jonacy Alves Pessoa',
    partnership: 'Art Lar Negócios Imobiliários',
    testimonial:
      'Estou super satisfeito com nossa parceria! Acredito que iremos fazer ótimos negócios juntos e já estou trabalhando bastante para que isso aconteça.',
  },
  {
    id: 9,
    partner: 'William Marques Colman',
    partnership: 'Corretor',
    testimonial:
      'Estou encantado com o atendimento do Time, simplesmente fantástico! Sempre pronto para ajudar e didáticos nas soluções. Satisfeito e entusiasmado com essa parceria! 👊',
  },
  {
    id: 10,
    partner: 'Eduardo Ubiratan Silva de Oliveira',
    partnership: 'Corretor',
    testimonial:
      'Ótima experiência. Vale a pena a parceria! Oportunidades de grandes ganhos.',
  },
  {
    id: 11,
    partner: 'Lecionina Martins Silva',
    partnership: 'Corretor',
    testimonial:
      'Ainda não fechei vendas, mas estou gostando muito da parceria e, com certeza, está agregando valor e conhecimento na minha profissão.',
  },
  {
    id: 12,
    partner: 'Carlos Juliano Barbosa Romão',
    partnership: 'Corretor',
    testimonial: 'To gostando muito da parceria.',
  },
]

const ambiente = process.env.REACT_APP_STAGE === 'prod' ? 'prod' : 'dev'

export const partnershipButtons = [
  {
    description: 'Corretor Autônomo',
    iconType: 'suitcase',
    id: `https://app.pipefy.com/public/form/4qlJp8lb?tipo_de_parceiro=Corretor%20aut%C3%B4nomo&ambiente=${ambiente}&source=emgea`,
  },
  {
    description: 'Imobiliárias',
    iconType: 'house',
    id: `https://app.pipefy.com/public/form/4qlJp8lb?tipo_de_parceiro=Imobili%C3%A1ria&ambiente=${ambiente}&source=emgea`,
  },
  {
    description: 'Leilão Imobiliário',
    iconType: 'auction',
    id: 'mqp3WHk9',
  },
]
