import React from 'react'
import { Icon, Text } from 'components'
import noop from 'lib/noop'
import PropTypes from 'prop-types'
import styles from './OpenSimulator.module.scss'

const OpenSimulator = ({ show, onClick }) =>
  show && (
    <span className={styles.openSimulator}>
      <span className={styles.indicator}>
        <Icon type="hand" size={26} color="darkColor" />{' '}
        <Text>Quer parcelar este imóvel?</Text>
      </span>
      <button type="button" className={styles.button} onClick={onClick}>
        <Text className={styles.simulatorText}>Simule aqui</Text>
      </button>
    </span>
  )

OpenSimulator.propTypes = {
  show: PropTypes.bool,
  onClick: PropTypes.func,
}

OpenSimulator.defaultProps = {
  show: true,
  onClick: noop,
}

export default React.memo(OpenSimulator)
