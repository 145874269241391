import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { RadioGroup, RadioButton as RadioInput } from 'react-radio-buttons'
import styles from './RadioButton.module.scss'

const RadioButton = ({ options, onChange, state }) => {
  return (
    <div className={classNames(styles.container)}>
      <RadioGroup onChange={onChange}>
        {options.map(option => (
          <RadioInput
            value={option.name}
            rootColor="#101010"
            pointColor="#00af93"
          >
            {state === option.name ? (
              <strong className={styles.textStrong}>{option.label}</strong>
            ) : (
              option.label
            )}
          </RadioInput>
        ))}
      </RadioGroup>
    </div>
  )
}

RadioButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf([]).isRequired,
  state: PropTypes.string.isRequired,
}

export default React.memo(RadioButton)
