import React from 'react'
import PropTypes from 'prop-types'
import styles from './svg.module.scss'

const House = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="house"
          d="M7.914 21.007l-.796.554-.02.014c-.808.543-1.904.329-2.448-.48-.55-.818-.342-1.926.468-2.49l17.645-12.27c.643-.447 1.497-.447 2.14 0l17.585 12.272c.81.563 1.017 1.67.467 2.489l-.014.02c-.554.8-1.653 1-2.454.445l-.735-.554v18.866c0 1.036-.839 1.875-1.875 1.875H9.79c-1.035 0-1.875-.84-1.875-1.875V21.007zm12.819 17.16h6.201V27.847c0-.207-.168-.375-.375-.375h-5.451c-.208 0-.375.168-.375.375v10.322zm-3.539 0V27.267c0-1.864 1.511-3.375 3.375-3.375h6.528c1.864 0 3.375 1.51 3.375 3.375v10.902h5.742v-19.02c0-.37-.18-.715-.484-.925l-11.857-8.22c-.055-.042-.082-.055-.04-.055s.014.014-.053.063l-11.843 8.21c-.304.21-.484.556-.484.925v19.022h5.741z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#house" />
        </mask>
        <use className={styles[color]} fillRule="nonzero" xlinkHref="#house" />
        <g mask="url(#prefix__b)">
          <path d="M0 0H48V48H0z" />
        </g>
      </g>
    </svg>
  )
}

House.propTypes = {
  viewBox: PropTypes.string,
  color: PropTypes.oneOf([
    'primaryColor',
    'darkColor',
    'whiteColor',
    'greenColor',
  ]),
}

House.defaultProps = {
  viewBox: '0 0 48 48',
  color: 'primaryColor',
}

export default House
