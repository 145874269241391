import React from 'react'
import PropTypes from 'prop-types'
import { Text, Icon } from 'components/'
import classNames from 'classnames'
import styles from './Discount.module.scss'

const Discount = ({ show, percent, withIcon, fontSize }) => {
  if (!percent) {
    return null
  }

  return (
    show && (
      <span className={styles.discount}>
        <span>
          <span>
            <Text
              className={withIcon ? styles.text : ''}
              fontSize={withIcon ? 'small' : 'medium'}
            >
              Deságio
            </Text>
          </span>
        </span>

        <span>
          <span>
            <p
              className={classNames(
                styles.percent,
                styles[withIcon ? 'medium' : 'large'],
                fontSize && styles[fontSize]
              )}
            >
              {`${percent}%`}
            </p>
          </span>
          <Icon
            type="down"
            size={16}
            color="secondColor"
            style={{ marginBottom: 3 }}
          />
        </span>
      </span>
    )
  )
}

Discount.propTypes = {
  show: PropTypes.bool,
  percent: PropTypes.number,
  withIcon: PropTypes.bool,
  fontSize: PropTypes.oneOf([
    'medium', // 16px
    'regular', // 18px
    'large', // 20px
  ]),
}

Discount.defaultProps = {
  show: true,
  withIcon: false,
  percent: null,
  fontSize: 'regular',
}

export default React.memo(Discount)
