/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import { Image } from 'components'
import { sanitizerStringAndRemoveSpaces } from 'utils/normalizeString'

export const tagConfigurations = (isAnActiveCampaign, campaigns = {}) => {
  const tags = {}

  if (Object.keys(campaigns).length) {
    Object.values(campaigns).forEach(
      ({ filter, tag, campaignKey, isActive }) => {
        if (!isActive) return

        if (tag?.name && /tag/i.test(tag?.type)) {
          const generateTagKey = () => {
            if (filter.name === filter?.name?.toUpperCase()) {
              return filter?.name
            }

            return tag?.name.replace(/(?:^|\s)\S/g, a => a?.toUpperCase())
          }

          tags[sanitizerStringAndRemoveSpaces(generateTagKey())] = {
            isShow: true,
            campaignKey: sanitizerStringAndRemoveSpaces(campaignKey),
            icon: () => <Image source={tag?.icon} />,
            bgColor: tag?.bgColor,
            color: tag?.color,
            closeIconColor: tag?.color,
            othersStyles: {},
            name: tag?.name,
          }
        }
      }
    )
  }

  return tags
}

export const tagsOfCampaigns = Object.entries(tagConfigurations(() => null))
  .map(([key]) => key)
  .filter(key => !!key)
