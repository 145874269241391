import { useEffect } from 'react'

export const useCleanBotHistory = () => {
  useEffect(() => {
    const cleanBotHistory = () => {
      const chatBotHistory = window.localStorage.getItem('blipSdkUAccount')
      if (chatBotHistory) window.localStorage.removeItem('blipSdkUAccount')
    }

    cleanBotHistory()
  }, [])
}
