import {
  toastSuccess,
  toastError,
} from 'components/ToastComponent/ToastComponent'
import { gaEvent } from 'utils/ga'
import * as providers from './provider'

export default setMessageModal => {
  const setField = (field, value) => {
    setMessageModal(prev => ({
      ...prev,
      form: { ...prev.form, [field]: value },
    }))
  }

  const sendForm = async (
    propertyId,
    form,
    saleType,
    propertySmallId,
    checkOffers,
    checkOffersResale
  ) => {
    const [err, res] = await providers.sendForm(
      propertyId,
      form,
      checkOffers,
      checkOffersResale
    )
    if (!res || err) {
      toastError('Houve um erro no envio da mensagem.')
      return false
    }
    gaEvent({
      category: 'L_Lead_Imovel',
      action: 'submit',
      label: propertySmallId,
    })
    toastSuccess('Recebemos seu contato com sucesso!')
    return res
  }

  const setOpenModal = async () => {
    setMessageModal(prev => ({ ...prev, openModal: !prev.openModal }))
  }

  return {
    setField,
    sendForm,
    setOpenModal,
  }
}
