import { useState, useLayoutEffect, useMemo } from 'react'
import useCreateStore from '../../lib/useCreateStore'
import rootActions from './actions'
import rootSelectors from './selectors'
// Store para armazenar os dados globais da aplicação

const showCookies = () => {
  const isCookiesAccepted = window.localStorage.getItem('cookiesResaleEmgea')

  if (isCookiesAccepted === 'accepted') {
    return false
  }
  return true
}

const initialState = {
  error: null,
  sendLeadEmailLoading: false,
  sendLeadEmailError: false,
  leadModal: {
    openModal: false,
    wasOpened: false,
  },
  cookies: showCookies(),
  hasInitialAppliedMegaFeirao: false,
  campaigns: {},
  campaignsFilters: { options: [], complexOptions: [] },
  campaignsLoading: true,
}

const RootStore = useCreateStore(() => {
  const [$root, setRoot] = useState(initialState)
  const actions = useMemo(() => rootActions(setRoot), [])
  const selectors = rootSelectors($root)

  useLayoutEffect(() => {
    actions.fetchCampaigns()
    // eslint-disable-next-line
  }, [])

  return { $root, ...actions, ...selectors }
})

export const useRoot = () => RootStore()
export const RootContext = RootStore.Context
export const RootProvider = RootStore.Provider
