import { apiWithoutPropertySuffix, baseApiCampaign } from 'service/api'
import { to } from 'nh-utils'
import isPreRendering from 'utils/isPreRendering'

export const fetchHomeNumbers = () => to(apiWithoutPropertySuffix.get('/home'))

export const sendEmail = (email, send_mail_emgea, send_mail_resale) =>
  to(
    apiWithoutPropertySuffix.post('/lead', {
      email,
      send_mail_emgea,
      send_mail_resale,
    })
  )

export const fetchCampaigns = () =>
  !isPreRendering() && to(baseApiCampaign.get('/emgea/campaigns'))
