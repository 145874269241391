import { toastError } from 'components/ToastComponent/ToastComponent'
import { gaEvent } from 'utils/ga'
import * as providers from './provider'

export default setProposalModal => {
  const setField = (field, value) => {
    setProposalModal(prev => ({
      ...prev,
      form: { ...prev.form, [field]: value },
    }))
  }

  const sendForm = async (
    propertyId,
    form,
    propertySmallId,
    checkOffers,
    checkOffersResale,
    checkOffersPgi,
    tokenCaptcha
  ) => {
    const [err, res] = await providers.sendForm(
      propertyId,
      form,
      checkOffers,
      checkOffersResale,
      checkOffersPgi,
      tokenCaptcha
    )
    if (!res || err) {
      toastError(
        'Não foi possível realizar a sua proposta. Verifique seu email e senha.'
      )
      return false
    }
    gaEvent({
      category: 'V_proposta',
      action: 'submit',
      label: propertySmallId,
    })
    return res.data
  }

  const sendRecoverPassword = async email => {
    const [err, res] = await providers.sendRecoverPassword(email)

    if (!res || err) {
      toastError(
        'Não foi possível enviar o email de recuperação. Verifique seu email.'
      )
      return false
    }
    return res.data
  }

  const setOpenModal = async () => {
    setProposalModal(prev => ({ ...prev, openModal: !prev.openModal }))
  }

  return {
    setField,
    sendForm,
    setOpenModal,
    sendRecoverPassword,
  }
}
