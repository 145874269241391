import getPropertyCategoryImage from 'utils/getPropertyCategoryImage'

export const getDefaultPropertyImages = (foto_capa, tipo_imovel) => {
  let photo = foto_capa
  let longPhoto = foto_capa

  if (!foto_capa) {
    photo = getPropertyCategoryImage(tipo_imovel)
    longPhoto = getPropertyCategoryImage(`${tipo_imovel}Large`)
    return { photo, longPhoto }
  }
  return { photo, longPhoto }
}
