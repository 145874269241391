import React from 'react'
import {
  Text,
  Button,
  Input,
  NotificationBox,
  NotificationBoxFooter,
} from 'components/'
import PropTypes from 'prop-types'
import { useAlert, useRoot } from 'store'
import { validateEmail } from 'utils/validations'
import { toastError } from 'components/ToastComponent/ToastComponent'
import { getUtms } from 'utils/Utms'
import styles from './NotFoundCard.module.scss'

const NotFoundCard = ({ openFilterModal }) => {
  const { setOpenAlertModal, setField, $alert } = useAlert()
  const { sendEmail, getSendLeadEmail } = useRoot()
  const { sendLeadEmailLoading, sendLeadEmailError } = getSendLeadEmail()

  const { form } = $alert

  const [touched, setTouched] = React.useState({})

  const handleBlur = field => () => setTouched({ ...touched, [field]: true })

  const handleErrorMessage = (value, errorMessage) =>
    value === '' ? 'Campo obrigatório' : errorMessage

  const onChange = e => {
    setTouched({ ...touched, [e.target.id]: false })
    setField(e.target.id, e.target.value)
  }

  const openAlertModal = () => {
    const utms = getUtms()
    const newForm = { email: form.email, ...utms }
    sendEmail(newForm, 'popup')

    if (sendLeadEmailError) {
      toastError('Não foi possível enviar seu email!')
    }

    setOpenAlertModal()
  }

  const isDisabled = !validateEmail(form.email)

  const notificationBoxProps = {
    renderMessage: () => (
      <Text color="whiteColor" fontSize="medium">
        Mas você pode<b className={styles.boldText}>criar um alerta</b> para ser
        avisado assim que surgir novos imóveis no seu perfil
      </Text>
    ),
    renderInput: className => (
      <Input
        errorMessage={handleErrorMessage(form.email, 'Email inválido')}
        onBlur={handleBlur('email')}
        id="email"
        type="email"
        className={className}
        value={form.email}
        onChange={onChange}
        onFocus={() => setTimeout(() => window.scrollTo(200, 100), 1)}
        hasError={!validateEmail(form.email) && touched.email}
        placeholder="Seu e-mail"
      />
    ),
    renderButton: className => (
      <Button
        onClick={() => {
          if (!touched.email) setTouched({ email: true })
          if (!isDisabled) openAlertModal()
        }}
        type="filled"
        greenColor
        backgroundColor="secondColor"
        className={className}
        isLoading={sendLeadEmailLoading}
        isDisabled={sendLeadEmailLoading}
      >
        Criar alerta
      </Button>
    ),
  }

  const notificationFooterProps = {
    renderTitle: () => {
      return (
        <Text fontSize="medium" className={styles.title}>
          Você também pode alterar os filtros para ver mais opções de imóveis
        </Text>
      )
    },
  }

  const { renderMessage, renderInput, renderButton } = notificationBoxProps

  const { renderTitle } = notificationFooterProps

  return (
    <div className={styles.notFoundCard}>
      <div className={styles.header}>
        <Text fontSize="regular" bold>
          Não encontramos imóveis com essas características :(
        </Text>
      </div>
      <NotificationBox
        renderMessage={renderMessage}
        renderInput={renderInput}
        renderButton={renderButton}
      />
      <NotificationBoxFooter
        renderTitle={renderTitle}
        openFilterModal={openFilterModal}
      />
    </div>
  )
}
NotFoundCard.propTypes = {
  openFilterModal: PropTypes.func.isRequired,
}
export default React.memo(NotFoundCard)
