const questions = [
  {
    title: 'Como funciona o pagamento?',
    body:
      'Se a nota fiscal for lançada após às 16h o pagamento será efetuado em até dois dias úteis, já se a nota for lançada antes das 16h o pagamento ocorrerá no mesmo dia.',
  },
  {
    title: 'O que é um RPA?',
    body:
      'O RPA é um recibo de pagamento autônomo que utilizamos para parceiros que não possuem NF.',
  },
  {
    title: 'Como funciona a guarda de chaves?',
    body:
      'Após a abertura de algumas unidades necessitamos que o parceiro faça a guarda das chaves até a entrega da unidade ao novo proprietário, neste caso, sempre que houver uma visita o cliente irá retirar a chave em sua loja e devolverá no término de sua visita.',
  },
]

export default questions
