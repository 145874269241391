/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Collapse,
  CategoryTitle,
  Text,
  Modal,
  DocumentMiniature,
} from 'components'
import { boldString, keyFromString } from 'utils/string'
import DOMPurify from 'dompurify'
import classNames from 'classnames'
import styles from './PublicCompetitionInfo.module.scss'

const FunctionalDocumentMiniature = ({ name, url, type }) => {
  const [state, setState] = React.useState(false)
  const changeState = () => setState(!state)

  return (
    <DocumentMiniature
      url={url}
      fileName={name}
      type={type}
      parameters={{
        page: 1,
        view: 'fitH',
        toolbar: 0,
        statusbar: 0,
        navpanes: 0,
      }}
      modalProps={{
        opened: state,
        closeModal: changeState,
      }}
    />
  )
}

const PublicCompetitionInfo = ({
  limitDate,
  limitTime,
  video,
  document,
  isModal,
}) => {
  const [viewMore, setViewMore] = React.useState(false)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const videoURL = video && video.split('embed/')[1]

  const sanitizer = DOMPurify.sanitize
  const limitText = `Faça sua proposta até o dia <strong>${limitDate}</strong> às <strong>${limitTime}h</strong> e garanta sua participação`

  return (
    <div className={classNames(styles.body, { [styles.modalBody]: isModal })}>
      <CategoryTitle
        icon="auction"
        title="Este imóvel está em Melhor Proposta"
      />
      <summary className={classNames({ [styles.modalSummary]: isModal })}>
        <Text color="darkBlueColor" className={styles.limitText}>
          {limitText
            .split('\n')
            .filter(p => p.trim() !== '')
            .map((p, index) => (
              <p
                key={`${keyFromString(p)}_${index.toString()}`}
                dangerouslySetInnerHTML={{
                  __html: sanitizer(boldString(p.trim()), {
                    ALLOWED_TAGS: ['strong'],
                  }),
                }}
              />
            ))}
        </Text>
        {!isModal && (
          <Collapse
            onClick={() => setViewMore(!viewMore)}
            checked={viewMore}
            isDetails
          />
        )}
      </summary>
      {viewMore && (
        <div className={styles.details}>
          <Text color="lightBlueColor">
            A Melhor Proposta é um processo de gamificação criado pela Resale
            que possui o objetivo de facilitar ao Vendedor a apuração da melhor
            condição de venda de um imóvel de acordo com o que o mercado está
            disposto a pagar por ele.
            <br /> O processo é simples, os proponentes apresentam suas melhores
            ofertas fechadas para os imóveis ofertados, com base no valor mínimo
            estipulado até um horário e data pré- definidos. Essas propostas,
            mantidas e protegidas por meio de tecnologia
            <b>
              <i>blockchain</i>
            </b>
            , somente serão conhecidas após a conclusão.
          </Text>
          <div className={styles.documents}>
            <div className={styles.document}>
              <FunctionalDocumentMiniature name="" url={document} type="" />
              <Text
                fontSize="medium"
                color="darkBlueColor"
                className={styles.docLabel}
              >
                Veja o regulamento
              </Text>
            </div>
            {video && (
              <div
                className={styles.document}
                onClick={() => setIsModalOpen(true)}
                onKeyDown={() => setIsModalOpen(true)}
                role="button"
                tabIndex={0}
              >
                <img
                  loading="lazy"
                  alt="Video concorrência pública thumbnail"
                  src={`https://img.youtube.com/vi/${videoURL}/sddefault.jpg`}
                />
                <Text fontSize="medium" color="darkBlueColor">
                  Como funciona
                </Text>
              </div>
            )}
          </div>
        </div>
      )}
      {isModal && (
        <Collapse
          onClick={() => setViewMore(!viewMore)}
          checked={viewMore}
          isDetails
        />
      )}
      {isModalOpen && (
        <Modal
          opened={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          className={styles.documentMiniatureModal}
        >
          <iframe
            title="video"
            width="100%"
            height="100%"
            src={video}
            frameBorder="0"
            allow="accelerometer;
            autoplay;
            clipboard-write;
            encrypted-media;
            gyroscope;
            picture-in-picture"
            allowFullScreen
          />
        </Modal>
      )}
    </div>
  )
}

FunctionalDocumentMiniature.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

PublicCompetitionInfo.propTypes = {
  limitDate: PropTypes.string.isRequired,
  limitTime: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
  document: PropTypes.string.isRequired,
  isModal: PropTypes.bool,
}

PublicCompetitionInfo.defaultProps = {
  isModal: false,
}

export default React.memo(PublicCompetitionInfo)
