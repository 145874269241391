import React from 'react'
import PropTypes from 'prop-types'
import { PublicCompetitionInfo } from 'components'

const PublicCompetition = ({
  isPublicCompetition,
  auctionInfo,
  video,
  document,
}) => {
  const isVisibleSection = isPublicCompetition && auctionInfo[0]

  if (!isVisibleSection) return null

  return (
    <section>
      <PublicCompetitionInfo
        limitDate={auctionInfo[0].data}
        limitTime={auctionInfo[0].hora}
        video={video}
        document={document}
      />
    </section>
  )
}

PublicCompetition.propTypes = {
  isPublicCompetition: PropTypes.bool.isRequired,
  video: PropTypes.string.isRequired,
  document: PropTypes.string.isRequired,
  auctionInfo: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.string.isRequired,
      hora: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default PublicCompetition
