import React from 'react'
import { CategoryTitle, Processes } from 'components'
import PropTypes from 'prop-types'

const Juridical = ({ juridical }) => {
  const isVisibleSection = juridical && !!juridical.contencioso.length

  if (!isVisibleSection) return null

  return (
    <section>
      <CategoryTitle icon="expenses" title="Processo" />
      <Processes litigation={juridical.contencioso} />
    </section>
  )
}

Juridical.propTypes = {
  juridical: PropTypes.shape({
    contencioso: PropTypes.arrayOf([]).isRequired,
  }).isRequired,
}

export default Juridical
