import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Title } from 'components'
import styles from './CategoryTitle.module.scss'

const CategoryTitle = ({ icon, title }) => {
  return (
    <div className={styles.categoryTitle}>
      <Icon type={icon} color="secondColor" size={24} />
      <Title bold type="h4" color="primaryColor">
        {title}
      </Title>
    </div>
  )
}

CategoryTitle.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

CategoryTitle.defaultProps = {}

export default React.memo(CategoryTitle)
