import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const StoreRoom = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="storeRoom"
          d="M27.984 3H3.93a.929.929 0 0 0-.93.929v24.055C3 28.545 3.455 29 4.015 29h23.969c.561 0 1.016-.455 1.016-1.016V4.016C29 3.455 28.545 3 27.984 3zM26.97 5.031v7.956h-9.953V5.03h9.953zm-11.985 0V26.97H5.031V5.03h9.953zm2.032 21.938v-11.95h9.953v11.95h-9.953zm1.963-16.961V8.01a1.016 1.016 0 1 1 2.031 0v1.997a1.016 1.016 0 1 1-2.03 0zm-12.072 6.5V14.51a1.016 1.016 0 1 1 2.032 0v1.997a1.016 1.016 0 1 1-2.032 0zm14.103 3.978a1.016 1.016 0 0 1-2.03 0v-1.998a1.016 1.016 0 0 1 2.03 0v1.998z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#storeRoom" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#storeRoom"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

StoreRoom.propTypes = {
  ...propTypesSvg,
}

StoreRoom.defaultProps = {
  ...defaultPropTypesSvg,
}

export default StoreRoom
