import React from 'react'
import { CategoryTitle, Text } from 'components'

const LegalNotice = () => {
  return (
    <section>
      <CategoryTitle icon="considerations" title="Aviso legal" />
      <Text fontSize="medium" color="lightTextColor">
        Descrição comercial do imóvel
      </Text>
      <br />
      <Text fontSize="medium" color="lightTextColor">
        A descrição do imóvel é disponibilizada em caráter meramente
        informativo, uma vez que foi criada a partir da matrícula apresentada
        pelo Vendedor e enriquecida com informações do laudo de avaliação,
        documentos que podem sofrer mudanças a qualquer momento e não refletir
        situação atual do imóvel. A Resale não se responsabiliza pela veracidade
        e atualização da descrição do imóvel e esclarece que toda e qualquer
        decisão de compra não deve se basear nas fotos divulgadas, mas sim, na
        realização de visita presencial ao imóvel.
      </Text>
      <br />
      <Text fontSize="medium" color="lightTextColor">
        Imagens do Imóvel
      </Text>
      <br />
      <Text fontSize="medium" color="lightTextColor">
        As imagens foram obtidas a partir do laudo de avaliação do imóvel e,
        portanto, podem não refletir sua situação e distribuição interna atual.
        Já as imagens obtidas por meio do Google Street View, bem como sua
        indicação no mapa, são baseadas no endereço cadastrado do imóvel e, em
        alguns casos, pode não ter sua localização exata reconhecida pela
        ferramenta, ou ainda, apresentar defasagem com a situação atual, em
        função da data em que foram coletadas pelo Google. Sendo assim, toda e
        qualquer decisão de compra NÃO deve se basear nas fotos divulgadas, mas
        sim, na realização de visita presencial ao imóvel.
      </Text>
      <br />
      <Text fontSize="medium" color="lightTextColor">
        O proponente está ciente de que os documentos e as informações
        fornecidos na proposta serão compartilhados com terceiros tais como,
        órgãos do Poder Judiciário, partes e terceiros envolvidos na ação
        judicial, administradoras de condomínio, tabelionatos de notas, cartório
        de registro de imóveis e prefeituras sempre com a finalidade exclusiva
        de dar cumprimento a esta proposta, bem como para viabilização e
        formalização da alienação judicial do imóvel aqui tratado.
      </Text>
    </section>
  )
}

export default LegalNotice
