import Qs from 'query-string'
import _ from 'lodash'

export const setUtms = search => {
  const newUtms = Qs.parse(search, { arrayFormat: 'comma' })

  if (_.isEmpty(newUtms)) {
    return
  }

  const { utm_campaign, utm_source, utm_medium, utm_content } = newUtms

  const currentUtms = JSON.parse(window.localStorage.getItem('utms'))
  const areTheUtmsEqual = _.isEqual(newUtms, currentUtms)

  if (!areTheUtmsEqual) {
    window.localStorage.removeItem('utms')

    if (utm_campaign || utm_source || utm_medium || utm_content) {
      window.localStorage.setItem(
        'utms',
        JSON.stringify({
          utm_campaign,
          utm_source,
          utm_medium,
          utm_content,
        })
      )
    }
  }
}

export const getUtms = () => {
  const utms = JSON.parse(window.localStorage.getItem('utms'))

  if (utms) {
    return utms
  }

  return null
}
