import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const Info = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="a"
          d="M16 15a2 2 0 0 1 2 2v4a2 2 0 1 1-4 0v-4a2 2 0 0 1 2-2zm0-6a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm0 20C8.82 29 3 23.18 3 16S8.82 3 16 3s13 5.82 13 13-5.82 13-13 13zm0-2.2c5.965 0 10.8-4.835 10.8-10.8 0-5.965-4.835-10.8-10.8-10.8-5.965 0-10.8 4.835-10.8 10.8 0 5.965 4.835 10.8 10.8 10.8z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use className={styles[color]} xlinkHref="#a" />
        <g className={styles[color]} mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Info.propTypes = {
  ...propTypesSvg,
}

Info.defaultProps = {
  ...defaultPropTypesSvg,
}

export default Info
