import React from 'react'
import PropTypes from 'prop-types'
import { CommentCard } from 'components'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import responsive from './responsive'
import styles from './PartnershipDepoiments.module.scss'

const PartnershipDepoiments = ({ data }) => {
  return (
    <Carousel
      additionalTransfrom={0}
      responsive={responsive}
      swipeable
      partialVisible
      arrows
      removeArrowOnDeviceType={['mobile']}
      containerClass={styles.list}
    >
      {data.map(item => (
        <CommentCard
          depoiment={item.testimonial}
          type="Parceiro"
          name={item.partner}
          age={item.partnership}
        />
      ))}
    </Carousel>
  )
}

PartnershipDepoiments.propTypes = {
  data: PropTypes.arrayOf([]).isRequired,
}

export default PartnershipDepoiments
