import React from 'react'
import PropTypes from 'prop-types'
import { CategoryTitle, Text } from 'components'

const Debts = ({ debts }) => {
  if (!debts.length) return null

  return (
    <section>
      <CategoryTitle icon="debts" title="Dívidas" />
      {debts.map((debt, index) => (
        <div key={index.toString()}>
          <Text fontSize="medium" color="lightTextColor">
            {`${debt.type}: `}
          </Text>
          <Text fontSize="medium" color="lightTextColor">
            {`${`R$ ${debt.value.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}`}
          </Text>
        </div>
      ))}
    </section>
  )
}

Debts.propTypes = {
  debts: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,

      value: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
}

export default Debts
