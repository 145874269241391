import React from 'react'
import ClassName from 'classnames'
import PropTypes from 'prop-types'
import ObjectToQueryString from 'lib/objectToQueryString'
import styles from './StreetView.module.scss'

const StreetView = ({ apiKey, className, title, parameters }) => {
  if (!parameters) return null

  const queryString = ObjectToQueryString(parameters)
  const url = `https://www.google.com/maps/embed/v1/streetview?key=${apiKey}&${queryString}`

  return (
    <iframe
      title={title}
      src={url}
      className={ClassName(styles.streetView, className)}
    />
  )
}

StreetView.propTypes = {
  title: PropTypes.string,
  apiKey: PropTypes.string.isRequired,
  className: PropTypes.string,
  parameters: PropTypes.shape({
    location: PropTypes.string, // comma-separated latitude and longitude value such as 37.4218,-122.0840
    pano: PropTypes.string, // is a specific panorama Id
    heading: PropTypes.string, // indicates the compass heading of the camera. Values are from -180° to 360°
    pitch: PropTypes.string, // specifies the angle, up or down, of the camera. Values are from -90° to 90°
    fov: PropTypes.string, // determines the horizontal field of view of the image. Values are from 10° to 100°
    language: PropTypes.string, // by default, visitors will see a map in their own language
    region: PropTypes.string, // accepts a region code specified as a two-character ccTLD (top-level domain) value. http://www.iana.org/domains/root/db
  }).isRequired,
}

StreetView.defaultProps = {
  className: '',
  title: 'streetView',
}

export default React.memo(StreetView)
