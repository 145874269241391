/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from '@reach/router'
import PropTypes from 'prop-types'
import logo from 'assets/images/logo-emgea.png'
// import {
//   SALE_CAMPAIGNS,
//   hasCampaignEnded,
//   isCampaignActive,
// } from 'service/campaigns'
import classNames from 'classnames'
import { onPath } from '../../utils/onPath'
import style from './Header.module.scss'
import clearPropertyReferenceStorage from '../../utils/clearPropertyReferenceStorage'

const Header = ({ preview }) => {
  const [menuOpen, toggleMenu] = React.useState(false)
  // const ambiente = process.env.REACT_APP_STAGE === 'prod' ? 'PROD' : 'DEV'

  const navMenu = [
    { name: 'Home', route: '/' },
    { name: 'Imóveis', route: '/busca' },
    {
      name: 'Aprove seu crédito',
      route: `https://simulador.credihome.com.br/resale/?partnerId=3417`,
    },
    { name: 'Ajuda', route: '/perguntas-frequentes' },
    { name: 'Fale Conosco', route: '/fale-conosco' },
    {
      name: 'Minhas Propostas',
      route: 'https://clientes.pagimovel.com.br/welcome/login',
    },
  ]

  // if (
  //   SALE_CAMPAIGNS.feirao.test(process.env.REACT_APP_CAMPAIGN) &&
  //   !hasCampaignEnded() &&
  //   isCampaignActive()
  // ) {
  //   navMenu.splice(2, 0, { name: 'Simulador', route: '/simulador' })
  // }

  const isSimuladorPath = onPath('/simulador')

  return (
    <header
      className={classNames({
        [style.header]: !isSimuladorPath,
        [style.simulatorHeader]: isSimuladorPath,
      })}
    >
      <Link
        to={preview ? '' : '/'}
        className={style.logo}
        title="Link para a home"
      >
        <img
          loading="lazy"
          src={logo}
          alt="Logo Portal Resale"
          className={preview ? style.logoImgPointer : style.logoImg}
        />
      </Link>
      <button
        type="button"
        title="Botão para abrir o menu"
        onClick={() => toggleMenu(!menuOpen)}
        className={`${style.burger} ${menuOpen ? style.burgerOpen : ''}`}
      >
        <div />
      </button>
      <nav className={`${style.navbar} ${menuOpen ? style.navbarOpen : ''}`}>
        <ul className={style.list}>
          {navMenu.map((item, index) => {
            if (preview) return null

            return (
              <li key={item + index.toString()} className={style.listItem}>
                {item.route.includes('http') ? (
                  <a
                    href={item.route}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="link para formulário de aprovação de crédito"
                  >
                    {item.name}
                  </a>
                ) : (
                  <Link
                    onClick={clearPropertyReferenceStorage}
                    state={{ clearLocality: true }}
                    to={item.route}
                    getProps={({ isCurrent }) => {
                      return {
                        className: `${style.link} ${isCurrent &&
                          style.linkActive}`,
                      }
                    }}
                  >
                    {item.name}
                  </Link>
                )}
              </li>
            )
          })}
        </ul>
      </nav>
    </header>
  )
}

Header.propTypes = {
  preview: PropTypes.bool,
}

Header.defaultProps = {
  preview: false,
}

export default React.memo(Header)
