import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Text } from 'components/'
import styles from './Stage.module.scss'

const stages = [
  { value: 'credito', name: 'Crédito' },
  { value: 'cobranca', name: 'Cobrança' },
  { value: 'venda_judicial', name: 'Jurídico' },
  { value: 'processo_retomada', name: 'Processo de Retomada' },
  { value: 'propriedade_credor', name: 'Propriedade do Credor' },
]

const Stage = ({ stage }) => (
  <div className={classNames(styles.timeline, styles.flexContainer)}>
    {stages.map(({ value, name }) => (
      <span
        id={value}
        key={value}
        className={classNames(styles.timelineItem, styles.flexItemsDefault, {
          [styles.selected]: stage === value,
        })}
      >
        <Text className={styles.text} fontSize="medium" color="lightBlueColor">
          {name}
        </Text>
      </span>
    ))}
  </div>
)

Stage.propTypes = {
  stage: PropTypes.oneOf([
    'credito',
    'cobranca',
    'juridico',
    'processo_retomada',
    'propriedade_credor',
  ]).isRequired,
}

export default React.memo(Stage)
