import React from 'react'
import PropTypes from 'prop-types'
import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'
import './RangeSlider.scss'
import noop from 'lib/noop'

const RangeSlider = ({
  max,
  min,
  onChange,
  onChangeComplete,
  step,
  value,
  className,
}) => {
  return (
    <div className={className}>
      <InputRange
        formatLabel={noop}
        maxValue={max}
        minValue={min}
        onChange={onChange}
        onChangeComplete={onChangeComplete}
        step={step}
        value={value}
        allowSameValues={false}
      />
    </div>
  )
}

RangeSlider.propTypes = {
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onChangeComplete: PropTypes.func.isRequired,
  step: PropTypes.number,
  className: PropTypes.string,
  value: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }).isRequired,
}

RangeSlider.defaultProps = {
  max: 0,
  min: 0,
  step: 1,
  className: '',
}

export default React.memo(RangeSlider)
