import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const Footage = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="footage"
          d="M29.68 21.598L13.615 5.533 10.402 2.32a1.094 1.094 0 0 0-1.547 0L2.32 8.855a1.094 1.094 0 0 0 0 1.547L21.598 29.68c.427.427 1.12.427 1.547 0l6.535-6.535a1.094 1.094 0 0 0 0-1.547zm-7.309 5.761L4.641 9.63l4.986-4.99 1.667 1.666-1.796 1.795a1.094 1.094 0 1 0 1.547 1.547l1.796-1.796 1.666 1.667-3.148 3.147a1.094 1.094 0 0 0 1.547 1.547l3.147-3.148 1.666 1.667-1.795 1.795a1.094 1.094 0 0 0 1.547 1.547l1.795-1.796 1.667 1.667-3.148 3.147a1.094 1.094 0 1 0 1.547 1.547l3.147-3.148 1.666 1.666-1.795 1.796a1.094 1.094 0 1 0 1.547 1.547l1.795-1.796 1.666 1.667-4.988 4.987z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#footage" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#footage"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Footage.propTypes = {
  ...propTypesSvg,
}

Footage.defaultProps = {
  ...defaultPropTypesSvg,
}

export default Footage
