import { useCampaigns } from 'hooks'
import { useState, useContext, useLayoutEffect } from 'react'
import { RootContext } from 'store/root'
import { getCache } from 'utils/cache'
import useCreateStore from '../../lib/useCreateStore'
import filterActions from './actions'
import filterSelectors from './selectors'

// Store para armazenar os dados de filtro selecionados pelo usuário

export const initialState = {
  filters: {
    booleanFilters: {
      purpose: {
        key: 'purpose',
        name: 'Finalidade',
        queryStringKey: 'finalidade',
        options: [
          { value: 'residencial', name: 'Residencial', checked: false },
          { value: 'rural', name: 'Rural', checked: false },
          { value: 'comercial', name: 'Comercial', checked: false },
          {
            value: 'incorporacao',
            name: 'Incorporação',
            checked: false,
          },
          { value: 'industrial', name: 'Industrial', checked: false },
          { value: 'misto', name: 'Misto', checked: false },
          { value: 'consultar', name: 'Indefinida', checked: false },
        ],
      },
      propertyType: {
        key: 'propertyType',
        name: 'Tipo de Imóvel',
        queryStringKey: 'tipo-imovel',
        options: [
          {
            value: 'apartamento',
            name: 'Apartamento',
            checked: false,
          },
          {
            value: 'area',
            name: 'Área',
            checked: false,
          },
          {
            value: 'areas_app',
            name: 'Áreas c/ APP',
            checked: false,
          },
          {
            value: 'box_garagem',
            name: 'Box garagem',
            checked: false,
          },
          {
            value: 'casa',
            name: 'Casa',
            checked: false,
          },
          {
            value: 'casa_condominio',
            name: 'Casa em condomínio',
            checked: false,
          },
          {
            value: 'chacara_sitio',
            name: 'Chácara/Sítio',
            checked: false,
          },
          {
            value: 'fazenda',
            name: 'Fazenda',
            checked: false,
          },
          {
            value: 'flat_hotel',
            name: 'Flat/Hotel',
            checked: false,
          },
          {
            value: 'galpao',
            name: 'Galpão',
            checked: false,
          },
          {
            value: 'predio',
            name: 'Prédio',
            checked: false,
          },
          {
            value: 'sala',
            name: 'Sala',
            checked: false,
          },
          {
            value: 'loja_salao_comercial',
            name: 'Loja/Salão comercial',
            checked: false,
          },
          {
            value: 'lote',
            name: 'Lote',
            checked: false,
          },
          {
            value: 'lote_condominio',
            name: 'Lote em condomínio',
            checked: false,
          },

          {
            value: 'outros',
            name: 'Outros',
            checked: false,
          },
        ],
      },
      paymentType: {
        key: 'paymentType',
        name: 'Forma de Pagamento',
        queryStringKey: 'forma_pagamento',
        options: [
          {
            value: 'vista',
            name: 'À vista',
            checked: false,
            isVisible: true,
          },
          {
            value: 'parcelamento_direto',
            name: 'Parcelamento Direto',
            checked: false,
            isVisible: true,
          },
          {
            value: 'financiado',
            name: 'Financiamento Imobiliário',
            checked: false,
            isVisible: true,
          },
        ],
      },
      saleCategory: {
        key: 'saleCategory',
        name: 'Categoria de venda',
        queryStringKey: 'categoria_venda',
        options: [
          {
            value: 'venda_judicial',
            name: 'Venda Judicial',
            checked: false,
          },
          {
            value: 'pre_venda',
            name: 'Pré-Venda',
            checked: false,
          },
          {
            value: 'primeiro_leilao',
            name: 'Primeiro Leilão',
            checked: false,
          },
          {
            value: 'segundo_leilao',
            name: 'Segundo Leilão',
            checked: false,
          },
          {
            value: 'outras',
            name: 'Outras',
            checked: false,
          },
        ],
      },
      situation: {
        key: 'situation',
        name: 'Situação',
        queryStringKey: 'situacao',
        options: [
          {
            value: 'ocupado',
            name: 'Ocupado',
            checked: false,
          },
          {
            value: 'desocupado',
            name: 'Desocupado',
            checked: false,
          },
          {
            value: 'locado',
            name: 'Alugado',
            checked: false,
          },
        ],
      },
      saleType: {
        key: 'saleType',
        name: 'Tipo de Venda',
        queryStringKey: 'tipo-venda',
        options: [
          {
            value: 'venda_direta',
            name: 'Venda Direta',
            checked: false,
          },
          { value: 'leilao', name: 'Leilão', checked: false },
          {
            value: 'melhor_proposta',
            name: 'Melhor Proposta',
            checked: false,
          },
        ],
      },
      purchaseType: {
        key: 'purchaseType',
        name: 'Tipo de Compra',
        queryStringKey: 'tipo-compra',
        options: [
          {
            value: 'plataforma',
            name: 'Via plataforma (direto c/banco)',
            checked: false,
          },
          {
            value: 'leilao',
            name: 'Via Leiloeiros (sites externos)',
            checked: false,
          },
          {
            value: 'outros',
            name: 'Outros (sites externos)',
            checked: false,
          },
        ],
      },
      discount: {
        key: 'discount',
        name: 'Deságio',
        queryStringKey: 'desagio',
        options: [
          { value: '30', name: 'Até 30%', checked: false },
          { value: '50', name: 'Até 50%', checked: false },
          { value: '51', name: 'Acima de 50%', checked: false },
        ],
      },
      legalActions: {
        key: 'legalActions',
        name: 'Ações judiciais',
        queryStringKey: 'possui-acao-judicial',
        options: [
          {
            name: 'Sem ações judiciais',
            checked: false,
            value: 'nao',
          },
        ],
      },
      debts: {
        key: 'debts',
        name: 'Dívidas',
        queryStringKey: 'possui-dividas',
        options: [
          {
            name: 'Imóvel sem dívidas',
            checked: false,
            value: 'nao',
          },
        ],
      },
      searchRegion: {
        key: 'searchRegion',
        name: 'Buscar na Região',
        queryStringKey: 'buscar-regiao', // confirmar esse nome com a resale
        options: [
          {
            name: 'Buscar na Região',
            checked: false,
            value: 'sim',
          },
        ],
      },
      visits: {
        key: 'visits',
        name: 'Visitas',
        queryStringKey: 'permite-visita',
        options: [
          {
            name: 'Permite visitar',
            checked: false,
            value: 'sim',
          },
          {
            name: 'Tour360',
            checked: false,
            value: 'tourvirtual',
          },
        ],
      },
      campaign: {
        key: 'campaign',
        name: 'Campanhas',
        queryStringKey: 'campanha-exclusiva',
        options: [],
        complexCampaigns: [],
      },
    },
    stringFilters: {
      searchId: {
        key: 'searchId',
        name: 'Buscar por ID',
        queryStringKey: 'id-banco',
        value: '',
      },
      orderBy: {
        key: 'orderBy',
        name: 'Ordenar por',
        queryStringKey: 'order',
        value: 'data',
        options: [
          { value: '', name: 'Ordenar por' },
          { value: 'relevante', name: 'Relevância' },
          { value: 'valor-min', name: 'Menor valor' },
          { value: 'valor-max', name: 'Maior valor' },
          { value: 'desagio', name: 'Desconto' },
          { value: 'data', name: 'Mais Recentes' },
        ],
      },
      coupon: {
        key: 'coupon',
        name: 'cupom',
        queryStringKey: 'cupom',
        value: '',
      },
      preActive: {
        key: 'preActive',
        name: 'pre-ativo',
        queryStringKey: 'pre-ativo',
        value: '',
      },
    },
    numberFilters: {
      minValue: {
        key: 'minValue',
        name: 'Min.',
        queryStringKey: 'valor-min',
        value: 0,
      },
      maxValue: {
        key: 'maxValue',
        name: 'Max.',
        queryStringKey: 'valor-max',
        value: 70000000,
      },
      page: {
        key: 'page',
        queryStringKey: 'page',
        value: 1,
      },
    },
    arrayFilters: {
      localities: {
        key: 'localities',
        name: 'Localidade',
        queryStringKey: 'search',
        value: [],
        options: getCache({ key: 'localitiesOptions' }) || [],
      },
    },
  },
  queryString: '',
  openFilterModal: false,
  appliedFilters: false,
}

const FilterStore = useCreateStore(() => {
  const [$filter, setFilter] = useState(
    JSON.parse(JSON.stringify(initialState))
  )
  const selectors = filterSelectors($filter)
  const {
    setError,
    $root: { campaignsFilters, campaigns },
    setLoadingCampaign,
  } = useContext(RootContext)
  const actions = filterActions({
    setState: setFilter,
    setError,
    initialState: JSON.parse(JSON.stringify(initialState)),
    $filter,
  })

  const { hasCampaignFilter } = useCampaigns()

  useLayoutEffect(() => {
    const { options, complexOptions } = campaignsFilters
    const hasCampaigns = !!Object.keys(campaigns).length
    const dontHasOptions = !options.length && !complexOptions.length

    if (options.length || complexOptions.length) {
      actions.setFilterCampaignOptions(options, complexOptions)
      setLoadingCampaign(false)
    } else if (hasCampaigns && !hasCampaignFilter()) {
      setLoadingCampaign(false)
    } else if (hasCampaigns && dontHasOptions) setLoadingCampaign(false)
    // eslint-disable-next-line
  }, [campaignsFilters])

  return {
    $filter,
    setState: setFilter,
    ...actions,
    ...selectors,
    initialState: JSON.parse(JSON.stringify(initialState)),
  }
})

export const useFilter = () => FilterStore()
export const FilterContext = FilterStore.Context
export const FilterProvider = FilterStore.Provider
