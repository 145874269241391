/* eslint-disable react/no-danger  */
/* 2020/01/18 -> Leandro e Mago: Estamos refatorando o código para manter o EsLint devidamente configurado e acusando os erros no pre commit, no caso do 'dangerouslySetInnerHTML', segundo a própria documentação do React (https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/no-danger.md), caso tenhamos certeza que o que está sendo injetado está devidamente sanitizado podemos dar disable na regra. O motivo desta regra e da necessidade do sanitize é para evitar XSS injection attacks
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import DOMPurify from 'dompurify'

import { NotFound } from 'assets/svgs'

import { BaseLayout } from 'templates'
import { boldString, keyFromString } from 'utils/string'

import btnStyles from 'components/Button/Button.module.scss'
import { Title } from 'components'
import { Link, Redirect, useMatch } from '@reach/router'
import Button from 'components/Button/Button'
import isPreRendering from 'utils/isPreRendering'

import styles from './ErrorPage.module.scss'

const sanitizer = DOMPurify.sanitize

export const errorLinks = [
  {
    label: 'Pesquise um imóvel',
    path: '/busca',
  },
  {
    label: 'Ajuda',
    path: '/perguntas-frequentes',
  },
  {
    label: 'Entre em contato',
    path: '/fale-conosco',
  },
]

const ErrorPage = ({ title, code, body, links, cta }) => {
  const isNotFound = code === 404 || code === 410

  const redirectImovel = useMatch('/imovel')

  if (redirectImovel?.uri === '/imovel' || redirectImovel?.uri === '/imovel/') {
    return <Redirect to="/busca/:slug" noThrow />
  }

  return (
    <>
      <Helmet>
        <title>Imóveis Emgea | 404 Error</title>
        <meta property="title" content="Imóveis Emgea | 404 Error" />
        <meta property="og:site_name" content="Imóveis Emgea" />
        <meta property="og:url" content="https://seuimovelbb.com.br/404" />
      </Helmet>
      {!isPreRendering() && (
        <BaseLayout backgroundColor="white">
          <main className={styles.wrapper}>
            <div className={styles.code}>
              {isNotFound ? <NotFound /> : code}
            </div>
            <div className={styles.divider} />
            <div className={styles.content}>
              <Title
                color={isNotFound ? 'secondaryColor' : 'primaryColor'}
                type="h1"
                fontSize="xlarge"
              >
                {title}
              </Title>
              {body
                .split('\n')
                .filter(p => p.trim() !== '')
                .map(p => (
                  <p
                    key={keyFromString(p)}
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(boldString(p.trim()), {
                        ALLOWED_TAGS: ['a'],
                        ALLOWED_ATTR: ['href'],
                      }),
                    }}
                  />
                ))}
              {Array.isArray(links) && !isNotFound && (
                <ul>
                  {links.map(l => (
                    <li key={l.path}>
                      <Link to={l.path}>{l.label}</Link>
                    </li>
                  ))}
                </ul>
              )}
              {isNotFound && (
                <Link to="/busca">
                  <Button
                    className={styles.seeMoreButton}
                    backgroundColor="secondColor"
                  >
                    Ver mais imóveis
                  </Button>
                </Link>
              )}
              {cta && cta.path && cta.label && (
                <Link
                  to={cta.path}
                  className={`${btnStyles.filled} ${btnStyles.primaryBackground}`}
                >
                  {cta.label}
                </Link>
              )}
            </div>
          </main>
        </BaseLayout>
      )}
    </>
  )
}

ErrorPage.propTypes = {
  title: PropTypes.string.isRequired,
  code: PropTypes.number.isRequired,
  body: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ),
  cta: PropTypes.shape({
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }),
}

ErrorPage.defaultProps = {
  links: [],
  cta: '',
}

export default React.memo(ErrorPage)
