import React from 'react'
import PropTypes from 'prop-types'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const documents = ({ viewBox, color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="documents"
          d="M14.43 9.95L13.282 8.2H6.2v3.666h6.289l1.94-1.916zm1.865 1.25l-2.406 2.376a1.7 1.7 0 0 1-1.195.49H6.2V23H26V11.2h-9.705zm.143-2.2H26.5a1.7 1.7 0 0 1 1.7 1.7v12.8a1.7 1.7 0 0 1-1.7 1.7H5.7A1.7 1.7 0 0 1 4 23.5V7.7A1.7 1.7 0 0 1 5.7 6h7.852a1.7 1.7 0 0 1 1.421.768L16.438 9z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#documents" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#documents"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

documents.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ...propTypesSvg,
}

documents.defaultProps = {
  size: 'auto',
  ...defaultPropTypesSvg,
}

export default documents
