import React from 'react'
import PropTypes from 'prop-types'
import { replaceCampaignToShow } from 'service/campaigns'
import { Toggle } from 'components'

export const RenderComplexCampaign = ({ optionFilter, onChange }) => {
  return (
    <Toggle
      checked={optionFilter.checked}
      onChange={() => onChange()}
      option={replaceCampaignToShow(optionFilter.name)}
      optionPosition="right-aligned"
      id={`campaign-toggle-${optionFilter.name}`}
    />
  )
}

RenderComplexCampaign.propTypes = {
  optionFilter: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
}
