import React from 'react'
import PropTypes from 'prop-types'
import { CategoryTitle, DocumentMiniature } from 'components'
import styles from './DocumentsSection.module.scss'

const DocumentsSection = ({ documents }) => {
  if (!documents.length) return null

  return (
    <section className={styles.documents}>
      <CategoryTitle icon="documents" title="Documentos" />
      <div className={styles.docs}>
        {documents.map(({ nome, url, type }, index) => (
          <FunctionalDocumentMiniature
            key={index.toString()}
            name={nome}
            url={url}
            type={type}
          />
        ))}
      </div>
    </section>
  )
}

const FunctionalDocumentMiniature = ({ name, url, type }) => {
  const [state, setState] = React.useState(false)
  const changeState = () => setState(!state)

  return (
    <DocumentMiniature
      url={url}
      fileName={name}
      type={type}
      parameters={{
        page: 1,
        view: 'fitH',
        toolbar: 0,
        statusbar: 0,
        navpanes: 0,
      }}
      modalProps={{
        opened: state,
        closeModal: changeState,
      }}
    />
  )
}

FunctionalDocumentMiniature.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

DocumentsSection.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default DocumentsSection
