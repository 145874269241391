import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { RangeSlider, RangeValueInput } from 'components'
import { parseToNumber } from 'utils/parseNumbers'
import { validatedMinValue, validatedMaxValue } from './utils'
import styles from './ValueFilter.module.scss'

const ValueFilter = ({
  setNumberFilter,
  step,
  limit,
  stateMinValue,
  stateMaxValue,
  type,
}) => {
  const [minValue, setMinValue] = React.useState(stateMinValue)
  const [maxValue, setMaxValue] = React.useState(stateMaxValue)
  const [localRange, setLocalRange] = React.useState({
    min: stateMinValue,
    max: stateMaxValue,
  })

  const handleLocalRange = localValue => {
    setLocalRange(localValue)
    setMinValue(localValue.min)
    setMaxValue(localValue.max)
  }

  const handleRangeSlider = value => {
    setNumberFilter('minValue', value.min)
    setNumberFilter('maxValue', value.max)
  }

  const handleInputOnBlurMin = value => {
    setNumberFilter(
      'minValue',
      validatedMinValue({ value, limit, step, maxValue: stateMaxValue })
    )
  }

  const handleInputOnBlurMax = value => {
    setNumberFilter(
      'maxValue',
      validatedMaxValue({ value, limit, step, minValue: stateMinValue })
    )
  }

  useEffect(() => {
    setLocalRange({
      min: stateMinValue,
      max: stateMaxValue,
    })

    if (parseToNumber(minValue) < limit.min) {
      setMinValue(limit.min)
    } else {
      setMinValue(stateMinValue)
    }

    if (parseToNumber(maxValue) > limit.max) {
      setMaxValue(limit.max)
    } else {
      setMaxValue(stateMaxValue)
    }
  }, [stateMinValue, stateMaxValue]) // eslint-disable-line

  useEffect(() => {
    if (parseToNumber(minValue) < limit.min) {
      setMinValue(limit.min)
    }
    if (parseToNumber(maxValue) > limit.max) {
      setMaxValue(limit.max)
    }
  }, [minValue, maxValue]) // eslint-disable-line

  return (
    <div className={styles.container}>
      <div className={styles.inputs}>
        <RangeValueInput
          className={styles.rangeInput}
          dataTestId={`min-value-${type}`}
          id={`min-value-${type}`}
          value={minValue}
          valueRange={minValue}
          onChange={setMinValue}
          onBlur={handleInputOnBlurMin}
          max={limit.max}
          min={limit.min}
          step={step}
        />
        <RangeValueInput
          dataTestId={`max-value-${type}`}
          className={styles.rangeInput}
          id={`max-value-${type}`}
          value={maxValue}
          valueRange={maxValue}
          onChange={setMaxValue}
          onBlur={handleInputOnBlurMax}
          max={limit.max}
          min={limit.min}
          step={step}
        />
      </div>
      <RangeSlider
        max={limit.max}
        min={limit.min}
        onChange={handleLocalRange}
        onChangeComplete={handleRangeSlider}
        step={step}
        className={styles.rangeSlider}
        value={localRange}
      />
    </div>
  )
}

ValueFilter.propTypes = {
  limit: PropTypes.shape({
    max: PropTypes.number,
    min: PropTypes.number,
  }).isRequired,
  step: PropTypes.number.isRequired,
  setNumberFilter: PropTypes.func.isRequired,
  stateMinValue: PropTypes.number.isRequired,
  stateMaxValue: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['page', 'modal']).isRequired,
}

export default ValueFilter
