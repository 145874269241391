import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const Money = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="money"
          d="M20.255 17.95a3.3 3.3 0 0 1 .132.944c0 1.024-.333 1.905-.999 2.645-.666.74-1.53 1.196-2.595 1.37v1.756c0 .094-.03.171-.09.231a.313.313 0 0 1-.23.09h-1.356a.308.308 0 0 1-.226-.095.309.309 0 0 1-.095-.226V22.91a6.337 6.337 0 0 1-1.28-.311 6.75 6.75 0 0 1-1.019-.447 6.764 6.764 0 0 1-.742-.482 5.809 5.809 0 0 1-.467-.376 2.533 2.533 0 0 1-.176-.181c-.114-.14-.12-.278-.02-.411l1.034-1.355c.047-.067.124-.108.23-.12a.248.248 0 0 1 .242.09l.02.02c.756.662 1.569 1.08 2.439 1.254.247.054.495.08.742.08.542 0 1.02-.144 1.43-.431.412-.288.618-.696.618-1.225 0-.187-.05-.365-.15-.532a1.887 1.887 0 0 0-.337-.421 2.75 2.75 0 0 0-.587-.377 13.446 13.446 0 0 0-.663-.32 20.826 20.826 0 0 0-.803-.327c-.26-.107-.466-.19-.617-.25a17.43 17.43 0 0 1-.617-.267 7.555 7.555 0 0 1-.628-.31 10.27 10.27 0 0 1-.567-.357 3.63 3.63 0 0 1-.537-.427 6.235 6.235 0 0 1-.436-.492 2.456 2.456 0 0 1-.357-.582 4.008 4.008 0 0 1-.21-.667 3.485 3.485 0 0 1-.086-.783c0-.924.328-1.733.984-2.43.655-.695 1.509-1.143 2.56-1.344V7.32c0-.087.031-.162.095-.226A.308.308 0 0 1 15.116 7h1.356c.093 0 .17.03.23.09s.09.138.09.231v1.767c.382.04.752.117 1.11.23.358.114.649.227.873.337.224.11.437.236.637.376.201.14.332.238.392.291.06.054.11.1.15.14.115.121.131.249.05.382l-.812 1.466c-.054.1-.13.154-.231.16a.32.32 0 0 1-.271-.07 2.08 2.08 0 0 0-.146-.12 5.514 5.514 0 0 0-.391-.266 4.488 4.488 0 0 0-1.335-.582 3.345 3.345 0 0 0-.858-.116c-.636 0-1.155.144-1.556.432-.402.287-.602.659-.602 1.114 0 .174.028.334.085.482.057.147.155.286.296.416.14.13.273.24.396.331.124.09.312.194.562.311a15.509 15.509 0 0 0 1.31.547c.355.134.626.24.814.316.187.077.441.194.762.352.321.157.574.3.758.426a6.5 6.5 0 0 1 .622.502c.231.208.409.42.532.638.124.217.23.473.316.767zM16 29C8.82 29 3 23.18 3 16S8.82 3 16 3s13 5.82 13 13-5.82 13-13 13zm0-2.2c5.965 0 10.8-4.835 10.8-10.8 0-5.965-4.835-10.8-10.8-10.8-5.965 0-10.8 4.835-10.8 10.8 0 5.965 4.835 10.8 10.8 10.8z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#money" />
        </mask>
        <use className={styles[color]} xlinkHref="#money" />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Money.propTypes = {
  ...propTypesSvg,
}

Money.defaultProps = {
  ...defaultPropTypesSvg,
}

export default Money
