import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const InternalTour = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          d="M23.669 6.125a3.457 3.457 0 0 1 3.456 3.456v9.669c0 1.93-1.57 3.456-3.456 3.456h-4.182a3.445 3.445 0 0 1-2.446-1.01l-1.757-1.758A1.82 1.82 0 0 0 14 19.405c-.479 0-.946.193-1.281.53l-1.759 1.759a3.446 3.446 0 0 1-2.447 1.012H4.33A3.457 3.457 0 0 1 .875 19.25V9.581c0-1.93 1.57-3.456 3.456-3.456H23.67zm0 1.64H4.33c-.986 0-1.815.8-1.815 1.816v9.669c0 1.018.83 1.816 1.815 1.816h4.182c.485 0 .943-.19 1.288-.533l1.758-1.757A3.467 3.467 0 0 1 14 17.764c.912 0 1.803.37 2.444 1.014l1.756 1.756c.344.342.801.532 1.287.532h4.182c.986 0 1.815-.799 1.815-1.816V9.581c0-1.017-.83-1.815-1.815-1.815zm-15.82 2.757a3.46 3.46 0 0 1 3.457 3.456 3.46 3.46 0 0 1-3.457 3.456 3.46 3.46 0 0 1-3.456-3.456 3.46 3.46 0 0 1 3.456-3.456zm12.302 0a3.46 3.46 0 0 1 3.456 3.456 3.46 3.46 0 0 1-3.456 3.456 3.46 3.46 0 0 1-3.457-3.456 3.46 3.46 0 0 1 3.457-3.456zm-12.302 1.64a1.818 1.818 0 0 0-1.815 1.816c0 1.001.814 1.816 1.815 1.816a1.818 1.818 0 0 0 1.816-1.816 1.818 1.818 0 0 0-1.816-1.816zm12.302 0a1.818 1.818 0 0 0-1.816 1.816c0 1.001.814 1.816 1.816 1.816a1.818 1.818 0 0 0 1.815-1.816 1.818 1.818 0 0 0-1.815-1.816z"
          id="internalTour"
        />
      </defs>
      <g transform="translate(0 -6)" fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#internalTour" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#internalTour"
        />
        <g mask="url(#b)">
          <path d="M0 0h28v28H0z" />
        </g>
      </g>
    </svg>
  )
}

InternalTour.propTypes = {
  ...propTypesSvg,
}

InternalTour.defaultProps = {
  ...defaultPropTypesSvg,
}

export default InternalTour
