import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'
import { BaseLayout } from 'templates'
import { usePageView } from 'hooks/useGAPageView'

import { Title, Image } from 'components'
import { MessageEmailContainer, MessageFormContainer } from 'containers'
import { MessageFormProvider } from 'store'
import { setUtms } from 'utils/Utms'
import PropTypes from 'prop-types'
import rosieImage from 'assets/images/rosie.png'
import styles from './Contact.module.scss'

export default function Contact({ location: { search } }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setUtms(search)
  }, [search])

  const [registerState, setRegisterState] = useState('email')

  usePageView()

  return (
    <>
      <Helmet>
        <title>Imóveis Emgea | Perguntas Frequentes</title>
        <meta
          property="og:title"
          content="Imóveis Emgea | Perguntas Frequentes"
        />
        <meta property="og:site_name" content="Imóveis Emgea" />
        <meta
          property="og:url"
          content="https://seuimovelbb.com.br/fale-conosco"
        />
        <meta
          property="og:description"
          content="Descubra o que são os imóveis com desconto do Banco do Brasil!"
        />
      </Helmet>
      <BaseLayout backgroundColor="white">
        <main id="contact">
          <div>
            <section className={styles.contactPage}>
              <div className={styles.form}>
                <Title className={styles.title} type="h1" color="secondColor">
                  Fale conosco
                </Title>
                <MessageFormProvider>
                  {registerState === 'email' ? (
                    <MessageEmailContainer
                      setRegisterState={setRegisterState}
                    />
                  ) : (
                    <MessageFormContainer />
                  )}
                </MessageFormProvider>
              </div>
              <div className={styles.image}>
                <Image source={rosieImage} alt="rosie image" />
              </div>
            </section>
          </div>
        </main>
      </BaseLayout>
    </>
  )
}

Contact.propTypes = {
  location: {
    search: PropTypes.string,
  },
}

Contact.defaultProps = {
  location: {
    search: '',
  },
}
