import { to } from 'nh-utils'
import api, { apiWithoutPropertySuffix } from 'service/api'
import isPreRendering from 'utils/isPreRendering'

export const bannerImage = async () =>
  !isPreRendering() && to(apiWithoutPropertySuffix.get('home/banner'))
export const advertisingData = async () =>
  !isPreRendering() && to(apiWithoutPropertySuffix.get(`/home/advertising`))
export const fetchBestSellingProperty = async () =>
  !isPreRendering() && to(api.get('?destaques=true'))
