import React from 'react'

import { One, Two, Three, Four, Five, Six } from 'assets/images/juridical-sale'

import PropTypes from 'prop-types'

import styles from './Processometer.module.scss'

export default function Processometer({ score }) {
  function handleProcessometer() {
    if (score <= 100) {
      return One
    }

    if (score >= 101 && score <= 150) {
      return Two
    }

    if (score >= 151 && score <= 300) {
      return Three
    }

    if (score >= 301 && score <= 550) {
      return Four
    }

    if (score >= 551 && score <= 700) {
      return Five
    }

    if (score >= 701 && score <= 1000) {
      return Six
    }

    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span>Processômetro</span>
      </div>
      <div className={styles.processometer}>
        <img
          className={styles.image}
          src={handleProcessometer()}
          alt="Processômetro"
        />
      </div>
    </div>
  )
}

Processometer.propTypes = {
  score: PropTypes.number.isRequired,
}
