import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const Desk = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="desk"
          d="M27.667 16H19.5v-2.333H23a2.336 2.336 0 0 0 2.333-2.334v-7A2.336 2.336 0 0 0 23 2H9a2.336 2.336 0 0 0-2.333 2.333v7A2.336 2.336 0 0 0 9 13.667h3.5V16H4.333A2.336 2.336 0 0 0 2 18.333v10.5a1.167 1.167 0 0 0 2.333 0v-10.5h14v10.5c0 .644.523 1.167 1.167 1.167h9.333c.644 0 1.167-.523 1.167-1.167v-10.5A2.336 2.336 0 0 0 27.667 16zm0 2.333v3.5h-7v-3.5h7zm-18.668-14H23v7H9l-.001-7zm5.834 9.334h2.334V16h-2.334v-2.333zm5.834 14v-3.5h7v3.5h-7z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#desk" />
        </mask>
        <use className={styles[color]} fillRule="nonzero" xlinkHref="#desk" />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Desk.propTypes = {
  ...propTypesSvg,
}

Desk.defaultProps = {
  ...defaultPropTypesSvg,
}

export default Desk
