import React from 'react'
import { useRoot } from 'store'

/**
 * 1. Receives a callback (modal opener setter) and a unique modal name for that modal
 * 2. Creates and event listener to be called when user intends to leave page. ATTENTION: as it's currently structured the event will only be called when user opens another tab or focus browser URL to type another site. If, in the future we want it to be called  when the user hovers over browser header we should use 'mouseleave' instead of 'blur' and set the event to tag body, instead of window
 * 3. the event to be called onBlur checkes if the modal at hand (passed as param modalName) has been opened before (saved in localStorage) if so, it prevents callback (modal setter) to be called, otherwise it allows setter to be called and set localStorage for that modalName so that it won't be opened in the future
 * @param {function} callback setter in store which opens modal in question (ex. setOpenAlertModal from useAlert())
 * @param {string} modalName unique name of modal in question, so that it can be a unique key in localStorage to prevent this hook to open the same alert after first opening
 * @author Leandro Parisi
 * @since 2020.03.26
 */
export const useWindowOut = (callback, modalName) => {
  const { setLeadModal } = useRoot()

  React.useEffect(() => {
    const excludePaths = ['/simulador']

    if (!excludePaths.includes(window.location.pathname)) {
      const onBlur = event => {
        const wasShown = window.localStorage.getItem(`${modalName}Displayed`)

        if (!wasShown) {
          setLeadModal({ openModal: false, wasOpened: true })
          callback({ event })
          window.localStorage.setItem(`${modalName}Displayed`, true)
        }
      }
      window.addEventListener('blur', onBlur, false)

      return () => window.removeEventListener('blur', onBlur, false)
    }
    return null
  }, [modalName, callback, setLeadModal])
}
