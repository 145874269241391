import React from 'react'
import PropTypes from 'prop-types'
import { Text, Modal, Button, Title } from 'components'
import styles from './DisclaimerModal.module.scss'

const DisclaimerModal = ({ opened, close }) => {
  return (
    <Modal
      opened={opened}
      className={styles.disclaimerModal}
      backdropClassName={styles.disclaimerBackdrop}
      hideCloseBtn
    >
      <Title type="h4">Aviso Importante</Title>
      <Text>
        As informações aqui disponibilizadas foram obtidas em bases públicas,
        para a finalidade específica de dar ciência ao potencial comprador sobre
        a real situação jurídica do imóvel, não devendo ser utilizadas, em
        hipótese alguma, para outras finalidades.
      </Text>
      <Button onClick={close}>Estou de acordo</Button>
    </Modal>
  )
}

DisclaimerModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
}

export default React.memo(DisclaimerModal)
