import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './AmountResults.module.scss'

const AmountResults = ({
  color,
  fontSize,
  bold,
  amountResults,
  afterSearch,
  profileType,
}) => {
  const profilePhrases = {
    iniciante:
      'Quero investir nesse tipo de imóvel mas ainda não tenho experiência',
    experiente:
      'Sou um(a) investidor(a) experiente e quero ter acesso a boas oportunidades',
    ousado:
      'Sou arrojado(a) e quero ter acesso a boas oportunidades independente do risco',
    nao_investidor: 'Quero um imóvel para morar',
  }

  const renderProfilePhrases = () => {
    if (afterSearch) {
      return (
        <p
          className={classNames(
            styles.text,
            styles.primaryColor,
            styles[`font-${fontSize}`],
            { [styles.bold]: bold }
          )}
        >
          {`"${profilePhrases[profileType]}"`}
        </p>
      )
    }
    return null
  }

  const searchPhrases = () => {
    if (afterSearch) {
      return 'oportunidades encontradas no seu perfil! '
    }

    if (!amountResults) {
      return 'Nenhuma oportunidade encontrada!'
    }

    return `${amountResults.toLocaleString(
      'pt-br'
    )} oportunidades disponíveis. Use a barra lateral para filtrar imóveis e encontrar o que procura!`
  }

  const searchPhrasesMobile = () => {
    if (!amountResults) {
      return ''
    }

    return `${amountResults.toLocaleString('pt-br')} imóveis encontrados`
  }

  return (
    <>
      <p
        className={classNames(
          styles.text,
          styles[color],
          styles[`font-${fontSize}`],
          styles.desktop,
          { [styles.bold]: bold }
        )}
      >
        {searchPhrases()}
        {renderProfilePhrases()}
      </p>

      {/* Mobile */}
      <p className={classNames(styles.mobile, styles[`font-${fontSize}`])}>
        {searchPhrasesMobile()}
        {renderProfilePhrases()}
      </p>
    </>
  )
}

AmountResults.propTypes = {
  color: PropTypes.oneOf(['darkBlueColor', 'lightTextColor', 'blackColor']),
  fontSize: PropTypes.oneOf(['small', 'medium', 'large']),
  bold: PropTypes.bool,
  amountResults: PropTypes.number.isRequired,
  afterSearch: PropTypes.bool.isRequired,
  profileType: PropTypes.string.isRequired,
}

AmountResults.defaultProps = {
  color: 'lightTextColor',
  fontSize: 'medium',
  bold: false,
}

export default React.memo(AmountResults)
