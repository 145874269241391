import React from 'react'

import PropTypes from 'prop-types'

import styles from './Advertising.module.scss'

const Advertising = ({ url, link }) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <figure className={styles.advertising}>
        <img
          loading="lazy"
          src={url}
          alt="#advertising"
          className={styles.infogDesk}
        />
      </figure>
    </a>
  )
}

Advertising.propTypes = {
  url: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default React.memo(Advertising)
