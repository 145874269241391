/* eslint-disable no-useless-escape */
import { parseToString } from './parseNumbers'

const validateEmail = email => {
  if (!email) {
    return null
  }

  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email.trim().toLowerCase())
}

const validatePhone = phone => {
  const onlyNumbers = parseToString(phone)
  return (
    (onlyNumbers.length >= 10 && onlyNumbers.length <= 11) ||
    onlyNumbers.length === 0
  )
}

const validateEntry = (price, entry) => {
  if (!entry) return null
  const entryValue = Number(entry)
  const priceValue = Number(price)
  const minEntryValue = Number((priceValue * 0.1).toFixed(0))
  return entryValue >= minEntryValue && entryValue < priceValue
}

const validateMandatoryPhone = phone => {
  if (!phone) return null
  const onlyNumbers = phone.replace(/[^0-9]/g, '')

  return onlyNumbers.length >= 10 && onlyNumbers.length <= 11
}

const validateFullName = fullName =>
  /^([a-zA-Z\u00C0-\u017F]+\s)+[a-zA-Z\u00C0-\u017F]+$/.test(fullName.trim())

const validateCurrency = value => Number(value) > 0

export {
  validateCurrency,
  validateEmail,
  validateEntry,
  validateFullName,
  validatePhone,
  validateMandatoryPhone,
}
