import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, Text } from 'components'
import { useFilter } from 'store'
import Filter from 'containers/FilterContainer/Filter/Filter'
import styles from './FiltersModal.module.scss'

const FiltersModal = ({ opened, closeModal, childrenElement }) => {
  const { clearFilters, updateQueryString } = useFilter()

  const close = () => {
    document.getElementById('list').scrollIntoView()
    closeModal()
  }

  const save = () => {
    close()
  }

  const reset = () => {
    clearFilters()
    updateQueryString()
  }

  return (
    <Modal
      opened={opened}
      closeModal={closeModal}
      closeButtonPosition="left"
      closeClassName={styles.filterModalClear}
      className={styles.modal}
    >
      <div className={styles.filtersContainer}>
        <div className={styles.header}>
          <Text fontSize="large" bold>
            Refinar busca
          </Text>
        </div>
        <button
          type="button"
          className={styles.clearLink}
          onClick={() => reset()}
        >
          Limpar
        </button>
        <Filter type="filterModal" />
        {childrenElement}
        <span className={styles.confirmButtonContainer}>
          <Button className={styles.confirmButton} onClick={() => save()}>
            Aplicar Filtros
          </Button>
        </span>
      </div>
    </Modal>
  )
}

FiltersModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  childrenElement: PropTypes.element,
}

FiltersModal.defaultProps = {
  childrenElement: <></>,
}

export default React.memo(FiltersModal)
