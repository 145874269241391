import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const Linkedin = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="linkedin"
          d="M12.661 21.203h-2.513v-7.559h2.513v7.56zm-1.256-8.591h-.017c-.843 0-1.388-.58-1.388-1.306 0-.742.562-1.306 1.421-1.306.86 0 1.389.564 1.405 1.306 0 .725-.545 1.306-1.421 1.306zm10.316 8.591h-2.513V17.16c0-1.016-.363-1.71-1.272-1.71-.694 0-1.108.468-1.29.92-.066.161-.082.387-.082.613v4.221H14.05s.033-6.85 0-7.559h2.513v1.07c.334-.515.931-1.247 2.264-1.247 1.653 0 2.893 1.08 2.893 3.402v4.334zM16 30C8.268 30 2 23.732 2 16S8.268 2 16 2s14 6.268 14 14-6.268 14-14 14zm0-2.2c6.517 0 11.8-5.283 11.8-11.8S22.517 4.2 16 4.2 4.2 9.483 4.2 16 9.483 27.8 16 27.8z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#linkedin" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#linkedin"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Linkedin.propTypes = {
  ...propTypesSvg,
}

Linkedin.defaultProps = {
  ...defaultPropTypesSvg,
}

export default Linkedin
