/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Text.module.scss'

const Text = ({
  children,
  color,
  fontSize,
  style,
  bold,
  className,
  id,
  title,
  alignCenter,
  onClick,
}) => {
  return (
    <p
      id={id}
      title={title}
      className={classNames(
        styles.text,
        styles[color],
        styles[`font-${fontSize}`],
        className,
        { [styles.bold]: bold, [styles.textCenter]: alignCenter }
      )}
      style={style}
      onClick={onClick}
    >
      {children}
    </p>
  )
}

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.oneOf([
    'primaryColor',
    'secondColor',
    'darkBlueColor',
    'lightTextColor',
    'blackColor',
    'resaleGreenColor',
    'grayColor',
    'goldenColor',
    'gunmetalColor',
    'whiteColor',
  ]),
  fontSize: PropTypes.oneOf([
    'xxsmall', // 10px
    'xsmall', // 12px
    'small', // 14px
    'medium', // 16px
    'regular', // 18px
    'large', // 20px
    'xlarge', // 24px
  ]),
  style: PropTypes.objectOf(PropTypes.shape({})),
  bold: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  alignCenter: PropTypes.bool,
  onClick: PropTypes.func,
}

Text.defaultProps = {
  color: 'blackColor',
  fontSize: 'small',
  style: {},
  bold: false,
  className: '',
  id: '',
  title: null,
  children: undefined,
  alignCenter: false,
  onClick: () => null,
}

export default React.memo(Text)
