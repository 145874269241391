/* eslint-disable jsx-a11y/label-has-for */
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FaTimes, FaSearch } from 'react-icons/fa'
import { Text } from 'components'
import noop from 'lib/noop'
import { parseToString } from 'utils/parseNumbers'

import styles from './Input.module.scss'

const MaskComponent = memo(
  ({ onChange, autocomplete, placeholder, ...base }) => {
    const beforeOnChange = e => {
      const applyMask = (currentValue, mask = base.mask) => {
        const maxAllowedLength = (mask.match(/\*/g) || []).length
        const onlyNumbers = parseToString(currentValue)

        if (onlyNumbers?.length > maxAllowedLength) {
          return currentValue.slice(0, currentValue.length - 1)
        }

        const formatedValue = []
        let valueIndex = 0
        let maskIndex = 0
        while (valueIndex < onlyNumbers?.length) {
          if (mask[maskIndex] === '*') {
            formatedValue.push(onlyNumbers[valueIndex])
            valueIndex += 1
          } else {
            formatedValue.push(mask[maskIndex])
          }
          maskIndex += 1
        }
        if (
          maxAllowedLength === onlyNumbers?.length &&
          mask[mask.length - 1] !== '*'
        ) {
          formatedValue.push(mask[mask.length - 1])
        }
        return formatedValue.join('')
      }
      const newValue = applyMask(e.target.value)
      e.target.value = newValue
      onChange(e)
    }
    return (
      <input
        className={styles.input}
        type="text"
        name={base.id}
        placeholder={placeholder}
        onChange={beforeOnChange}
        autoComplete={autocomplete}
        {...base}
      />
    )
  }
)

MaskComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  autocomplete: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
}

MaskComponent.defaultProps = {
  autocomplete: 'on',
}
const NormalInput = memo(
  ({
    rows,
    icon,
    round,
    className,
    onClear,
    onSearch,
    autocomplete,
    placeholder,
    invertIconOnX,
    ...base
  }) => {
    const classesNamesInput = classNames(styles.input, className, {
      [styles.icon]: icon,
      [styles.round]: round,
    })

    return (
      <div className={icon && styles.inputWrapper}>
        {rows > 0 ? (
          <textarea
            rows={rows}
            className={classesNamesInput}
            name={base.id}
            {...base}
          />
        ) : (
          <input
            className={classesNamesInput}
            name={base.id}
            placeholder={placeholder}
            autoComplete={autocomplete}
            onKeyDown={event => {
              if (event.key === 'Enter' && icon === 'search') {
                onSearch()
              }
            }}
            {...base}
          />
        )}
        {icon && (
          <span className={styles.adornment}>
            {icon === 'search' && (
              <FaSearch
                className={classNames(styles.xIcon, styles.searchIcon, {
                  [styles.xInvertedIcon]: invertIconOnX,
                })}
                onClick={onSearch}
              />
            )}
            {icon === 'clear' && (
              <FaTimes
                onClick={onClear}
                className={classNames(styles.xIcon, {
                  [styles.xInvertedIcon]: invertIconOnX,
                })}
              />
            )}
          </span>
        )}
      </div>
    )
  }
)

NormalInput.propTypes = {
  rows: PropTypes.number,
  round: PropTypes.bool,
  icon: PropTypes.string,
  className: PropTypes.string,
  onClear: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  autocomplete: PropTypes.string,
  invertIconOnX: PropTypes.bool,
}

NormalInput.defaultProps = {
  rows: 0,
  icon: '',
  round: false,
  className: '',
  onClear: noop,
  onSearch: noop,
  autocomplete: 'on',
  invertIconOnX: false,
}

const Input = ({
  errorMessage,
  hasError,
  label,
  round,
  labelClassName,
  className,
  autocomplete,
  placeholder,
  labelTextColor,
  ...base
}) => {
  const classesInput = classNames(className, styles.wrapper, {
    [styles.error]: hasError,
  })

  const maskProps = { ...base }
  delete maskProps.onClear
  if (!maskProps.onSearch) {
    delete maskProps.onSearch
  }

  return (
    <div className={classesInput}>
      {label && (
        <label
          id={base.id}
          htmlFor={base.id}
          className={classNames(styles.label, labelClassName)}
        >
          <Text fontSize="medium" color={labelTextColor || null}>
            {label}
          </Text>
        </label>
      )}
      {base.mask && base.mask.length > 0 ? (
        <MaskComponent
          {...maskProps}
          placeholder={placeholder}
          autoComplete={autocomplete}
          className={classNames(styles.input, className)}
        />
      ) : (
        <NormalInput
          placeholder={placeholder}
          autoComplete={autocomplete}
          className={className}
          {...base}
          mask={undefined}
        />
      )}
      {hasError && <span className={styles.span}>{errorMessage}</span>}
    </div>
  )
}

Input.propTypes = {
  rows: PropTypes.number,
  errorMessage: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  hasError: PropTypes.bool,
  round: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  mask: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.string,
  onClear: PropTypes.func,
  onSearch: PropTypes.func,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  autocomplete: PropTypes.string,
  labelTextColor: PropTypes.string,
}

Input.defaultProps = {
  rows: 0,
  errorMessage: 'error',
  onBlur: noop,
  onFocus: noop,
  onClear: noop,
  onSearch: null,
  hasError: false,
  id: undefined,
  mask: undefined,
  placeholder: '',
  type: 'text',
  value: '',
  icon: '',
  round: false,
  className: '',
  labelClassName: '',
  label: '',
  autocomplete: 'on',
  labelTextColor: '',
}

export default React.memo(Input)
