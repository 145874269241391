/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useLayoutEffect } from 'react'
import isPreRendering from 'utils/isPreRendering'
import { getBanners } from 'service/campaigns'
import { useCampaigns } from 'hooks'
import { useLocation } from '@reach/router'
import styles from './ListBanner.module.scss'
import { CarouselHome } from '../BannerCarousel/Carousel'

const setImageBySearch = (arr, search) => {
  const index = arr?.findIndex(banner => banner.link.includes(search))
  const current = index !== -1 && arr[index]

  if (current) {
    arr.splice(index, 1)
    arr.unshift(current)
  }
}
const ListBanner = () => {
  const { campaigns } = useCampaigns()
  const { listBanners } = getBanners({ campaigns })
  const { search } = useLocation()

  const [bannerVisible, setBannerVisible] = useState(false)

  setImageBySearch(listBanners, search)

  useLayoutEffect(() => {
    if (!isPreRendering()) {
      setBannerVisible(true)
    }
  }, [])

  if (!bannerVisible || !listBanners.length) return null
  return (
    <section id="carousel-home-container" className={styles.carousel}>
      <CarouselHome
        bannersList={listBanners}
        autoPlay={listBanners.length > 1 && !search}
        hasCountDown={false}
      />
    </section>
  )
}

ListBanner.propTypes = {}

ListBanner.defaultProps = {}

export default React.memo(ListBanner)
