const mediaInfoPoviders = {
  data: [
    {
      nota: 4.7,
      depoimento:
        '"Boa empresa de se trabalhar contado bem comunicativos e pagamento de imediato logo após prestação de serviço."',
      tipo: 'Chaveiro',
      nome: 'Valdinei',
      cidade: 'Campo Grande - MS',
    },
    {
      nota: 4.7,
      depoimento:
        '"Foi muito bom!! Estou muito satisfeita com todo procedimento. Vcs estão de parabéns!! 👏🏻👏🏻👏🏻"',
      tipo: 'Diligente',
      nome: 'Dra. Ana Paula',
      cidade: 'Betim - MG',
    },
    {
      nota: 4.7,
      depoimento:
        '"Fui contratado pelo Bruno para fazer uma pauta fotográfico em uma repartição do Banco do Brasil aqui em Brasília. Assim que executei os serviços e entreguei a nota fiscal fui pago no mesmo dia."',
      tipo: 'Fotógrafo',
      nome: 'R.W Fotografia',
      cidade: 'Brasília - DF',
    },
    {
      nota: 4.7,
      depoimento:
        '"É uma honra poder prestar serviços para uma empresa prestigiada como é a Resale. Que mesmo tendo uma atuação gigantesca no mercado imobiliário, não perde a pessoalidade em cada negociação. Uma empresa organizada e eficiente, pontual em seus compromissos."',
      tipo: 'Diligente',
      nome: 'Dra. Daiane',
      cidade: 'Serra - ES',
    },
  ],
}
export { mediaInfoPoviders }
