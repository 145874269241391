import React, { useEffect } from 'react'
import { BestSellingProperty, Title, Button, Loading } from 'components'
import { navigate, Link } from '@reach/router'
import { useHome } from 'store'
import { setListPropertySlug } from 'utils/propertyUrl'
import Carousel from 'react-multi-carousel'
import responsive from './responsive'
import styles from './BestSellingPropertyContainer.module.scss'

const BestSellingPropertyContainer = () => {
  const { getBestSellers, fetchBestSellingProperty } = useHome()
  const { bestSellers, bestSellersLoading, bestSellersErr } = getBestSellers()

  useEffect(() => {
    fetchBestSellingProperty()
  }, [fetchBestSellingProperty])

  if (bestSellersErr) return null

  return !bestSellers.length && !bestSellersLoading ? (
    <></>
  ) : (
    <section className={styles.bestSellingSection}>
      <Title type="h2" fontSize="xxxlarge">
        Imóveis em destaque
      </Title>

      {bestSellersLoading || !bestSellers.length ? (
        <div className={styles.loading}>
          <Loading size="medium" />
        </div>
      ) : (
        <div className={styles.bestSellingList}>
          <Carousel
            additionalTransfrom={0}
            responsive={responsive}
            swipeable
            partialVisible
            arrows
            removeArrowOnDeviceType={[
              'mobileXxSmall',
              'mobileXSmall',
              'mobileSmall',
              'mobileMid',
            ]}
            containerClass={styles.highlightedList}
          >
            {bestSellers.map(property => (
              <BestSellingProperty
                key={property.id}
                property={property}
                navigateRouter={`/imovel/${setListPropertySlug(property)}`}
                onClick={() => {
                  window.scrollTo(0, 0)
                  navigate(`/imovel/${setListPropertySlug(property)}`)
                }}
              />
            ))}
          </Carousel>
        </div>
      )}

      <Link to="/busca" className={styles.buttonOpportunity}>
        <Button
          onClick={() => {
            window.scrollTo(0, 0)
            navigate(`/busca`)
          }}
          fontSize="medium"
          textColor="whiteColor"
        >
          Ver todos os imóveis
        </Button>
      </Link>
    </section>
  )
}

BestSellingPropertyContainer.propTypes = {}

BestSellingPropertyContainer.defaultProps = {}

export default BestSellingPropertyContainer
