import React from 'react'
import PropTypes from 'prop-types'
import {
  Image,
  AprraisedValue,
  Feature,
  Discount,
  Tag,
  SaleValueText,
  Tooltip,
  Text,
  PicsCarousel,
} from 'components'
import { Link } from '@reach/router'
import noop from 'lib/noop'
import CONSTANTS from 'utils/systemsConstants'
import getPropertyCategoryImage from 'utils/getPropertyCategoryImage'
import styles from './BestSellingProperty.module.scss'

const BestSellingProperty = ({ property, onClick, navigateRouter }) => {
  const {
    name,
    photo,
    longPhoto,
    features,
    appraisedValue,
    appraisedLabel,
    desagio,
    saleLabel,
    saleValue,
    tags,
    soldStatus,
    category,
    city,
    state,
    picsArray,
  } = property

  const isSold = soldStatus === 'vendido'
  const { CONCORRENCIA } = CONSTANTS

  const ImageHeader = () => {
    return (
      <figure className={`${isSold ? styles.sold : ''} ${styles.image}`}>
        <Link to={navigateRouter} title={`Link para o imóvel ${name}`}>
          <Image
            source={photo}
            sourceLarge={longPhoto}
            noPhoto={getPropertyCategoryImage(category)}
            noPhotoLarge={getPropertyCategoryImage(`${category}Large`)}
            onClick={onClick}
            alt={`Link para o imóvel ${name}`}
          />
        </Link>
        <div>
          <span>VENDIDO</span>
        </div>
      </figure>
    )
  }

  const Features = () => {
    return (
      <div className={styles.features}>
        {features.map(({ id, feature, amount, icon }) => (
          <Feature
            key={id}
            icon={icon}
            number={amount}
            feature={feature}
            type="rowWithoutName"
          />
        ))}
      </div>
    )
  }

  const Info = () => {
    return (
      <div className={styles.information}>
        <div className={styles.tags}>
          {tags.map(tag => (
            <Tag key={tag} campaign={tag === CONCORRENCIA}>
              {tag}
            </Tag>
          ))}
        </div>
        <header>
          <Text className={styles.state}>{`${city}/${state}`}</Text>
          <Link to={navigateRouter} className={styles.link}>
            <Tooltip className={styles.propertyText}>{name}</Tooltip>
          </Link>
        </header>
        <Features />
      </div>
    )
  }

  const Values = () => {
    return (
      <div className={styles.values}>
        {Number(appraisedValue) > Number(saleValue) ? (
          <span className={styles.prices}>
            <AprraisedValue
              value={appraisedValue}
              strike={appraisedValue !== saleValue}
              label={appraisedLabel}
            />
            <span className={styles.discount}>
              <Discount percent={desagio} />
              <SaleValueText saleLabel={saleLabel} value={saleValue} />
            </span>
          </span>
        ) : (
          <span className={styles.saleOnly}>
            <SaleValueText saleLabel={saleLabel} value={saleValue} />
          </span>
        )}
      </div>
    )
  }

  return (
    <article className={styles.card}>
      {Array.isArray(picsArray) && picsArray.length ? (
        <PicsCarousel>
          {picsArray.map(pic => (
            <Image
              alt={`Foto do imóvel ${name}`}
              sourceMobile={pic}
              source={pic}
              sourceLarge={pic}
              noPhotoMobile={getPropertyCategoryImage(`${category}Large`)}
              noPhoto={getPropertyCategoryImage(category)}
              noPhotoLarge={getPropertyCategoryImage(category)}
              onClick={onClick}
            />
          ))}
        </PicsCarousel>
      ) : (
        <ImageHeader />
      )}
      <Info />
      <Values />
      <Link to={navigateRouter} className={styles.mobileNavigate} />
    </article>
  )
}

BestSellingProperty.propTypes = {
  property: PropTypes.shape({
    name: PropTypes.string,
    photo: PropTypes.string,
    longPhoto: PropTypes.string,
    address: PropTypes.string,
    description: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.shape({})),
    appraisedValue: PropTypes.number,
    desagio: PropTypes.number,
    saleValue: PropTypes.number,
    saleLabel: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    soldStatus: PropTypes.string,
    appraisedLabel: PropTypes.string,
    category: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    picsArray: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  navigateRouter: PropTypes.string.isRequired,
  favoriteButton: PropTypes.shape({
    onClick: PropTypes.func,
    value: PropTypes.bool,
  }),
}

BestSellingProperty.defaultProps = {
  favoriteButton: {
    onClick: noop,
    value: false,
  },
}

export default React.memo(BestSellingProperty)
