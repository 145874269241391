/**
 * Capitalizes first letter of string
 * @param {string} string
 * @returns string
 * @author Leandro Parisi
 */
const capitalize = string => {
  if (typeof string !== 'string') return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export default capitalize
