import React from 'react'
import PropTypes from 'prop-types'
import styles from './Slide.module.scss'

const Slide = ({ url, alt, noPhoto, className }) => {
  const [newSource, setSource] = React.useState(url)

  if (url !== newSource) {
    setSource(url)
  }

  return (
    <img
      className={className || styles.slide}
      loading="lazy"
      alt={alt}
      src={newSource}
      onError={() => setSource(noPhoto)}
    />
  )
}

Slide.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  noPhoto: PropTypes.string.isRequired,
  className: PropTypes.string,
}

Slide.defaultProps = {
  className: '',
}

export default Slide
