import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const Bed = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="bed"
          d="M27.849 6.188c0-.617-.49-1.117-1.094-1.117-.604 0-1.093.5-1.093 1.117v2.048a16.041 16.041 0 0 0-19.324 0V6.119c0-.617-.49-1.117-1.093-1.117-.604 0-1.094.5-1.094 1.117v9.928C2.923 16.321 2 17.44 2 18.774v4.913c0 .617.49 1.117 1.094 1.117h2.133v1.08c0 .616.49 1.116 1.093 1.116.604 0 1.094-.5 1.094-1.117v-1.079h17.172v1.08c0 .616.49 1.116 1.094 1.116.604 0 1.093-.5 1.093-1.117v-1.079h2.133c.604 0 1.094-.5 1.094-1.117v-4.913c0-1.335-.923-2.453-2.151-2.727V6.188zm-2.187 4.953v4.841h-4.266v-3.275c0-.617-.49-1.116-1.094-1.116h-8.604c-.604 0-1.094.5-1.094 1.116v3.275H6.338v-4.841c5.388-5.172 13.858-5.247 19.324 0zm-12.87 4.842v-2.16h6.416v2.16h-6.416zm15.02 6.588H4.188v-3.797c0-.308.246-.558.547-.558h22.532c.301 0 .547.25.547.558v3.797z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#bed" />
        </mask>
        <use className={styles[color]} fillRule="nonzero" xlinkHref="#bed" />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Bed.propTypes = {
  ...propTypesSvg,
}

Bed.defaultProps = {
  ...defaultPropTypesSvg,
}

export default Bed
