/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Title, Text, Button } from 'components'
import applyMask from 'utils/applyMask'
import masks from 'utils/masks'
import styles from './FinishedPropertyModal.module.scss'

const FinishedPropertyModal = ({ opened, closeModal, contactInfo }) => {
  return (
    <Modal opened={opened} closeModal={closeModal}>
      <section className={styles.finishedPropertyModal}>
        <header className={styles.header}>
          <Title type="h1" fontSize="xlarge">
            Aviso - Imóvel Encerrado
          </Title>
        </header>
        <main className={styles.main}>
          <div className={styles.info}>
            <Text fontSize="medium" color="lightTextColor">
              Esse imóvel não está disponível para receber lances no momento.
              {contactInfo.name && contactInfo.phone && (
                <span>
                  No entanto, caso deseje obter mais informações sobre como
                  comprá-lo, entre em contato com a empresa
                  <b> {contactInfo.name}</b>, pelo telefone: <br />
                  <b> {applyMask(contactInfo.phone, masks.landLinePhone)} </b>
                  (horário comercial).
                </span>
              )}
            </Text>
          </div>
        </main>
        <footer className={styles.footer}>
          <Button
            onClick={closeModal}
            fontSize="medium"
            className={styles.submit}
          >
            Ok, entendi.
          </Button>
        </footer>
      </section>
    </Modal>
  )
}

FinishedPropertyModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  contactInfo: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
}

export default React.memo(FinishedPropertyModal)
