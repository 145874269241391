/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import PropTypes from 'prop-types'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import {
  Button,
  Checkbox,
  Input,
  Modal,
  Text,
  Title,
  Optins,
  PublicCompetitionInfo,
} from 'components'
import {
  validateEmail,
  validateFullName,
  validateMandatoryPhone,
} from 'utils/validations'
import mask from 'utils/masks'
import pagimovelLogo from 'assets/images/logo-pagimovel.png'
import selectPhoneMask from 'utils/selectPhoneMask'
import styles from './ProposalModal.module.scss'

const ProposalModal = ({
  closeModal,
  isLoading,
  isLoadingRecoverPassword,
  opened,
  sendForm,
  setField,
  values,
  success,
  recoverPassword,
  url,
  competitionProps,
  idButton,
}) => {
  const [touched, setTouched] = React.useState({})
  const [checkOffers, setCheckOffers] = React.useState(true)
  const [checkOffersResale, setCheckOffersResale] = React.useState(true)
  const [checkOffersPgi, setCheckOffersPgi] = React.useState(true)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleReCaptchaVerify = React.useCallback(async () => {
    if (!executeRecaptcha) {
      // eslint-disable-next-line no-console
      console.log('Execute recaptcha not yet available')
      return
    }
    const token = await executeRecaptcha('submit')
    return token
  }, [executeRecaptcha])

  const handleBlur = field => () => setTouched({ ...touched, [field]: true })

  const handleErrorMessage = (value, errorMessage, length) => {
    if (value === '') {
      return 'Campo obrigatório'
    }
    if (length && value.length < length) {
      return `Campo deve possuir no mínimo ${length} caracteres`
    }
    return errorMessage
  }

  const handleClose = () => {
    setTouched({})
    closeModal()
  }

  const onChange = e => {
    setTouched({ ...touched, [e.target.id]: false })
    setField(e.target.id, e.target.value)
  }

  const callSendForm = async () => {
    setTouched({})
    const tokenCaptcha = await handleReCaptchaVerify()
    if (sendForm)
      await sendForm(
        checkOffers,
        checkOffersResale,
        checkOffersPgi,
        tokenCaptcha
      )
  }

  const { confirmPassword, email, name, password, phone, registered } = values

  const isDisabled =
    !validateEmail(email) ||
    !validateFullName(name) ||
    !validateMandatoryPhone(phone) ||
    password.length < 6 ||
    (!registered && password !== confirmPassword) ||
    !checkOffersPgi

  const onChangeCheckbox = e => {
    setTouched({
      ...touched,
      [e.target.id]: false,
      confirmPassword: false,
      password: false,
    })
    setField('confirmPassword', '')
    setField('password', '')
    setField(e.target.id, !registered)
  }

  const pagimovelText = (
    <Text color="lightTextColor" fontSize="medium">
      {'Você será direcionado(a) para a plataforma '}
      <strong>Pagimovel®</strong>
      {', um '}
      <strong>ambiente seguro</strong>
      {' onde você poderá continuar com o processo de compra.'}
    </Text>
  )

  return (
    <Modal opened={opened} closeModal={handleClose}>
      <section className={styles.messageModal}>
        <header className={styles.header}>
          <div className={styles.info}>
            <Title type="h1" fontSize="xlarge">
              Proposta de compra
            </Title>
            <div className={styles.pagImovel}>
              <img
                loading="lazy"
                src={pagimovelLogo}
                alt="pagimovel logo"
                className={styles.pagimovelLogo}
              />
              {pagimovelText}
            </div>
            <section>
              {competitionProps && (
                <PublicCompetitionInfo {...competitionProps} isModal />
              )}
            </section>
          </div>
        </header>
        <main className={styles.main}>
          <Input
            errorMessage={handleErrorMessage(name, 'Nome completo inválido')}
            onBlur={handleBlur('name')}
            hasError={!validateFullName(name) && touched.name}
            id="name"
            label="Nome completo*"
            onChange={onChange}
            placeholder=""
            value={name}
            className={styles.inputName}
            labelClassName={styles.modalLabel}
          />
          <Input
            errorMessage={handleErrorMessage(email, 'Email inválido')}
            onBlur={handleBlur('email')}
            hasError={!validateEmail(email) && touched.email}
            id="email"
            label="E-mail*"
            type="email"
            onChange={onChange}
            placeholder=""
            value={email}
            className={styles.inputEmail}
            labelClassName={styles.modalLabel}
          />
          <Input
            errorMessage="Telefone inválidio"
            onBlur={handleBlur('phone')}
            hasError={!validateMandatoryPhone(phone) && touched.phone}
            mask={selectPhoneMask(phone, mask)}
            id="phone"
            label="Telefone*"
            onChange={onChange}
            placeholder=""
            value={phone}
            className={styles.inputPhone}
            labelClassName={styles.modalLabel}
          />
          <Checkbox
            id="registered"
            option="Já sou cadastrado e gostaria de usar o mesmo email."
            optionPosition="right"
            onChange={onChangeCheckbox}
            checked={registered}
            className={styles.checkbox}
          />
          <div className={styles.passwordContainer}>
            <Input
              errorMessage={handleErrorMessage(password, '', 6)}
              type="password"
              onBlur={handleBlur('password')}
              hasError={
                touched.password &&
                (password.length < 6 ||
                  (touched.confirmPassword && password !== confirmPassword))
              }
              id="password"
              label="Senha*"
              onChange={onChange}
              placeholder=""
              value={password}
              className={styles.inputPassword}
              labelClassName={styles.modalLabel}
            />
            <span>
              {registered && (
                <Button
                  onClick={recoverPassword}
                  type="text"
                  fontSize="small"
                  className={styles.recoverPasswordButton}
                  isLoading={isLoadingRecoverPassword}
                  textLoading="Enviando email para recuperação..."
                >
                  Esqueceu sua senha?
                </Button>
              )}
            </span>
          </div>
          {!registered && (
            <Input
              errorMessage={handleErrorMessage(
                confirmPassword,
                'Senhas diferentes',
                6
              )}
              type="password"
              onBlur={handleBlur('confirmPassword')}
              hasError={
                touched.confirmPassword &&
                (confirmPassword.length < 6 ||
                  (touched.password && password !== confirmPassword))
              }
              id="confirmPassword"
              label="Repetir senha*"
              onChange={onChange}
              placeholder=""
              value={confirmPassword}
              className={styles.inputConfirmPassword}
              labelClassName={styles.modalLabel}
            />
          )}
          <div className={styles.optins}>
            <Optins
              location="location-ProposalModal"
              checkOffers={checkOffers}
              setCheckOffers={setCheckOffers}
              checkOffersResale={checkOffersResale}
              setCheckOffersResale={setCheckOffersResale}
              checkOffersPgi={checkOffersPgi}
              setCheckOffersPgi={setCheckOffersPgi}
            />
          </div>
          <div className={styles.beforeFooter}>
            <Text
              fontSize="medium"
              color="lightTextColor"
              className={styles.lineTwo}
            >
              Clique abaixo para continuar com a sua proposta.
            </Text>
          </div>
        </main>
        <footer className={styles.footer}>
          {!success && (
            <Button
              id={idButton}
              onClick={() => callSendForm()}
              type="filled"
              fontSize="medium"
              disabled={isDisabled || isLoading}
              isLoading={isLoading}
              className={styles.submit}
            >
              Continuar compra
            </Button>
          )}
          {success && (
            <a href={url} className={styles.link}>
              Não foi redirecionado? Clique aqui
            </a>
          )}
        </footer>
      </section>
    </Modal>
  )
}

ProposalModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  sendForm: PropTypes.func.isRequired,
  recoverPassword: PropTypes.func.isRequired,
  setField: PropTypes.func.isRequired,
  values: PropTypes.shape({
    confirmPassword: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    registered: PropTypes.bool.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingRecoverPassword: PropTypes.bool,
  success: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  competitionProps: PropTypes.shape({
    limitDate: PropTypes.string.isRequired,
    limitTime: PropTypes.string.isRequired,
    video: PropTypes.string.isRequired,
    document: PropTypes.string.isRequired,
  }),
  idButton: PropTypes.string,
}

ProposalModal.defaultProps = {
  isLoadingRecoverPassword: false,
  competitionProps: {},
  idButton: '',
}

export default React.memo(ProposalModal)
