import { tagsOfCampaigns } from 'service/campaigns'
import systemsConstants from './systemsConstants'

const CAMPAIGNS_TAGS = tagsOfCampaigns || []
const highPriorityTags = [
  'Novembro Mágico',
  'Corujão',
  'Novidade',
  ...CAMPAIGNS_TAGS,
]

const parseConcurenceTag = tags => {
  const concurrencePosition = tags.indexOf('Concorrência pública')
  const parsedTags = [...tags]

  if (concurrencePosition !== -1) {
    parsedTags.splice(concurrencePosition, 1)
    parsedTags.splice(0, 0, systemsConstants.CONCORRENCIA)
  }
  return parsedTags
}

const reorderTagArray = (tagsToReorder, tags) => {
  const reorderedTags = [...tags]
  tagsToReorder.forEach(newTag => {
    reorderedTags.unshift(newTag)
  })
  return [...new Set(reorderedTags)]
}

const extractHighPriorityTags = tags => {
  const tagsToReorder = tags
    .filter(tag => highPriorityTags.includes(tag))
    .reverse()

  return tagsToReorder
}

const orderTags = tags => {
  if (tags.some(tag => highPriorityTags.includes(tag))) {
    const tagsToReorder = extractHighPriorityTags(tags)
    const reorderedTags = reorderTagArray(tagsToReorder, tags)
    return reorderedTags
  }
  return tags
}

const handleTags = ({ tags }) => {
  let newTags = tags.map(tag => tag)
  newTags = newTags.filter(tag => tag && tag)

  newTags = parseConcurenceTag(newTags)

  newTags = orderTags(newTags)

  return newTags
}

export default handleTags
