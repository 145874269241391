export default {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2400 },

    items: 4,

    partialVisibilityGutter: -4,
  },

  desktop: {
    breakpoint: { max: 2400, min: 1200 },

    items: 4,

    partialVisibilityGutter: -5,
  },

  tablet: {
    breakpoint: { max: 1200, min: 901 },

    items: 3,

    partialVisibilityGutter: -5,
  },

  mobile: {
    breakpoint: { max: 900, min: 651 },

    items: 2,

    partialVisibilityGutter: 4,
  },

  mobileMid: {
    breakpoint: { max: 650, min: 531 },

    items: 1,

    partialVisibilityGutter: 195,
  },

  mobileSmall: {
    breakpoint: { max: 530, min: 376 },

    items: 1,

    partialVisibilityGutter: 69,
  },

  mobileXSmall: {
    breakpoint: { max: 375, min: 361 },

    items: 1,

    partialVisibilityGutter: 30,
  },

  mobileXxSmall: {
    breakpoint: { max: 360, min: 0 },

    items: 1,

    partialVisibilityGutter: 15,
  },
}
