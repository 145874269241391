import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const Vacancies = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="bathroom"
          d="M6.284 13.24V7.945C6.284 5.548 8.067 4 10.072 4c1.988 0 3.787 1.581 3.787 3.946a1.077 1.077 0 0 1-2.154 0c0-1.109-.778-1.792-1.633-1.792-.878 0-1.634.656-1.634 1.792v5.293h20.485a1.077 1.077 0 0 1 0 2.154v1.077a8.618 8.618 0 0 1-5.385 7.99v1.702a1.077 1.077 0 1 1-2.153 0V25.02a8.7 8.7 0 0 1-1.077.067h-8.616a8.7 8.7 0 0 1-1.077-.067v1.143a1.077 1.077 0 1 1-2.153 0V24.46a8.618 8.618 0 0 1-5.385-7.989v-1.077a1.077 1.077 0 0 1 0-2.154h3.207zm-1.053 2.153v1.077a6.462 6.462 0 0 0 6.461 6.462h8.616a6.462 6.462 0 0 0 6.461-6.462v-1.077H5.231z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#bathroom" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#bathroom"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Vacancies.propTypes = {
  ...propTypesSvg,
}

Vacancies.defaultProps = {
  ...defaultPropTypesSvg,
}

export default Vacancies
