// Logic for filters that only accepts one checked option at a time

export const handleFiltersWithSingleOption = ({
  filter,
  prev,
  optionName,
  newOptions,
}) => {
  const singleOptionFilters = ['discount']
  const options = [...newOptions]

  if (singleOptionFilters.includes(filter)) {
    const currentOptions = [...prev.filters.booleanFilters[filter].options]
    const currentCheckedIndex = currentOptions.findIndex(item => item.checked)

    if (
      currentCheckedIndex !== -1 &&
      currentOptions[currentCheckedIndex].name !== optionName
    )
      options[currentCheckedIndex].checked = false
  }

  return options
}
