import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'components'
import styles from './AsteriskExplain.module.scss'

const AsteriskExplain = ({ show, callToActionConsiderations, strongStyle }) => {
  return (
    show && (
      <div className={styles.AsteriskExplain}>
        {callToActionConsiderations.map(consideration => (
          <Text
            key={consideration}
            bold={strongStyle}
            fontSize={strongStyle ? 'small' : 'xsmall'}
          >
            {consideration}
          </Text>
        ))}
      </div>
    )
  )
}

AsteriskExplain.propTypes = {
  callToActionConsiderations: PropTypes.arrayOf(PropTypes.string).isRequired,
  strongStyle: PropTypes.bool,
  show: PropTypes.bool,
}

AsteriskExplain.defaultProps = {
  strongStyle: false,
  show: false,
}

export default React.memo(AsteriskExplain)
