import React from 'react'
import ClassName from 'classnames'
import PropTypes from 'prop-types'
import ObjectToQueryString from 'lib/objectToQueryString'
import styles from './Map.module.scss'

const Map = ({ apiKey, className, title, parameters }) => {
  const formatedParameters = parameters
  formatedParameters.q =
    formatedParameters.q && formatedParameters.q.replace(' ', '+')

  const queryString = ObjectToQueryString(formatedParameters)
  const url = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&${queryString}`
  return (
    <iframe
      title={title}
      src={url}
      className={ClassName(styles.map, className)}
    />
  )
}

Map.propTypes = {
  title: PropTypes.string,
  apiKey: PropTypes.string.isRequired,
  className: PropTypes.string,
  parameters: PropTypes.shape({
    q: PropTypes.string, // defines the place to highlight on the map. It accepts a location as either a place name, address, or place ID.
    center: PropTypes.string, // comma-separated latitude and longitude value such as 37.4218,-122.0840
    zoom: PropTypes.string, // accepted values range from 0 (the whole world) to 21
    maptype: PropTypes.oneOf(['satellite', 'roadmap']), // defines the type of map tiles to load
    language: PropTypes.string, // by default, visitors will see a map in their own language
    region: PropTypes.string, // accepts a region code specified as a two-character ccTLD (top-level domain) value. http://www.iana.org/domains/root/db
  }).isRequired,
}

Map.defaultProps = {
  className: '',
  title: 'map',
}

export default React.memo(Map)
