import { useState } from 'react'
import { getCustomerInfoLocalStorage } from 'utils/customerInfoLocalStorage'
import useCreateStore from '../../lib/useCreateStore'
import visitScheduleModalActions from './actions'
import visitScheduleModalSelectors from './selectors'

const initialState = {
  form: {
    name: getCustomerInfoLocalStorage().name || '',
    email: getCustomerInfoLocalStorage().email || '',
    phone: getCustomerInfoLocalStorage().phone || '',
    days: {
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
    },
    times: {
      morning: false,
      afternoon: false,
    },
    canal_venda_codigo: '',
  },
  openModal: false,
}

const VisitScheduleModalStore = useCreateStore(() => {
  const [$visitScheduleModal, setVisitScheduleModal] = useState(initialState)

  const actions = visitScheduleModalActions(setVisitScheduleModal)
  const selectors = visitScheduleModalSelectors($visitScheduleModal)

  return { $visitScheduleModal, ...actions, ...selectors }
})

export const useVisitScheduleModal = () => VisitScheduleModalStore()
export const VisitScheduleModalContext = VisitScheduleModalStore.Context
export const VisitScheduleModalProvider = VisitScheduleModalStore.Provider
