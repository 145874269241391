import React from 'react'
import PropTypes from 'prop-types'

import {
  RootProvider,
  AlertProvider,
  MessageModalProvider,
  PropertyProvider,
  FilterProvider,
  ListProvider,
  LocalityModalProvider,
  HomeProvider,
} from 'store'

const StateProvider = ({ children }) => (
  <RootProvider>
    <PropertyProvider>
      <AlertProvider>
        <ListProvider>
          <FilterProvider>
            <MessageModalProvider>
              <HomeProvider>
                <LocalityModalProvider>{children}</LocalityModalProvider>
              </HomeProvider>
            </MessageModalProvider>
          </FilterProvider>
        </ListProvider>
      </AlertProvider>
    </PropertyProvider>
  </RootProvider>
)

StateProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default StateProvider
