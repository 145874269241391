import React from 'react'
import PropTypes from 'prop-types'
import { Text, Icon } from 'components/'
import { formatBRL } from 'utils/formatNumber'
import styles from './DiscountValue.module.scss'

const DiscountValue = ({
  discount,
  totalValue,
  value,
  mainValueLabel,
  appraisedLabel,
}) => {
  const percentualDiscount = Math.round((discount / totalValue) * 100)

  const lineDiscountStyle = {
    width: `${percentualDiscount}%`,
  }

  return (
    <div className={styles.container}>
      <div className={styles.valuesBox}>
        <div>
          <Text
            fontSize="medium"
            color="lightTextColor"
            className={styles.minimumBidTitle}
          >
            {mainValueLabel || ''}
          </Text>
          <Text fontSize="xlarge" bold className={styles.minimumBidText}>
            {value
              ? `R$ ${parseInt(value, 10).toLocaleString('pt-br')}`
              : 'Sob consulta'}
          </Text>
        </div>
        <div className={styles.economyBox}>
          <div className={styles.percentualDiscount}>
            <Text
              fontSize="medium"
              color="whiteColor"
              bold
              className={styles.percentualDiscountText}
            >
              {`${percentualDiscount}%`}
            </Text>
            <Icon
              color="whiteColor"
              type="down"
              size={16}
              className={styles.downArrow}
            />
          </div>
          <Text
            fontSize="medium"
            color="lightTextColor"
            className={styles.economyText}
          >
            Você economiza
          </Text>
          <Text
            fontSize="xlarge"
            className={styles.valueDiscount}
            color="primaryColor"
            bold
          >
            {formatBRL(discount, false)}
          </Text>
        </div>
      </div>
      <div className={styles.line}>
        <div className={styles.lineDiscount} style={lineDiscountStyle} />
      </div>
      <Text fontSize="xsmall" color="lightTextColor">
        {`${appraisedLabel}: ${formatBRL(totalValue, false)}`}
      </Text>
    </div>
  )
}

DiscountValue.propTypes = {
  discount: PropTypes.number.isRequired,
  totalValue: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  mainValueLabel: PropTypes.string.isRequired,
  appraisedLabel: PropTypes.string.isRequired,
}

export default React.memo(DiscountValue)
