import {
  apartment,
  apartmentLarge,
  condominium,
  condominiumLarge,
  farm,
  farmLarge,
  garage,
  garageLarge,
  ground,
  groundLarge,
  hotel,
  hotelLarge,
  house,
  houseLarge,
  landLot,
  landLotLarge,
  officeBuilding,
  officeBuildingLarge,
  shed,
  shedLarge,
  site,
  siteLarge,
  store,
  storeLarge,
  // noPhoto,
  // noPhotoLarge,
} from 'assets/images/property-categories'

const getPropertyCategoryImage = category => {
  const images = {
    Apartamento: apartment,
    ApartamentoLarge: apartmentLarge,
    Area: ground,
    AreaLarge: groundLarge,
    Areas_app: ground,
    Areas_appLarge: groundLarge,
    Casa_Condominio: condominium,
    Casa_CondominioLarge: condominiumLarge,
    Fazenda: farm,
    FazendaLarge: farmLarge,
    Box_Garagem: garage,
    Box_GaragemLarge: garageLarge,
    flat_hotel: hotel,
    flat_hotelLarge: hotelLarge,
    Casa: house,
    CasaLarge: houseLarge,
    Sala: officeBuilding,
    SalaLarge: officeBuildingLarge,
    Predio: officeBuilding,
    PredioLarge: officeBuildingLarge,
    Galpao: shed,
    GalpaoLarge: shedLarge,
    Chacara_Sitio: site,
    Chacara_SitioLarge: siteLarge,
    Loja_Salao_Comercial: store,
    Loja_Salao_ComercialLarge: storeLarge,
    Lote: landLot,
    LoteLarge: landLotLarge,
    Lote_Condominio: ground,
    Lote_CondominioLarge: groundLarge,
    // Outros: noPhoto,
    // OutrosLarge: noPhotoLarge,
  }
  return images[category]
}

export default getPropertyCategoryImage
