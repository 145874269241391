import React from 'react'
import PropTypes from 'prop-types'
import styles from './VideoPlayer.module.scss'

const VideoPlayer = ({ url, height, width, title }) => (
  <div className={styles.videoWrapper}>
    <iframe
      title={title}
      width={width}
      height={height}
      src={url}
      frameBorder="0"
      allowFullScreen
    />
  </div>
)

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
}

VideoPlayer.defaultProps = {
  height: '100%',
  width: '100%',
  title: 'video',
}
export default React.memo(VideoPlayer)
