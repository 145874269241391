import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Title, Text, Button, Input, Optins } from 'components'
import { validateEmail } from 'utils/validations'
import applyMask from 'utils/applyMask'
import masks from 'utils/masks'
import {
  setCustomerInfoLocalStorage,
  getCustomerInfoLocalStorage,
} from 'utils/customerInfoLocalStorage'
import styles from './AuctionModal.module.scss'

const AuctionModal = ({
  opened,
  closeModal,
  submit,
  isLoading,
  contactInfo,
  propertyId,
  success,
  url,
}) => {
  const [email, setEmail] = React.useState(
    getCustomerInfoLocalStorage().email || ''
  )
  const [checkOffers, setCheckOffers] = React.useState(true)
  const [checkOffersResale, setCheckOffersResale] = React.useState(true)

  const [touched, setTouched] = React.useState({})

  const handleBlur = field => () => setTouched({ ...touched, [field]: true })

  const handleErrorMessage = (value, errorMessage) =>
    value === '' ? 'Campo obrigatório' : errorMessage

  const handleClose = () => {
    setTouched({})
    closeModal()
    setEmail('')
  }

  const handleConfirm = (idProperty, emailValue) => () => {
    submit(idProperty, emailValue, checkOffers, checkOffersResale)

    setCustomerInfoLocalStorage({
      email: emailValue,
      ...getCustomerInfoLocalStorage(),
    })

    setTouched({})
  }

  const onChange = e => {
    setTouched({ ...touched, [e.target.id]: false })
    setEmail(e.target.value)
  }

  const isDisabled = !validateEmail(email) || !checkOffers

  return (
    <Modal opened={opened} closeModal={handleClose}>
      <section className={styles.auctionModal}>
        <header className={styles.header}>
          <Title type="h1" fontSize="xlarge">
            Aviso
          </Title>
        </header>
        <main className={styles.main}>
          <div className={styles.info}>
            <Text fontSize="medium" color="lightTextColor">
              {`Você será redirecionado(a) para o site da empresa
              ${
                contactInfo.name ? `${contactInfo.name},` : ''
              } responsável pela comercialização desse imóvel
              e poderá obter mais informações sobre o processo de compra.`}
            </Text>
            <Text
              fontSize="medium"
              color="lightTextColor"
              bold
              className={styles.boldInformation}
            >
              Preencha seu email para ser redirecionado para a página do imóvel.
            </Text>
          </div>
          <Input
            errorMessage={handleErrorMessage(email, 'Email inválido')}
            onBlur={handleBlur('email')}
            hasError={!validateEmail(email) && touched.email}
            id="email"
            type="email"
            label="Seu e-mail"
            value={email}
            onChange={onChange}
            className={styles.inputProps}
          />
          <div className={styles.inputProps}>
            <Optins
              checkOffers={checkOffers}
              setCheckOffers={setCheckOffers}
              checkOffersResale={checkOffersResale}
              setCheckOffersResale={setCheckOffersResale}
            />
          </div>
        </main>
        <footer className={styles.footer}>
          {!success && (
            <Button
              onClick={handleConfirm(propertyId, email)}
              fontSize="medium"
              className={styles.submit}
              disabled={isDisabled || isLoading}
              isLoading={isLoading}
            >
              Ok. Continuar.
            </Button>
          )}
          {success && (
            <a href={url} className={styles.link}>
              Não foi redirecionado? Clique aqui
            </a>
          )}
          {contactInfo.phone && (
            <Text
              fontSize="small"
              color="lightTextColor"
              className={styles.info}
            >
              <span>
                {`Caso prefira você pode entrar em contato diretamente pelo
              telefone`}
              </span>
              <br />
              <span>
                {` \n${applyMask(
                  contactInfo.phone,
                  masks.landLinePhone
                )} (horário comercial)`}
              </span>
            </Text>
          )}
        </footer>
      </section>
    </Modal>
  )
}

AuctionModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  contactInfo: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
  propertyId: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  success: PropTypes.bool.isRequired,
}

export default React.memo(AuctionModal)
