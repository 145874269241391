import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Countdown from 'react-countdown'
import { timeDictionary } from 'store/property/dictionaries'
import isPreRendering from 'utils/isPreRendering'
import styles from './CountDown.module.scss'

const countDownRenderer = ({ days, hours, minutes, seconds }) => {
  const time = [days, hours, minutes, seconds]

  return (
    !isPreRendering() && (
      <div className={styles.counterContainer}>
        {time.map((item, index) => (
          <>
            <div className={styles.box}>
              <span className={styles.title}>{timeDictionary[index]}</span>
              <span className={styles.numberBox}>{`0${item}`.slice(-2)}</span>
            </div>
            <span className={styles.colon}>:</span>
          </>
        ))}
      </div>
    )
  )
}

const CountDown = ({ endDate, title, onComplete }) => {
  return (
    <div className={classNames(styles.countDownContainer, styles.absolute)}>
      <Countdown
        date={endDate}
        renderer={countDownRenderer}
        onComplete={onComplete}
      />
      <span className={styles.subTitle}>{title}</span>
    </div>
  )
}

CountDown.propTypes = {
  endDate: PropTypes.string.isRequired,
  title: PropTypes.string,
  onComplete: PropTypes.func,
}

CountDown.defaultProps = {
  title: '',
  onComplete: () => {},
}

export default React.memo(CountDown)
