import { SALE_CAMPAIGNS } from 'service/campaigns'
import {
  getImageBySizeToVendor,
  bannersVariations,
} from 'utils/bannersDictionary'

export const getEstateBannersCampaign = ({
  property,
  campaigns,
  isAnActiveCampaign,
}) => {
  if (!property) return null

  const {
    PROPERTY_DESKTOP,
    PROPERTY_MOBILE,
    PROPERTY_LARGE,
  } = bannersVariations

  const coupon = property && property.coupon
  const campaignsData = property && property.campaignsData
  const isPreActive =
    isAnActiveCampaign(SALE_CAMPAIGNS.preAtivo) &&
    property &&
    property.preActive

  let images = null

  if (Object.keys(campaigns).length) {
    Object.values(campaigns)
      .sort((a, b) => b.bannerOrder - a.bannerOrder)
      .forEach(
        ({ banners, couponCode, campaignKey, campaignType, isActive, id }) => {
          const desktop = getImageBySizeToVendor({
            banners,
            size: PROPERTY_DESKTOP,
            coupon,
            couponCode,
            campaignsData,
            campaignKey,
            campaignType,
            campaignId: id,
            isPreActive,
          })
          const mobile = getImageBySizeToVendor({
            banners,
            size: PROPERTY_MOBILE,
            coupon,
            couponCode,
            campaignsData,
            campaignKey,
            campaignType,
            campaignId: id,
            isPreActive,
          })
          const large = getImageBySizeToVendor({
            banners,
            size: PROPERTY_LARGE,
            coupon,
            couponCode,
            campaignsData,
            campaignKey,
            campaignType,
            campaignId: id,
            isPreActive,
          })

          if (isActive && desktop && mobile && large) {
            images = {
              desktop: desktop?.image,
              mobile: mobile?.image,
              desktopLarge: large?.image,
            }
          }
        }
      )
  }

  return images
}
export const getEstateCardCampaign = ({
  campaigns,
  coupon,
  campaignsData,
  isAnActiveCampaign,
  preActive,
}) => {
  const isPreActive = isAnActiveCampaign(SALE_CAMPAIGNS.preAtivo) && preActive
  let images = null

  if (Object.keys(campaigns).length) {
    Object.values(campaigns).forEach(
      ({ banners, couponCode, campaignKey, campaignType, isActive, id }) => {
        const img = getImageBySizeToVendor({
          banners,
          size: bannersVariations.PROPERTY_CARD,
          coupon,
          couponCode,
          campaignsData,
          campaignKey,
          campaignType,
          campaignId: id,
          isPreActive,
        })
        if (isActive && img) {
          images = img
        }
      }
    )
  }
  return images
}
