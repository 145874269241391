import React from 'react'
import { Title, CarouselDepoiments } from 'components'

import { useProviders } from 'store'
import styles from './MessagesProviderContainer.module.scss'

const MessagesProviderContainer = () => {
  const {
    $providers: { messageProviders, messageProvidersLoading },
  } = useProviders()
  return (
    <div className={styles.containerDepoiments}>
      <Title type="h1" fontSize="xlarge" className={styles.titleDepoiments}>
        Saiba o que nossos fornecedores credenciados estão falando
      </Title>
      {!messageProvidersLoading && messageProviders.length && (
        <section className={styles.depoiments}>
          <CarouselDepoiments data={messageProviders} />
        </section>
      )}
    </div>
  )
}

export default MessagesProviderContainer
