import isPreRendering from 'utils/isPreRendering'
import clearPropertyReferenceStorage from '../../utils/clearPropertyReferenceStorage'

export const isAbleForANewRequest = (queryString, urlParams) => {
  const isFiltersFromUrlSynced = Boolean(queryString)
  const isFirstRequest = !queryString && !urlParams

  return isFiltersFromUrlSynced || isFirstRequest
}

export const scrollToItem = () => {
  const reference = window.localStorage.getItem('propertyReference')

  if (!isPreRendering() && reference) {
    const referencedProperty = document.getElementById(reference)

    if (referencedProperty) {
      referencedProperty.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    clearPropertyReferenceStorage()
  }
}

export const replaceMegaFeiraoSendToApi = query => {
  let newQuery = query

  const uniqueOnQuery = query.includes('campanha-exclusiva=megafeiraoemgea')
  const multipleOnQuery = query.includes('campanha-exclusiva=megafeiraoemgea,')

  if (uniqueOnQuery) {
    newQuery = newQuery.replace('campanha-exclusiva=megafeiraoemgea', '')
  }

  if (multipleOnQuery) {
    newQuery = newQuery.replace('megafeiraoemgea', '')
  }

  return newQuery
}

export const isTourVirtualReplaceSendApi = query => {
  let newQuery = query
  const hasOption = newQuery.includes('tourvirtual')

  if (hasOption && newQuery.includes('permite-visita=tourvirtual')) {
    newQuery = newQuery.replace(
      'permite-visita=tourvirtual',
      'tourvirtual=true'
    )
  }

  if (hasOption && newQuery.includes('permite-visita=sim,tourvirtual')) {
    newQuery = newQuery.replace(
      'permite-visita=sim,tourvirtual',
      'permite-visita=sim&tourvirtual=true'
    )
  }

  return newQuery
}

export const replaceCampaignKey = query => {
  let newQuery = query

  const hasOption = newQuery.includes('campanha-exclusiva')

  if (hasOption) {
    newQuery = newQuery.replace('campanha-exclusiva', 'campanha-code')
  }

  return newQuery
}
