export const indexDictionary = {
  '': '',
  igpm: 'IGP-M',
  'IGP-M': 'IGP-M',
  ipca: 'IPCA',
  tr: 'TR',
  outro: 'Outro',
}

export const timeDictionary = {
  0: 'Dias',
  1: 'Horas',
  2: 'Minutos',
  3: 'Segundos',
}

/**
 * Dictionary to centralize buttons labels.
 * legend:
 * CTA -> callToAction label
 * CB -> contactButton label
 */
export const labelsDictionary = {
  proposal: {
    CTA: 'Faça sua proposta',
    CB: 'Fale conosco',
  },
  directSale: {
    CTA: 'Comprar',
    CB: 'Fale conosco',
  },
  auction: {
    CTA: 'Ver leilão',
    CB: 'Fale com o leiloeiro',
    info: 'Data do Leilão',
  },
  competition: {
    CTA: 'Melhor Proposta',
    CB: 'Fale conosco',
    info: 'Data da concorrência',
  },
  over: {
    CTA: 'Encerrado',
  },
  learnMore: {
    CTA: 'Saiba Mais',
  },
  sold: {
    CTA: 'Vendido',
  },
}
