import React from 'react'
import PropTypes from 'prop-types'
import { CategoryTitle, DiscountValue } from 'components'
import styles from './ValuesAndDiscounts.module.scss'

const ValuesAndDiscounts = ({
  hideConditions,
  desagio,
  appraisedPrice,
  mainValue,
  campaignInfo,
  salePrice,
  mainValueLabel,
  appraisedLabel,
  hasActiveCampaign,
}) => {
  const isVisibleSection = !hideConditions && !!desagio

  if (!isVisibleSection) return null

  const appraisedLowerThanMain = appraisedPrice < mainValue
  const campaignTotalValue =
    campaignInfo && campaignInfo.values.campaignTotalValue
  const hasPromotion = Boolean(campaignTotalValue && hasActiveCampaign())

  return (
    <section className={styles.discount}>
      <CategoryTitle icon="graphs" title="Valores e descontos" />
      <div className={styles.chart}>
        <DiscountValue
          discount={
            (!appraisedLowerThanMain ? appraisedPrice : mainValue) -
            (!campaignTotalValue || !hasPromotion
              ? salePrice
              : campaignTotalValue)
          }
          totalValue={appraisedPrice}
          value={
            !campaignTotalValue || !hasPromotion
              ? mainValue
              : campaignTotalValue
          }
          mainValueLabel={mainValueLabel}
          appraisedLabel={appraisedLabel}
        />
      </div>
    </section>
  )
}

ValuesAndDiscounts.propTypes = {
  hideConditions: PropTypes.bool.isRequired,
  desagio: PropTypes.number.isRequired,
  appraisedPrice: PropTypes.number.isRequired,
  mainValue: PropTypes.number.isRequired,
  salePrice: PropTypes.number.isRequired,
  mainValueLabel: PropTypes.string.isRequired,
  appraisedLabel: PropTypes.string.isRequired,
  campaignInfo: PropTypes.shape({
    values: PropTypes.shape({
      campaignTotalValue: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  hasActiveCampaign: PropTypes.func.isRequired,
}

export default ValuesAndDiscounts
