import React from 'react'
import PropTypes from 'prop-types'
import { SaleValue, Text } from 'components'
import classNames from 'classnames'
import styles from './SaleValueText.module.scss'

const SaleValueText = ({
  show,
  value,
  saleLabel,
  propertyCard,
  asterisk,
  fontSize,
  isCampaign,
  className,
}) =>
  show && (
    <span
      className={classNames(styles.saleValueText, className, {
        [styles.propertyCard]: propertyCard,
      })}
    >
      <Text
        className={classNames(styles.text, {
          [styles.propertyCard]: propertyCard,
          [styles.campaign]: isCampaign,
          [styles.saldao]: saleLabel === 'Valor Saldão',
        })}
      >
        {saleLabel}
      </Text>
      <SaleValue
        value={value}
        asterisk={asterisk}
        fontSize={fontSize}
        isCampaign={isCampaign}
      />
    </span>
  )

SaleValueText.propTypes = {
  show: PropTypes.bool,
  value: PropTypes.number.isRequired,
  saleLabel: PropTypes.string.isRequired,
  propertyCard: PropTypes.bool,
  asterisk: PropTypes.bool,
  fontSize: PropTypes.oneOf([
    'xxsmall', // 10px
    'xsmall', // 12px
    'small', // 14px
    'medium', // 16px
    'regular', // 18px
    'large', // 20px
    'xlarge', // 24px
  ]),
  isCampaign: PropTypes.bool,
  className: PropTypes.string,
}

SaleValueText.defaultProps = {
  propertyCard: false,
  asterisk: false,
  fontSize: 'regular',
  isCampaign: false,
  className: '',
  show: true,
}

export default React.memo(SaleValueText)
