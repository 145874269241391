/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Carousel,
  BannerIcons,
  VideoPlayer,
  Map,
  StreetView,
  TourResale,
  Icon,
  Image,
  Tag,
} from 'components'
import padlock from 'assets/svgs/padlock.svg'
import classNames from 'classnames'
import isIos from 'utils/isIos'
import styles from './PropertyBanner.module.scss'

const PropertyBanner = ({
  bannerIconsProps,
  carouselProps,
  videoPlayerProps,
  mapProps,
  streetViewProps,
  internalTourProps,
  externalTourProps,
  isPreActive,
}) => {
  const menu = () => {
    const Menu = [
      {
        icon: 'photos',
        description: 'Fotos',
      },
      {
        icon: 'map',
        description: 'Mapa',
      },
      {
        icon: 'video',
        description: 'Vídeo',
      },
      {
        icon: 'streetview',
        description: 'Vista da rua',
      },
      {
        icon: 'internaltour',
        description: 'Tour interno',
      },
      {
        icon: 'externaltour',
        description: 'Tour externa',
      },
    ]

    !videoPlayerProps.url &&
      Menu.splice(
        Menu.findIndex(el => el.icon === 'video'),
        1
      )
    !streetViewProps.parameters.heading &&
      !streetViewProps.parameters.pitch &&
      Menu.splice(
        Menu.findIndex(el => el.icon === 'streetview'),
        1
      )
    !internalTourProps.url &&
      Menu.splice(
        Menu.findIndex(el => el.icon === 'internaltour'),
        1
      )
    !externalTourProps.url &&
      Menu.splice(
        Menu.findIndex(el => el.icon === 'externaltour'),
        1
      )
    return Menu
  }

  const Padlock = () => {
    return (
      <figure className={styles.clockIcon}>
        <img loading="lazy" src={padlock} alt="Relógio" title="Relógio" />
      </figure>
    )
  }

  const { selectedItem } = bannerIconsProps

  return (
    <div className={styles.propertyBannerWrapper}>
      <button
        type="button"
        onClick={() => window.history.back()}
        className={classNames(styles.buttonReturn, {
          [styles.iphone]: isIos(),
        })}
      >
        <Icon type="arrow" size={32} color="whiteColor" />
      </button>
      {isPreActive && (
        <div className={styles.tagOverBanner}>
          <Tag>Em breve</Tag>
        </div>
      )}
      <div className={styles.contentArea}>
        {isPreActive && <Padlock />}
        {selectedItem === 'photos' && carouselProps.images.length > 0 && (
          <div className={selectedItem === 'photos' ? styles.active : ''}>
            <Carousel {...carouselProps} />
          </div>
        )}
        {selectedItem === 'photos' && !carouselProps.images.length && (
          <div className={selectedItem === 'photos' ? styles.active : ''}>
            <Image
              alt="Sem imagens disponíveis"
              source={carouselProps.noPhoto}
            />
          </div>
        )}
        {selectedItem === 'video' && videoPlayerProps.url && (
          <div className={selectedItem === 'video' ? styles.activeVideo : ''}>
            <VideoPlayer {...videoPlayerProps} />
          </div>
        )}
        {selectedItem === 'map' && (
          <div className={selectedItem === 'map' ? styles.active : ''}>
            <Map {...mapProps} />
          </div>
        )}
        {selectedItem === 'streetview' && (
          <div className={selectedItem === 'streetview' ? styles.active : ''}>
            <StreetView {...streetViewProps} />
          </div>
        )}
        {selectedItem === 'internaltour' && internalTourProps.url && (
          <div className={selectedItem === 'internaltour' ? styles.active : ''}>
            <TourResale {...internalTourProps} />
          </div>
        )}
        {selectedItem === 'externaltour' && externalTourProps.url && (
          <div className={selectedItem === 'externaltour' ? styles.active : ''}>
            <TourResale {...externalTourProps} />
          </div>
        )}
      </div>
      <div className={styles.buttonsArea}>
        <BannerIcons {...bannerIconsProps} menu={menu()} />
      </div>
    </div>
  )
}

PropertyBanner.propTypes = {
  carouselProps: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      })
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    active: PropTypes.number.isRequired,
    noPhoto: PropTypes.string,
  }).isRequired,
  bannerIconsProps: PropTypes.shape({
    selectedItem: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
  }).isRequired,
  videoPlayerProps: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  mapProps: PropTypes.shape({
    title: PropTypes.string,
    apiKey: PropTypes.string.isRequired,
    className: PropTypes.string,
    parameters: PropTypes.shape({
      q: PropTypes.string, // defines the place to highlight on the map. It accepts a location as either a place name, address, or place ID.
      center: PropTypes.string, // comma-separated latitude and longitude value such as 37.4218,-122.0840
      zoom: PropTypes.string, // accepted values range from 0 (the whole world) to 21
      maptype: PropTypes.oneOf(['satellite', 'roadmap']), // defines the type of map tiles to load
      language: PropTypes.string, // by default, visitors will see a map in their own language
      region: PropTypes.string, // accepts a region code specified as a two-character ccTLD (top-level domain) value. http://www.iana.org/domains/root/db
    }).isRequired,
  }).isRequired,
  streetViewProps: PropTypes.shape({
    title: PropTypes.string,
    apiKey: PropTypes.string.isRequired,
    className: PropTypes.string,
    parameters: PropTypes.shape({
      location: PropTypes.string, // comma-separated latitude and longitude value such as 37.4218,-122.0840
      pano: PropTypes.string, // is a specific panorama Id
      heading: PropTypes.string, // indicates the compass heading of the camera. Values are from -180° to 360°
      pitch: PropTypes.string, // specifies the angle, up or down, of the camera. Values are from -90° to 90°
      fov: PropTypes.string, // determines the horizontal field of view of the image. Values are from 10° to 100°
      language: PropTypes.string, // by default, visitors will see a map in their own language
      region: PropTypes.string, // accepts a region code specified as a two-character ccTLD (top-level domain) value. http://www.iana.org/domains/root/db
    }).isRequired,
  }).isRequired,
  internalTourProps: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  externalTourProps: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  isPreActive: PropTypes.bool.isRequired,
}

export default React.memo(PropertyBanner)
