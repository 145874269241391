import CONSTANTS from 'utils/systemsConstants'

const {
  AREA_UTIL,
  AREA_PRIVADA,
  AREA_CONSTRUIDA,
  AREA_DO_TERRENO,
  DORMITORIOS,
  SUITES,
  BANHEIROS,
  SALAS,
  VAGAS_NA_GARAGEM,
} = CONSTANTS

const HOUSE_FEATURE_PRIORITY = [
  DORMITORIOS,
  SUITES,
  BANHEIROS,
  SALAS,
  VAGAS_NA_GARAGEM,
]
const AREA_PRIORITY = [
  AREA_UTIL,
  AREA_PRIVADA,
  AREA_CONSTRUIDA,
  AREA_DO_TERRENO,
]

const sortPriorities = priorityList => (firstFeature, secondFeature) => {
  const firstPriorityIndex = priorityList.findIndex(
    priority => firstFeature.feature.indexOf(priority) !== -1
  )
  const secondPriorityIndex = priorityList.findIndex(
    priority => secondFeature.feature.indexOf(priority) !== -1
  )

  if (firstPriorityIndex === -1 && secondPriorityIndex !== -1) {
    return 1
  }

  if (secondPriorityIndex === -1 && firstPriorityIndex !== -1) {
    return -1
  }

  return firstPriorityIndex < secondPriorityIndex ? -1 : 1
}

const sortFeatures = features => {
  const areaFeatureList = features.filter(({ feature }) => {
    return (
      feature && feature.indexOf('Área') !== -1 && feature !== 'Área de Serviço'
    )
  })
  areaFeatureList.sort(sortPriorities(AREA_PRIORITY))
  const areaFeature = areaFeatureList[0]

  const filteredFeatures = features.filter(({ amount, feature }) => {
    return (
      feature &&
      amount &&
      amount !== '00' &&
      (feature.indexOf('Área') === -1 || feature === 'Área de Serviço')
    )
  })

  filteredFeatures.sort(sortPriorities(HOUSE_FEATURE_PRIORITY))

  if (areaFeature) {
    filteredFeatures.splice(3, 0, areaFeature)
  }

  return filteredFeatures.slice(0, 4)
}

export default sortFeatures
