import React from 'react'
import PropTypes from 'prop-types'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const Search = ({ viewBox, color, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
      className={className}
    >
      <defs>
        <path
          id="a"
          d="M11.286 18.419a7.75 7.75 0 1 1 1.808 1.727l-4.96 4.96a1.25 1.25 0 1 1-1.768-1.767l4.92-4.92zm6.187.581a5.25 5.25 0 1 0 0-10.5 5.25 5.25 0 0 0 0 10.5z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use className={styles[color]} xlinkHref="#a" />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Search.propTypes = {
  ...propTypesSvg,
  className: PropTypes.string,
}

Search.defaultProps = {
  ...defaultPropTypesSvg,
  className: '',
}

export default Search
