const resaleInfos = {
  street: 'Rua XV de Novembro',
  number: 'nº1.504',
  neighborhood: 'Bairro Alto',
  cityState: 'Piracicaba / SP',
  CEP: '13.419-235',
  CNPJ: '21.129.873/0001-79  |  Creci responsável: 29.715-J',
}

const { street, number, neighborhood, cityState, CEP, CNPJ } = resaleInfos

const getFullAddress = () => `${street}, ${number} - ${neighborhood}`

const getCityState = () => `${cityState}`

const getCEP = () => `CEP: ${CEP}`

const getCNPJ = () => `CNPJ: ${CNPJ}`

export { getFullAddress, getCityState, getCEP, getCNPJ }
