import React from 'react'
import PropTypes from 'prop-types'
import { CategoryTitle, Text } from 'components'
import styles from './Pendencies.module.scss'

const Pendencies = ({ pendencies }) => {
  if (!pendencies) {
    return null
  }

  return (
    <section>
      <CategoryTitle icon="about" title="Pendência(s) Existente(s)" />
      <Text fontSize="medium" className={styles.darkColor}>
        {pendencies}
      </Text>
    </section>
  )
}

Pendencies.propTypes = {
  pendencies: PropTypes.string.isRequired,
}

export default Pendencies
