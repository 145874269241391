import React from 'react'
import { Text, Image, Title } from 'components'
import info1 from 'assets/images/info1.png'
import info2 from 'assets/images/info2.png'
import info3 from 'assets/images/info3.png'
import classNames from 'classnames'
import styles from './BannerPropertyInfo.module.scss'

const Banner = () => {
  return (
    <article className={styles.main}>
      <header className={styles.contentLeft}>
        <Title type="h1" className={styles.text} color="whiteColor">
          <strong> Sobre os imóveis </strong>
        </Title>
      </header>
      <div className={styles.contentRight}>
        <figure className={styles.item}>
          <Image source={info1} alt="ícone de imóvel" />
          <figcaption>
            <Text color="whiteColor" fontSize="regular">
              São imóveis de propriedade da
              <strong>
                {' '}
                Emgea que serão vendidos diretamente ao consumidor.
              </strong>
            </Text>
          </figcaption>
        </figure>
        <figure className={classNames(styles.item, styles.middle)}>
          <Image source={info2} alt="ícone mapa do Brasil" />
          <figcaption>
            <Text color="whiteColor" fontSize="regular">
              Eles estão localizados em
              <strong>
                {' '}
                todo o Brasil, entre casas, apartamentos e terrenos.
              </strong>
            </Text>
          </figcaption>
        </figure>
        <figure className={styles.item}>
          <Image source={info3} alt="ícone compradores" />
          <figcaption>
            <Text color="whiteColor" fontSize="regular">
              Para compradores dos mais variados perfis, desde um
              <strong>
                {' '}
                investidor experiente até alguém que busca um imóvel para morar
                com valor abaixo do mercado.
              </strong>
            </Text>
          </figcaption>
        </figure>
      </div>
    </article>
  )
}
export default React.memo(Banner)
