import React from 'react'
import PropTypes from 'prop-types'
import getPropertyCategoryImage from 'utils/getPropertyCategoryImage'
import { PropertyBanner } from 'components'
import styles from './FunctionalPropertyBanner.module.scss'

const FunctionalPropertyBanner = ({ propertyData, isPreActive }) => {
  const {
    smallId,
    address,
    location,
    map,
    imgs,
    video,
    externalTour,
    internalTour,
    category,
  } = propertyData

  return (
    <main className={styles.noBorder}>
      <FunctionalBanner
        propertyInfo={{
          propertySmallId: smallId,
          propertyAddress: address,
          propertyLocation: location,
          propertyMap: map,
          propertyImgs: imgs,
          propertyVideo: video,
          propertyExternalTour: externalTour,
          propertyInternalTour: internalTour,
          propertyCategory: category,
        }}
        propertyIsPreActive={isPreActive}
      />
    </main>
  )
}

const FunctionalBanner = ({ propertyInfo, propertyIsPreActive }) => {
  const [selectedItem, setSelectedItem] = React.useState('photos')
  const [carouselIndex, setCarouselIndex] = React.useState(0)

  const {
    propertyLocation,
    propertyImgs,
    propertyVideo,
    propertyMap,
    propertyInternalTour,
    propertyExternalTour,
    propertyCategory,
  } = propertyInfo

  const selectedItemOnChange = item => {
    setSelectedItem(item)
  }
  const carouselOnChange = value => setCarouselIndex(value)
  const props = {
    bannerIconsProps: {
      selectedItem,
      handleClick: selectedItemOnChange,
    },
    carouselProps: {
      images: propertyImgs,
      onChange: carouselOnChange,
      active: carouselIndex,
      noPhoto: getPropertyCategoryImage(propertyCategory),
    },
    videoPlayerProps: {
      url: propertyVideo,
      height: 555,
      width: '100%',
    },
    mapProps: {
      apiKey: 'AIzaSyB53TpACLfJ0UvxXceqjWnCb-4tbenuSs0',
      parameters: {
        q: propertyLocation,
        center: propertyLocation,
        zoom: '15',
        mapType: 'roadmap',
      },
    },
    streetViewProps: {
      apiKey: 'AIzaSyB53TpACLfJ0UvxXceqjWnCb-4tbenuSs0',
      parameters: {
        location: propertyLocation,
        heading: propertyMap.heading,
        pitch: propertyMap.pitch,
        fov: '50',
      },
    },
    internalTourProps: {
      url: propertyInternalTour,
    },
    externalTourProps: {
      url: propertyExternalTour,
    },
    isPreActive: propertyIsPreActive,
  }

  return <PropertyBanner {...props} />
}

FunctionalBanner.propTypes = {
  propertyInfo: PropTypes.shape({
    propertySmallId: PropTypes.string.isRequired,
    propertyLocation: PropTypes.string.isRequired,
    propertyMap: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      pitch: PropTypes.string.isRequired,
    }).isRequired,
    propertyImgs: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    propertyVideo: PropTypes.string.isRequired,
    propertyExternalTour: PropTypes.string.isRequired,
    propertyInternalTour: PropTypes.string.isRequired,
    propertyCategory: PropTypes.string.isRequired,
    propertyIsPreActive: PropTypes.bool.isRequired,
    propertyTags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  propertyIsPreActive: PropTypes.bool.isRequired,
}

FunctionalPropertyBanner.propTypes = {
  propertyData: PropTypes.shape({
    smallId: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    map: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      pitch: PropTypes.string.isRequired,
    }).isRequired,
    imgs: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    video: PropTypes.string.isRequired,
    externalTour: PropTypes.string.isRequired,
    internalTour: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
  }).isRequired,
  isPreActive: PropTypes.bool.isRequired,
}

export default FunctionalPropertyBanner
