import { defaultFetcher } from 'store/utils'
import * as providers from './provider'
import selector from './selector'

export default setProviders => {
  const fetchMessagesProviders = async () => {
    await defaultFetcher(
      setProviders,
      {
        error: 'messageProvidersErr',
        loading: 'messageProvidersLoading',
        stateKey: 'messageProviders',
      },
      providers.fetchMessagesProviders,
      selector().serializeMessagesProviders
    )
  }
  return {
    fetchMessagesProviders,
  }
}
