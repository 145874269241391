import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './CarouselNavigator.module.scss'

const CarouselNavigator = ({ size, selected, handleClick }) => (
  <div className={styles.root}>
    {[...Array(size).keys()].map(key => (
      <button
        type="button"
        onClick={() => handleClick(key)}
        className={styles.button}
        key={key}
      >
        <div
          className={classnames(
            styles.dot,
            key === selected ? styles.dotSelected : styles.dotUnselected
          )}
        />
      </button>
    ))}
  </div>
)

CarouselNavigator.propTypes = {
  size: PropTypes.number.isRequired,
  selected: PropTypes.number.isRequired,
  handleClick: PropTypes.func,
}

CarouselNavigator.defaultProps = {
  handleClick: () => {},
}
export default React.memo(CarouselNavigator)
