import { to } from 'nh-utils'
import api from 'service/api'
import { origin_device } from 'utils/deviceDetector'

export const sendForm = ({
  propertyId,
  form,
  send_mail_emgea,
  send_mail_resale,
}) => {
  const payload = { ...form, send_mail_emgea, origin_device, send_mail_resale }
  return to(api.post(`/${propertyId}/contact`, payload))
}
