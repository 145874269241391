export const sanitizerString = string => {
  if (string)
    return string
      .replace(/[ãâáà]/g, 'a')
      .replace(/[éèê]/g, 'e')
      .replace(/[íì]/g, 'i')
      .replace(/[óòôõ]/g, 'o')
      .replace(/[úùû]/g, 'u')
  return null
}

const normalizeString = string => {
  if (string) return sanitizerString(string.toLowerCase())

  return null
}

export default normalizeString

export const normalizeUrl = url => {
  if (url.includes('http') || url.includes('https')) {
    return url
  }

  return `http://${url}`
}

export const sanitizerStringAndRemoveSpaces = string => {
  if (!string || !string.replace) return ''

  const newString = string
    .replace(/(?:^|\s)\S/g, a => a?.toUpperCase())
    .replace(/ /g, '')
    .replace(/-/g, '')

  return sanitizerString(newString)
}

export const normalizeStringAndChangeSpaces = string => {
  if (!string || !string.replace) return ''

  const newString = sanitizerString(string)
    .toLowerCase()
    .replace(/ /g, '_')
    .replace(/-/g, '_')
  return newString
}
