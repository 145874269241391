export const bannersLocations = {
  POPUP: ['popup_640_824'],
  HOME: ['home_750_998', 'home_1280_474', 'home_1920_474'],
  PROPERTY: [
    'property_1280_56',
    'property_1280_280',
    'property_2560_110',
    'property_780_84',
  ],
  LISTING: [
    'listing_750_176',
    'listing_1280_280',
    'listing_1920_474',
    'listing_2560_560',
  ],
}

export const bannersVariations = {
  HOME_MOBILE: 'home_750_998',
  HOME_NORMAL: 'home_1280_474',
  HOME_LARGE: 'home_1920_474',
  PROPERTY_DESKTOP: 'property_1280_56',
  PROPERTY_MOBILE: 'property_1280_280',
  PROPERTY_LARGE: 'property_2560_110',
  PROPERTY_CARD: 'property_780_84',
  LISTING_NORMAL: 'listing_1280_280',
  LISTING_LARGE: 'listing_1920_474',
  LISTING_MOBILE: 'listing_750_176',
  LISTING_X_LARGE: 'listing_2560_560',
}

export const extractBanners = (campaigns, location = '') => {
  const key = bannersLocations[location.toUpperCase()]
  const images = []

  Object.values(campaigns).forEach(({ banners, isActive, isPreLaunch }) => {
    if (!banners || !banners.length) return

    banners.forEach(banner => {
      if (!isActive && !isPreLaunch) return

      if (key.includes(banner.location) && banner.image) {
        images.push(banner)
      }
    })
  })
  return images
}

export const getImageBySize = (banners, size) => {
  const img = banners?.find(banner => banner.location === size)?.image
  if (!img) return
  return img
}

export const getLinkBySize = (banners, size) => {
  const img = banners?.find(banner => banner.location === size)
  if (!img) return
  return img
}

const vendorId = '4ed450aa-7600-d4ef-95ed-5f4ea43a9dee'

export const getImageBySizeToVendor = ({
  banners,
  size,
  coupon,
  couponCode,
  campaignsData = [],
  campaignKey,
  campaignType,
  campaignId,
  isPreActive,
}) => {
  return banners?.find(banner => {
    const hasCampaign = campaignsData.some(
      camp => camp.codigo === campaignKey || camp.id === campaignId
    )
    const hasVendor = banner.resale_id ? banner.resale_id === vendorId : true
    const bannerLocation = banner.location === size

    const hasBannerCampaign = bannerLocation && hasCampaign && hasVendor
    const hasCupon =
      coupon &&
      couponCode &&
      coupon === couponCode &&
      bannerLocation &&
      hasVendor
    const hasCampaignType =
      campaignType === 'geral' && bannerLocation && hasVendor

    const hasPreActive = bannerLocation && isPreActive && hasVendor

    if (hasPreActive) {
      return banner.image
    }

    if (hasBannerCampaign || hasCupon || hasCampaignType) {
      return banner.image
    }

    return null
  })
}
