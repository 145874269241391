import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'components'
import { isMobile } from 'utils/deviceDetector'
import classNames from 'classnames'
import styles from './CheckboxFiltersOptions.module.scss'

const CheckboxFiltersOptions = ({
  options,
  filterKey,
  setBooleanFilter,
  type,
}) => {
  if (!Array.isArray(options)) return null

  return options.map(({ name, checked, value, isVisible }) => {
    if (typeof isVisible === 'boolean' && !isVisible) return null
    return (
      <Checkbox
        key={name + value + type}
        id={name + value + type}
        option={name}
        optionPosition="right-joined"
        onChange={() => setBooleanFilter(filterKey, name)}
        checked={checked}
      />
    )
  })
}

const FiltersCategory = ({
  filterKey,
  options,
  setBooleanFilter,
  justOneColumn,
}) => {
  return (
    <div
      className={classNames({
        [styles['one-column']]: justOneColumn === true || isMobile,
        [styles['two-columns']]: !justOneColumn && !isMobile,
      })}
    >
      {CheckboxFiltersOptions({ options, filterKey, setBooleanFilter })}
    </div>
  )
}

FiltersCategory.propTypes = {
  filterKey: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  setBooleanFilter: PropTypes.func.isRequired,
  justOneColumn: PropTypes.bool,
}

FiltersCategory.defaultProps = {
  justOneColumn: false,
}

CheckboxFiltersOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  filterKey: PropTypes.string.isRequired,
  setBooleanFilter: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
}

export default React.memo(FiltersCategory)
