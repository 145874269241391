import React from 'react'
import PropTypes from 'prop-types'
import styles from './svg.module.scss'

const SuitCase = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="suitcase"
          d="M28.503 4.275c2.52 0 4.578 1.972 4.717 4.457l.008.268-.001 1.349 9.523.001c1.27 0 2.31.987 2.394 2.236l.006.164v15.107c0 .995-.61 1.866-1.5 2.225V41.25c0 1.325-1.075 2.4-2.4 2.4H6.75c-1.325 0-2.4-1.075-2.4-2.4l-.002-11.17c-.836-.338-1.425-1.128-1.492-2.05l-.006-.173V12.75c0-1.27.987-2.31 2.236-2.394l.164-.006 9.525-.001V9c0-2.52 1.972-4.579 4.457-4.718l.268-.007h9.003zM7.648 30.653l.002 9.697h32.7l-.002-9.696-14.099 2.169L26.25 34c0 1.105-.895 2-2 2h-.5c-1.105 0-2-.895-2-2l-.001-1.177-14.101-2.17zM41.85 13.65H6.15v13.434l15.599 2.4.001-.484c0-1.105.895-2 2-2h.5c1.105 0 2 .895 2 2l-.001.484 15.601-2.4V13.65zM28.503 7.725H19.5c-.65 0-1.186.486-1.265 1.115l-.01.16v1.349h11.552V9c0-.65-.485-1.186-1.114-1.265l-.16-.01z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#suitcase" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#suitcase"
        />
        <g mask="url(#b)">
          <path d="M0 0H48V48H0z" />
        </g>
      </g>
    </svg>
  )
}

SuitCase.propTypes = {
  viewBox: PropTypes.string,
  color: PropTypes.oneOf([
    'primaryColor',
    'darkColor',
    'whiteColor',
    'greenColor',
  ]),
}

SuitCase.defaultProps = {
  viewBox: '0 0 48 48',
  color: 'primaryColor',
}

export default SuitCase
