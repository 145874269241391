export const extractTags = ({
  campaigns,
  campaignsPropertyData,
  hasCampaignsOnProperty,
}) => {
  const propertyIsAnCampaign = (code, idCampaign) =>
    hasCampaignsOnProperty &&
    campaignsPropertyData.some(
      item =>
        new RegExp(`^${String(item.codigo)}$`, 'i').test(String(code)) ||
        new RegExp(`^${String(item.id)}$`, 'i').test(String(idCampaign))
    )
  const filteredCampaigns = Object.values(campaigns).filter(campaign => {
    if (!campaign.isActive) return false
    return (
      /tag/i.test(campaign?.tag?.type) &&
      (campaign.campaignType === 'geral' ||
        propertyIsAnCampaign(campaign.code, campaign.id))
    )
  })
  if (!filteredCampaigns.length) return null

  return filteredCampaigns.map(({ tag }) => tag?.name)
}
