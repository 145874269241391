import React, { useState } from 'react'
import classNames from 'classnames'
import { Link } from '@reach/router'
import { Icon } from 'components/'
import styles from './DidYouKnowNotification.module.scss'

const DidYouKnowNotification = () => {
  const [isOpen, setIsopen] = useState(true)
  return (
    <aside
      className={classNames(styles.didYouKnowContainer, {
        [styles.closed]: !isOpen,
      })}
    >
      <header className={styles.headerNotification}>
        <h3 className={styles.title}>Você Sabia?</h3>
        <span className={styles.divider} />
        <figure className={styles.iconContainer}>
          <button type="button" onClick={() => setIsopen(false)}>
            <Icon color="whiteColor" type="clear" size={26} />
          </button>
        </figure>
      </header>
      <p className={styles.info}>
        As compras de imóveis em nosso site são 100% digitais, não pedimos
        documentos físicos e nem utilizamos representantes. Isso garante que seu
        processo de compra aconteça em um ambiente seguro e moderno. Caso tenha
        alguma dúvida entre em contato com a{' '}
        <Link to="/fale-conosco?assunto=ouvidoria">gente aqui!</Link>
      </p>
      <figure className={styles.iconContainerBig}>
        <button type="button" onClick={() => setIsopen(false)}>
          <Icon color="whiteColor" type="clear" size={26} />
        </button>
      </figure>
    </aside>
  )
}

export default React.memo(DidYouKnowNotification)
