import React from 'react'
import PropTypes from 'prop-types'
import { Input, Icon } from 'components'
import styles from './IdSearchField.module.scss'

const IdSearchField = ({ value, onChange, id }) => {
  return (
    <div className={styles.wrapper}>
      <button
        type="button"
        onClick={() => onChange('')}
        className={styles.close}
        disabled={!value}
      >
        <Icon color="grayColor" type="clear" size={24} />
      </button>
      <Input
        id={id}
        onChange={e => onChange(e.target.value)}
        value={value}
        icon="search"
        type="text"
        placeholder="Número do ID"
        round
        className={styles.input}
      />
    </div>
  )
}

IdSearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  idQuery: PropTypes.shape({
    searchId: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
}

IdSearchField.defaultProps = {
  value: '',
}

export default React.memo(IdSearchField)
