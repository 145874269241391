import React from 'react'
import ClassName from 'classnames'
import PropTypes from 'prop-types'
import styles from './TourResale.module.scss'

const TourResale = ({ className, title, url }) => {
  return (
    <iframe
      title={title}
      src={url}
      className={ClassName(styles.tourResale, className)}
    />
  )
}

TourResale.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  url: PropTypes.string.isRequired,
}

TourResale.defaultProps = {
  className: '',
  title: 'Tour Resale',
}

export default React.memo(TourResale)
