import React from 'react'

import { Button } from 'components'

import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Processes.module.scss'

export default function Processes({ litigation }) {
  async function handleOpenDocument(url) {
    window.open(url)
  }

  return (
    <div className={styles.container}>
      {litigation.map((process, idx) => {
        return (
          <div
            className={classNames(styles.itemContainer, {
              [styles.margin]: idx > 0,
            })}
          >
            <div className={styles.data}>
              <p>Número do Processo: {process.processo}</p>
              <p>
                {process.vara}ª Vara Cível - {process.comarca} -{' '}
                {process.estado}
              </p>
            </div>
            {process.documentos.length > 0 && (
              <div className={styles.document}>
                <Button
                  onClick={() => handleOpenDocument(process.documentos[0].url)}
                >
                  Ver Autos do Processo
                </Button>
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

Processes.propTypes = {
  litigation: {
    map: PropTypes.func.isRequired,
  }.isRequired,
}
