import React from 'react'

import ErrorPage from './ErrorPage'

const linkUnavailableInfo = {
  code: 410,
  title: 'Link não está mais disponível',
  body: `
    Oi, este imóvel não está mais disponível. Encontre outras opções clicando no botão abaixo.
  `,
  cta: {
    label: 'Ver mais imóveis',
    path: '/busca',
  },
}

export default () => <ErrorPage {...linkUnavailableInfo} />
