import { parseToNumber } from 'utils/parseNumbers'

export const validatedMinValue = ({ value, limit, step, maxValue }) => {
  const minValue = parseToNumber(value)
  let newValue = Number(minValue)

  if (minValue > limit.max - step) {
    newValue = Number(limit.max) - step
  }

  if (minValue < limit.min + step) {
    newValue = Number(limit.min) + step
  }

  if (minValue > maxValue) {
    newValue = Number(limit.min)
  }

  return newValue
}

export const validatedMaxValue = ({ value, limit, step, minValue }) => {
  const maxValue = parseToNumber(value)
  let newValue = Number(maxValue)

  if (maxValue < limit.min + step) {
    newValue = Number(limit.min) + step
  }

  if (maxValue < minValue) {
    newValue = Number(limit.max)
  }

  return newValue
}
