export default {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1500 },
    items: 6,
    partialVisibilityGutter: 94,
  },
  desktop: {
    breakpoint: { max: 1500, min: 1025 },
    items: 5,
    partialVisibilityGutter: 58,
  },
  tabletPro: {
    breakpoint: { max: 1025, min: 1024 },
    items: 4,
    partialVisibilityGutter: 32,
  },
  tablet: {
    breakpoint: { max: 1023, min: 464 },
    items: 3,
    partialVisibilityGutter: 32,
  },
  mobilePro: {
    breakpoint: { max: 464, min: 350 },
    items: 2,
    partialVisibilityGutter: -8,
  },
  mobile: {
    breakpoint: { max: 350, min: 256 },
    items: 2,
    partialVisibilityGutter: -64,
  },
  mobileMin: {
    breakpoint: { max: 256, min: 0 },
    items: 2,
    partialVisibilityGutter: -99,
  },
}
