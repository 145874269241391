/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import CurrencyInput from 'react-currency-input-field'
import PropTypes from 'prop-types'

import { Text } from 'components'
import classNames from 'classnames'
import noop from 'lib/noop'
import styles from './CurrencyInput.module.scss'

const _CurrencyInput = ({
  id,
  name,
  value,
  onChange,
  className,
  label,
  labelTextColor,
  labelClassName,
  hasError,
  errorMessage,
  ...base
}) => {
  const classesInput = classNames(className, styles.wrapper, {
    [styles.error]: hasError,
  })
  return (
    <div className={classesInput}>
      {label && (
        <label
          htmlFor={id}
          className={classNames(styles.label, labelClassName)}
        >
          <Text fontSize="medium" color={labelTextColor || null}>
            {label}
          </Text>
        </label>
      )}
      <CurrencyInput
        id={id}
        name={name}
        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
        className={classNames(styles.input, className)}
        value={value}
        decimalsLimit={2}
        placeholder="R$ "
        prefix="R$ "
        onValueChange={e => {
          onChange(id, e)
        }}
        {...base}
      />
      {hasError && <span className={styles.span}>{errorMessage}</span>}
    </div>
  )
}

_CurrencyInput.propTypes = {
  rows: PropTypes.number,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
  labelTextColor: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}
_CurrencyInput.defaultProps = {
  rows: 0,
  errorMessage: 'error',
  hasError: false,
  id: 0,
  placeholder: '',
  labelTextColor: '',
  type: 'text',
  value: '',
  icon: '',
  className: '',
  labelClassName: '',
  label: '',
  onChange: noop,
}

export default React.memo(_CurrencyInput)
