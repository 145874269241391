import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const BathSink = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="bathSink"
          d="M29.48 21.43c.618 0 1.12.497 1.12 1.112 0 .615-.502 1.112-1.12 1.112h-.533c-.21 3.319-3.001 5.946-6.393 5.946H10.046c-3.392 0-6.183-2.627-6.393-5.946H3.12c-.618 0-1.12-.497-1.12-1.112 0-.615.502-1.113 1.12-1.113h3.266V20.15c0-.615.502-1.112 1.12-1.112.619 0 1.12.497 1.12 1.112v1.28h2.326a9.516 9.516 0 0 1-.818-.888c-1.576-1.94-2.442-4.501-2.442-7.215 0-2.713.866-5.275 2.442-7.214C11.762 4.109 13.954 3 16.3 3c2.346 0 4.538 1.109 6.166 3.113 1.576 1.939 2.442 4.5 2.442 7.214 0 2.714-.866 5.276-2.442 7.214a9.517 9.517 0 0 1-.818.888h2.236V20.15c0-.615.502-1.112 1.12-1.112.618 0 1.12.497 1.12 1.112v1.28h3.356zm-6.813-8.103c0-4.48-2.874-8.102-6.367-8.102s-6.367 3.622-6.367 8.102c0 3.938 2.234 7.264 5.202 7.966v-3.775a.54.54 0 0 0-.547-.532.54.54 0 0 0-.546.532c0 .615-.502 1.112-1.12 1.112-.618 0-1.12-.497-1.12-1.112 0-1.521 1.25-2.757 2.786-2.757 1.537 0 2.788 1.236 2.788 2.757v3.796c3.01-.656 5.291-4.006 5.291-7.987zM10.046 27.375h12.508c2.157 0 3.94-1.633 4.145-3.72H5.901c.204 2.087 1.988 3.72 4.145 3.72z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#bathSink" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#bathSink"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

BathSink.propTypes = {
  ...propTypesSvg,
}

BathSink.defaultProps = {
  ...defaultPropTypesSvg,
}

export default BathSink
