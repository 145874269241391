import React from 'react'
import PropTypes from 'prop-types'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const immobile = ({ viewBox, color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="immobile"
          d="M5.276 14.005l-.53.369-.014.01a1.177 1.177 0 0 1-1.632-.32 1.203 1.203 0 0 1 .312-1.66l11.763-8.18a1.25 1.25 0 0 1 1.427 0l11.723 8.18c.54.376.678 1.115.312 1.66l-.01.013a1.176 1.176 0 0 1-1.635.297l-.49-.37v12.578c0 .69-.56 1.25-1.25 1.25H6.526c-.69 0-1.25-.56-1.25-1.25V14.005zm8.546 11.44h4.134v-6.881a.25.25 0 0 0-.25-.25h-3.634a.25.25 0 0 0-.25.25v6.881zm-2.36 0v-7.268a2.25 2.25 0 0 1 2.25-2.25h4.353a2.25 2.25 0 0 1 2.25 2.25v7.268h3.828v-12.68a.75.75 0 0 0-.323-.616l-7.905-5.48c-.036-.028-.054-.037-.026-.037s.01.009-.036.042l-7.895 5.474a.75.75 0 0 0-.323.616v12.681h3.828z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#immobile" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#immobile"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

immobile.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ...propTypesSvg,
}

immobile.defaultProps = {
  size: 'auto',
  ...defaultPropTypesSvg,
}

export default immobile
