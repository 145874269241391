import { useState } from 'react'
import { getCustomerInfoLocalStorage } from 'utils/customerInfoLocalStorage'
import useCreateStore from '../../lib/useCreateStore'
import proposalModalActions from './actions'
import proposalModalSelectors from './selectors'

const initialState = {
  form: {
    confirmPassword: '',
    name: getCustomerInfoLocalStorage().name || '',
    email: getCustomerInfoLocalStorage().email || '',
    phone: getCustomerInfoLocalStorage().phone || '',
    password: '',
    registered: false,
    token: '',
  },
  openModal: false,
}

const ProposalModalStore = useCreateStore(() => {
  const [$proposalModal, setProposalModal] = useState(initialState)

  const actions = proposalModalActions(setProposalModal)
  const selectors = proposalModalSelectors($proposalModal)

  return { $proposalModal, ...actions, ...selectors }
})

export const useProposalModal = () => ProposalModalStore()
export const ProposalModalContext = ProposalModalStore.Context
export const ProposalModalProvider = ProposalModalStore.Provider
