const TERMS_OF_USE = 'terms'
const PRIVACY_POLICY = 'privacy'
const COOKIES_POLICY = 'cookies'
const DELETION_POLICY = 'dataDeletion'

export default {
  TERMS_OF_USE,
  PRIVACY_POLICY,
  COOKIES_POLICY,
  DELETION_POLICY,
}

export const paramDict = {
  privacidade: PRIVACY_POLICY,
  cookies: COOKIES_POLICY,
  exclusao_de_dados: DELETION_POLICY,
}

export const replaceParamDict = {
  [PRIVACY_POLICY]: 'privacidade',
  [COOKIES_POLICY]: 'cookies',
  [DELETION_POLICY]: 'exclusao_de_dados',
}

export const getTerm = term => paramDict[term] || TERMS_OF_USE

export const getParam = term => replaceParamDict[term] || ''
