import React from 'react'
import rosie from 'assets/images/rosie-chat.png'
import { Text, Title, Button, Input } from 'components/'
import { useAlert, useRoot } from 'store'
import { validateEmail } from 'utils/validations'
import { toastError } from 'components/ToastComponent/ToastComponent'
import { isMobile } from 'utils/deviceDetector'
import { getUtms } from 'utils/Utms'
import styles from './AlertCardContainer.module.scss'

const AlertCard = () => {
  const { setOpenAlertModal, setField, $alert } = useAlert()
  const { sendEmail, getSendLeadEmail } = useRoot()
  const { sendLeadEmailLoading, sendLeadEmailError } = getSendLeadEmail()

  const { form } = $alert

  const [touched, setTouched] = React.useState({})

  const handleBlur = field => () => setTouched({ ...touched, [field]: true })

  const handleErrorMessage = (value, errorMessage) =>
    value === '' ? 'Campo obrigatório' : errorMessage

  const onChange = e => {
    const { value } = e.target
    setTouched({ ...touched, email: false })
    setField('email', value)
  }

  const openAlertModal = async () => {
    const utms = getUtms()
    const newForm = { email: form.email, ...utms }
    await sendEmail(newForm, 'popup')

    if (sendLeadEmailError) {
      toastError('Não foi possível enviar seu email!')
    }

    setOpenAlertModal()
  }

  const isDisabled = !validateEmail(form.email)

  return (
    <div className={styles.alertCard}>
      <div className={styles.container}>
        <figure className={styles.figure}>
          <img
            loading="lazy"
            className={styles.img}
            alt="Icone de busca: Robô com uma lupa"
            src={rosie}
          />
        </figure>
        <div className={styles.body}>
          <div className={styles.info}>
            <Title
              type="h2"
              fontSize={isMobile ? 'large' : 'xlarge'}
              color="whiteColor"
            >
              Ainda não encontrou seu imóvel?
            </Title>
            <Text fontSize="medium" color="whiteColor" className={styles.text}>
              Crie um alerta que a gente te envia novos imóveis assim que
              chegarem
            </Text>
            {!isMobile && (
              <Text
                fontSize="medium"
                bold
                color="whiteColor"
                className={styles.text}
              >
                Digite seu e-mail para criar um alerta.
              </Text>
            )}
            <div className={styles.emailInfo}>
              <Input
                errorMessage={handleErrorMessage(form.email, 'Email inválido')}
                onBlur={handleBlur('email')}
                id="email"
                type="email"
                placeholder="Seu e-mail"
                className={styles.emailInput}
                value={form.email}
                onChange={onChange}
                hasError={!validateEmail(form.email) && touched.email}
              />
              <Button
                onClick={() => {
                  if (!touched.email) setTouched({ email: true })
                  if (!isDisabled) openAlertModal()
                }}
                type="filled"
                textColor="whiteColor"
                backgroundColor="secondColor"
                className={styles.buttonSubmit}
                isLoading={sendLeadEmailLoading}
                isDisabled={sendLeadEmailLoading}
                id="criar_alerta_listing"
              >
                Criar alerta
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(AlertCard)
