import { formatDate } from 'components/CallToAction/utils'
import {
  featureIconDictionary,
  featureNameDictionary,
  pendingTexts,
} from '../../list/dictionaries'

export const numberWithDots = x =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')

export const parseFeatures = (features, areas) => {
  const newFeatures = []

  Object.keys(Object.assign(features, areas)).map((feature, idx) => {
    const value = feature.includes('area_')
      ? areas[feature].value
      : features[feature]

    const measurementUnit = feature.includes('area_')
      ? areas[feature].unidade_medida
      : ''

    if (
      featureNameDictionary[feature] &&
      featureIconDictionary[feature] &&
      value !== null &&
      value
    ) {
      newFeatures.push({
        id: idx,
        feature: featureNameDictionary[feature],
        amount: value,
        icon: featureIconDictionary[feature],
        unit: measurementUnit,
      })
    }
    return null
  })

  return newFeatures
}

export const parseExpenses = despesas => {
  if (!despesas.length) {
    return []
  }
  return despesas.map(despesa => {
    if (despesa.type.toLowerCase() === 'iptu') {
      return { ...despesa, type: 'Valor do IPTU (anual)' }
    }
    if (despesa.type.toLowerCase() === 'condominio') {
      return { ...despesa, type: 'Valor do condomínio (mensal)' }
    }
    return despesa
  })
}

export const isAuctionCheck = string => {
  const lowerCaseString = string.toLowerCase()
  if (
    lowerCaseString === 'primeiro leilão' ||
    lowerCaseString === 'segundo leilão' ||
    lowerCaseString === 'primeiro_leilao' ||
    lowerCaseString === 'segundo_leilao' ||
    lowerCaseString === 'primeiro_leilão' ||
    lowerCaseString === 'segundo_leilão' ||
    lowerCaseString === 'leilao' ||
    lowerCaseString === 'leilão'
  ) {
    return true
  }
  return false
}
export const isDirectOrAgencySale = stringToCheck => {
  const directSaleRegex = new RegExp('^venda[_ ]direta$', 'i')
  const agencyRegex = new RegExp('^imobili[aá]ria$', 'i')
  if (directSaleRegex.test(stringToCheck) || agencyRegex.test(stringToCheck)) {
    return true
  }
  return false
}

export const isBestProposal = tipoVenda => {
  const bestProposalRegex = new RegExp('^melhor[ _]proposta$', 'i')

  if (bestProposalRegex.test(tipoVenda)) {
    return true
  }

  return false
}

export const isCompetition = stringToCheck => {
  const competitionRegex = new RegExp('^concorr[eê]ncia[ _]p[uú]blica$')
  const competitionRegexDispute = new RegExp('^melhor[ _]proposta$')
  if (
    competitionRegex.test(stringToCheck) ||
    competitionRegexDispute.test(stringToCheck)
  ) {
    return true
  }
  return false
}

export const isAgencySale = stringToCheck => {
  const agentRegex = new RegExp('^corretor[_ ]aut[oô]nomo$', 'i')
  const agencyRegex = new RegExp('^imobili[aá]ria$', 'i')

  if (agentRegex.test(stringToCheck) || agencyRegex.test(stringToCheck)) {
    return true
  }
  return false
}

export const isLearnMore = call_to_actions => {
  const lowerCallToAction = call_to_actions.toLowerCase()
  const learnMoreRegex = new RegExp('^saiba[ _]mais$', 'i')

  return learnMoreRegex.test(lowerCallToAction)
}

export const isHideDisplay = checkedString => {
  const lowerCheckedString = checkedString.toLowerCase()
  const hideRegex = new RegExp('^n[aã]o[ _]exibir$', 'i')

  return hideRegex.test(lowerCheckedString)
}

export const isClosed = stringToCheck => {
  return stringToCheck.toLowerCase() === 'encerrado'
}

export const isSold = stringToCheck => {
  return stringToCheck.toLowerCase() === 'vendido'
}

export const isSacre = conditions => {
  return (
    conditions.parcelamento_direto &&
    conditions.parcelamento_direto.sistema_amortizacao &&
    conditions.parcelamento_direto.sistema_amortizacao === 'price'
  )
}

export const isSaleValue = stringToCheck => {
  const lowerCheckedString = stringToCheck.toLowerCase()
  const saleValueRegex = new RegExp('^valor[ _]venda$', 'i')

  return saleValueRegex.test(lowerCheckedString)
}

export const isReferenceValue = stringToCheck => {
  const lowerCheckedString = stringToCheck.toLowerCase()
  const referenceValueRegex = new RegExp('^valor[ _]refer[eê]ncia$', 'i')

  return referenceValueRegex.test(lowerCheckedString)
}

export const isUpFrontValue = stringToCheck => {
  const lowerCheckedString = stringToCheck.toLowerCase()
  const upFrontValueRegex = new RegExp('^valor[ _]vista$', 'i')

  return upFrontValueRegex.test(lowerCheckedString)
}

export const paymentConditionSwitch = (bankOption, bank) => {
  switch (bankOption) {
    case 'escolha_cliente':
      return 'Pode ser financiado em banco da escolha do cliente.'
    case 'somente_banco':
      return `Financiado somente pelo Banco ${bank}`
    case 'condicao_banco':
      return `Financiado em condição especial pelo Banco ${bank}`
    default:
      return ''
  }
}

export const checkIsActiveByDate = data_limite => {
  const [auctionDate, auctionHour] = data_limite?.split(' ', 2) || []

  const dt_limite =
    auctionDate && auctionHour && formatDate(auctionDate, auctionHour)

  const expiration_date = new Date() >= new Date(dt_limite)

  const isAuctionActive = !data_limite || expiration_date

  return [isAuctionActive, expiration_date]
}

export const transformPendingTexts = pending => {
  if (!pending) return pending

  const separatePending = pending.split(',')

  const treatedText = separatePending
    .filter(element => pendingTexts[element])
    .map(element => pendingTexts[element])

  return treatedText
}

export const formatDateToPreActive = initial => {
  const [date, hour] = initial.split(' ')

  const formatedDate = date
    .split('/')
    .reverse()
    .join('-')

  return `${formatedDate} ${hour}`
}
