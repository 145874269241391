import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon } from 'components/'
import style from './Modal.module.scss'

const isAndroid = window.navigator.userAgent.indexOf('iPhone') === -1

const Modal = ({
  closeModal,
  children,
  opened,
  className,
  closeClassName,
  hideCloseBtn,
  backdropClassName,
  closeButtonPosition,
  noOverflowX,
}) => {
  const close = React.useCallback(() => {
    document.body.style.overflow = 'scroll'
    document.body.style.position = 'unset'
    closeModal()
  }, [closeModal])

  const closeOnEsc = React.useCallback(
    e => {
      const key = e.key || e.keyCode
      if (key === 'Escape' || key === 'Esc' || key === 27) {
        close()
      }
    },
    [close]
  )

  React.useEffect(() => {
    if (opened) {
      document.addEventListener('keydown', closeOnEsc)
      if (window.innerWidth < 1024) {
        document.body.style.overflow = 'hidden'
        document.body.style.top = 0
        document.body.style.left = 0
        document.body.style.right = 0
      }
    }

    return () => {
      document.removeEventListener('keydown', closeOnEsc)
      document.body.style.overflow = 'scroll'
      document.body.style.position = 'unset'
    }
  }, [opened]) //eslint-disable-line

  return (
    <>
      {opened && (
        <div
          className={classNames(style.backdrop, backdropClassName)}
          aria-hidden
          onClick={close}
        />
      )}

      <div
        className={classNames(
          className,
          style.wrappedModal,
          opened && style.active,
          isAndroid && style.androidWrapper,
          { [style.noOverflowX]: noOverflowX }
        )}
        aria-hidden={!opened}
      >
        {!hideCloseBtn && (
          <span className={classNames(style.clear, style[closeButtonPosition])}>
            <button
              type="button"
              onClick={close}
              className={classNames(closeClassName, style.closeButton)}
            >
              <Icon color="darkColor" type="clear" size={26} />
            </button>
          </span>
        )}
        {children}
      </div>
    </>
  )
}

Modal.propTypes = {
  opened: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  closeClassName: PropTypes.string,
  hideCloseBtn: PropTypes.bool,
  backdropClassName: PropTypes.string,
  closeButtonPosition: PropTypes.string,
  noOverflowX: PropTypes.bool,
}

Modal.defaultProps = {
  opened: false,
  className: '',
  closeClassName: '',
  hideCloseBtn: false,
  backdropClassName: '',
  closeButtonPosition: 'left',
  noOverflowX: false,
}

export default React.memo(Modal)
