/* eslint-disable no-plusplus */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import { useCampaigns } from 'hooks'
import { PropertyCard, Pagination, NotFoundCard } from 'components'
import { AlertCardContainer } from 'containers'
import { useList, useFilter, useRoot } from 'store'
import { setListPropertySlug } from 'utils/propertyUrl'
import { parseFilterFromQueryString } from 'store/filter/utils/parseFilterFromQueryString'
import {
  isAbleForANewRequest,
  scrollToItem,
  replaceMegaFeiraoSendToApi,
  isTourVirtualReplaceSendApi,
  replaceCampaignKey,
} from './utils'
import styles from './EstateListContainer.module.scss'

const EstateListContainer = ({ urlParams }) => {
  const {
    $root: { campaignsLoading },
  } = useRoot()
  const { fetchList, getListData, isListLoading, clearList } = useList()
  const {
    $filter: {
      filters,
      filters: {
        arrayFilters: { localities },
      },
      queryString,
    },
    clearFilters,
    setField,
    setNumberFilter,
    setStringFilter,
    setBooleanFilter,
    setArrayFilter,
    updateQueryString,
    setComplexCampaigns,
  } = useFilter()

  const { hasActiveCampaign } = useCampaigns()

  const { list, pagination } = getListData()
  const { page, max_pages } = pagination
  const listLoading = isListLoading()

  useEffect(() => {
    if (queryString) {
      window.history.replaceState('busca', 'Title', queryString)
    }

    const request = async () => {
      await fetchList(
        replaceCampaignKey(
          replaceMegaFeiraoSendToApi(isTourVirtualReplaceSendApi(queryString))
        )
      )
      scrollToItem()
    }

    if (isAbleForANewRequest(queryString, urlParams)) request()

    // eslint-disable-next-line
  }, [queryString])

  useEffect(() => {
    if (
      localities.options.length &&
      urlParams &&
      !queryString &&
      !campaignsLoading
    ) {
      parseFilterFromQueryString({
        queryString: urlParams,
        filters,
        setStringFilter,
        setBooleanFilter,
        setArrayFilter,
        setNumberFilter,
        setComplexCampaigns,
      })
      updateQueryString()
    }
    // eslint-disable-next-line
  }, [localities.options, campaignsLoading])

  useEffect(() => {
    return () => {
      clearList()
      clearFilters()
    }
    // eslint-disable-next-line
  }, [])

  const isBrowser = () => typeof window !== 'undefined'

  const widthScreen = isBrowser ? window.innerWidth : 1024

  const handlerLoader = i => {
    if (widthScreen < 400) {
      return i * 10
    }
    if (widthScreen > 1024) {
      return i * 100 - 100
    }

    return i * 500 - 490
  }

  const placeholder = (
    <ContentLoader
      height={widthScreen > 1024 ? 600 : 1300}
      className={styles.loader}
    >
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
        return (
          <rect
            key={i}
            x="0"
            y={handlerLoader(i)}
            rx="3"
            ry="3"
            width="100%"
            height={widthScreen > 1024 ? 90 : 490}
          />
        )
      })}
    </ContentLoader>
  )

  const handlePage = ({ selected: selectedPage }) => {
    window.scrollTo(0, 0) //eslint-disable-line
    setNumberFilter('page', Number(selectedPage) + 1)
    updateQueryString()
  }

  const uniqueList = [
    ...list
      .reduce((map, estate) => {
        map.set(estate.id, estate)
        return map
      }, new Map())
      .values(),
  ]

  return (
    <section className={styles.wrapper}>
      {!listLoading &&
        uniqueList.map(estate => (
          <div id={estate.id} key={estate.id} className={styles.container}>
            <PropertyCard
              hasActiveCampaign={hasActiveCampaign}
              navigateRouter={`/imovel/${setListPropertySlug(estate)}`}
              immobile={estate}
            />
            {estate.alertCard && <AlertCardContainer onClick={() => {}} />}
          </div>
        ))}
      {listLoading && placeholder}
      {!listLoading && !list.length && (
        <>
          <NotFoundCard
            openFilterModal={() => setField('openFilterModal', true)}
          />
        </>
      )}
      {!listLoading && !!list.length && (
        <Pagination
          handleClick={handlePage}
          totalPages={max_pages}
          forcePage={page - 1}
        />
      )}
    </section>
  )
}

EstateListContainer.propTypes = {
  urlParams: PropTypes.shape({
    search: PropTypes.string,
    slug: PropTypes.string,
    discount: PropTypes.string,
  }).isRequired,
}

export default EstateListContainer
