import React, { useState, useLayoutEffect, useRef } from 'react'
import {
  countDownsConfigurations,
  activeCountDown,
} from 'service/campaigns/countDown'
import { CountDown } from 'components'
import { useCampaigns } from './useCampaigns'

const LOCATIONS_COUNTDOWN = {
  HOME: 'home_banner',
  POPUP: 'home_popup',
  PROPERTY: 'detalhe_imovel',
}
export const useCountDown = ({ styles = {}, callOn = '' }) => {
  const [displayCountdown, setDisplayCountdown] = useState({})
  const [displayCountdownProperty, setDisplayCountdownProperty] = useState({})
  const [displayCountdownModal, setDisplayCountdownModal] = useState({})
  const { HOME, POPUP, PROPERTY } = LOCATIONS_COUNTDOWN

  const timeoutId = useRef()

  const { campaigns } = useCampaigns()

  const setState = type => {
    if (!type) {
      const countDowns = activeCountDown(campaigns, HOME)
      setDisplayCountdown(prev => ({
        ...prev,
        ...countDowns,
      }))
    }
    if (type === 'modal') {
      const countDowns = activeCountDown(campaigns, POPUP)
      setDisplayCountdownModal(prev => ({
        ...prev,
        ...countDowns,
      }))
    }
    if (type === 'property') {
      const countDowns = activeCountDown(campaigns, PROPERTY)
      setDisplayCountdownProperty(prev => ({
        ...prev,
        ...countDowns,
      }))
    }
  }

  useLayoutEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
    timeoutId.current = setTimeout(() => {
      if (!callOn) setState()
      if (callOn === 'ModalCampaign') setState('modal')
      if (callOn === 'AuctionCampaigns') setState('property')
    }, 1500)

    return () => {
      if (timeoutId.current) clearTimeout(timeoutId.current)
    }
    // eslint-disable-next-line
  }, [callOn, campaigns])

  const renderCountDown = ({ nameCampaign = '', campaignsData = [] }) => {
    const countDowns = countDownsConfigurations(campaigns)
    return (
      <>
        {!callOn &&
          countDowns.map(({ show, timer, title }) => {
            if (!show(displayCountdown, HOME, nameCampaign)) return null

            return (
              <span className={styles.countDown}>
                <CountDown title={title} endDate={timer} />
              </span>
            )
          })}
        {callOn === 'AuctionCampaigns' &&
          countDowns.map(({ show, timer, name, title }) => {
            if (
              !show(displayCountdownProperty, PROPERTY, name, campaignsData)
            ) {
              return null
            }

            return (
              <span className={styles.countDown}>
                <CountDown
                  title={title}
                  endDate={timer}
                  campaign={new RegExp(`\\[${name}\\]`, 'i')}
                />
              </span>
            )
          })}
        {callOn === 'ModalCampaign' &&
          countDowns.map(({ show, timer, title }) => {
            if (!show(displayCountdownModal, POPUP, nameCampaign)) return null

            return (
              <span className={styles.countDown}>
                <CountDown title={title} endDate={timer} />
              </span>
            )
          })}
      </>
    )
  }

  return { renderCountDown }
}
