import React from 'react'
import { TagSetContainer } from 'containers'
import { Button } from 'components'
import { isMobile } from 'utils/deviceDetector'

import PropTypes from 'prop-types'

import styles from './NotificationBoxFooter.module.scss'

const NotificationBoxFooter = ({
  tagsDisplay,
  renderTitle,
  showFilterButton,
  onClick,
  openFilterModal,
}) => {
  return (
    <div className={styles.notificationFooterContainer}>
      {renderTitle && renderTitle()}
      <TagSetContainer tagsDisplay={tagsDisplay} />
      {(isMobile || showFilterButton) && (
        <>
          <Button
            id="filterButton"
            name="filterButton"
            onClick={onClick || openFilterModal}
            iconPosition="right"
            textColor="darkColor"
            icon="filter"
            type="outline"
            darkColor
            backgroundColor="darkColor"
          >
            Editar filtros
          </Button>
        </>
      )}
    </div>
  )
}

NotificationBoxFooter.propTypes = {
  tagsDisplay: PropTypes.oneOf(['all']),
  renderTitle: PropTypes.func,
  showFilterButton: PropTypes.bool,
  onClick: PropTypes.func,
  openFilterModal: PropTypes.func,
}

NotificationBoxFooter.defaultProps = {
  tagsDisplay: 'all',
  renderTitle: null,
  showFilterButton: false,
  onClick: null,
  openFilterModal: null,
}

export default NotificationBoxFooter
