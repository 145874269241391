import React, { memo } from 'react'
import PropTypes from 'prop-types'

import banner from 'assets/images/go-resale@2x.png'
import styles from './PagesBanner.module.scss'

const PageBanner = ({ title, aboutPage }) => {
  const headerClass = aboutPage ? 'aboutBanner' : 'pageBanner'

  return (
    <header className={styles[headerClass]}>
      <h1>{title}</h1>
      {aboutPage && (
        <img
          loading="lazy"
          src={banner}
          alt="#goresale"
          className={styles.image}
        />
      )}
    </header>
  )
}

PageBanner.propTypes = {
  title: PropTypes.string.isRequired,
  aboutPage: PropTypes.bool,
}

PageBanner.defaultProps = {
  aboutPage: false,
}
export default memo(PageBanner)
