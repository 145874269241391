/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { extractNameCampaign } from 'service/campaigns'
import {
  Address,
  Image,
  AprraisedValue,
  Feature,
  Discount,
  Tag,
  Button,
  SaleValueText,
  Tooltip,
  PicsCarousel,
} from 'components'
import noop from 'lib/noop'
import CONSTANTS from 'utils/systemsConstants'
import getPropertyCategoryImage from 'utils/getPropertyCategoryImage'
import { useCampaigns, UseParseTag } from 'hooks'
import { extractTags } from 'utils/extractTags'
import styles from './PropertyCard.module.scss'

const PropertyCard = ({ immobile, navigateRouter, hasActiveCampaign }) => {
  const {
    name,
    photo,
    longPhoto,
    address,
    saleCategory,
    city,
    state,
    description,
    features,
    appraisedValue,
    appraisedLabel,
    desagio,
    saleValue,
    saleLabel,
    tags,
    soldStatus,
    category,
    picsArray,
    campaignInfo: {
      values: { campaignTotalValue },
    },
    isPreActive,
    isPreActiveExpired,
    coupon,
    campaignsData,
  } = immobile
  const { isAnActiveCampaign, campaigns } = useCampaigns()

  const { CONCORRENCIA } = CONSTANTS

  const width = window.innerWidth
  const hasPromotion = Boolean(campaignTotalValue && hasActiveCampaign())
  const isSold = soldStatus === 'vendido'

  const getNameCampaign = () => {
    if (tags && tags.some(condition => /Novembro Mágico/i.test(condition))) {
      return 'Saldão'
    }
    return extractNameCampaign(immobile, campaigns)
  }

  const tagsMap = {
    venda_judicial: 'Venda Judicial',
    compra_garantida: 'Compra Garantida',
  }

  Object.entries(tagsMap).forEach(
    ([value, label]) => saleCategory === value && tags.splice(1, 0, label)
  )
  const dontRenderTags = ['Corujão']
  const picsIsArray = Array.isArray(picsArray) && picsArray.length
  const hasCampaigns = campaignsData && campaignsData.length
  const conditionalTags = []
  const tagsByApi =
    extractTags({
      campaigns,
      campaignsPropertyData: campaignsData,
      hasCampaignsOnProperty: hasCampaigns,
      coupon,
    }) || []
  const renderTags = UseParseTag(
    [...tagsByApi, ...tags, ...conditionalTags],
    isAnActiveCampaign
  )
    .filter(tag => !!tag)
    .filter(tag => !dontRenderTags.includes(tag))

  return (
    <article className={styles.card}>
      <figure
        className={classNames(styles.image, {
          [styles.sold]: isSold,
          [styles.isCarousel]: picsIsArray,
        })}
      >
        {isPreActive && !isPreActiveExpired && (
          <div className={styles.preActiveTag}>
            <Tag>Em breve</Tag>
          </div>
        )}
        <a
          href={navigateRouter}
          title={`Link para o imóvel ${name}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {picsIsArray ? (
            <PicsCarousel imgList={picsArray} property={{ category, name }} />
          ) : (
            <Image
              alt={`Foto do imóvel ${name}`}
              sourceMobile={longPhoto}
              source={photo}
              sourceLarge={photo}
              noPhotoMobile={getPropertyCategoryImage(`${category}Large`)}
              noPhoto={getPropertyCategoryImage(category)}
              noPhotoLarge={getPropertyCategoryImage(category)}
            />
          )}
        </a>
        {isSold && (
          <div>
            <span>VENDIDO</span>
          </div>
        )}
      </figure>

      <div className={styles.information}>
        <div className={styles.tags}>
          {renderTags.map(tag => (
            <Tag campaign={tag === CONCORRENCIA} key={tag}>
              {tag}
            </Tag>
          ))}
        </div>

        <header>
          <a
            href={navigateRouter}
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Tooltip className={styles.propertyText}>{name}</Tooltip>
          </a>
        </header>

        <p className={styles.resumeAddress}>{`${city}/${state}`}</p>

        <Address address={address} />

        <Tooltip ignoreTooltipTreatment className={styles.ellipsis}>
          {description}
        </Tooltip>

        <div className={styles.features}>
          {features.map(({ id, feature, amount, icon }) => (
            <Feature
              key={id}
              icon={icon}
              number={amount}
              feature={feature}
              type="row"
              propertyCard
            />
          ))}
        </div>
      </div>
      <div className={styles.values}>
        {appraisedValue > saleValue ? (
          <span className={classNames(styles.prices)}>
            <AprraisedValue
              value={appraisedValue}
              strike={appraisedValue !== saleValue}
              label={appraisedLabel}
              isCampaign={hasPromotion}
            />

            {hasPromotion ? (
              <>
                {' '}
                <SaleValueText
                  saleLabel={saleLabel || 'Valor de venda'}
                  value={saleValue}
                  propertyCard
                  isCampaign={hasPromotion}
                />
                <Discount percent={desagio} withIcon={width > 1024} />
                <SaleValueText
                  saleLabel={`Valor ${getNameCampaign()}`}
                  value={campaignTotalValue}
                  propertyCard
                  className={styles.promotionPrice}
                />
              </>
            ) : (
              <>
                {' '}
                <Discount percent={desagio} withIcon={width > 1024} />
                <SaleValueText
                  saleLabel={saleLabel || 'Valor de venda'}
                  value={saleValue}
                  propertyCard
                  isCampaign={hasPromotion}
                />
              </>
            )}
          </span>
        ) : (
          <span>
            <SaleValueText
              saleLabel={saleLabel || 'Valor de venda'}
              value={saleValue}
              propertyCard
              isCampaign={hasPromotion}
            />
            {!!hasPromotion && (
              <SaleValueText
                saleLabel={`Valor ${getNameCampaign()}`}
                value={campaignTotalValue}
                propertyCard
                className={styles.promotionPrice}
              />
            )}
          </span>
        )}
        <a
          href={navigateRouter}
          className={styles.buttonOpportunity}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            onClick={() => {}}
            className={styles.button}
            fontSize="xsmall"
            disabled={isSold}
          >
            {isSold ? 'VENDIDO' : 'VER OPORTUNIDADE'}
          </Button>
        </a>
      </div>
      <a
        href={navigateRouter}
        className={styles.mobileNavigate}
        target="_blank"
        rel="noopener noreferrer"
      />
    </article>
  )
}

PropertyCard.propTypes = {
  immobile: PropTypes.shape({
    name: PropTypes.string,
    photo: PropTypes.string,
    longPhoto: PropTypes.string,
    saleCategory: PropTypes.string,
    address: PropTypes.string,
    description: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.shape({})),
    appraisedValue: PropTypes.number,
    desagio: PropTypes.number,
    saleValue: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.string),
    soldStatus: PropTypes.string,
    saleLabel: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    appraisedLabel: PropTypes.string,
    category: PropTypes.string,
    campaignInfo: PropTypes.shape().isRequired,
    picsArray: PropTypes.arrayOf(PropTypes.string),
    isPreActive: PropTypes.bool.isRequired,
    isPreActiveExpired: PropTypes.bool.isRequired,
    coupon: PropTypes.string.isRequired,
    campaignsData: PropTypes.arrayOf([]).isRequired,
  }).isRequired,
  navigateRouter: PropTypes.string.isRequired,
  favoriteButton: PropTypes.shape({
    onClick: PropTypes.func,
    value: PropTypes.bool,
  }),
  hasActiveCampaign: PropTypes.func.isRequired,
}

PropertyCard.defaultProps = {
  favoriteButton: {
    onClick: noop,
    value: false,
  },
}

export default React.memo(PropertyCard)
