import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const Filter = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="filter"
          d="M8.337 21a3.5 3.5 0 0 1 6.326 0H26.5a1.5 1.5 0 0 1 0 3H14.663a3.5 3.5 0 0 1-6.326 0H5.5a1.5 1.5 0 0 1 0-3h2.837zm9-7a3.5 3.5 0 0 1 6.326 0H26.5a1.5 1.5 0 0 1 0 3h-2.837a3.5 3.5 0 0 1-6.326 0H5.5a1.5 1.5 0 0 1 0-3h11.837zm-9-7a3.5 3.5 0 0 1 6.326 0H26.5a1.5 1.5 0 0 1 0 3H14.663a3.5 3.5 0 0 1-6.326 0H5.5a1.5 1.5 0 0 1 0-3h2.837zM11.5 24a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm9-7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm-9-7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#filter" />
        </mask>
        <use className={styles[color]} xlinkHref="#filter" />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Filter.propTypes = {
  ...propTypesSvg,
}

Filter.defaultProps = {
  ...defaultPropTypesSvg,
}

export default Filter
