import React from 'react'

import { CallToAction } from 'components'
import PropTypes from 'prop-types'
import {
  useProperty,
  useProposalModal,
  useMessageModal,
  useVisitScheduleModal,
  useSimulatorForm,
} from 'store'
import ContentLoader from 'react-content-loader'
import styles from './PropertyCardContainer.module.scss'

const PropertyCardContainer = ({ idEffect, preview }) => {
  const {
    getPropertyData,
    clearPropertyData,
    setPropertyIsPreActive,
    $property: { propertyIsPreActive },
  } = useProperty()
  const { setOpenModal: setOpenMessageModal } = useMessageModal()
  const { setOpenModal: setOpenProposalModal } = useProposalModal()
  const { setOpenModal: setOpenVisitModal } = useVisitScheduleModal()
  const { setOpenModal: setOpenSimulatorModal } = useSimulatorForm()

  const data = getPropertyData()

  const placeholder = (
    <ContentLoader height={600}>
      <rect x="30" y="5" rx="0" ry="650" width="350" height="400" />
    </ContentLoader>
  )

  React.useEffect(() => {
    return () => clearPropertyData()
    // eslint-disable-next-line
  }, [])

  return (
    <div id={idEffect} className={styles.wrapper}>
      {!data && placeholder}
      {data && (
        <div className={styles.card}>
          <CallToAction
            estateData={data}
            proposalAction={setOpenProposalModal}
            contactUsAction={setOpenMessageModal}
            simulatorAction={setOpenSimulatorModal}
            scheduleAction={setOpenVisitModal}
            preview={preview}
            propertyIsPreActive={propertyIsPreActive}
            setPropertyIsPreActive={setPropertyIsPreActive}
          />
        </div>
      )}
    </div>
  )
}

PropertyCardContainer.propTypes = {
  idEffect: PropTypes.string,
  preview: PropTypes.bool,
}

PropertyCardContainer.defaultProps = {
  idEffect: '',
  preview: false,
}

export default PropertyCardContainer
