import { create } from 'apisauce'
import * as fakeApi from './sample.json'

const baseUrl = process.env.REACT_APP_API_URL
const pagImovelBaseUrl = 'https://clientes.pagimovel.com.br'
const resaleBaseUrl = process.env.REACT_APP_API_URL_RESALE
const baseUrlCampaign = process.env.REACT_APP_API_CAMPAIGN_URL

export const baseApiCampaign = create({
  baseURL: baseUrlCampaign,
  headers: {
    'X-API-KEY': process.env.REACT_APP_API_CAMPAIGN_KEY,
  },
})

export const cityApi = create({
  baseURL: baseUrl,
  headers: {
    'X-API-KEY': process.env.REACT_APP_API_KEY,
  },
})

export const apiWithoutPropertySuffix = create({
  baseURL: `${baseUrl}`,
  headers: {
    'X-API-KEY': process.env.REACT_APP_API_KEY,
  },
})

export const pagImovelApi = create({
  baseURL: pagImovelBaseUrl,
  headers: { 'X-API-KEY': process.env.REACT_APP_API_KEY_PAGIMOVEL },
})

export const resaleApi = create({
  baseURL: resaleBaseUrl,
  headers: {
    'X-API-KEY': process.env.REACT_APP_API_KEY,
  },
})

export default create({
  baseURL: `${baseUrl}/property`,
  headers: {
    'X-API-KEY': process.env.REACT_APP_API_KEY,
  },
})

export const getMockData = async data => {
  return Promise.resolve({ data: fakeApi.default[data] })
}

export const requestMockData = create({
  baseURL: `http://localhost:5000`,
})
