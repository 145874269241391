import * as React from 'react'
import PropTypes from 'prop-types'

const warnNoProvider = () => {
  // tslint:disable-next-line
  console.warn('[useCreateStore] Missing Provider') // eslint-disable-line
}
const canUseProxy =
  process.env.REACT_APP_STAGE === 'dev' && typeof Proxy !== 'undefined'

const defaultValue = canUseProxy
  ? new Proxy({}, { get: warnNoProvider, apply: warnNoProvider })
  : {}

export default useValue => {
  const Context = React.createContext(defaultValue)

  const Provider = props => {
    const { children } = props
    const value = useValue(props)

    return <Context.Provider value={value}>{children}</Context.Provider>
  }

  Provider.propTypes = {
    children: PropTypes.node.isRequired,
  }

  const useContext = () => React.useContext(Context)

  useContext.Context = Context

  useContext.Provider = Provider

  return useContext
}
