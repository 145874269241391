import { gaEvent } from 'utils/ga'
import isPreRendering from 'utils/isPreRendering'
import * as providers from './provider'

export default setAlert => {
  const setField = (field, value) => {
    setAlert(prev => ({ ...prev, form: { ...prev.form, [field]: value } }))
  }

  const setPurpose = (field, value) => {
    setAlert(prev => ({
      ...prev,
      form: {
        ...prev.form,
        purpose: { ...prev.form.purpose, [field]: value },
      },
    }))
  }

  // TODO(Filters): Verificar se precisa mudar essa funcao
  const clearStateAlert = () => {
    setAlert(prev => ({
      ...prev,
      form: {
        periodicity: {
          daily: false,
          weekly: false,
          avaiable: false,
        },
        whatsapp: false,
        purpose: {
          live: false,
          invest: false,
        },
        orderBy: 'data',
        situation: '',
      },
    }))
  }

  const setOpenAlertModal = async () => {
    setAlert(prev => ({ ...prev, openModal: !prev.openModal }))
  }

  const setOpenExitModal = async () => {
    setAlert(prev => ({ ...prev, openExitModal: !prev.openExitModal }))
  }

  const createAlert = async payload => {
    const [err, res] = await providers.createAlert(payload)

    if (err) {
      return false
    }

    if (!isPreRendering()) {
      const ReactGALabel = purpose => {
        const { live, invest } = purpose
        if (live && invest) {
          return 'comprar_investir/morar'
        }

        if (live) {
          return 'comprar_morar'
        }

        return 'comprar_investir'
      }

      gaEvent({
        category: 'L_rosie_alerta',
        action: 'submit',
        label: ReactGALabel(payload.filters.finalidade),
      })
    }

    return res
  }

  return {
    setField,
    createAlert,
    clearStateAlert,
    setOpenAlertModal,
    setOpenExitModal,
    setPurpose,
  }
}
