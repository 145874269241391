/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'components/'
import DOMPurify from 'dompurify'
import classNames from 'classnames'
import styles from './FinancialData.module.scss'

const FinancialData = ({ show, paymentsMethods, className }) => {
  const sanitizer = DOMPurify.sanitize

  const renderContent = contents => {
    return contents.map(({ label, value }, index) => (
      <div
        key={index.toString()}
        className={styles.contentWrapper}
        dangerouslySetInnerHTML={{
          __html: sanitizer(
            `<p>${label}</p><strong>${
              typeof value === 'number'
                ? `R$ ${value.toLocaleString('pt-br')}`
                : value
            }</strong>`
          ),
        }}
      />
    ))
  }

  const renderContentNoLabel = contents => {
    return contents.map((content, index) => (
      <div
        key={index.toString()}
        className={styles.contentWrapper}
        dangerouslySetInnerHTML={{
          __html: sanitizer(`<p>${content}</p>`),
        }}
      />
    ))
  }

  const renderPaymentMethods = methods => {
    return methods.map((method, index) => (
      <div key={index.toString()}>
        <div
          className={classNames({
            [styles.row]:
              (method.content && typeof method.content === 'string') ||
              method.value,
          })}
        >
          {method.value ? (
            <>
              <Text
                className={styles.textTitle}
                fontSize="medium"
                color="lightTextColor"
              >
                {method.title}
              </Text>
              <strong>
                {`R$ ${Number(method.value).toLocaleString('pt-br')}`}
              </strong>
            </>
          ) : (
            <>
              <Text
                className={styles.textTitle}
                fontSize="medium"
                color="lightTextColor"
              >
                {method.title}
              </Text>
              {method.content && typeof method.content === 'string' ? (
                <div
                  key={index.toString()}
                  className={styles.contentWrapper}
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(`<p>${method.content}</p>`),
                  }}
                />
              ) : (method.content && typeof method.content[0] === 'string') ||
                typeof method.content[0][0] === 'string' ? (
                renderContentNoLabel(method.content)
              ) : method.content ? (
                renderContent(method.content)
              ) : null}
            </>
          )}
        </div>
        <br />
      </div>
    ))
  }

  return (
    show && (
      <div className={classNames(className, styles.financialData)}>
        {renderPaymentMethods(paymentsMethods)}
      </div>
    )
  )
}

FinancialData.propTypes = {
  paymentsMethods: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  className: PropTypes.string,
  show: PropTypes.bool,
}

FinancialData.defaultProps = {
  className: '',
  show: true,
}

export default React.memo(FinancialData)
