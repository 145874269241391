import React, { useState } from 'react'
import { Advertising } from 'components'
import { useHome } from 'store'

const AdvertisingContainer = () => {
  const { $home } = useHome()
  const { urlDesktop, urlMobile, link } = $home.advertising

  const [advertisingType, setAdvertising] = useState(
    window.innerWidth < 700 ? 'mobile' : 'desk'
  )

  const returnImage = () => {
    const widthScreen = window.innerWidth

    if (widthScreen < 700 && advertisingType !== 'mobile') {
      return setAdvertising('mobile')
    }

    if (widthScreen > 700 && advertisingType !== 'desk') {
      return setAdvertising('desk')
    }
    return null
  }

  window.addEventListener('resize', returnImage)

  return (
    urlDesktop &&
    urlMobile && (
      <Advertising
        url={advertisingType === 'mobile' ? urlMobile : urlDesktop}
        link={link}
      />
    )
  )
}

export default React.memo(AdvertisingContainer)
