import { useState, useContext, useMemo } from 'react'
import { RootContext } from 'store/root'
import { useCampaigns } from 'hooks'
import useCreateStore from '../../lib/useCreateStore'
import listActions from './actions'
import listSelectors from './selectors'

const initialState = {
  estatesList: [],
  loadingList: true,
  pagination: {
    total_items: 0,
    offset: 0,
    max_pages: 0,
  },
  openLeadModal: true,
}

const ListStore = useCreateStore(() => {
  const [$list, setList] = useState(initialState)
  const { isAnActiveCampaign } = useCampaigns()
  const selectors = listSelectors({ $list, isAnActiveCampaign })
  const { setError } = useContext(RootContext)
  const actions = useMemo(
    () => listActions({ setList, setError, initialState }),
    // eslint-disable-next-line
    [setError]
  )

  return { $list, ...actions, ...selectors }
})

export const useList = () => ListStore()
export const ListContext = ListStore.Context
export const ListProvider = ListStore.Provider
