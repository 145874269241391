/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link, navigate } from '@reach/router'
import PropTypes from 'prop-types'
import { Icon } from 'components'
import isIos from 'utils/isIos'
import styles from './Breadcrumb.module.scss'

const Breadcrumb = ({ steps, blockNavigate }) => {
  const lastStep = steps[steps.length - 1]
  const otherSteps = steps.slice(0, -1)
  const myDot = <span className={styles.dot} />
  const newTab =
    window.history.length === 1
      ? () => {
          window.scrollTo(0, 0)
          navigate(`/busca`)
        }
      : () => (blockNavigate ? {} : window.history.back())
  return (
    <div className={styles.wrapper}>
      <button
        type="button"
        onClick={steps[1].onChange || newTab}
        className={styles.buttonReturn}
      >
        <Icon
          type="arrow"
          size={32}
          className={isIos() ? styles.arrowIOS : ''}
        />
        <span className={styles.back}>Voltar</span>
      </button>
      {myDot}
      <div className={styles.breadcrumb}>
        {steps &&
          otherSteps.map(({ name, route, onChange = null }, index) => (
            <div>
              <Link to={blockNavigate ? '' : route} onClick={onChange}>
                {name}
              </Link>
              {index === steps.length - 1 ? (
                ''
              ) : (
                <span className={styles.separator}>{'>'}</span>
              )}
            </div>
          ))}
        <div title={lastStep.name} className={styles.lastStep}>
          <Link to={blockNavigate ? '' : lastStep.route} onClick={() => null}>
            {lastStep.name}
          </Link>
        </div>
      </div>
    </div>
  )
}

Breadcrumb.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
      onChange: PropTypes.func,
    }).isRequired
  ).isRequired,
  blockNavigate: PropTypes.bool,
}

Breadcrumb.defaultProps = {
  blockNavigate: false,
}

export default React.memo(Breadcrumb)
