import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Title.module.scss'

const PREFIX = 'font-'

const Title = ({
  children,
  type,
  color,
  fontSize,
  onClick,
  className,
  bold,
  alignCenter,
}) => {
  const myClassName = classNames(
    styles.title,
    styles[color],
    styles[`${PREFIX}${fontSize}`],
    onClick && styles.click,
    { [styles.bold]: bold, [styles.textCenter]: alignCenter },
    className
  )

  return React.createElement(
    type,
    { className: myClassName, onClick: onClick || (() => {}) },
    children
  )
}

export const fontSizes = Object.keys(styles)
  .filter(className => className.lastIndexOf(PREFIX, 0) === 0)
  .map(fontSize => fontSize.substring(PREFIX.length))

Title.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
  color: PropTypes.oneOf([
    'primaryColor',
    'secondColor',
    'blackColor',
    'whiteColor',
  ]),
  fontSize: PropTypes.oneOf(fontSizes),
  onClick: PropTypes.func,
  className: PropTypes.string,
  bold: PropTypes.bool,
  alignCenter: PropTypes.bool,
}

Title.defaultProps = {
  color: 'primaryColor',
  fontSize: 'medium',
  children: null,
  onClick: null,
  className: '',
  bold: false,
  alignCenter: false,
}

export default React.memo(Title)
