/* eslint-disable no-restricted-properties */
import React, { useEffect, useCallback } from 'react'
import {
  Button,
  Text,
  Select,
  CurrencyInput,
  ValuesContainer,
} from 'components'
import { alternativeFormater } from 'utils/formatNumber'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import noop from 'lib/noop'
import emgeaIcon from 'assets/svgs/emgeaGreenIcon.svg'
import pagimovelClientes from 'assets/images/pagimovel-clientes.png'
import pagimovelCorretores from 'assets/images/pagimovel-corretores.png'
import { validateCurrency, validateEntry } from 'utils/validations'
import { boolean } from '@storybook/addon-knobs'
import SimulateValues from './simulateValues'
import styles from './SimulatorForm.module.scss'

const SimulatorForm = ({
  values,
  setField,
  setTouched,
  touched,
  handleClose,
  noModal,
}) => {
  const {
    balance_amount,
    entrance_value,
    first_parcel_amount,
    last_parcel_amount,
    parcel_quantity,
    cet_percent,
  } = values
  const yearlyInterest = 8
  const monthlyInterestDivided = yearlyInterest / 100
  let monthlyInterest = Math.pow(1 + monthlyInterestDivided, 1 / 12) - 1
  monthlyInterest *= 100

  const onCurrencyInputChange = (id, value) => {
    setTouched({ ...touched, [id]: false })
    setField(id, value)
    if (id === 'balance_amount') {
      setTouched({ ...touched, entrance_value: true })
    }
  }

  const handleErrorMessage = (value, errorMessage) => {
    if (!Number(value)) {
      return 'Valor inválido'
    }
    return value === '' ? 'Campo obrigatório' : errorMessage
  }

  const handleBlur = field => () => setTouched({ ...touched, [field]: true })

  const onChange = e => {
    setTouched({ ...touched, [e.target.id]: false })
    setField(e.target.id, e.target.value)
  }

  const options = [
    { value: '', name: 'Selecione' },
    { value: 12, name: '12' },
    { value: 24, name: '24' },
    { value: 36, name: '36' },
    { value: 48, name: '48' },
    { value: 60, name: '60' },
    { value: 72, name: '72' },
    { value: 84, name: '84' },
    { value: 96, name: '96' },
    { value: 108, name: '108' },
    { value: 120, name: '120' },
  ]

  const valuesSection = [
    {
      state: alternativeFormater(balance_amount),
      content: `Valor do imóvel`,
      className: '',
      format: true,
    },
    {
      state: alternativeFormater(entrance_value || 0),
      content: `Valor de entrada`,
      className: '',
      format: true,
    },
    {
      state: alternativeFormater(balance_amount - (entrance_value || 0)),
      content: `Valor parcelado`,
      className: '',
      format: true,
    },
    {
      state: true,
      content: <hr />,
      className: 'hrbar',
      format: false,
    },
    {
      state: parcel_quantity && `${parcel_quantity}x`,
      content: `Parcelado em`,
      className: '',
      format: false,
    },
    {
      state: first_parcel_amount,
      content: `Valor das parcelas`,
      className: 'highlighted',
      format: true,
    },
    {
      state: `${yearlyInterest.toFixed(2).toString().replace('.', ',')}%`,
      content: `Taxa de juros ao ano (nominal)`,
      className: 'interest',
      format: false,
    },
    {
      state: cet_percent && `${cet_percent}%`,
      content: `CET ao ano`,
      className: 'interest',
      format: false,
    },
    {
      state: true,
      content: `Sistema de amortização: PRICE`,
      className: 'footNote',
      format: false,
    },
    {
      state: true,
      content: `Correção monetária: TR`,
      className: 'footNote',
      format: false,
    },
  ]

  const simulateValues = useCallback(simulationParams => {
    return SimulateValues(simulationParams)
  }, [])

  useEffect(() => {
    simulateValues({
      priceValue: balance_amount,
      entryValue: entrance_value,
      monthlyInterestValue: monthlyInterest,
      installmentsValue: parcel_quantity,
      setterFirstInstallment: setField,
      setterLastInstallment: setField,
      setterCET: setField,
    })
    // eslint-disable-next-line
  }, [
    entrance_value,
    balance_amount,
    monthlyInterest,
    parcel_quantity,
    simulateValues,
    first_parcel_amount,
    last_parcel_amount,
    cet_percent,
  ])

  const [installmentsOptions, setInstallmentsOptions] = React.useState(options)

  useEffect(() => {
    if (first_parcel_amount) {
      const newOptions = options
        ?.map(option => {
          if (!first_parcel_amount) return option

          const principal = balance_amount - entrance_value
          const monthlyInterestRate = 0.006666666667
          const numerator = principal * monthlyInterestRate
          const denominator =
            1 - Math.pow(1 + monthlyInterestRate, -option.value)
          const payment = numerator / denominator

          if (payment < 400) {
            return null
          }

          return option
        })
        ?.filter(Boolean)

      setInstallmentsOptions(newOptions)

      if (
        parcel_quantity &&
        !newOptions?.find(option => option.value === Number(parcel_quantity))
      ) {
        if (newOptions[0]) {
          setField('parcel_quantity', newOptions[0].value)
        }
      }
    }
    // eslint-disable-next-line
  }, [first_parcel_amount, entrance_value, parcel_quantity, balance_amount])

  return (
    <section
      className={classNames(styles.simulatorContainer, {
        [styles.noModal]: noModal,
      })}
    >
      {!noModal && (
        <header
          className={classNames(styles.header, { [styles.noModal]: noModal })}
        >
          <div className={styles.top}>
            <Text fontSize="regular" color="grayColor" className={styles.title}>
              Parcelamento Direto
            </Text>
            <figure>
              <img
                loading="lazy"
                src={emgeaIcon}
                alt="Ícone da logo do vendedor Emgea"
              />
            </figure>
          </div>
        </header>
      )}
      <main className={styles.main}>
        <Text
          fontSize="medium"
          color="blackColor"
          className={styles.contactText}
          bold
        >
          Opções de parcelamento
        </Text>
        <CurrencyInput
          errorMessage={handleErrorMessage(balance_amount, 'Preço inválido')}
          onBlur={handleBlur('balance_amount')}
          hasError={!validateCurrency(balance_amount) && touched.balance_amount}
          id="balance_amount"
          name="balance_amount"
          label="Valor do imóvel"
          placeholder="R$"
          className={styles.inputName}
          labelClassName={styles.modalLabel}
          value={balance_amount}
          onChange={onCurrencyInputChange}
          allowDecimals={false}
        />
        <CurrencyInput
          errorMessage={handleErrorMessage(
            entrance_value,
            'O valor de entrada ser entre 10% e 99% do valor do imóvel.'
          )}
          onBlur={handleBlur('entrance_value')}
          hasError={
            !validateEntry(balance_amount, entrance_value) &&
            touched.entrance_value
          }
          id="entrance_value"
          name="entrance_value"
          label="Valor de entrada R$ (mínimo de 10% do valor do imóvel)"
          placeholder="R$"
          className={styles.inputName}
          labelClassName={styles.modalLabel}
          value={entrance_value}
          onChange={onCurrencyInputChange}
          allowDecimals={false}
        />
        <CurrencyInput
          id="calculated_value"
          name="calculated_value"
          label="Valor parcelado"
          placeholder="R$"
          disabled
          className={styles.inputName}
          labelClassName={styles.modalLabel}
          value={balance_amount - entrance_value}
          allowDecimals={false}
        />
        <Select
          errorMessage="Selecione um número de parcelas"
          onBlur={handleBlur('parcel_quantity')}
          hasError={parcel_quantity === '' && touched.parcel_quantity}
          id="parcel_quantity"
          onChange={onChange}
          value={parcel_quantity}
          options={installmentsOptions}
          className={styles.inputSubject}
          label="Número de parcelas"
        />

        {balance_amount && entrance_value && parcel_quantity && (
          <ValuesContainer valuesSection={valuesSection} />
        )}

        <Text
          fontSize="small"
          color="blackColor"
          className={styles.warnMessage}
        >
          <br />
          <strong>IMPORTANTE</strong>: Os valores apresentados são meramente
          para efeitos de simulação e estão sujeitos a variações de acordo com a
          data da contratação. A aprovação do crédito está sujeito à análise do
          vendedor do imóvel.
        </Text>
        <a
          className={styles.term}
          href="https://marketing.resale.com.br/rpem001-regulamento-da-modalidade-de-pagamento-parcelamento-emgea"
          target="_blank"
          rel="noreferrer"
        >
          Consultar regulamento
        </a>
        {noModal && (
          <div className={styles.pagimovelContainer}>
            <Text className={styles.pagimovelTitle}>
              Para retornar a sua proposta acesse o Pagimovel
            </Text>
            <div>
              <a
                href="https://clientes.pagimovel.com.br/welcome/login"
                target="blank"
              >
                <img
                  loading="lazy"
                  src={pagimovelClientes}
                  alt="Pagimovel Clientes"
                />
              </a>
              <a
                href="https://corretores.pagimovel.com.br/welcome/login"
                target="blank"
              >
                <img
                  loading="lazy"
                  src={pagimovelCorretores}
                  alt="Pagimovel Corretores"
                />
              </a>
            </div>
          </div>
        )}
      </main>
      {!noModal && (
        <footer
          className={classNames(styles.footer, { [styles.noModal]: noModal })}
        >
          <div className={styles.buttonContainer}>
            <Button
              id="btn_criar_visita_fechar"
              type="filled"
              darkColor
              className={classNames(styles.button, {
                [styles.noModal]: noModal,
              })}
              fontSize="medium"
              whiteColor
              onClick={() => handleClose()}
            >
              Fechar
            </Button>
          </div>
        </footer>
      )}
    </section>
  )
}

SimulatorForm.propTypes = {
  setTouched: PropTypes.func.isRequired,
  touched: PropTypes.objectOf(boolean).isRequired,
  handleClose: PropTypes.func.isRequired,
  setField: PropTypes.func,
  values: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    balance_amount: PropTypes.string.isRequired,
    entrance_value: PropTypes.string.isRequired,
    parcel_quantity: PropTypes.string.isRequired,
    first_parcel_amount: PropTypes.string,
    last_parcel_amount: PropTypes.string,
    cet_percent: PropTypes.string.isRequired,
  }),
  noModal: PropTypes.bool,
}

SimulatorForm.defaultProps = {
  setField: noop,
  noModal: false,
  values: {
    first_parcel_amount: '',
    last_parcel_amount: '',
  },
}
export default React.memo(SimulatorForm)
