import { gaEvent } from 'utils/ga'
import isPreRendering from 'utils/isPreRendering'
import * as providers from './provider'
import { generateFilterCampaignOptions } from './utils/generateFilterCampaignOptions'
import selector from './selectors'

export default setRoot => {
  const setError = error =>
    setRoot(prev => ({
      ...prev,
      error,
    }))

  const setLoadingCampaign = status => {
    setRoot(prev => ({ ...prev, campaignsLoading: status }))
  }

  const fetchCampaigns = async () => {
    if (isPreRendering()) return
    const [err, res] = await providers.fetchCampaigns()

    const hasError = err || !res || !res?.data || !res?.data?.data

    if (hasError) return setLoadingCampaign(false)

    const newData = hasError ? [] : res.data.data
    const serializedCampaigns = selector().getCampaigns(newData)

    return setRoot(prev => ({
      ...prev,
      campaigns: { ...prev.campaigns, ...serializedCampaigns },
      campaignsFilters: generateFilterCampaignOptions(serializedCampaigns),
    }))
  }

  const sendEmail = async (email, checkOffers, checkOffersResale) => {
    setRoot(prev => ({
      ...prev,
      sendLeadEmailLoading: true,
    }))

    const [err] = await providers.sendEmail(
      email,
      checkOffers,
      checkOffersResale
    )

    if (err) {
      return setRoot(prev => ({
        ...prev,
        sendLeadEmailError: true,
        sendLeadEmailLoading: false,
      }))
    }
    gaEvent({
      category: 'L_Lead_Home',
      action: 'submit',
    })

    return setRoot(prev => ({
      ...prev,
      sendLeadEmailLoading: false,
    }))
  }

  const setField = (value, field) =>
    setRoot(prev => ({
      ...prev,
      [field]: value,
    }))

  const setCookies = cookies => {
    setRoot(prev => ({
      ...prev,
      cookies,
    }))
  }

  const setLeadModal = leadModalStatus => {
    setRoot(prev => ({
      ...prev,
      leadModal: leadModalStatus,
    }))
  }

  return {
    setError,
    sendEmail,
    setCookies,
    setLeadModal,
    setField,
    fetchCampaigns,
    setLoadingCampaign,
  }
}
