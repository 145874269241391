import React from 'react'
import PropTypes from 'prop-types'
import { CategoryTitle, PropertyFeatures } from 'components'

const Features = ({ features, propertyName }) => {
  if (!features.length) {
    return null
  }

  const typeProperty = name =>
    (name && /Casa/i.test(name)) || (name && /Apartamento/i.test(name))

  return (
    <section>
      <CategoryTitle icon="characteristics" title="Características" />
      <PropertyFeatures
        isHouseApartment={typeProperty(propertyName)}
        allFeatures={features}
      />
    </section>
  )
}

Features.propTypes = {
  features: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  propertyName: PropTypes.string.isRequired,
}

export default Features
