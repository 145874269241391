import { sanitizerString } from 'utils/normalizeString'
import { isActiveCampaign } from 'service/campaigns'

export default ($root = {}) => {
  const getError = () => $root.error

  const getSendLeadEmail = () => {
    const { sendLeadEmailLoading, sendLeadEmailError } = $root

    return {
      sendLeadEmailLoading,
      sendLeadEmailError,
    }
  }

  const getCampaigns = data => {
    const serializedData = {}

    if (!data) return serializedData

    data.forEach(campaign => {
      const key = sanitizerString(campaign.codigo)

      const banners = campaign?.banners?.map(banner => ({
        image: banner.image,
        location: banner.localizacao,
        name: campaign.codigo,
        resale_id: banner.resale_id,
        url: banner.url_redirecionamento,
      }))

      const countDowns = campaign?.contadores?.map(countDown => ({
        location: countDown?.localizacao,
        dateHour: countDown?.data_hora,
        text: countDown?.texto,
      }))

      serializedData[key] = {
        id: campaign?.id,
        code: campaign.codigo,
        campaignName: campaign?.nome,
        campaignKey: key,
        campaignType: campaign?.tipo_campanha,
        campaignModel: campaign?.modelo_campanha_repex,
        startDate: campaign.data_inicio,
        endDate: campaign.data_fim,
        isActive: isActiveCampaign(campaign.data_inicio, campaign.data_fim),
        isPreLaunch: new Date() < new Date(campaign.data_inicio),
        banners,
        bannerOrder: campaign.ordem,
        couponCode: campaign?.cupom,
        tag: {
          name: campaign?.tag_faixa?.nome,
          bgColor: campaign?.tag_faixa?.cor_fundo,
          color: campaign?.tag_faixa?.cor_fonte,
          icon: campaign?.tag_faixa?.icone,
          type: campaign?.tag_faixa?.tipo,
        },
        countDowns,
        filter: {
          name: campaign?.filtro?.nome,
          pattern: campaign?.filtro?.padrao,
          url: campaign?.filtro?.url,
        },
      }
    })

    return serializedData
  }

  return { getSendLeadEmail, getError, getCampaigns }
}
