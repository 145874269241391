const separator = ','

function queryStringFromBooleanFilters(booleanFilters) {
  const booleanFilterKeys = Object.keys(booleanFilters)
  let queryString = ''

  booleanFilterKeys.forEach(key => {
    let values = ''
    booleanFilters[key].options.forEach(option => {
      if (option.checked) values = `${values}${separator}${option.value}`
    })

    const { queryStringKey } = booleanFilters[key]

    if (values)
      queryString = `${queryString}&${queryStringKey}=${values.substr(1)}`
  })

  return queryString
}

function queryStringFromStringFilters(stringFilters, initialFilterState) {
  const stringFilterKeys = Object.keys(stringFilters)
  let queryString = ''

  stringFilterKeys.forEach(key => {
    const { queryStringKey, value } = stringFilters[key]
    if (value && value !== initialFilterState.stringFilters[key].value)
      queryString = `${queryString}&${queryStringKey}=${value}`
  })

  return queryString
}

function queryStringFromArrayFilters(arrayFilters) {
  const arrayFilterKeys = Object.keys(arrayFilters)
  let queryString = ''

  arrayFilterKeys.forEach(key => {
    const { queryStringKey, value } = arrayFilters[key]

    let values = ''
    value.forEach(item => {
      values = `${values}${separator}${item.slug}`
    })

    if (values)
      queryString = `${queryString}&${queryStringKey}=${values.substr(1)}`
  })

  return queryString
}

function queryStringFromNumberFilters(numberFilters, initialFilterState) {
  const numberFilterKeys = Object.keys(numberFilters)
  let queryString = ''

  numberFilterKeys.forEach(key => {
    const { queryStringKey, value } = numberFilters[key]
    if (value !== initialFilterState.numberFilters[key].value)
      queryString = `${queryString}&${queryStringKey}=${value}`
  })

  return queryString
}

export const parseFilterToQueryString = (filters, initialFilterState) => {
  const booleanFiltersQS = queryStringFromBooleanFilters(filters.booleanFilters)
  const stringFiltersQS = queryStringFromStringFilters(
    filters.stringFilters,
    initialFilterState.filters
  )
  const arrayFiltersQS = queryStringFromArrayFilters(filters.arrayFilters)
  const numberFiltersQS = queryStringFromNumberFilters(
    filters.numberFilters,
    initialFilterState.filters
  )

  let queryString = ''

  if (booleanFiltersQS) queryString = queryString.concat(booleanFiltersQS)
  if (stringFiltersQS) queryString = queryString.concat(stringFiltersQS)
  if (arrayFiltersQS) queryString = queryString.concat(arrayFiltersQS)
  if (numberFiltersQS) queryString = queryString.concat(numberFiltersQS)

  return `?${queryString.substr(1)}`
}
