import React from 'react'
import { BannerImage } from 'components'
import PropTypes from 'prop-types'

const BannerImageContainer = ({
  bannerImageMobile,
  bannerImageDesk,
  bannerImageDeskLarge,
  link,
  alt,
  linkTitle,
}) => {
  return (
    <BannerImage
      bannerImageMobile={bannerImageMobile}
      bannerImageDesk={bannerImageDesk}
      bannerImageDeskLarge={bannerImageDeskLarge}
      link={link}
      alt={alt}
      linktitle={linkTitle}
    />
  )
}

BannerImageContainer.propTypes = {
  bannerImageMobile: PropTypes.string.isRequired,
  bannerImageDesk: PropTypes.string.isRequired,
  bannerImageDeskLarge: PropTypes.string.isRequired,
  link: PropTypes.string,
  alt: PropTypes.string,
  linkTitle: PropTypes.string,
}

BannerImageContainer.defaultProps = {
  link: undefined,
  alt: 'banner',
  linkTitle: 'link',
}

export default BannerImageContainer
