import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Text } from 'components/'
import styles from './JuridicalProcess.module.scss'

const stages = [
  { value: 'inicio_acao', name: 'Início da Ação' },
  { value: 'citacao', name: 'Citação' },
  { value: 'penhora_imovel', name: 'Penhora do Imóvel' },
  { value: 'avaliacao_imovel', name: 'Avaliação do Imóvel' },
  { value: 'alienacao_imovel', name: 'Alienação do Imóvel' },
]

const JuridicalProcess = ({ processStage }) => (
  <div className={classNames(styles.timeline, styles.flexContainer)}>
    {stages.map(({ value, name }) => (
      <span
        key={value}
        className={classNames(styles.timelineItem, styles.flexItemsDefault, {
          [styles.selected]: processStage === value,
        })}
      >
        <Text className={styles.text} fontSize="medium" color="lightBlueColor">
          {name}
        </Text>
      </span>
    ))}
  </div>
)

JuridicalProcess.propTypes = {
  processStage: PropTypes.oneOf([
    'inicio_acao',
    'citacao',
    'penhora_imovel',
    'avaliacao_imovel',
    'alienacao_imovel',
  ]).isRequired,
}

export default React.memo(JuridicalProcess)
