import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { BsChatDots } from 'react-icons/bs'
import styles from './FloatingChatBalloon.module.scss'

const FloatingChatBalloon = ({ text, link }) => {
  const [displayMessage, setDisplayMessage] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setDisplayMessage(true)
    }, 3000)
  }, [])
  return (
    <a
      className={styles.container}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div>
        {displayMessage && (
          <div className={styles.message}>
            <p>{text}</p>
          </div>
        )}
      </div>
      <div className={styles.circle}>
        <BsChatDots className={styles.icon} />
      </div>
    </a>
  )
}

FloatingChatBalloon.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default FloatingChatBalloon
