import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useProviders } from 'store'
import { Widget } from '@typeform/embed-react'
import { BaseLayout } from 'templates'
import {
  HowToBecomeSupplierContainer,
  FAQProviderContainer,
  MessagesProviderContainer,
} from 'containers'
import { setUtms } from 'utils/Utms'
import { PagesBanner, Breadcrumb, ServicesWeSeek } from 'components'
import styles from './ProvidersPage.module.scss'

const ProvidersPage = ({ preview, location: { search } }) => {
  const providerFormId = 'zsXs4GQq'
  const [formId, setFormId] = useState('')
  const { fetchMessagesProviders } = useProviders()

  useEffect(() => {
    setUtms(search)
  }, [search])

  useEffect(() => {
    fetchMessagesProviders()
    // eslint-disable-next-line
  }, [])

  const steps = [
    { name: 'Home', route: '/' },
    {
      name: 'Fornecedores',
      route: '/fornecedores',
      onChange: () => setFormId(''),
    },
    {
      name: 'Quero me tornar um fornecedor',
      route: '/fornecedores',
    },
  ]
  const handleClick = () => {
    setFormId(providerFormId)
    window.scrollTo(0, 0)
  }

  return (
    <BaseLayout backgroundColor="white" preview={preview}>
      {formId ? (
        <div className={styles.formContainer}>
          <div className={styles.breadCrumb}>
            <Breadcrumb steps={steps} />
          </div>

          <Widget id={formId} className={styles.form} hideFooter hideHeaders />
        </div>
      ) : (
        <>
          <PagesBanner title="Fornecedores" />
          <HowToBecomeSupplierContainer onClick={handleClick} />
          <ServicesWeSeek />
          <FAQProviderContainer />
          <MessagesProviderContainer />
        </>
      )}
    </BaseLayout>
  )
}

ProvidersPage.propTypes = {
  preview: PropTypes.bool,
  location: {
    search: PropTypes.string,
  },
}

ProvidersPage.defaultProps = {
  preview: false,
  location: {
    search: '',
  },
}

export default ProvidersPage
