import React from 'react'
import Slider from 'react-slick'
import MagicSliderDots from 'react-magic-slider-dots'
import 'react-magic-slider-dots/dist/magic-dots.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-multi-carousel/lib/styles.css'
import PropTypes from 'prop-types'
import getPropertyCategoryImage from 'utils/getPropertyCategoryImage'
import { Image } from 'components'
import styles from './PicsCarousel.module.scss'

export const CustomRightArrow = ({ onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
    />
  )
}

export const CustomLeftArrow = ({ onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
    />
  )
}

const PicsCarousel = ({ imgList, property }) => {
  if (!imgList) return null

  const { category, name } = property || {}

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => {
      return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={30} />
    },
    nextArrow: <CustomRightArrow />,
    prevArrow: <CustomLeftArrow />,
  }

  return (
    <div data-testid="carousel" className={styles.container}>
      <Slider {...settings}>
        {imgList.map(pic => (
          <Image
            alt={`Foto do imóvel ${name}`}
            source={pic}
            noPhotoMobile={getPropertyCategoryImage(`${category}Large`)}
            noPhoto={getPropertyCategoryImage(category)}
            noPhotoLarge={getPropertyCategoryImage(category)}
          />
        ))}
      </Slider>
    </div>
  )
}

PicsCarousel.propTypes = {
  imgList: PropTypes.arrayOf().isRequired,
  property: PropTypes.shape({
    category: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}

CustomLeftArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
}

CustomRightArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default React.memo(PicsCarousel)
