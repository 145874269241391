import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const Bell = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="bell"
          d="M22.445 16.207c0 1.963.807 3.845 2.244 5.233.631.61.82 1.528.478 2.325-.341.797-1.146 1.317-2.04 1.317h-3.893c0 1.73-1.45 3.132-3.241 3.132-1.79 0-3.241-1.402-3.241-3.132H8.859c-.893 0-1.699-.52-2.04-1.317a2.08 2.08 0 0 1 .478-2.325 7.277 7.277 0 0 0 2.244-5.233V14.37c0-2.92 2.08-5.372 4.886-6.048V6.457c0-.836.701-1.513 1.566-1.513.865 0 1.566.677 1.566 1.513v1.866c2.807.676 4.886 3.127 4.886 6.048v1.836zm-6.452 10.191c.751 0 1.362-.59 1.362-1.316h-2.724c0 .726.61 1.316 1.362 1.316zm7.438-3.327a.309.309 0 0 0-.07-.347c-1.803-1.74-2.795-4.055-2.795-6.517V14.37c0-2.436-2.052-4.418-4.573-4.418-2.521 0-4.573 1.982-4.573 4.418v1.836c0 2.462-.992 4.776-2.794 6.517a.31.31 0 0 0-.071.347c.05.119.17.196.304.196h14.268a.328.328 0 0 0 .304-.196zM7.396 16.196a.968.968 0 0 1-.303.05.938.938 0 0 1-.89-.616c-1.37-3.89.79-8.132 4.815-9.457a.948.948 0 0 1 1.193.567.9.9 0 0 1-.587 1.152c-3.045 1.002-4.679 4.21-3.641 7.153a.9.9 0 0 1-.587 1.151zM10.148 4.78C5.32 6.37 2.73 11.457 4.374 16.121a.9.9 0 0 1-.586 1.152.968.968 0 0 1-.303.049.938.938 0 0 1-.89-.615C.617 11.094 3.733 4.973 9.542 3.062a.949.949 0 0 1 1.193.566.9.9 0 0 1-.587 1.152zm10.82 1.393c4.026 1.325 6.186 5.567 4.814 9.457a.938.938 0 0 1-.889.615.968.968 0 0 1-.303-.049.9.9 0 0 1-.586-1.151c1.037-2.943-.597-6.151-3.642-7.153a.9.9 0 0 1-.587-1.152.948.948 0 0 1 1.193-.567zm7.875 2.321a10.352 10.352 0 0 1 .548 8.213.938.938 0 0 1-.89.615.968.968 0 0 1-.303-.049.9.9 0 0 1-.586-1.152 8.605 8.605 0 0 0-.456-6.826c-1.09-2.142-2.979-3.745-5.318-4.515a.9.9 0 0 1-.586-1.152c.167-.474.7-.728 1.192-.566 2.814.926 5.086 2.855 6.399 5.432z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#bell" />
        </mask>
        <use fillRule="nonzero" xlinkHref="#bell" className={styles[color]} />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Bell.propTypes = {
  ...propTypesSvg,
}

Bell.defaultProps = {
  ...defaultPropTypesSvg,
}

export default Bell
