import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const Instagram = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="instagram"
          d="M21.377 4A6.63 6.63 0 0 1 28 10.623v10.754A6.63 6.63 0 0 1 21.377 28H10.623A6.63 6.63 0 0 1 4 21.377V10.623A6.63 6.63 0 0 1 10.623 4h10.754zm4.494 17.377V10.623a4.5 4.5 0 0 0-4.494-4.494H10.623a4.499 4.499 0 0 0-4.494 4.494v10.754a4.499 4.499 0 0 0 4.494 4.494h10.754a4.499 4.499 0 0 0 4.494-4.494zM16 9.817A6.191 6.191 0 0 1 22.184 16 6.191 6.191 0 0 1 16 22.184 6.191 6.191 0 0 1 9.816 16 6.191 6.191 0 0 1 16 9.816zm0 10.238A4.06 4.06 0 0 0 20.055 16 4.06 4.06 0 0 0 16 11.945 4.06 4.06 0 0 0 11.945 16 4.06 4.06 0 0 0 16 20.055zM22.444 8.01c.411 0 .814.166 1.104.457a1.567 1.567 0 0 1 0 2.209c-.291.29-.693.457-1.104.457-.41 0-.814-.168-1.103-.457a1.574 1.574 0 0 1-.459-1.105 1.568 1.568 0 0 1 1.562-1.562z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#instagram" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#instagram"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Instagram.propTypes = {
  ...propTypesSvg,
}

Instagram.defaultProps = {
  ...defaultPropTypesSvg,
}

export default Instagram
