import { tagConfigurations } from 'service/campaigns/4_TagConfigurations'
import { sanitizerStringAndRemoveSpaces } from 'utils/normalizeString'
import { useCampaigns } from './useCampaigns'

export const useCampaignTags = () => {
  const { isAnActiveCampaign, campaigns } = useCampaigns()

  const tagConfig = (childrenName, styles = {}) => {
    const configurations = tagConfigurations(isAnActiveCampaign, campaigns)
    const tag = configurations[sanitizerStringAndRemoveSpaces(childrenName)]

    const isValidOnRegex = tag && new RegExp(tag.name, 'i').test(childrenName)

    if (!tag || !tag.isShow || !isValidOnRegex)
      return { isAnCampaign: false, tagProps: { styles: {} } }

    return {
      isAnCampaign: true,
      tagProps: {
        newChildren: tag.name,
        styles: {
          backgroundColor: tag.bgColor,
          color: tag.color,
          ...tag.othersStyles,
        },
        closeIconColor: tag.closeIconColor,
        icon: () => tag.icon(styles),
      },
    }
  }

  return { tagConfig }
}
