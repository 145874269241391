import React from 'react'
import { Title } from 'components'
import Carousel from 'react-multi-carousel'
import content from './content'
import styles from './ServicesWeSeek.module.scss'
import 'react-multi-carousel/lib/styles.css'
import responsive from './responsive'

const ServicesWeSeek = () => {
  return (
    <section className={styles.servicesWeSeek}>
      <Title type="h1" color="darkColor">
        Serviços que buscamos
      </Title>
      <Carousel
        additionalTransfrom={0}
        responsive={responsive}
        swipeable
        partialVisible
        arrows
        containerClass={styles.list}
      >
        {content.map(item => (
          <div className={styles.iconContainer}>
            {item.icon()}
            <Title type="h2" color="darkColor">
              {item.title}
            </Title>
          </div>
        ))}
      </Carousel>
    </section>
  )
}

export default ServicesWeSeek
