import React from 'react'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'
import {
  PropertyDataContainer,
  PropertyCardContainer,
  ProposalModalContainer,
  MessageModalContainer,
  VisitScheduleModalContainer,
  SimulatorModalContainer,
} from 'containers'
import { setUtms } from 'utils/Utms'
import {
  useRoot,
  ProposalModalProvider,
  useProperty,
  VisitScheduleModalProvider,
} from 'store'
import { FloatingChatBalloon, WhatsappCTA } from 'components'
import { useCampaigns, useParseUrl } from 'hooks'
import { BaseLayout } from 'templates'
import { LinkUnavailablePage, InternalErrorPage, NotFoundPage } from 'pages'
import { usePageView } from 'hooks/useGAPageView'
import getWindowWidth from 'lib/windowWidth'
import { getEstateBannersCampaign } from 'assets/images/vendor-banners'
import styles from './Property.module.scss'

// eslint-disable-next-line react/prop-types
const PropertyPage = ({
  location: { search },
  city,
  id,
  walletId,
  preview,
}) => {
  usePageView()

  const { getError, setError } = useRoot()

  const { clearPropertyData, getPropertyData } = useProperty()
  let timeoutScrollDown = null
  let oldScrollPosition =
    window.pageYOffset || document.documentElement.scrollTop
  let isScrollingDown = false

  React.useEffect(() => {
    setUtms(search)
  }, [search])

  const data = getPropertyData()

  const isCompraGarantida = data && /compra_garantida/i.test(data.saleCategory)

  const whatsappCallToAction = () => {
    if (!data) return ''

    if (isCompraGarantida) {
      return `https://api.whatsapp.com/send?phone=5519978102614&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20Compra%20Garantida.`
    }

    return ''
  }

  const { campaigns, isAnActiveCampaign } = useCampaigns()

  const isDisputeBot = process.env.REACT_APP_BOT_DISPUTE === 'true'

  const isBotDigitalDispute =
    data && data.tags.includes('Disputa Digital') && isDisputeBot

  const handleScroll = () => {
    if (timeoutScrollDown) {
      clearTimeout(timeoutScrollDown)
    }

    let currentScroll = window.pageYOffset || document.documentElement.scrollTop

    timeoutScrollDown = setTimeout(() => {
      currentScroll = window.pageYOffset || document.documentElement.scrollTop
      timeoutScrollDown = null

      if (isScrollingDown && document.querySelector('#scrolleffect')) {
        document.querySelector('#scrolleffect').classList.add('scrollStopped')
      }
    }, 100)

    if (currentScroll < 120) {
      if (!isScrollingDown && document.querySelector('#scrolleffect')) {
        document
          .querySelector('#scrolleffect')
          .classList.remove('scrollStopped')
      }
    }

    isScrollingDown = currentScroll > oldScrollPosition
    oldScrollPosition = currentScroll
  }

  const handleResize = () => {
    window.removeEventListener('scroll', handleScroll)
    const windowWidth = getWindowWidth()
    if (windowWidth > 1024) {
      window.addEventListener('scroll', handleScroll)
    }
  }

  const { pathname } = window.location
  const UseParseUrl = url => {
    return useParseUrl(url)
  }

  React.useEffect(() => {
    if (pathname.includes('undefined') || pathname.includes('//')) {
      const path = UseParseUrl(window.location.pathname)
      navigate(path)
    }
    if (data) window.localStorage.setItem('propertyReference', data.propertyId)
  }, [data, city, clearPropertyData, id, pathname])

  React.useEffect(() => {
    return () => clearPropertyData()
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    window.scrollTo(0, 0)
    setUtms(search)

    window.addEventListener('resize', handleResize)
    if (process.env.REACT_APP_STAGE === 'prod' && window.fbq) {
      window.fbq('track', 'ViewContent')
    }

    const windowWidth = getWindowWidth()
    if (windowWidth > 1024) {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      setError(null)
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }
  }, []) //eslint-disable-line

  if (getError() === 404) return <NotFoundPage />
  if (getError() === 410) return <LinkUnavailablePage />
  if (getError()) return <InternalErrorPage errorCode={getError()} />

  const banners = getEstateBannersCampaign({
    property: data,
    campaigns,
    isAnActiveCampaign,
  })

  const RenderPropertyBanner = () => {
    if (!banners) return null
    return (
      <figure className={styles.imageContainer}>
        <>
          {banners.desktopLarge && (
            <img
              loading="lazy"
              src={banners.desktopLarge}
              alt="Banner de oferta exclusiva do Banco do Brasil"
              className={styles.bannerImageLarge}
            />
          )}
          <img
            loading="lazy"
            src={banners.desktop}
            alt="Banner de oferta exclusiva do Emgea"
            className={styles.bannerImage}
          />
          <img
            loading="lazy"
            src={banners.mobile}
            alt="Banner de oferta exclusiva do Emgea"
            className={styles.bannerImageMobile}
          />
        </>
      </figure>
    )
  }

  return (
    <BaseLayout backgroundColor="white" preview={preview}>
      <ProposalModalProvider>
        <VisitScheduleModalProvider>
          {data && banners && RenderPropertyBanner()}
          <div className={styles.wrapper}>
            <main className={styles.property}>
              <div className={styles.data}>
                <PropertyDataContainer
                  id={id}
                  preview={preview}
                  walletId={walletId}
                />
              </div>
              <div className={styles.card}>
                <PropertyCardContainer
                  idEffect="scrolleffect"
                  preview={preview}
                />
              </div>
              {isBotDigitalDispute && (
                <FloatingChatBalloon
                  text="Tem dúvidas sobre a Disputa Digital? Clique aqui e saiba mais!"
                  link="https://marketing.resale.com.br/rsl-lp-disputa-digital"
                />
              )}
              <ProposalModalContainer />
              <MessageModalContainer
                locality={data && `${data.city}/${data.state}`}
              />
              <VisitScheduleModalContainer />
            </main>

            {isCompraGarantida && (
              <WhatsappCTA
                message="Precisa de ajuda com esse imóvel?"
                link={whatsappCallToAction()}
              />
            )}
          </div>
        </VisitScheduleModalProvider>
        <SimulatorModalContainer />
      </ProposalModalProvider>
    </BaseLayout>
  )
}
PropertyPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  city: PropTypes.string,
  id: PropTypes.string,
  walletId: PropTypes.string,
  preview: PropTypes.bool, // Add prop type validation for 'preview'
}

PropertyPage.defaultProps = {
  location: {
    search: '',
  },
  city: '',
  id: '',
  walletId: '',
  preview: false, // Add default prop value for 'preview'
}

export default PropertyPage
