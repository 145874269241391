/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Input,
  Modal,
  Text,
  Title,
  Image,
  Optins,
  Select,
} from 'components'
import {
  validateEmail,
  validateFullName,
  validateMandatoryPhone,
} from 'utils/validations'
import mask from 'utils/masks'
import logo from 'assets/images/logo-emgea.png'
import styles from './MessageModal.module.scss'

const options = [
  { value: '', name: '' },
  { value: 'Compra de imóvel', name: 'Compra de imóvel' },
  { value: 'Parcerias', name: 'Parcerias' },
  { value: 'Financiamento', name: 'Financiamento' },
  { value: 'Visita', name: 'Visita' },
  { value: 'SAC', name: 'SAC' },
]

const optionsCategory = [
  { value: '', name: '' },
  {
    value: 'Quero ser um corretor parceiro',
    name: 'Quero ser um corretor parceiro',
  },
  {
    value: 'Já sou parceiro e preciso de suporte',
    name: 'Já sou parceiro e preciso de suporte',
  },
]

const MessageModal = ({
  closeModal,
  contactInfo,
  isLoading,
  opened,
  sendForm,
  setField,
  values,
  locality,
  property,
  idButton,
}) => {
  const [touched, setTouched] = React.useState({})
  const [checkOffers, setCheckOffers] = React.useState(true)
  const [checkOffersResale, setCheckOffersResale] = React.useState(true)

  const { contactButtonLabel } = property

  const TalkToLeiloeiro = /Fale com o leiloeiro/i.test(contactButtonLabel)

  const handleBlur = field => () => setTouched({ ...touched, [field]: true })

  const handleErrorMessage = (value, errorMessage) =>
    value === '' ? 'Campo obrigatório' : errorMessage

  const handleClose = () => {
    setTouched({})
    closeModal()
  }

  const onChange = e => {
    setTouched({ ...touched, [e.target.id]: false })
    setField(e.target.id, e.target.value)
  }

  const callSendForm = async () => {
    setTouched({})
    await sendForm(checkOffers, checkOffersResale)
    setField('message', '')
    setField('subject', '')
  }

  const {
    email,
    message,
    name,
    phone,
    subject,
    category,
    property_locality,
  } = values

  const subjectIsPartnerships = /Parcerias/i.test(subject)
  const buyPartnership = /Compra de imóvel/i.test(subject)

  const isDisabled =
    !validateEmail(email) ||
    !validateFullName(name) ||
    !validateMandatoryPhone(phone) ||
    message === '' ||
    subject === '' ||
    phone === '' ||
    !checkOffers

  useEffect(() => {
    setField('property_locality', locality)
    // eslint-disable-next-line
  }, [locality, opened])

  return (
    <Modal opened={opened} closeModal={handleClose} closeButtonPosition="left">
      <section className={styles.messageModal}>
        <header className={styles.header}>
          <div className={styles.info}>
            <Title type="h1" fontSize="xlarge">
              Tire suas dúvidas
            </Title>
            {contactInfo.name && contactInfo.phone && (
              <Text
                fontSize="medium"
                color="lightTextColor"
                className={styles.contactText}
              >
                {`${contactInfo.name} | ${contactInfo.phone}`}
              </Text>
            )}
          </div>
          {contactInfo.logo &&
            contactInfo.logo !== `${process.env.REACT_APP_CDN}logos/None` && (
              <figure className={styles.logo}>
                <Image
                  alt={contactInfo.name || 'Logo do leiloeiro'}
                  source={`${process.env.REACT_APP_CDN}logos/LOGO%20RESALE%20NOVO.png`}
                  noPhoto={logo}
                />
              </figure>
            )}
        </header>
        <main className={styles.main}>
          <Input
            errorMessage={handleErrorMessage(name, 'Nome completo inválido')}
            onBlur={handleBlur('name')}
            hasError={!validateFullName(name) && touched.name}
            id="name"
            label="Nome completo*"
            onChange={onChange}
            placeholder=""
            value={name}
            className={styles.inputName}
            labelClassName={styles.modalLabel}
          />
          <Input
            errorMessage={handleErrorMessage(email, 'Email Inválido')}
            onBlur={handleBlur('email')}
            hasError={!validateEmail(email) && touched.email}
            id="email"
            label="E-mail*"
            type="email"
            onChange={onChange}
            placeholder=""
            value={email}
            className={styles.inputEmail}
            labelClassName={styles.modalLabel}
          />
          <Input
            errorMessage={handleErrorMessage(phone, 'Telefone Inválido')}
            onBlur={handleBlur('phone')}
            hasError={!validateMandatoryPhone(phone) && touched.phone}
            mask={mask.phone}
            id="phone"
            label="Telefone*"
            onChange={onChange}
            placeholder=""
            value={phone}
            className={styles.inputPhone}
            labelClassName={styles.modalLabel}
          />
          {TalkToLeiloeiro ? (
            <Input
              errorMessage="Selecione um assunto"
              onBlur={handleBlur('subject')}
              hasError={subject === '' && touched.subject}
              id="subject"
              onChange={onChange}
              value={subject}
              label="Assunto*"
              placeholder=""
              className={styles.inputSubject}
              labelClassName={styles.modalLabel}
            />
          ) : (
            <Select
              errorMessage="Selecione um assunto"
              onBlur={handleBlur('subject')}
              hasError={subject === '' && touched.subject}
              id="subject"
              onChange={onChange}
              value={subject}
              options={options}
              className={styles.inputSubject}
              label="Assunto*"
            />
          )}
          {buyPartnership && (
            <Input
              label="Localidade do imóvel"
              id="property_locality"
              onChange={onChange}
              value={property_locality}
              className={styles.inputSubject}
            />
          )}
          {subjectIsPartnerships && (
            <Select
              errorMessage="Selecione uma categoria"
              onBlur={handleBlur('category')}
              hasError={category === '' && touched.category}
              id="category"
              onChange={onChange}
              value={category}
              options={optionsCategory}
              className={styles.inputSubject}
              label="Categoria*"
            />
          )}
          <Input
            errorMessage={handleErrorMessage(message)}
            onBlur={handleBlur('message')}
            hasError={message === '' && touched.message}
            id="message"
            label="Mensagem*"
            onChange={onChange}
            placeholder=""
            value={message}
            className={styles.inputMessage}
            rows={4}
            labelClassName={styles.modalLabel}
          />
          <div className={styles.optins}>
            <Optins
              location="location-MessageModal"
              checkOffers={checkOffers}
              setCheckOffers={setCheckOffers}
              checkOffersResale={checkOffersResale}
              setCheckOffersResale={setCheckOffersResale}
            />
          </div>
        </main>
        <footer className={styles.footer}>
          <Button
            id={idButton}
            onClick={() => callSendForm()}
            type="filled"
            className={styles.button}
            fontSize="medium"
            disabled={isDisabled || isLoading}
            isLoading={isLoading}
          >
            Enviar dúvida
          </Button>
        </footer>
      </section>
    </Modal>
  )
}

MessageModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  contactInfo: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    logo: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  opened: PropTypes.bool.isRequired,
  sendForm: PropTypes.func.isRequired,
  setField: PropTypes.func.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
}

export default React.memo(MessageModal)
