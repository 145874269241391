import React from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Text,
  Button,
  Input,
  NotificationBoxFooter,
  NotificationBox,
  FiltersModal,
  RadioButton,
  Optins,
} from 'components'
import classNames from 'classnames'
import mask from 'utils/masks'
import { validateEmail } from 'utils/validations'
import { origin_device } from 'utils/deviceDetector'
import { FilterContainer } from 'containers'
import { useFilter } from 'store'
import { toastError } from 'components/ToastComponent/ToastComponent'
import styles from './AlertModal.module.scss'

const AlertModal = ({
  opened,
  closeModal,
  values,
  createAlert,
  setField,
  setPurpose,
  isLoading,
  notificationBoxProps: { renderTitle, renderMessage },
  isDisabled,
  urlParams,
}) => {
  const [touched, setTouched] = React.useState({})
  const [expandFilters, setExpandFilters] = React.useState(false)
  const [checkOffers, setCheckOffers] = React.useState(false)

  const {
    $filter: {
      filters: { arrayFilters, booleanFilters },
    },
  } = useFilter()

  const isMobile = origin_device === 'mobile'

  const handleBlur = field => () => setTouched({ ...touched, [field]: true })

  const handleErrorMessage = (value, errorMessage) =>
    value === '' ? 'Campo obrigatório' : errorMessage

  const handleClose = () => {
    setTouched({})
    closeModal()
  }

  const handleConfirm = valuesForm => () => {
    if (isDisabled) {
      toastError(
        'Você precisa selecionar pelo menos um filtro e preencher todos os campos obrigatórios'
      )
      setTouched({ ...touched, email: true, submit: true, phone: true })
    } else {
      createAlert(valuesForm)
      setTouched({})
    }
  }

  const handlePurpose = field => {
    switch (field) {
      case 'both':
        setPurpose('live', true)
        setPurpose('invest', true)
        break

      case 'live':
        setPurpose('live', true)
        setPurpose('invest', false)
        break

      case 'invest':
        setPurpose('live', false)
        setPurpose('invest', true)
        break

      default:
        break
    }
  }

  const customFilters = [
    arrayFilters.localities.key,
    booleanFilters.searchRegion.key,
    booleanFilters.purpose.key,
    'value',
    booleanFilters.propertyType.key,
    booleanFilters.situation.key,
  ]

  const purposes = [
    { label: 'Morar', name: 'live' },
    { label: 'Investir', name: 'invest' },
    { label: 'Ambos', name: 'both' },
  ]

  const handleTextField = e => {
    setTouched({ ...touched, [e.target.id]: false })
    setField(e.target.id, e.target.value)
  }

  return (
    <>
      <Modal
        opened={opened}
        closeModal={handleClose}
        className={styles.modalClass}
        floatPosition="left"
        noOverflowX
        closeButtonPosition="left"
      >
        <section className={styles.alertModal}>
          <NotificationBox
            renderTitle={renderTitle}
            renderMessage={renderMessage}
          />

          <main className={styles.main}>
            <NotificationBoxFooter
              renderTitle={() => (
                <Text color="primaryColor" fontSize="medium" bold>
                  Personalize os filtros para direcionar nossa busca aos imóveis
                  que sejam do seu interesse
                </Text>
              )}
              showFilterButton
              onClick={() => setExpandFilters(prev => !prev)}
            />

            {expandFilters && (
              <section
                className={classNames(styles.filtersContainer, {
                  [styles.expand]: expandFilters,
                })}
              >
                <hr className={styles.horizontalLine} />
                <FilterContainer
                  urlParams={urlParams}
                  customFilters={customFilters}
                  type="alertModal"
                  showHeader
                />
                <hr className={styles.horizontalLine} />
              </section>
            )}

            <section className={styles.purposeSection}>
              <Text color="primaryColor" fontSize="medium" bold>
                Você deseja buscar imóveis para:
              </Text>
              <div className={styles.purposes}>
                <RadioButton
                  options={purposes}
                  onChange={value => handlePurpose(value)}
                  state={values.purpose}
                />
              </div>
            </section>
            <section className={styles.contactSection}>
              <Text color="primaryColor" fontSize="medium" bold>
                Onde gostaria de receber as ofertas?
              </Text>
              <Input
                errorMessage={handleErrorMessage(
                  values.phone,
                  'Celular inválido'
                )}
                onBlur={handleBlur('email')}
                hasError={!validateEmail(values.email) && touched.email}
                id="email"
                type="email"
                placeholder="email@email.com"
                label={isMobile ? 'Seu e-mail*' : null}
                value={values.email}
                onChange={handleTextField}
                labelTextColor="darkBlueColor"
                className={styles.alertInput}
                data-testid="emailInput"
              />

              <section
                className={classNames(styles.inputPhone, styles.alertInput, {
                  [styles.expand]: values.whatsapp,
                })}
              >
                <Input
                  errorMessage={handleErrorMessage(
                    values.phone,
                    'Celular inválido'
                  )}
                  onBlur={handleBlur('phone')}
                  hasError={
                    values.whatsapp &&
                    touched.phone &&
                    values.phone.replace(/[^0-9]/g, '').length !== 11
                  }
                  id="phone"
                  type="text"
                  label={isMobile ? 'Seu telefone celular' : null}
                  value={values.phone}
                  placeholder="(11) 99999-9999"
                  mask={mask.phone}
                  onChange={handleTextField}
                  labelTextColor="darkBlueColor"
                />
              </section>
            </section>

            <Optins
              checkOffers={checkOffers}
              setCheckOffers={setCheckOffers}
              location="alert"
            />
          </main>

          <footer className={styles.footer}>
            <Text fontSize="xxsmall" color="lightBlueColor">
              Fique tranquilo, você poderá cancelar esse alerta a qualquer
              momento.
            </Text>

            {isMobile && <hr />}

            <Button
              id="btn_criar_alerta"
              onClick={handleConfirm(values)}
              fontSize="medium"
              className={styles.submit}
              disabled={isLoading || !checkOffers}
              isLoading={isLoading}
              redColor
            >
              Criar alerta
            </Button>
          </footer>
        </section>
      </Modal>

      {expandFilters && (
        <div className={classNames(styles.filtersContainerMobile)}>
          <FiltersModal
            urlParams={urlParams}
            opened={expandFilters}
            closeModal={() => setExpandFilters(false)}
            customFilters={customFilters}
            type="alertModal"
          />
        </div>
      )}
    </>
  )
}

AlertModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  values: PropTypes.shape({
    email: PropTypes.string.isRequired,
    periodicity: PropTypes.shape({}).isRequired,
    whatsapp: PropTypes.bool.isRequired,
    phone: PropTypes.string.isRequired,
    purpose: PropTypes.shape({
      live: PropTypes.bool.isRequired,
      invest: PropTypes.bool.isRequired,
    }),
  }).isRequired,
  createAlert: PropTypes.func.isRequired,
  setField: PropTypes.func.isRequired,
  setPurpose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  notificationBoxProps: PropTypes.shape({
    renderTitle: PropTypes.func.isRequired,
    renderMessage: PropTypes.func.isRequired,
  }).isRequired,
  periodicityProps: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        checked: PropTypes.bool,
      })
    ),
  }),
  isDisabled: PropTypes.func.isRequired,
  urlParams: PropTypes.string.isRequired,
}

AlertModal.defaultProps = {
  periodicityProps: {},
}

export default React.memo(AlertModal)
