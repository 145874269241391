import { useState } from 'react'
import { getCustomerInfoLocalStorage } from 'utils/customerInfoLocalStorage'
import useCreateStore from '../../lib/useCreateStore'
import messageModalActions from './actions'
import messageModalSelectors from './selectors'

const initialState = {
  form: {
    name: getCustomerInfoLocalStorage().name || '',
    email: getCustomerInfoLocalStorage().email || '',
    phone: getCustomerInfoLocalStorage().phone || '',
    subject: '',
    category: '',
    message: '',
    property_locality: '',
  },
  openModal: false,
}

const MessageModalStore = useCreateStore(() => {
  const [$messageModal, setMessageModal] = useState(initialState)

  const actions = messageModalActions(setMessageModal)
  const selectors = messageModalSelectors($messageModal)

  return { $messageModal, ...actions, ...selectors }
})

export const useMessageModal = () => MessageModalStore()
export const MessageModalContext = MessageModalStore.Context
export const MessageModalProvider = MessageModalStore.Provider
