import React from 'react'
import PropTypes from 'prop-types'
import { Text, Icon } from 'components/'
import styles from './BannerIcons.module.scss'

const BannerIcons = ({ selectedItem, handleClick, menu }) => {
  const renderIcons = () => {
    return menu.map(({ icon, description }) => (
      <button
        className={styles.button}
        type="button"
        onClick={() => handleClick(icon)}
        key={icon}
      >
        <Icon
          type={icon}
          size={32}
          color={selectedItem === icon ? 'primaryColor' : 'grayColor'}
        />
        <Text
          color={selectedItem === icon ? 'primaryColor' : 'grayColor'}
          bold
          fontSize="medium"
        >
          {description}
        </Text>
      </button>
    ))
  }

  return <div className={styles.bannerIconWrapper}>{renderIcons()}</div>
}

BannerIcons.propTypes = {
  selectedItem: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default React.memo(BannerIcons)
