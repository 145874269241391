import api, { pagImovelApi } from 'service/api'
import { to } from 'nh-utils'
import { origin_device } from 'utils/deviceDetector'

export const sendForm = ({
  propertyId,
  form,
  send_mail_emgea,
  send_mail_resale,
  send_mail_pgi,
  tokenCaptcha,
}) => {
  const payload = {
    ...form,
    send_mail_emgea,
    send_mail_resale,
    send_mail_pgi,
    tokenCaptcha,
    origin_device,
  }

  return to(api.post(`/${propertyId}/opportunity`, payload))
}

export const sendRecoverPassword = email => {
  return to(
    pagImovelApi.post(`/welcome/login/forgot_password?email=${email.email}`)
  )
}
