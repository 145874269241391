import React from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import PropTypes from 'prop-types'
import { Tag } from 'components'
import classNames from 'classnames'

import styles from './Localities.module.scss'

function Localities({ localities, removeLocality, carrouselMode }) {
  if (!localities) {
    return null
  }

  return (
    <div
      className={classNames(styles.container, {
        [styles.carrouselMode]: carrouselMode,
      })}
    >
      <ScrollContainer className={styles.scrollContainer} vertical={false}>
        {localities &&
          localities[0] &&
          localities.map((locality, index) => (
            <Tag
              key={locality.name}
              onClick={() => removeLocality('localities', index)}
            >
              {locality.name}
            </Tag>
          ))}
      </ScrollContainer>
    </div>
  )
}

Localities.propTypes = {
  localities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  removeLocality: PropTypes.func.isRequired,
  carrouselMode: PropTypes.bool,
}

Localities.defaultProps = {
  carrouselMode: false,
}

export default Localities
