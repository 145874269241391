import { toastError } from 'components/ToastComponent/ToastComponent'
import api, { cityApi } from 'service/api'
import { to } from 'nh-utils'
import { origin_device } from 'utils/deviceDetector'

export const fetchProperty = async (id, preview, walletId) => {
  if (!id) {
    return null
  }

  let url = preview ? `/${id}?preview=1` : `/${id}`
  if (walletId && preview) {
    url.concat(`&wallet-id=${walletId}`)
  } else if (walletId) {
    url = url.concat(`?wallet-id=${walletId}`)
  }

  const [err, res] = await to(api.get(url))

  if (res.problem || err) {
    toastError('Erro ao buscar propriedade.')
    return { error: true, code: err || res.status || 500 }
  }
  const { data } = res.data

  return data
}
export const sendAuctionForm = ({
  propertyId,
  email,
  checkOffers,
  checkOffersResale,
}) => {
  const payload = {
    lead_mail: email,
    send_mail_bb: checkOffers,
    send_mail_resale: checkOffersResale,
    origin_device,
    propertyId,
  }
  return to(cityApi.post(`/property/${propertyId}/lead`, payload))
}
