/* eslint-disable react/prop-types */
import React from 'react'
import Slider from 'react-slick'
import MagicSliderDots from 'react-magic-slider-dots'
import 'react-magic-slider-dots/dist/magic-dots.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-multi-carousel/lib/styles.css'
import PropTypes from 'prop-types'
import { Modal, Image } from 'components'
import { SALE_CAMPAIGNS, getModalImages } from 'service/campaigns'
import normalizeString from 'utils/normalizeString'
import { useCountDown, useCampaigns } from 'hooks'
import { extractBanners } from 'utils/bannersDictionary'
import styles from './ModalCampaign.module.scss'

export const CustomRightArrow = ({ onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
    />
  )
}

export const CustomLeftArrow = ({ onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
    />
  )
}

const ModalCampaign = ({ opened, closeModal, onClick }) => {
  const { isAnActiveCampaign, campaigns } = useCampaigns()
  const bannersOrder = Object.entries(campaigns)
    .sort(([, a], [, b]) => a.bannerOrder - b.bannerOrder)
    .map(([key]) => normalizeString(key))
  const campaignsBanners = extractBanners(campaigns, 'popup')

  const { imagesModalCampaign } = getModalImages({
    bannersOrder,
    campaignsBanners,
  })

  const hasModalCampaign = () => !!imagesModalCampaign.length

  const titleCountDown = isAnActiveCampaign(SALE_CAMPAIGNS.preBlackout)
    ? 'Para o início da campanha. Prepare-se!'
    : ''

  const { renderCountDown } = useCountDown({
    styles,
    titleCountDown,
    callOn: 'ModalCampaign',
  })

  const settings = {
    autoplay: true,
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => {
      return <MagicSliderDots dots={dots} numDotsToShow={3} dotWidth={30} />
    },
    nextArrow: <CustomRightArrow />,
    prevArrow: <CustomLeftArrow />,
  }

  return (
    !!imagesModalCampaign.length && (
      <Modal
        opened={opened && hasModalCampaign()}
        closeModal={() => closeModal(false)}
        className={styles.modalCamp}
        closeClassName={styles.closeButtonCamp}
        isCampaign
      >
        <Slider {...settings}>
          {imagesModalCampaign.map(
            ({ src = '', srcMobile = '', link = '', name }) => (
              <div>
                <Image
                  className={styles.slide}
                  sourceMobile={srcMobile}
                  source={src}
                  link={link}
                  onClick={name === 'EsquentaBlackoutEmgea' && onClick}
                />
                {renderCountDown({ nameCampaign: name })}
              </div>
            )
          )}
        </Slider>
      </Modal>
    )
  )
}

ModalCampaign.propTypes = {
  closeModal: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
}

CustomLeftArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
}

CustomRightArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
}
export default React.memo(ModalCampaign)
