import { SALE_CAMPAIGNS } from './1_SaleCampaigns'

export const parseTags = (tags, isAnActiveCampaign) => {
  let newTags = [...tags]

  const corujaoPosition = newTags.findIndex(value => /Coruj[aã]o/i.test(value))
  const cashbackPosition = newTags.findIndex(value => /Cashback/i.test(value))
  const novemberMagicPosition = newTags.findIndex(value =>
    /Novembro Mágico/i.test(value)
  )

  if (
    SALE_CAMPAIGNS.cashback.test(newTags[cashbackPosition]) &&
    isAnActiveCampaign(SALE_CAMPAIGNS.blackout)
  ) {
    newTags.splice(cashbackPosition, 1, 'Blackout')
  }

  if (
    SALE_CAMPAIGNS.corujao.test(newTags[corujaoPosition]) &&
    isAnActiveCampaign(SALE_CAMPAIGNS.blackoutDay)
  ) {
    newTags.splice(corujaoPosition, 1, 'BlackoutDay')
  }

  if (isAnActiveCampaign(SALE_CAMPAIGNS.megaFeiraoEmgea)) {
    newTags.splice(0, 0, 'MegaFeiraoEmgea')
  }

  if (
    novemberMagicPosition !== -1 &&
    isAnActiveCampaign(SALE_CAMPAIGNS.blackout)
  ) {
    newTags.splice(corujaoPosition, 1, '')
    newTags.splice(novemberMagicPosition, 1, '')
    newTags = ['BLACKOUT', ...newTags]
  }

  return newTags
}
