import React from 'react'
import PropTypes from 'prop-types'

import ErrorPage, { errorLinks } from './ErrorPage'

const InternalError = ({ errorCode }) => {
  const internalErrorInfo = {
    code: errorCode,
    title:
      errorCode === 404 ? 'Página não encontrada' : 'Problemas no servidor',
    body: `
      Ei, esta página não está disponível!
      Separamos alguns links que podem te ajudar:
    `,
    links: errorLinks,
  }

  return <ErrorPage {...internalErrorInfo} />
}

InternalError.propTypes = {
  errorCode: PropTypes.number.isRequired,
}

export default InternalError
