import React from 'react'
import PropTypes from 'prop-types'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const stage = ({ viewBox, color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="stage"
          d="M18.757 11.828l3.071-3.07a5 5 0 1 1 1.414 1.414l-3.07 3.07a5 5 0 0 1-6.929 6.929l-3.071 3.072a5 5 0 1 1-1.414-1.414l3.07-3.072a5 5 0 0 1 6.929-6.929zM16 19a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM6 29a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM26 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#stage" />
        </mask>
        <use className={styles[color]} fillRule="nonzero" xlinkHref="#stage" />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

stage.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ...propTypesSvg,
}

stage.defaultProps = {
  size: 'auto',
  ...defaultPropTypesSvg,
}

export default stage
