import parseFeatures from 'utils/parseFeatures'
import parseExpenses from 'utils/parseExpenses'
import handleTags from 'utils/tags'
import isConcurrenceProperty from 'utils/isConcurrenceProperty'
import {
  isAuctionCheck,
  isSacre,
  setValuesAndConsiderations,
  setFinancingConditions,
  setPaymentMethod,
  setAuctionAndCTALabels,
  transformPendingTexts,
  formatDateToPreActive,
} from './utils'

export default ({ $property }) => {
  const getPropertyData = () => $property.data
  const getPropertyId = () => $property.data?.propertyId || ''
  const getPropertySmallId = () => $property.data.propertySmallId || ''
  const getLoadingProperty = () => $property.loadingProperty
  const getContactInfo = () => $property.data?.contactInfo
  const getPaymentConditions = () => $property.data?.paymentConditions
  const getFinancingValue = () => ({
    minimumInput: $property.data?.minimumInput || '',
    salePrice: $property.data ? parseInt($property.data.salePrice, 10) : '',
  })
  const getSaleType = () => {
    if (!$property.data) return null
    if ($property.data.isAuction) return 'auction'
    if ($property.data.tags.some(tag => tag === 'Venda direta')) return 'direct'
    if ($property.data.tags.some(tag => tag === 'Imobiliária'))
      return 'realestate'
    return null
  }
  const getSaleTypeProperty = () => $property.data?.saleType

  return {
    getPropertyData,
    getPropertyId,
    getPropertySmallId,
    getContactInfo,
    getPaymentConditions,
    getLoadingProperty,
    getFinancingValue,
    getSaleType,
    getSaleTypeProperty,
  }
}

export const serializeProperty = data => {
  const {
    aceita_visita,
    caracteristicas,
    areas,
    despesas,
    valores,
    tipo_de_venda,
    data_limite,
    datas_leilao,
    call_to_action_url,
    call_to_actions,
    carteira,
    tags,
    status_da_venda,
    recebeu_oferta,
    conditions,
    pre_ativo_expiracao,
  } = data

  const { condicoes, tipo_comissao, quem_paga_comissao } = carteira

  const newExpenses = parseExpenses(despesas)
  const newFeatures = parseFeatures(caracteristicas, areas)
  const movie = data.video ? data.video.replace('watch?v=', 'embed/') : null
  const showVisit = /sim/i.test(aceita_visita)
  const salePrice = data.valores.valor_venda
  const saleLabel = isConcurrenceProperty(handleTags({ tags }))
    ? 'Proposta Mínima'
    : data.valores.valor_venda_label
  const mainValueLabel = isConcurrenceProperty(handleTags({ tags }))
    ? 'Proposta Mínima'
    : valores.valor_venda_label
  const valor_exibido = carteira.valor_exibido || ''

  let callToActionConsiderations = [`* ${data.consideracoes_extra}`]
  let formas_pagamento_card = []

  const isPreActive = !!pre_ativo_expiracao
  const isPreActiveExpired =
    pre_ativo_expiracao &&
    new Date().getTime() >=
      new Date(formatDateToPreActive(pre_ativo_expiracao)).getTime()

  const {
    callToActionLabel,
    showContactUs,
    contactButtonLabel,
    showAuctionDate,
    showMultipleAuctionDate,
    auctionInfo,
    callToActionUrl,
    isAuction,
    isConcurrenceOver,
    isPublicCompetition,
    activeContactUs,
  } = setAuctionAndCTALabels({
    tipo_de_venda,
    data_limite,
    datas_leilao,
    call_to_action_url,
    call_to_actions,
    status_da_venda,
  })

  const {
    hideConditions,
    showDiscount,
    hideValue,
    showProposal,
    mainValue,
    callToActionConsiderationsIn,
  } = setValuesAndConsiderations(
    carteira,
    valores,
    callToActionConsiderations,
    formas_pagamento_card
  )
  callToActionConsiderations = callToActionConsiderationsIn

  const { formsInstallment, isDirectInstallment } = setPaymentMethod({
    valores,
    condicoes,
    formas_pagamento_card,
  })
  formas_pagamento_card = [...formsInstallment]

  const fullContactButton = showContactUs && !showVisit

  const {
    showFinanceEstate,
    formasPagamentoIn,
    financialText,
  } = setFinancingConditions(condicoes, formas_pagamento_card)
  formas_pagamento_card = [...formasPagamentoIn]

  const tipoVenda = tipo_de_venda.toLowerCase()
  if (mainValueLabel === 'Lance mínimo' && !isAuctionCheck(tipoVenda)) {
    if (
      tipo_comissao.toLowerCase() === 'incorporada' &&
      quem_paga_comissao.toLowerCase() === 'comprador'
    ) {
      callToActionConsiderations.push(
        `*No valor do imóvel já estão embutidos os honorários a serem pagos pelo comprador`
      )
    }
  }

  if (valor_exibido.toLowerCase() === 'nao_exibir') {
    callToActionConsiderations = []
  }

  const isJudicialSale = data.categoria_venda === 'venda_judicial'
  const isFriendlySale = data.categoria_venda === 'compra_garantida'
  const images = data.imagens.map((img, index) => ({
    url: img.url,
    alt: `imagem ${index + 1} do imóvel ${data.imovel_name}`,
  }))
  const wasClosed = /encerrado/i.test(status_da_venda)
  const wasSold = /vendido/i.test(status_da_venda)

  const initialTags = data.tags || []

  const arrayTags = [...initialTags].filter(tag => !!tag)
  const isBestProposal = /melhor( |_)proposta/i.test(tipo_de_venda)

  const property = {
    propertyId: data.id,
    pendingCategory: transformPendingTexts(data.categoria_pendencias),
    propertySmallId: data.id_do_imovel,
    propertyName: data.imovel_name,
    address: data.endereco.endereco_completo,
    city: data.endereco.cidade,
    state: data.endereco.uf,
    saleCategory: data.categoria_venda,
    saleType: data.tipo_de_venda,
    campaignInfo: {
      values: {
        campaignValue: data.valores.valor_campanha,
        campaignTotalValue: data.valores.valor_total_campanha,
      },
      dates: {
        campaignStartDate: data.data_inicio_campanha,
        campaignEndData: data.data_fim_campanha,
      },
    },
    juridical: {
      updatedAt: data.juridico.atualizado_em,
      scorePoints: data.juridico.pontuacao_score,
      status: data.juridico.status_processo_principal,
      processStatus: data.juridico.status_processo_principal,
      contencioso: data.juridico.contencioso,
      judicialProcessTimeline: data.juridico.timeline_processo_judicial,
    },
    tags: handleTags({ tags: arrayTags }),
    description: data.descricao,
    considerations: data.consideracoes_importantes,
    appraisedPrice: data.valores.valor_avaliado,
    appraisedLabel: data.valores.valor_avaliado_label,
    minimumInput: condicoes?.financiamento_imobiliario?.entrada_minima || '',
    salePrice,
    saleLabel,
    mainValue: Math.round(mainValue),
    mainValueWithoutComission: data.valores.valor_sem_comissao,
    minInstallmentEntry: condicoes?.parcelamento_direto?.entrada_minima || 0,
    mainValueLabel,
    forwardPrice: data.valores.valor_a_prazo,
    cashPrice: data.valores.valor_a_vista,
    commissionAmount:
      data.carteira.taxa_comissao == null
        ? 0
        : data.carteira.taxa_comissao / 100,
    expenses: newExpenses,
    isSacre: isSacre(data.carteira.condicoes),
    debts: data.dividas,
    stage: data.estagio_retorno,
    images: images || [],
    location: `${data.mapa.lat}, ${data.mapa.long}`,
    paymentConditions: data.formas_pagamento,
    registration: data.matricula,
    rgi: data.rgi,
    cityHallRegistration: data.inscricao_prefeitura,
    bankId: data.id_banco,
    features: newFeatures,
    contactInfo: data.canal_atendimento,
    movie,
    documents: data.documents,
    callToActionConsiderations,
    showDiscount,
    hideValue,
    desagio: data.desagio,
    activeContactUs,
    showProposal,
    showVisit,
    showContactUs,
    financialText,
    fullContactButton,
    contactButtonLabel,
    callToActionLabel,
    callToActionUrl,
    showFinanceEstate,
    isDirectInstallment,
    showAuctionDate,
    showMultipleAuctionDate,
    auctionInfo,
    isConcurrenceOver,
    hideConditions,
    formas_pagamento_card,
    salesman: data.vendedor,
    map: data.mapa,
    isAuction,
    isPublicCompetition,
    acceptLow: condicoes?.financiamento_imobiliario?.aceita_abaixo === 'sim',
    externalTour: data.url_tour_externo || '',
    internalTour: data.url_tour_interno || '',
    offerReceived: recebeu_oferta,
    conditions,
    category: data.tipo_imovel,
    videos: data.videos,
    document: data.documento_regulamentacao,
    importantRemarks: data.observacoes_importantes,
    isJudicialSale,
    isFriendlySale,
    preActive: isPreActive && !isPreActiveExpired,
    preActiveExpiration: pre_ativo_expiracao,
    wasClosed,
    wasSold,
    isBestProposal,
    coupon: carteira.cupom,
    campaignsData: carteira?.campanhas || [],
    purpose: data.finalidade,
  }
  return property
}
