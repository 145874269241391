import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const Kitchen = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="kitchen"
          d="M27.54 19c1.494 0 2.71 1.205 2.71 2.688 0 1.482-1.216 2.687-2.71 2.687h-.865a16.47 16.47 0 0 1-.705 2.542c-.333.915-1.2 2.708-.975 2.181l-.065.152H7.07l-.065-.152c.226.528-.642-1.265-.976-2.182a16.57 16.57 0 0 1-.704-2.541h-.864c-1.495 0-2.711-1.205-2.711-2.688C1.75 20.206 2.966 19 4.46 19h.571v-1.125h-.64a1 1 0 0 1-1-1v-.125a1 1 0 0 1 1-1h.772c.53-2.184 2.35-4.25 4.22-4.25h2.899a7.31 7.31 0 0 1 1.007-.765V9.313c0-1.483 1.217-2.688 2.711-2.688 1.494 0 2.71 1.205 2.71 2.688v1.422a7.31 7.31 0 0 1 1.008.765h2.9c1.87 0 3.688 2.066 4.219 4.25h.772a1 1 0 0 1 1 1v.125a1 1 0 0 1-1 1h-.64V19h.57zM16.57 9.312A.567.567 0 0 0 16 8.75a.567.567 0 0 0-.57.563v.602a4.164 4.164 0 0 1 1.14 0v-.602zm6.047 4.313h-3.702l-.186-.082-.246-.273C17.965 12.686 16.969 12 16 12c-.964 0-1.945.675-2.485 1.272l-.244.27-.186.083H9.383c-.682 0-1.47.97-1.955 2.125h17.144c-.486-1.155-1.273-2.125-1.955-2.125zm-18.156 7.5a.567.567 0 0 0-.57.563c0 .309.255.562.57.562h.598a16 16 0 0 1-.028-.946v-.179h-.57zm20.367.179v-3.429H7.172v3.429c0 1.994.43 3.994 1.248 5.821h15.16a14.297 14.297 0 0 0 1.248-5.821zm2.113.946h.598c.315 0 .57-.253.57-.563a.567.567 0 0 0-.57-.562h-.57v.179a16 16 0 0 1-.028.946zM23.438 5.5c-.768 0-1.391.618-1.391 1.375a.25.25 0 0 1-.25.25h-1.64a.25.25 0 0 1-.25-.25c0-1.93 1.583-3.5 3.53-3.5h1.641c.767 0 1.39-.618 1.39-1.375a.25.25 0 0 1 .25-.25h1.641a.25.25 0 0 1 .25.25c0 1.93-1.584 3.5-3.53 3.5h-1.642zm3.28 1.125c.768 0 1.391-.618 1.391-1.375a.25.25 0 0 1 .25-.25H30a.25.25 0 0 1 .25.25c0 1.93-1.584 3.5-3.531 3.5h-1.64c-.768 0-1.392.618-1.392 1.375a.25.25 0 0 1-.25.25h-1.64a.25.25 0 0 1-.25-.25c0-1.93 1.584-3.5 3.531-3.5h1.64z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#kitchen" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#kitchen"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Kitchen.propTypes = {
  ...propTypesSvg,
}

Kitchen.defaultProps = {
  ...defaultPropTypesSvg,
}

export default Kitchen
