import React from 'react'
import rosie from 'assets/images/rosie-chat.png'
import PropTypes from 'prop-types'
import styles from './NotificationBox.module.scss'

const NotificationBox = ({
  renderTitle,
  renderMessage,
  renderInput,
  renderButton,
}) => {
  return (
    <div className={styles.container}>
      <figure className={styles.figure}>
        <div className={styles.imgContainer}>
          <img
            loading="lazy"
            className={styles.img}
            alt="Logo de nossa assistente virtual"
            src={rosie}
          />
        </div>
      </figure>
      <div className={styles.body}>
        {renderTitle && renderTitle()}
        <div className={styles.info}>
          {renderMessage && renderMessage()}
          {renderInput && renderInput(styles.emailInput)}
          {renderButton && renderButton(styles.buttonSubmit)}
        </div>
      </div>
    </div>
  )
}

NotificationBox.propTypes = {
  renderTitle: PropTypes.func,
  renderMessage: PropTypes.func.isRequired,
  renderInput: PropTypes.func,
  renderButton: PropTypes.func,
}

NotificationBox.defaultProps = {
  renderTitle: null,
  renderInput: null,
  renderButton: null,
}

export default NotificationBox
