import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg } from './propTypes'

const StreetView = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="streetView"
          d="M14.04 14.552v9.398c0 .675.432 1.222.965 1.222h1.931c.533 0 .966-.547.966-1.222v-9.398h1.931v3.79h.965v-5.266c-1.4-.349-3.03-.527-4.887-.527-1.836 0-3.426.174-4.768.514v5.28h.966v-3.791h1.93zm5.793 5.722v3.933c0 1.6-1.297 2.896-2.897 2.896h-1.93a2.897 2.897 0 0 1-2.897-2.896v-3.933h-.966a1.931 1.931 0 0 1-1.93-1.931v-5.28c0-.884.6-1.655 1.456-1.872 1.508-.382 3.255-.573 5.242-.573 2.006 0 3.791.195 5.354.584.86.215 1.464.988 1.464 1.874v5.267a1.931 1.931 0 0 1-1.93 1.93h-.966zm-9.655 1.67v2.008c-.25.07-.488.145-.716.225-1.45.507-2.181 1.113-2.181 1.478 0 .366.731.97 2.181 1.478 1.69.592 4.016.936 6.509.936 2.493 0 4.819-.344 6.508-.936 1.45-.507 2.181-1.112 2.181-1.478 0-.365-.731-.97-2.181-1.478-.227-.08-.466-.155-.715-.225v-2.007c2.903.718 4.827 2.011 4.827 3.71 0 2.675-4.77 4.345-10.62 4.345S5.35 28.33 5.35 25.655c0-1.699 1.924-2.992 4.828-3.71zm5.793-12.22a3.862 3.862 0 1 1 0-7.724 3.862 3.862 0 0 1 0 7.724zm0-1.93a1.931 1.931 0 1 0 0-3.863 1.931 1.931 0 0 0 0 3.862z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#streetView" />
        </mask>
        <use
          className={styles[color]}
          // transform="rotate(45 16.04 14.04)"
          xlinkHref="#streetView"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

StreetView.propTypes = {
  ...propTypesSvg,
}

StreetView.defaultProps = {
  viewBox: '0 0 32 32',
  color: 'grayColor',
}

export default StreetView
