export default {
  title: 'Perguntas frequentes',
  questions: [
    {
      title: '1. Qual o vínculo da Resale com os imóveis da Emgea?',
      body: `
      A Resale, o Maior Outlet Imobiliário do Brasil, é a empresa responsável pelo site emgeaimoveis.com.br. Os imóveis para venda são de propriedade da Emgea (Empresa Gestora de Ativos) do Governo Federal, disponíveis para compra direta pelo consumidor final.`,
    },
    {
      title: '2. Como funciona o site Imóveis Emgea?',
      body: `O site reúne os imóveis disponíveis para venda que podem ser comprados diretamente da Emgea, seja na modalidade de venda direta, imobiliárias, corretores ou em leilões realizados periodicamente.`,
    },
    {
      title: '3. Quais tipos de imóveis são vendidos no site?',
      body: `Estão disponíveis para venda imóveis de propriedade da Emgea, sem dívidas e 100% quitados. Entre esses imóveis estão residenciais, comerciais, rurais e até imóveis de uso da União que não são mais utilizados. Alguns estão desocupados e outros ainda ocupados por ex-mutuários. Nesse último caso, o desconto é maior exatamente por esse motivo, tendo em vista que o processo de desocupação fica por conta do comprador.`,
    },
    {
      title: '4. Quais as condições de pagamento disponíveis?',
      body: `Os imóveis da Emgea poderão ser comprados à vista. Não será possível, por enquanto, a compra com financiamento imobiliário ou utilizando FGTS.`,
    },
    {
      title: '5. Como funciona o processo de compra?',
      body: `Todas as vendas acontecem por meio da Pagimovel®, uma plataforma transacional imobiliária que garante segurança, transparência e agilidade em todo o processo de compra e venda de imóveis, da proposta até o registro.`,
    },
    {
      title: '6. Entenda os diferentes tipos de compra:',
      body: `<b>Via plataforma (direto c/ banco):</b> Os imóveis nesta modalidade podem ser comprados 100% dentro do site emgeaimoveis.com.br e contam com a assessoria especializada do nosso time comercial.
      <b>Via Leiloeiros (sites externos):</b> Os processos de lance e compra são realizados diretamente no site do leiloeiro, sendo o pós-vendas realizado pela Plataforma Pagimovel®.
      <b>Via Imobiliárias (corretores parceiros):</b> Nessa modalidade você terá a assessoria de corretores parceiros, que irão lhe auxiliar no processo de compra, sendo o pós-vendas realizado pela Plataforma Pagimovel®.`,
    },
    {
      title:
        '7. A descrição e as fotos do imóvel refletem a situação atual do mesmo?',
      body: `A descrição, fotos e áreas informadas são as encontradas na matrícula do imóvel e/ou laudos de avaliação e podem não refletir a situação atual do imóvel. O mesmo vale para fotos obtidas por meio do serviço Street View, do Google.`,
    },
    {
      title:
        '8. Quando um imóvel consta como ocupado, quem é responsável pela desocupação?',
      body: `A desocupação do imóvel será de total responsabilidade dos compradores, exceto nos casos onde conste informação diferente no campo "observações importantes". Por este motivo, imóveis ocupados tendem a ter um valor de venda menor do que aqueles desocupados.`,
    },
    {
      title: '9. Como funciona o processo de desocupação do imóvel?',
      body: `A desocupação é um processo judicial que precisa ser iniciado ou continuado pelo comprador do imóvel, que têm legitimidade para tal em função de ter adquirido a propriedade do mesmo. A assessoria de um advogado é sempre recomendada para que esse processo seja mais rápido e com menos riscos de exposição pessoal.`,
    },
    {
      title:
        '10. Os moradores podem se negar a desocupar o imóvel (alienação fiduciária)?',
      body: `É natural que os ex-mutuários resistam a uma desocupação do imóvel e, até mesmo, se recusem a fazê-lo. No entanto, a Lei é clara e será cumprida pelo Judiciário, como vem ocorrendo desde 1997, quando foi instituída a alienação fiduciária para os contratos de financiamento imobiliário no Brasil.
      Para entender melhor essa questão, recomendamos a <a href="https://globoplay.globo.com/v/5282746/" target="_blank" rel="noopener noreferrer">Entrevista</a> com o desembargador Werson Rego, no programa Mais Você, da Rede Globo.`,
    },
  ],
}
