const { GoogleSpreadsheet } = require('google-spreadsheet')

const credentials = require('./leads-esquenta-black-2022-c89ff5bb0477.json')

const ID_SPREADSHEET = '182Ig2IOWoouKfGji5C8UxM6q5wl6fEs6Xg60YzKa1Yk'

export const SpreadsheetApiEsquentaBlackout = async () => {
  const doc = new GoogleSpreadsheet(ID_SPREADSHEET)
  await doc.useServiceAccountAuth(credentials)
  await doc.loadInfo()
  return doc
}
