import { gaEvent } from 'utils/ga'
import { toastError } from 'components/ToastComponent/ToastComponent'
import * as providers from './provider'

export default setMessageForm => {
  const setField = (field, value) => {
    setMessageForm(prev => ({
      ...prev,
      form: { ...prev.form, [field]: value },
    }))
  }

  const clearForm = () =>
    setMessageForm(prev => ({
      ...prev,
      form: {
        name: '',
        email: window.localStorage.getItem('emailResale') || '',
        phone: '',
        subject: '',
        message: '',
        property_locality: '',
      },
    }))

  const sendForm = async form => {
    setMessageForm(prev => ({
      ...prev,
      sendFormLoading: true,
    }))

    const [err] = await providers.sendForm(form)

    if (err) {
      toastError('Houve um erro no envio da mensagem.')
      return setMessageForm(prev => ({
        ...prev,
        sendFormError: true,
        sendFormLoading: false,
      }))
    }

    gaEvent({
      category: 'L_contato_Home',
      action: 'submit',
      label: form.email,
    })

    return setMessageForm(prev => ({
      ...prev,
      sendFormLoading: false,
    }))
  }

  return {
    setField,
    sendForm,
    clearForm,
  }
}
