/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import CONSTANTS from 'utils/systemsConstants'
import { CategoryTitle, Tag, Text, Title } from 'components'
import styles from './PropertyIntroduction.module.scss'
import { BannerSection } from '..'

const PropertyIntroduction = ({
  propertyData,
  campaigns,
  campaignsData,
  coupon,
  isAnActiveCampaign,
}) => {
  const { CONCORRENCIA } = CONSTANTS

  const {
    isJudicialSale,
    isFriendlySale,
    tags,
    propertySmallId,
    propertyName,
    address,
    preActive,
  } = propertyData

  if (isJudicialSale) {
    tags.splice(1, 0, 'Venda Judicial')
  }
  if (isFriendlySale) {
    tags.splice(1, 0, 'Compra Garantida')
  }

  return (
    <section className={styles.addressSection}>
      <BannerSection
        campaigns={campaigns}
        campaignsData={campaignsData}
        coupon={coupon}
        isAnActiveCampaign={isAnActiveCampaign}
        preActive={preActive}
      />
      <CategoryTitle icon="immobile" title="Imóvel" />
      <div className={styles.tags}>
        {tags.map((tag, index) => (
          <Tag campaign={tag === CONCORRENCIA} key={index.toString()}>
            {tag}
          </Tag>
        ))}
      </div>
      <Text className={styles.propertyIdText} color="lightTextColor">
        {propertySmallId}
      </Text>
      <Title fontSize="xlarge" type="h1">
        {propertyName}
      </Title>
      <Text fontSize="small">{address}</Text>
    </section>
  )
}

PropertyIntroduction.propTypes = {
  propertyData: PropTypes.shape({
    isJudicialSale: PropTypes.bool.isRequired,
    isFriendlySale: PropTypes.bool.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    propertySmallId: PropTypes.string.isRequired,
    propertyName: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
  }).isRequired,
}

export default PropertyIntroduction
