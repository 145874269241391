/* eslint-disable no-restricted-syntax */
import React from 'react'

import { useFilter } from 'store'
// import useWindowResize from 'hooks/useWindowResize'
import { Tag } from 'components'
import classNames from 'classnames'

import PropTypes from 'prop-types'

import styles from './TagSetContainer.module.scss'

const TagSetContainer = ({ carrouselMode }) => {
  // const { width } = useWindowResize(0)
  // const isMobile = width <= 950

  const {
    $filter: {
      filters: { arrayFilters, booleanFilters },
    },
    removeArrayFilterPosition,
    setBooleanFilter,
  } = useFilter()

  const renderLocalityTags = () => {
    return (
      arrayFilters.localities.value &&
      arrayFilters.localities.value[0] &&
      arrayFilters.localities.value.map((locality, index) => (
        <Tag onClick={() => removeArrayFilterPosition('localities', index)}>
          {locality.name}
        </Tag>
      ))
    )
  }

  const renderBooleanFilters = () => {
    const allOptionsChecked = []
    for (const { key, options = [] } of Object.values(booleanFilters)) {
      for (const option of options) {
        if (option.checked) allOptionsChecked.push({ key, option })
      }
    }

    return allOptionsChecked.map(({ key, option }) => (
      <Tag onClick={() => setBooleanFilter(key, option.name)}>
        {option.name}
      </Tag>
    ))
  }

  return (
    <div
      className={classNames(styles.tagSetContainer, {
        [styles.carrouselMode]: carrouselMode,
      })}
    >
      {renderLocalityTags()}
      {renderBooleanFilters()}
    </div>
  )
}

TagSetContainer.propTypes = {
  carrouselMode: PropTypes.oneOf([true, false]),
}

TagSetContainer.defaultProps = {
  carrouselMode: false,
}

export default TagSetContainer
