import deburr from 'lodash.deburr'
import { setCache } from 'utils/cache'
import isPreRendering from 'utils/isPreRendering'
import { useList } from '../list/index'
import { parseFilterToQueryString } from './utils/parseFilterToQueryString'
import { handleFiltersWithSingleOption } from './utils/filterGeneralUtils'

import * as providers from './provider'

export default ({ setState, setError, initialState }) => {
  const { clearList } = useList()

  const setField = (field, value) => {
    return setState(prev => ({ ...prev, [field]: value }))
  }

  const setFilterCampaignOptions = (options = [], complexOptions = []) =>
    setState(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        booleanFilters: {
          ...prev.filters.booleanFilters,
          campaign: {
            ...prev.filters.booleanFilters.campaign,
            options,
            complexCampaigns: complexOptions,
          },
        },
      },
    }))

  function pageSideEffects(filter, prevFilters) {
    const { page } = prevFilters.filters.numberFilters
    if (page.value !== 1 && filter !== 'page') {
      return {
        numberFilters: {
          ...prevFilters.filters.numberFilters,
          page: {
            ...prevFilters.filters.numberFilters.page,
            value: 1,
          },
        },
      }
    }

    return {}
  }

  function orderBySideEffects(filter, prevFilters) {
    const { orderBy } = prevFilters.filters.stringFilters
    if (
      orderBy.value === 'data' &&
      orderBy.value !== 'relevante' &&
      filter !== 'page' &&
      filter !== 'orderBy'
    ) {
      return {
        stringFilters: {
          ...prevFilters.filters.stringFilters,
          orderBy: {
            ...prevFilters.filters.stringFilters.orderBy,
            value: 'relevante',
          },
        },
      }
    }
    return {}
  }

  function filterSideEffects(filter, prevFilters) {
    return {
      ...pageSideEffects(filter, prevFilters),
      ...orderBySideEffects(filter, prevFilters),
    }
  }

  function updateQueryString() {
    setState(prev => {
      const queryString = parseFilterToQueryString(prev.filters, initialState)

      if (prev.queryString !== queryString) {
        if (!queryString.includes('page') && clearList) {
          clearList()
        }
        return { ...prev, queryString }
      }

      return { ...prev }
    })
  }

  const setStringFilter = (filter, value) => {
    let newValue = value
    setState(prev => {
      if (filter === 'coupon') {
        const previous = prev.filters.stringFilters[filter].value
        const newPrevious = previous.split(',')
        if (newPrevious.includes(value)) {
          newValue = newPrevious.filter(item => item !== value).join()
        } else {
          newValue = [...newPrevious, value].filter(Boolean).join()
        }
      }
      return {
        ...prev,
        filters: {
          ...prev.filters,
          ...filterSideEffects(filter, prev),
          stringFilters: {
            ...prev.filters.stringFilters,
            ...orderBySideEffects(filter, prev).stringFilters,
            [filter]: {
              ...prev.filters.stringFilters[filter],
              value: newValue,
            },
          },
        },
      }
    })
    updateQueryString()
  }

  const setBooleanFilter = (filter, optionName) => {
    setState(prev => {
      let newOptions = [...prev.filters.booleanFilters[filter].options]

      newOptions = handleFiltersWithSingleOption({
        filter,
        prev,
        optionName,
        newOptions,
      })

      const fieldIndex = newOptions.findIndex(item => item.name === optionName)
      if (newOptions[fieldIndex]) {
        newOptions[fieldIndex].checked = !newOptions[fieldIndex]?.checked
      }

      return {
        ...prev,
        filters: {
          ...prev.filters,
          ...filterSideEffects(filter, prev),
          booleanFilters: {
            ...prev.filters.booleanFilters,
            [filter]: {
              ...prev.filters.booleanFilters[filter],
              options: newOptions,
            },
          },
        },
      }
    })
    updateQueryString()
  }

  const setNumberFilter = (filter, value) => {
    setState(prev => {
      return {
        ...prev,
        filters: {
          ...prev.filters,
          ...orderBySideEffects(filter, prev),
          numberFilters: {
            ...prev.filters.numberFilters,
            ...pageSideEffects(filter, prev).numberFilters,
            [filter]: {
              ...prev.filters.numberFilters[filter],
              value,
            },
          },
        },
      }
    })
    updateQueryString()
  }

  const setArrayFilter = (filter, value) => {
    setState(prev => {
      return {
        ...prev,
        filters: {
          ...prev.filters,
          ...filterSideEffects(filter, prev),
          arrayFilters: {
            ...prev.filters.arrayFilters,
            [filter]: {
              ...prev.filters.arrayFilters[filter],
              value,
            },
          },
        },
      }
    })
    updateQueryString()
  }

  const removeArrayFilterPosition = (filter, position) => {
    setState(prev => {
      const value = [...prev.filters.arrayFilters[filter].value]
      value.splice(position, 1)

      return {
        ...prev,
        filters: {
          ...prev.filters,
          ...filterSideEffects(filter, prev),
          arrayFilters: {
            ...prev.filters.arrayFilters,
            [filter]: {
              ...prev.filters.arrayFilters[filter],
              value,
            },
          },
        },
      }
    })
    updateQueryString()
  }

  const clearFilters = () => {
    setState(prev => ({
      ...initialState,
      filters: {
        ...initialState.filters,
        arrayFilters: {
          ...initialState.filters.arrayFilters,
          localities: {
            ...initialState.filters.arrayFilters.localities,
            options: prev.filters.arrayFilters.localities.options,
          },
        },
      },
    }))
  }

  const fetchLocalities = async () => {
    if (isPreRendering()) return
    const [err, res = {}] = await providers.getLocalities()

    if (err || res.error || !res.data || !Array.isArray(res.data)) {
      return setError(res.code || 500)
    }

    const localities = res.data.map(({ name, ...locality }) => ({
      ...locality,
      name,
      deburredName: deburr(name.toLowerCase()),
    }))

    setCache({ key: 'localitiesOptions', data: localities, expiration: 24 })

    setState(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        arrayFilters: {
          ...prev.filters.arrayFilters,
          localities: {
            ...prev.filters.arrayFilters.localities,
            options: localities,
          },
        },
      },
    }))

    return localities
  }

  const setComplexCampaigns = (campaign, status = null) => {
    setState(prev => {
      const newOptions = [
        ...prev.filters.booleanFilters.campaign.complexCampaigns,
      ]

      const fieldIndex = newOptions.findIndex(item => item.name === campaign)
      const isValidField = fieldIndex !== -1

      if (status === null && isValidField) {
        newOptions[fieldIndex].checked = !newOptions[fieldIndex]?.checked
      } else if (isValidField) {
        newOptions[fieldIndex].checked = status
      }

      return {
        ...prev,
        filters: {
          ...prev.filters,
          booleanFilters: {
            ...prev.filters.booleanFilters,
            campaign: {
              ...prev.filters.booleanFilters.campaign,
              complexCampaigns: newOptions,
            },
          },
        },
      }
    })
  }

  return {
    setField,
    clearFilters,
    setBooleanFilter,
    setStringFilter,
    setNumberFilter,
    setArrayFilter,
    removeArrayFilterPosition,
    updateQueryString,
    fetchLocalities,
    setComplexCampaigns,
    setFilterCampaignOptions,
  }
}
