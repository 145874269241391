import React from 'react'
import PropTypes from 'prop-types'
import styles from './Loading.module.scss'

const Loading = ({ size }) => (
  <span className={`${styles.loader} ${styles[size]}`} />
)

Loading.propTypes = {
  size: PropTypes.oneOf([
    'small', // 15px

    'medium', // 30px
  ]).isRequired,
}
export default React.memo(Loading)
