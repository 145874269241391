import React, { useEffect } from 'react'
import { AuctionModal } from 'components'
import { useProperty } from 'store'
import PropTypes from 'prop-types'
import { fakePropertyId, fakeContactInfo } from 'utils/mockData'
import { normalizeUrl } from 'utils/normalizeString'

const AuctionContainer = ({ opened, closeModal, url }) => {
  const [loadingFetch, setloadingFetch] = React.useState(false)
  const [fetchSuccess, setFetchSuccess] = React.useState(false)

  useEffect(() => {
    return () => {
      setFetchSuccess(false)
    }
  }, [])

  const {
    getPropertyId,
    getContactInfo,
    getPropertySmallId,
    sendAuctionForm,
  } = useProperty()

  const idProperty = getPropertyId() || fakePropertyId
  const contactInfo = getContactInfo() || fakeContactInfo

  const handleConfirm = async (
    propertyId,
    email,
    checkOffers,
    checkOffersResale
  ) => {
    const propertySmallId = getPropertySmallId()
    setloadingFetch(true)
    let success = false
    if (sendAuctionForm) {
      success = await sendAuctionForm(
        propertyId,
        email,
        propertySmallId,
        checkOffers,
        checkOffersResale
      )
    }
    setloadingFetch(false)

    if (success) {
      window.location.href = normalizeUrl(url)
      setFetchSuccess(true)
    }
  }

  return (
    <AuctionModal
      opened={opened}
      closeModal={closeModal}
      submit={handleConfirm}
      isLoading={loadingFetch}
      contactInfo={contactInfo}
      propertyId={idProperty}
      success={fetchSuccess}
      url={url}
    />
  )
}

AuctionContainer.propTypes = {
  opened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  url: PropTypes.string,
}

AuctionContainer.defaultProps = {
  url: '',
}

export default AuctionContainer
