import isConcurrenceProperty from 'utils/isConcurrenceProperty'
import parseFeatures from 'utils/parseFeatures'
import sortFeatures from 'utils/sortFeatures'
import handleTags from 'utils/tags'

export default ({ $home, isAnActiveCampaign }) => {
  const getBestSellers = () => {
    const { bestSellers, bestSellersErr, bestSellersLoading } = $home

    const newBestSellersList = bestSellers.map(property => {
      const {
        id,
        nome_imovel, //eslint-disable-line
        foto_capa, //eslint-disable-line
        endereco,
        descricao,
        caracteristicas,
        valores,
        desagio,
        tags,
        tipo_imovel,
        id_banco,
      } = property

      return {
        id,
        propertySmallId: id_banco,
        name: nome_imovel,
        photo: foto_capa,
        longPhoto: foto_capa,
        address: endereco.endereco_completo,
        description: descricao,
        features: sortFeatures(parseFeatures(caracteristicas)),
        appraisedValue: valores.valor_avaliado,
        appraisedLabel: valores.valor_avaliado_label,
        desagio,
        saleValue: valores.valor_venda,
        saleLabel: isConcurrenceProperty(
          handleTags({ tags, isAnActiveCampaign })
        )
          ? 'Proposta Mínima'
          : valores.valor_venda_label || 'Valor de venda',
        city: endereco.cidade,
        state: endereco.estado,
        tags: handleTags({ tags: tags || [], isAnActiveCampaign }),
        category: tipo_imovel,
      }
    })

    return {
      bestSellers: newBestSellersList,
      bestSellersErr,
      bestSellersLoading,
    }
  }

  return { getBestSellers }
}
