import Qs from 'query-string'
import { setComplexCampaignsByUrl } from 'service/campaigns'

const replaceQuerySeparator = query => {
  return query.replace(/%2C/gi, ',')
}

function setBooleanFilterFromQueryString({
  setBooleanFilter,
  booleanFilters,
  queryStringObject,
  queryStringKeys,
  setComplexCampaigns,
}) {
  const booleanFilterKeys = Object.keys(booleanFilters)

  const booleanFiltersQueryStringKeys = booleanFilterKeys.map(
    key => booleanFilters[key].queryStringKey
  )

  queryStringKeys.forEach(QSKey => {
    if (booleanFiltersQueryStringKeys.includes(QSKey)) {
      if (Array.isArray(queryStringObject[QSKey])) {
        queryStringObject[QSKey].forEach(value => {
          booleanFilterKeys.forEach(filterKey => {
            const { options, queryStringKey } = booleanFilters[filterKey]
            options.forEach(option => {
              if (option.value === value && QSKey === queryStringKey) {
                setBooleanFilter(filterKey, option.name)
                setComplexCampaignsByUrl({
                  filterKey,
                  optionName: option.name,
                  setComplexCampaigns,
                })
              }
            })
          })
        })
      } else {
        booleanFilterKeys.forEach(filterKey => {
          const { options, queryStringKey } = booleanFilters[filterKey]
          options.forEach(option => {
            if (
              option.value === queryStringObject[QSKey] &&
              QSKey === queryStringKey
            ) {
              setBooleanFilter(filterKey, option.name)
              setComplexCampaignsByUrl({
                filterKey,
                optionName: option.name,
                setComplexCampaigns,
              })
            }
          })
        })
      }
    }
  })
}

function setNumberFilterFromQueryString({
  setNumberFilter,
  numberFilters,
  queryStringObject,
  queryStringKeys,
}) {
  const numberFilterKeys = Object.keys(numberFilters)

  const numberFiltersQueryStringKeys = numberFilterKeys.map(
    key => numberFilters[key].queryStringKey
  )

  queryStringKeys.forEach(QSKey => {
    if (numberFiltersQueryStringKeys.includes(QSKey)) {
      numberFilterKeys.forEach(filterKey => {
        if (QSKey === numberFilters[filterKey].queryStringKey) {
          setNumberFilter(filterKey, queryStringObject[QSKey])
        }
      })
    }
  })
}

function setArrayFilterFromQueryString({
  setArrayFilter,
  arrayFilters,
  queryStringObject,
  queryStringKeys,
}) {
  const arrayFilterKeys = Object.keys(arrayFilters)

  const arrayFiltersQueryStringKeys = arrayFilterKeys.map(
    key => arrayFilters[key].queryStringKey
  )

  queryStringKeys.forEach(QSKey => {
    if (arrayFiltersQueryStringKeys.includes(QSKey)) {
      arrayFilterKeys.forEach(filterKey => {
        const { queryStringKey, options } = arrayFilters[filterKey]
        if (QSKey === queryStringKey) {
          let value = []

          if (Array.isArray(queryStringObject[QSKey])) {
            value = queryStringObject[QSKey].map(arrayItem => {
              let item = {}

              options.forEach(option => {
                if (QSKey === 'search' && option.slug === arrayItem) {
                  item = { name: option.name, slug: option.slug }
                }
              })

              return item
            })
          } else {
            let item = {}

            options.forEach(option => {
              if (
                QSKey === 'search' &&
                option.slug === queryStringObject[QSKey]
              ) {
                item = { name: option.name, slug: option.slug }
              }
            })

            value = [item]
          }
          setArrayFilter(filterKey, value)
        }
      })
    }
  })
}

function setStringFilterFromQueryString({
  setStringFilter,
  stringFilters,
  queryStringObject,
  queryStringKeys,
  setComplexCampaigns,
}) {
  const stringFilterKeys = Object.keys(stringFilters)

  const stringFiltersQueryStringKeys = stringFilterKeys.map(
    key => stringFilters[key].queryStringKey
  )

  queryStringKeys.forEach(QSKey => {
    if (stringFiltersQueryStringKeys.includes(QSKey)) {
      stringFilterKeys.forEach(filterKey => {
        if (QSKey === stringFilters[filterKey].queryStringKey) {
          setStringFilter(filterKey, queryStringObject[QSKey])
          setComplexCampaignsByUrl({
            filterKey,
            optionName: queryStringObject[QSKey],
            setComplexCampaigns,
          })
        }
      })
    }
  })
}

export const parseFilterFromQueryString = ({
  queryString,
  filters,
  setBooleanFilter,
  setStringFilter,
  setArrayFilter,
  setNumberFilter,
  setComplexCampaigns,
}) => {
  if (!queryString) return

  const { booleanFilters, stringFilters, arrayFilters, numberFilters } = filters

  const queryStringObject = Qs.parse(replaceQuerySeparator(queryString), {
    arrayFormat: 'comma',
  })

  const queryStringKeys = Object.keys(queryStringObject)

  setBooleanFilterFromQueryString({
    setBooleanFilter,
    booleanFilters,
    queryStringObject,
    queryStringKeys,
    setComplexCampaigns,
  })

  setStringFilterFromQueryString({
    setStringFilter,
    stringFilters,
    queryStringObject,
    queryStringKeys,
    setComplexCampaigns,
  })

  setArrayFilterFromQueryString({
    setArrayFilter,
    arrayFilters,
    queryStringObject,
    queryStringKeys,
  })

  setNumberFilterFromQueryString({
    setNumberFilter,
    numberFilters,
    queryStringObject,
    queryStringKeys,
  })
}
