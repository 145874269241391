import React from 'react'
import PropTypes from 'prop-types'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const data = ({ viewBox, color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="data"
          d="M17.094 11.547c0 .604-.49 1.094-1.094 1.094H7.396a1.094 1.094 0 1 1 0-2.188H16c.604 0 1.094.49 1.094 1.094zM16 14.755H7.396a1.094 1.094 0 1 0 0 2.188H16a1.094 1.094 0 1 0 0-2.188zm-4.302 4.302H7.396a1.094 1.094 0 1 0 0 2.188h4.302a1.094 1.094 0 0 0 0-2.188zm9.698-12.323v18.23a2.738 2.738 0 0 1-2.734 2.734H4.734A2.738 2.738 0 0 1 2 24.964V6.734A2.737 2.737 0 0 1 4.734 4h13.928a2.737 2.737 0 0 1 2.734 2.734zm-2.188 0a.548.548 0 0 0-.546-.546H4.734a.547.547 0 0 0-.546.546v18.23c0 .301.245.547.546.547h13.928a.548.548 0 0 0 .546-.547V6.734zM30 9.396v12.906c0 .29-.115.568-.32.773l-2.151 2.152a1.094 1.094 0 0 1-1.547 0l-2.151-2.152a1.093 1.093 0 0 1-.32-.773V9.396a3.248 3.248 0 0 1 3.244-3.245A3.248 3.248 0 0 1 30 9.396zm-2.188 0a1.058 1.058 0 0 0-2.114 0v12.453l1.057 1.057 1.058-1.057V9.396z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#data" />
        </mask>
        <use className={styles[color]} fillRule="nonzero" xlinkHref="#data" />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

data.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ...propTypesSvg,
}

data.defaultProps = {
  size: 'auto',
  ...defaultPropTypesSvg,
}

export default data
