import React from 'react'
import { Helmet } from 'react-helmet'

import { BaseLayout } from 'templates'
import { Title } from 'components'

import { usePageView } from 'hooks/useGAPageView'
import { SimulatorModalContainer } from 'containers'
import PropTypes from 'prop-types'
import styles from './Simulator.module.scss'

const Simulator = ({ noModal }) => {
  usePageView()

  return (
    <>
      <Helmet>
        <title>Imóveis Emgea | Simulador</title>
        <meta property="og:title" content="Imóveis Emgea | Simulador" />
        <meta
          property="og:description"
          content="Simulação de parcelamento direto dos imóveis emgea."
        />
        <meta
          property="og:url"
          content="https://emgeaimoveis.com.br/simulador"
        />
      </Helmet>
      <BaseLayout backgroundColor="white">
        <main>
          <section className={styles.simulatorContainer}>
            <Title color="secondColor" type="h1" className={styles.mainTitle}>
              Simulador
              <SimulatorModalContainer noModal={noModal} />
            </Title>
          </section>
        </main>
      </BaseLayout>
    </>
  )
}

Simulator.propTypes = {
  noModal: PropTypes.bool,
}
Simulator.defaultProps = {
  noModal: false,
}
export default React.memo(Simulator)
