/* eslint-disable react/prop-types */
import { getEstateCardCampaign } from 'assets/images/vendor-banners'
import React from 'react'
import styles from './bannerSection.module.scss'

export const BannerSection = ({
  campaigns,
  campaignsData,
  coupon,
  isAnActiveCampaign,
  preActive,
}) => {
  const bannerCampaign = getEstateCardCampaign({
    campaigns,
    campaignsData,
    coupon,
    isAnActiveCampaign,
    preActive,
  })
  if (!bannerCampaign) return null
  return (
    <div className={styles.containerImg}>
      {bannerCampaign.url ? (
        <a
          key={bannerCampaign.resale_id}
          href={bannerCampaign.url}
          target="_blank"
          rel="noreferrer"
        >
          <img src={bannerCampaign.image} alt={bannerCampaign.name} />
        </a>
      ) : (
        <img
          key={bannerCampaign.resale_id}
          src={bannerCampaign.image}
          alt={bannerCampaign.name}
        />
      )}
    </div>
  )
}
