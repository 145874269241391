import React from 'react'
import PropTypes from 'prop-types'
import { CategoryTitle, Text } from 'components'

const PaymentMethods = ({ conditions }) => {
  if (!conditions) return null

  return (
    <section>
      <CategoryTitle title="Formas de Pagamento" icon="payment" />
      {conditions.map((condition, index) => (
        <Text key={index.toString()} fontSize="medium" color="lightTextColor">
          {condition.title}
        </Text>
      ))}
    </section>
  )
}

PaymentMethods.propTypes = {
  conditions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default PaymentMethods
