/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Title,
  Text,
  Button,
  Input,
  Selector,
  Optins,
  Image,
  Checkbox,
} from 'components'
import {
  validateEmail,
  validateFullName,
  validateMandatoryPhone,
} from 'utils/validations'
import mask from 'utils/masks'
import logo from 'assets/images/logo-resale.png'
import styles from './VisitScheduleModal.module.scss'

const days = [
  { label: 'Seg', name: 'mon' },
  { label: 'Ter', name: 'tue' },
  { label: 'Qua', name: 'wed' },
  { label: 'Qui', name: 'thu' },
  { label: 'Sex', name: 'fri' },
]
const periods = [
  { label: 'Manhã', name: 'morning' },
  { label: 'Tarde', name: 'afternoon' },
]

const VisitScheduleModal = ({
  closeModal,
  contactInfo,
  isLoading,
  opened,
  sendForm,
  setField,
  values,
}) => {
  const [touched, setTouched] = React.useState({})
  const [checkOffers, setCheckOffers] = React.useState(true)
  const [checkOffersResale, setCheckOffersResale] = React.useState(true)
  const [checkedSaleChannel, setCheckedSaleChannel] = React.useState(false)

  const handleBlur = field => () => setTouched({ ...touched, [field]: true })

  const handleErrorMessage = (value, errorMessage) =>
    value === '' ? 'Campo obrigatório' : errorMessage

  const handleClose = () => {
    setTouched({})
    closeModal()
  }

  const onChange = e => {
    setTouched({ ...touched, [e.target.id]: false })
    setField(e.target.id, e.target.value)
  }

  const callSendForm = async () => {
    setTouched({})
    await sendForm(checkOffers, checkOffersResale)
  }
  const { email, name, phone, days: stateDays, times: statePeriods } = values

  const onClickDay = value => {
    setField('days', { ...stateDays, [value]: !stateDays[value] })
  }

  const onClickPeriod = value => {
    setField('times', { ...statePeriods, [value]: !statePeriods[value] })
  }

  const isDisabled =
    !validateEmail(email) ||
    !validateFullName(name) ||
    !validateMandatoryPhone(phone) ||
    Object.values(stateDays).filter(key => key === true).length === 0 ||
    Object.values(statePeriods).filter(key => key === true).length === 0 ||
    !checkOffers ||
    (checkedSaleChannel && values.canal_venda_codigo.trim() === '')

  return (
    <Modal opened={opened} closeModal={handleClose}>
      <section className={styles.visitScheduleModal}>
        <header className={styles.header}>
          <div className={styles.info}>
            <Title type="h1" fontSize="xlarge">
              Agendar visita
            </Title>
            {contactInfo.name && contactInfo.phone && (
              <Text
                fontSize="medium"
                color="lightTextColor"
                className={styles.contactText}
              >
                {`${contactInfo.name} | ${contactInfo.phone}`}
              </Text>
            )}
            <Text
              fontSize="medium"
              color="lightTextColor"
              className={styles.description}
            >
              Para agendar sua visita preencha os campos abaixo e selecione o
              melhor dia e horário
            </Text>
          </div>
          {contactInfo.logo &&
            contactInfo.logo !== `${process.env.REACT_APP_CDN}logos/None` && (
              <figure className={styles.logo}>
                <Image
                  alt={contactInfo.name || 'Logo do leiloeiro'}
                  source={logo}
                  noPhoto={logo}
                />
              </figure>
            )}
        </header>
        <main className={styles.main}>
          <Input
            errorMessage={handleErrorMessage(name, 'Nome completo inválido')}
            onBlur={handleBlur('name')}
            hasError={!validateFullName(name) && touched.name}
            id="name"
            label="Nome completo*"
            onChange={onChange}
            placeholder=""
            value={name}
            className={styles.inputName}
            labelClassName={styles.modalLabel}
          />
          <Input
            errorMessage={handleErrorMessage(email, 'Email Inválido')}
            onBlur={handleBlur('email')}
            hasError={!validateEmail(email) && touched.email}
            id="email"
            label="E-mail*"
            type="email"
            onChange={onChange}
            placeholder=""
            value={email}
            className={styles.inputEmail}
            labelClassName={styles.modalLabel}
          />
          <Input
            errorMessage="Telefone Inválido"
            onBlur={handleBlur('phone')}
            hasError={!validateMandatoryPhone(phone) && touched.phone}
            mask={mask.phone}
            id="phone"
            label="Telefone*"
            onChange={onChange}
            placeholder=""
            value={phone}
            className={styles.inputPhone}
            labelClassName={styles.modalLabel}
          />
          <div className={styles.selectDay}>
            <label>
              <Text fontSize="medium">Melhor dia*</Text>
            </label>
            <Selector active={stateDays} onClick={onClickDay} options={days} />
          </div>
          <div className={styles.selectPeriod}>
            <label>
              <Text fontSize="medium">Melhor horário*</Text>
            </label>
            <Selector
              active={statePeriods}
              onClick={onClickPeriod}
              options={periods}
            />
          </div>
          <div className={styles.optins}>
            <Optins
              location="location-visitScheduleModal"
              checkOffers={checkOffers}
              setCheckOffers={setCheckOffers}
              checkOffersResale={checkOffersResale}
              setCheckOffersResale={setCheckOffersResale}
            />
          </div>
          <div className={styles.warning}>
            <p>Informamos que a visita ao imóvel não garante seu bloqueio.</p>
          </div>
          <Checkbox
            id="saleChannel"
            option="Estou sendo auxiliado por um canal parceiro"
            optionPosition="right"
            onChange={() => setCheckedSaleChannel(prevState => !prevState)}
            checked={checkedSaleChannel}
            className={styles.checkbox}
          />
          {checkedSaleChannel && (
            <Input
              errorMessage={handleErrorMessage(
                values.canal_venda_codigo,
                'Código obrigatório'
              )}
              onBlur={handleBlur('canal_venda_codigo')}
              hasError={
                touched.canal_venda_codigo &&
                values.canal_venda_codigo.trim() === ''
              }
              id="canal_venda_codigo"
              label="Código do canal*"
              onChange={onChange}
              placeholder=""
              value={values.canal_venda_codigo}
              className={styles.inputName}
              labelClassName={styles.modalLabel}
            />
          )}
        </main>
        <footer className={styles.footer}>
          <Button
            id="btn_criar_visita"
            onClick={() => callSendForm()}
            type="filled"
            className={styles.button}
            fontSize="medium"
            disabled={isDisabled || isLoading}
            isLoading={isLoading}
          >
            Agendar visita
          </Button>
        </footer>
      </section>
    </Modal>
  )
}

VisitScheduleModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  contactInfo: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    logo: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  opened: PropTypes.bool.isRequired,
  sendForm: PropTypes.func.isRequired,
  setField: PropTypes.func.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    days: PropTypes.objectOf(PropTypes.bool).isRequired,
    times: PropTypes.objectOf(PropTypes.bool).isRequired,
    canal_venda_codigo: PropTypes.string,
  }).isRequired,
}

export default React.memo(VisitScheduleModal)
