import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import bot from 'assets/images/rosie-cards.png'
import { Modal, Title, Text, Button } from 'components'
import styles from './AlertSuccessModal.module.scss'

const AlertSuccessModal = ({ opened, closeModal, openAlertModal }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const close = () => {
    const element = document.getElementById('list')
    if (element) {
      element.scrollIntoView()
    }

    closeModal()
  }

  return (
    <Modal opened={opened} closeModal={closeModal} hideCloseBtn>
      <div className={styles.alertSuccessModal}>
        <header className={styles.header}>
          <figure className={styles.img}>
            <img
              loading="lazy"
              alt="Icone de busca: Robô com uma lupa"
              src={bot}
              className={styles.botImage}
            />
          </figure>
          <div className={styles.infoHeader}>
            <Title type="h1" fontSize="xlarge" bold>
              Tudo certo! Agora você não perde nenhuma oportunidade!
            </Title>
          </div>
        </header>

        <div className={styles.infoBody}>
          <Text fontSize="medium" color="primaryColor">
            Nosso alerta já está analizando oportunidades com base nos seus
            filtros.
          </Text>
          <br />
          <Text fontSize="medium" color="primaryColor">
            Você pode criar novos alertas se quiser.
          </Text>
        </div>

        <footer className={styles.footer}>
          <Button
            onClick={openAlertModal}
            type="outline"
            className={styles.button}
            textColor="darkColor"
            backgroundColor="darkColor"
            bold
          >
            Criar novos alertas
          </Button>
          <Button onClick={close} className={styles.button} greenColor bold>
            CONTINUAR BUSCANDO
          </Button>
        </footer>
      </div>
    </Modal>
  )
}

AlertSuccessModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openAlertModal: PropTypes.func.isRequired,
}

export default React.memo(AlertSuccessModal)
