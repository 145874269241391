import React from 'react'
import PropTypes from 'prop-types'
import { CategoryTitle, JuridicalProcess as LegalProcess } from 'components'
import styles from './JuridicalProcess.module.scss'

const JuridicalProcess = ({ stage, judicialProcessTimeline }) => {
  if (stage !== 'venda_judicial') return null

  return (
    <section className={styles.juridicalProcess}>
      <CategoryTitle icon="stage" title="Processo jurídico" />
      <div className={styles.stage}>
        <LegalProcess processStage={judicialProcessTimeline} />
      </div>
    </section>
  )
}

JuridicalProcess.propTypes = {
  stage: PropTypes.string.isRequired,
  judicialProcessTimeline: PropTypes.string.isRequired,
}

export default JuridicalProcess
