import React from 'react'

import { FiltersModal } from 'components'

import { useFilter } from 'store'

const FiltersModalContainer = () => {
  const {
    $filter: { openFilterModal },
    setField,
  } = useFilter()

  return (
    <>
      <FiltersModal
        opened={openFilterModal}
        closeModal={() => setField('openFilterModal', false)}
      />
    </>
  )
}

export default React.memo(FiltersModalContainer)
