import { useState, useEffect, useMemo } from 'react'
import { useCampaigns } from 'hooks'
import useCreateStore from '../../lib/useCreateStore'
import homeActions from './actions'
import homeSelectors from './selector'

const initialState = {
  banners: [],
  advertising: {
    urlDesktop: '',
    urlMobile: '',
    link: '',
  },
  bestSellers: [],
  bestSellersErr: false,
  bestSellersLoading: true,
  bannersStatus: null,
  isLoadingBanners: false,
}

const HomeStore = useCreateStore(() => {
  const [$home, setHome] = useState(initialState)
  const { isAnActiveCampaign } = useCampaigns()

  const actions = useMemo(
    () => homeActions({ setHome }),
    // eslint-disable-next-line
    []
  )
  const selector = homeSelectors({ $home, isAnActiveCampaign })

  useEffect(() => {
    actions.fetchBanner()
  }, [actions])

  return { $home, setHome, ...actions, ...selector }
})

export const useHome = () => HomeStore()
export const HomeContext = HomeStore.Context
export const HomeProvider = HomeStore.Provider
