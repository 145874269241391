import { useEffect } from 'react'
import clearPropertyReferenceStorage from '../utils/clearPropertyReferenceStorage'

export const useClearPropertyReferenceStorage = () => {
  useEffect(() => {
    return () => {
      clearPropertyReferenceStorage()
    }
  }, [])
}
export default useClearPropertyReferenceStorage
