import React from 'react'
import PropTypes from 'prop-types'
import { Text, Button, Icon } from 'components'
import styles from './PartnershipButton.module.scss'

const PartnershipButton = ({ description, iconType, onClick }) => {
  return (
    <Button className={styles.partnerButton} onClick={onClick}>
      <Icon
        className={styles.icon}
        type={iconType}
        size={48}
        color="primaryColor"
      />
      <Text fontSize="large" color="darkBlueColor">
        Parceria
      </Text>
      <Text fontSize="regular" color="darkBlueColor" bold>
        {description}
      </Text>
    </Button>
  )
}

PartnershipButton.propTypes = {
  description: PropTypes.string.isRequired,
  iconType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default React.memo(PartnershipButton)
