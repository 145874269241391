import React from 'react'
import { ToastContainer, toast } from 'react-toastify'

import styles from './ToastComponent.module.scss'
import 'react-toastify/dist/ReactToastify.css'

const ToastComponent = () => {
  return (
    <ToastContainer
      position="top-center"
      autoClose={3000}
      toastClassName={styles.toast}
      style={{ zIndex: '13' }}
    />
  )
}

export const toastSuccess = msg =>
  toast.success(msg, {
    toastClassName: styles.toast,
    progressClassName: styles.success,
  })

export const toastError = msg =>
  toast.error(msg, {
    toastClassName: styles.toast,
    progressClassName: styles.error,
  })

export default React.memo(ToastComponent)
