import {
  featureIconDictionary,
  featureNameDictionary,
} from 'store/list/dictionaries'

const parseFeatures = (features, areas) => {
  const newFeatures = []

  Object.keys(Object.assign(features, areas)).map((feature, idx) => {
    const value =
      feature.indexOf('area_') === -1 ? features[feature] : areas[feature].value

    if (
      featureNameDictionary[feature] &&
      featureIconDictionary[feature] &&
      value !== null &&
      value
    ) {
      newFeatures.push({
        id: idx,
        feature: featureNameDictionary[feature],
        amount: value,
        icon: featureIconDictionary[feature],
      })
    }
    return null
  })

  return newFeatures
}

export default parseFeatures
