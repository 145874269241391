const parseExpenses = despesas => {
  if (!despesas.length) {
    return []
  }
  return despesas.map(despesa => {
    if (despesa.type.toLowerCase() === 'iptu') {
      return { ...despesa, type: 'Valor do IPTU (anual)' }
    }
    if (despesa.type.toLowerCase() === 'condominio') {
      return { ...despesa, type: 'Valor do condomínio (mensal)' }
    }
    return despesa
  })
}

export default parseExpenses
