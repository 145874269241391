import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg } from './propTypes'

const Map = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="map"
          d="M6.683 15.525a9.825 9.825 0 0 1-.183-1.894C6.5 8.321 10.787 4 16.051 4c5.267 0 9.551 4.324 9.531 9.631 0 .713-.067 1.393-.202 1.993 0 .038-.006.07-.016.108l-.01.057c-.262 1.16-.922 2.52-1.917 4.045a36.462 36.462 0 0 1-2.339 3.15 63.229 63.229 0 0 1-4.351 4.743 1.061 1.061 0 0 1-.696.273.962.962 0 0 1-.7-.277 59.18 59.18 0 0 1-4.037-4.346c-2.473-2.955-4.146-5.607-4.612-7.733a.164.164 0 0 0-.005-.036.475.475 0 0 1-.014-.083zm16.687-.145v-.061l.005-.043c.118-.534.177-1.09.177-1.665 0-4.175-3.38-7.57-7.521-7.57-4.13 0-7.501 3.404-7.501 7.57 0 .491.054 1.002.141 1.498.018.037.03.07.036.107.005.05.005.05 0 .04.59 2.657 3.676 6.564 7.344 10.275 3.614-3.657 6.733-7.598 7.32-10.151zm-7.575-4.987a3.383 3.383 0 0 1 3.394 3.394 3.396 3.396 0 0 1-3.394 3.393 3.41 3.41 0 0 1-3.393-3.393 3.396 3.396 0 0 1 3.393-3.394zm0 4.723c.737 0 1.33-.592 1.33-1.33 0-.736-.593-1.329-1.33-1.329-.737 0-1.33.593-1.33 1.33 0 .737.593 1.33 1.33 1.33z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#map" />
        </mask>
        <use
          className={styles[color]}
          // transform="rotate(45 16.04 14.04)"
          xlinkHref="#map"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Map.propTypes = {
  ...propTypesSvg,
}

Map.defaultProps = {
  viewBox: '0 0 32 32',
  color: 'grayColor',
}

export default Map
