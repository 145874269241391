import React from 'react'
import PropTypes from 'prop-types'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const Hand = ({ viewBox, color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox={viewBox}
    >
      <defs>
        <path
          id="hand"
          d="M24.465 11.024c-.371 0-.724.077-1.045.215a2.654 2.654 0 0 0-3.62-1.403 2.654 2.654 0 0 0-3.209-1.281V5.644a2.65 2.65 0 0 0-5.301 0v10.474l-1.91-2.164a2.67 2.67 0 0 0-1.92-.819h-.013c-.708 0-1.372.271-1.869.764-.876.868-.925 2.157-.133 3.537 1.026 1.785 2.141 3.468 3.125 4.953.719 1.086 1.398 2.11 1.894 2.958.43.736 1.574 3.121 1.585 3.145.149.31.464.508.81.508h11.337c.39 0 .734-.249.854-.616.211-.651 2.066-6.425 2.066-8.655v-6.06a2.65 2.65 0 0 0-2.651-2.645zm-.857 2.644c0-.478.384-.868.857-.868s.858.39.858.868v6.06c0 1.483-1.15 5.467-1.785 7.496H13.425c-.372-.766-1.069-2.186-1.41-2.768-.52-.89-1.213-1.935-1.946-3.041-.97-1.463-2.068-3.121-3.065-4.857-.26-.454-.5-1.065-.158-1.404a.88.88 0 0 1 1.207-.005l3.458 3.918c.246.279.64.377.99.247a.888.888 0 0 0 .582-.831V5.643c0-.478.385-.867.857-.867.473 0 .858.39.858.868v8.291c0 .49.402.888.897.888a.892.892 0 0 0 .896-.888v-2.88c0-.478.385-.867.858-.867s.857.389.857.867v4.038c0 .491.402.888.897.888a.892.892 0 0 0 .896-.888v-2.879c0-.478.385-.868.858-.868s.858.39.858.868v4.039c0 .49.401.888.896.888a.893.893 0 0 0 .897-.888v-2.585z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="hand">
          <use xlinkHref="#hand" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          transform="rotate(90 16 16)"
          xlinkHref="#hand"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Hand.propTypes = {
  ...propTypesSvg,
  size: PropTypes.number,
}

Hand.defaultProps = {
  ...defaultPropTypesSvg,
  size: 36,
}

export default Hand
