export const boldString = str => {
  return str.replace(
    // eslint-disable-next-line
    /\*{2}(?=[^\s\*])(.*?)([^\s\*])\*{2}/gi,
    s => `<b>${s.replace(/\*/g, '')}</b>`
  )
}

export const keyFromString = (str, length = 10) => {
  return (
    str.substr(0, length / 2) + str.substr(str.length - length / 2, str.length)
  )
}
