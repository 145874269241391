import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg } from './propTypes'

const doubleQuote = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18">
    <path
      className={styles[color]}
      fill="#fff"
      d="M21.504 17.024V7.808h-5.632c.128-1.195.512-2.197 1.152-3.008.64-.81 1.387-1.216 2.24-1.216V0c-2.176 0-3.925.853-5.248 2.56a9.754 9.754 0 00-1.28 2.688c-.299.981-.448 2.07-.448 3.264v8.512h9.216zm-12.352 0V7.808H3.584c.085-1.195.448-2.197 1.088-3.008.64-.81 1.387-1.216 2.24-1.216V0C4.693 0 2.965.853 1.728 2.56.576 4.181 0 6.165 0 8.512v8.512h9.152z"
    />
  </svg>
)

doubleQuote.propTypes = {
  color: propTypesSvg.color,
}

doubleQuote.defaultProps = {
  color: 'primaryColor',
}

export default doubleQuote
