import atuacao from 'assets/images/ProvidersPage/svgs/atuacao.svg'
import cadastro from 'assets/images/ProvidersPage/svgs/cadastro.svg'
import contrato from 'assets/images/ProvidersPage/svgs/contrato.svg'
import habilitacao from 'assets/images/ProvidersPage/svgs/habilitacao.svg'

const content = [
  {
    title: 'Cadastro',
    subtitle: 'Preencha o formulário de cadastro com suas informações',
    image: cadastro,
  },
  {
    title: 'Contrato',
    subtitle:
      'Para determinados tipos de serviços será gerado um contrato entre a Resale e o fornecedor',
    image: contrato,
  },
  {
    title: 'Habilitação',
    subtitle:
      'Após a assinatura do contrato o prestador estará apto para nos atender',
    image: habilitacao,
  },
  {
    title: 'Atuação',
    subtitle: 'Agora é só esperar ser acionado para algum serviço!',
    image: atuacao,
  },
]

export default content
