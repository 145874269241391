import React from 'react'
import PropTypes from 'prop-types'
import whatsapp from 'assets/images/whatsapp.webp'
import styles from './WhatsappCTA.module.scss'

const WhatsappCTA = ({ link, message }) => {
  return (
    <a
      href={link}
      className={styles.wrapper}
      target="_blank"
      rel="noreferrer"
      data-testid="link"
    >
      <span>{message}</span>
      <img loading="lazy" src={whatsapp} alt="Pedir ajuda no whatsapp" />
    </a>
  )
}

WhatsappCTA.propTypes = {
  link: PropTypes.string.isRequired,
  message: PropTypes.string,
}

WhatsappCTA.defaultProps = {
  message: 'Precisa de ajuda com esse imóvel?',
}

export default React.memo(WhatsappCTA)
