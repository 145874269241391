import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg } from './propTypes'

const Down = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="down"
          d="M18.096 20.218L9.44 11.56a1.5 1.5 0 0 1 2.122-2.122l8.657 8.657v-5.378a1.5 1.5 0 0 1 3 0v9a1.5 1.5 0 0 1-1.5 1.5h-9a1.5 1.5 0 0 1 0-3h5.378z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#down" />
        </mask>
        <use
          className={styles[color]}
          transform="rotate(45 16.109 16.109)"
          xlinkHref="#down"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Down.propTypes = {
  ...propTypesSvg,
}

Down.defaultProps = {
  viewBox: '0 0 32 32',
  color: 'secondColor',
}

export default Down
