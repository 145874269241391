import React from 'react'

import { Modal, SimulatorForm } from 'components'
import { useSimulatorForm } from 'store'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import SuccesCard from './SuccessCard/SuccessCard'
import styles from './SimulatorModalContainer.module.scss'

const SimulatorModalContainer = ({ noModal }) => {
  const {
    getForm,
    getOpenModal,
    setOpenModal,
    setField,
    sendForm,
    getSucceeded,
    getLoading,
    resetForm,
  } = useSimulatorForm()
  const values = getForm()
  const success = getSucceeded()
  const loading = getLoading()
  const [touched, setTouched] = React.useState({})

  const handleClose = () => {
    setTouched({})
    setField('succeeded', false, true)
    setOpenModal()
    resetForm()
  }

  return (
    <>
      {noModal ? (
        <>
          {success ? (
            <SuccesCard handleClose={handleClose} noModal={noModal} />
          ) : (
            <SimulatorForm
              values={values}
              setField={setField}
              closeModal={() => setOpenModal()}
              sendForm={async form => {
                const formValues = { ...form }
                delete formValues.property_id
                await sendForm(formValues)
                window.scrollTo(0, 0)
              }}
              setTouched={setTouched}
              touched={touched}
              handleClose={handleClose}
              loading={loading}
              noModal={noModal}
            />
          )}
        </>
      ) : (
        <Modal
          hideCloseBtn
          opened={getOpenModal()}
          closeModal={handleClose}
          className={classNames(styles.modalClass, {
            [styles.success]: success,
          })}
        >
          {success ? (
            <SuccesCard handleClose={handleClose} />
          ) : (
            <SimulatorForm
              values={values}
              setField={setField}
              closeModal={() => setOpenModal()}
              setTouched={setTouched}
              touched={touched}
              handleClose={handleClose}
              noModal={noModal}
            />
          )}
        </Modal>
      )}
    </>
  )
}
SimulatorModalContainer.propTypes = {
  noModal: PropTypes.bool,
}
SimulatorModalContainer.defaultProps = {
  noModal: false,
}
export default SimulatorModalContainer
