/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lib/noop'
import classNames from 'classnames'

import {
  AprraisedValue,
  Discount,
  SaleValueText,
  JudicialSaleCard,
} from 'components'

import styles from './ActionValuesContainer.module.scss'

const ActionValuesContainer = ({
  hasPromotion,
  isJudicialSale,
  juridical,
  mainValue,
  appraisedPrice,
  appraisedLowerThanMain,
  appraisedLabel,
  showDiscount,
  desagio,
  hideValue,
  mainValueLabel,
  callToActionConsiderations,
  campaignTotalValue,
  getNameCampaign,
}) => {
  const isAprraisedValue =
    mainValue !== appraisedPrice && !appraisedLowerThanMain

  return (
    <div
      className={classNames(styles.valuesContainer, {
        [styles.activeCampaign]: hasPromotion,
      })}
    >
      <JudicialSaleCard show={isJudicialSale} juridicalData={juridical} />

      <AprraisedValue
        show={isAprraisedValue}
        value={appraisedPrice}
        type="row"
        label={appraisedLabel}
        strike={mainValue !== appraisedPrice}
        isCampaign={hasPromotion}
      />
      <Discount show={showDiscount} percent={desagio} />
      <SaleValueText
        value={hideValue ? null : mainValue}
        saleLabel={mainValueLabel}
        asterisk={!!callToActionConsiderations.length}
        isCampaign={hasPromotion}
      />
      <SaleValueText
        show={!!hasPromotion}
        saleLabel={`Valor ${getNameCampaign()}`}
        value={campaignTotalValue}
        propertyCard
        asterisk
        className={styles.promotionPrice}
      />
    </div>
  )
}

ActionValuesContainer.propTypes = {
  hasPromotion: PropTypes.bool,
  isJudicialSale: PropTypes.bool,
  juridical: PropTypes.shape({
    scorePoints: PropTypes.number.isRequired,
  }).isRequired,
  appraisedLowerThanMain: PropTypes.bool,
  showDiscount: PropTypes.bool,
  hideValue: PropTypes.bool,
  campaignTotalValue: PropTypes.number.isRequired,
  callToActionConsiderations: PropTypes.arrayOf(PropTypes.string),
  appraisedPrice: PropTypes.number.isRequired,
  appraisedLabel: PropTypes.string.isRequired,
  mainValue: PropTypes.number.isRequired,
  desagio: PropTypes.number.isRequired,
  getNameCampaign: PropTypes.func,
  mainValueLabel: PropTypes.string.isRequired,
}

ActionValuesContainer.defaultProps = {
  callToActionConsiderations: [],
  appraisedLowerThanMain: false,
  hasPromotion: false,
  showDiscount: false,
  hideValue: false,
  isJudicialSale: false,
  getNameCampaign: noop,
}

export default React.memo(ActionValuesContainer)
