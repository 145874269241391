import React from 'react'
import { Button } from 'components'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './CookiesDesktop.module.scss'

const CookiesDesktop = ({ acceptCookies, cookies, hideCookies }) => {
  if (!cookies || hideCookies) return null

  return (
    <div
      className={classNames(styles.container, {
        [styles.showingCookie]: cookies,
      })}
      id="cookies"
    >
      <span>
        A Resale utiliza cookies e outras tecnologias para otimizar as
        funcionalidades do site e melhorar sua experiência de compra. Ao
        continuar navegando você concorda com nossa política de privacidade.
        <a href="/termos"> Saiba mais.</a>
      </span>
      <Button onClick={acceptCookies} className={styles.button}>
        CONCORDAR E FECHAR
      </Button>
    </div>
  )
}

CookiesDesktop.propTypes = {
  acceptCookies: PropTypes.func.isRequired,
  cookies: PropTypes.bool.isRequired,
  hideCookies: PropTypes.bool.isRequired,
}

export default React.memo(CookiesDesktop)
