import { toastError } from 'components/ToastComponent/ToastComponent'
import api, { apiWithoutPropertySuffix } from 'service/api'
import { formatDateToPreActive } from 'store/property/utils'
import { to } from 'nh-utils'
import isConcurrenceProperty from 'utils/isConcurrenceProperty'
import parseFeatures from 'utils/parseFeatures'
import handleTags from 'utils/tags'
import { getDefaultPropertyImages } from './utils'

export const getEstatesList = async query => {
  const [err, estates] = await to(api.get(`${query}`))

  if (!estates) {
    toastError('Erro ao buscar propriedades.')
    return { error: true, code: err || 500 }
  }

  const { data = {} } = estates || {}
  const apiList = data.data || []

  const newList = apiList.map((estate, index) => {
    const {
      id,
      nome_imovel, //eslint-disable-line
      foto_capa, //eslint-disable-line
      endereco,
      descricao,
      caracteristicas,
      valores,
      desagio,
      tags,
      status_da_venda,
      tipo_imovel,
      id_banco,
      categoria_venda,
      imagens,
      data_inicio_campanha = null,
      data_fim_campanha = null,
      cupom,
      pre_ativo_expiracao,
      campanhas,
    } = estate

    const { valor_total_campanha = 0, valor_campanha = 0 } = valores

    const { photo, longPhoto } = getDefaultPropertyImages(
      foto_capa,
      tipo_imovel
    )

    const isPreActive = !!pre_ativo_expiracao
    const isPreActiveExpired =
      pre_ativo_expiracao &&
      new Date().getTime() >=
        new Date(formatDateToPreActive(pre_ativo_expiracao)).getTime()

    return {
      id,
      propertySmallId: id_banco,
      name: nome_imovel,
      photo,
      longPhoto,
      address: endereco.endereco_completo,
      saleCategory: categoria_venda,
      picsArray: Array.isArray(imagens) && imagens.map(el => el.url),
      campaignInfo: {
        values: {
          campaignValue: valor_campanha,
          campaignTotalValue: valor_total_campanha,
        },
        dates: {
          campaignStartDate: data_inicio_campanha,
          campaignEndData: data_fim_campanha,
        },
      },
      city: endereco.cidade,
      state: endereco.estado,
      description: descricao,
      features: parseFeatures(caracteristicas),
      appraisedValue: valores.valor_avaliado,
      appraisedLabel: valores.valor_avaliado_label,
      desagio,
      saleValue: valores.valor_venda,
      saleLabel: isConcurrenceProperty(handleTags({ tags }))
        ? 'Proposta Mínima'
        : valores.valor_venda_label || 'Valor de venda',
      tags,
      alertCard:
        (index === apiList.length - 1 && apiList.length < 4) ||
        (index + 1) % 4 === 0,
      soldStatus: status_da_venda,
      category: tipo_imovel,
      coupon: cupom,
      campaignsData: campanhas || [],
      isPreActive,
      isPreActiveExpired,
    }
  })

  return { list: newList, pagination: data.pagination || {} }
}

export const existImg = async url => to(url.get(''))

export const bannerImage = async () =>
  to(apiWithoutPropertySuffix.get('list/banner'))
