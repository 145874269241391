import React from 'react'
import PropTypes, { number, string } from 'prop-types'
import styles from './ValuesContainer.module.scss'

const ValuesContainer = ({ valuesSection }) => {
  if (!valuesSection) return null

  return (
    <section className={styles.valuesContainer}>
      {valuesSection.map(
        value =>
          !!value.state && (
            <p key={value.content} className={styles[value.className]}>
              {value.content}{' '}
              {value.format ? (
                <span>R$ {value.state}</span>
              ) : (
                <span>{value.state}</span>
              )}
            </p>
          )
      )}
    </section>
  )
}
ValuesContainer.propTypes = {
  valuesSection: PropTypes.arrayOf({
    state: PropTypes.oneOf([Date, string, number]).isRequired,
    content: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    format: PropTypes.bool.isRequired,
  }).isRequired,
}
export default React.memo(ValuesContainer)
