import React from 'react'
import PropTypes from 'prop-types'
import * as Icons from 'assets/svgs'

export const ICONS_TYPES = Object.keys(Icons).map(_icon =>
  _icon.toLocaleLowerCase()
)

const getIcon = type =>
  Object.keys(Icons).find(_icon => _icon.toLocaleLowerCase() === type)

const Icon = ({ size, color, type, style, className, rotate, id }) => {
  return (
    <span
      id={id}
      className={className}
      style={{ width: `${size}px`, height: `${size}px`, ...style }}
    >
      {React.createElement(Icons[getIcon(type)], { color, size, rotate })}
    </span>
  )
}

Icon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.shape({})),
  color: PropTypes.oneOf([
    'primaryColor',
    'secondColor',
    'darkColor',
    'whiteColor',
    'grayColor',
  ]),
  type: PropTypes.oneOf(ICONS_TYPES).isRequired,
  className: PropTypes.string,
  rotate: PropTypes.number,
  id: PropTypes.string,
}

Icon.defaultProps = {
  color: 'primaryColor',
  size: 36,
  style: {},
  className: '',
  rotate: 90,
  id: '',
}

export default React.memo(Icon)
