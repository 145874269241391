import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

function useOutsideAlerter(ref, action) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      // alert('You clicked outside of me!')
      action()
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })
}

/**
 * Component that alerts if you click outside of it
 */
function OutsideAlerter({ children, action, className }) {
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, action)

  return (
    <div ref={wrapperRef} className={className}>
      {children}
    </div>
  )
}

OutsideAlerter.propTypes = {
  children: PropTypes.node.isRequired,
  action: PropTypes.func.isRequired,
  className: PropTypes.string,
}

OutsideAlerter.defaultProps = {
  className: '',
}

export default OutsideAlerter
