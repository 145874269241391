import { useState, useEffect, useContext } from 'react'
import { RootContext } from 'store/root'
import { useCampaigns } from 'hooks'
import useCreateStore from '../../lib/useCreateStore'
import propertyActions from './actions'
import propertySelectors from './selectors'

// Store para armazenar os dados globais da aplicação

const initialState = {
  id: null,
  walletId: null,
  data: null,
  preview: false,
  loadingProperty: false,
  propertyIsPreActive: false,
}

const PropertyStore = useCreateStore(() => {
  const [$property, setProperty] = useState(initialState)
  const { isAnActiveCampaign } = useCampaigns()
  const actions = propertyActions({ setProperty, isAnActiveCampaign })
  const selectors = propertySelectors({ $property, isAnActiveCampaign })
  const { setError } = useContext(RootContext)

  useEffect(() => {
    const fetchProperty = async () => {
      const myActions = propertyActions({
        setProperty,
        setError,
        isAnActiveCampaign,
      })
      myActions.setPropertyLoading(true)
      await myActions.fetchProperty(
        $property.id,
        $property.preview,
        $property.walletId
      )
      myActions.setPropertyLoading(false)
    }

    fetchProperty()
  }, [$property.id]) //eslint-disable-line

  useEffect(() => {
    if ($property.data && $property.data.preActive) {
      actions.setPropertyIsPreActive(true)
    } else {
      actions.setPropertyIsPreActive(false)
    }
    // eslint-disable-next-line
  }, [$property.data])

  return { $property, ...actions, ...selectors }
})

export const useProperty = () => PropertyStore()
export const PropertyContext = PropertyStore.Context
export const PropertyProvider = PropertyStore.Provider
