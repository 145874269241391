import React, { useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import { useHome, useRoot } from 'store'
import { isMobile } from 'utils/deviceDetector'
import styles from './BannerCarousel.module.scss'
import { CarouselHome } from './Carousel'

const BannerCarousel = ({ imageList, autoPlay }) => {
  const {
    $home: { banners, isLoadingBanners },
    fetchBanner,
  } = useHome()
  const {
    $root: { campaigns, campaignsLoading },
  } = useRoot()
  useLayoutEffect(() => {
    fetchBanner(campaigns)
    // eslint-disable-next-line
  }, [fetchBanner, campaigns])

  const placeholderHeight = !isMobile ? 150 : 500

  const placeholder = (
    <ContentLoader height={placeholderHeight} className={styles.loader}>
      <rect x="0" y={0} rx="3" ry="3" width="100%" height={placeholderHeight} />
    </ContentLoader>
  )
  const bannersList = [...imageList, ...banners]

  if (isLoadingBanners || campaignsLoading) return placeholder
  if (!bannersList.length) return null

  return (
    <section id="carousel-home-container" className={styles.carousel}>
      <CarouselHome
        bannersList={banners}
        autoPlay={bannersList.length > 1 || autoPlay}
      />
    </section>
  )
}

BannerCarousel.propTypes = {
  imageList: PropTypes.arrayOf(
    PropTypes.shape({
      mobileSrc: PropTypes.string,
      normalSrc: PropTypes.string,
      largeSrc: PropTypes.string,
      link: PropTypes.string,
      alt: PropTypes.string,
    })
  ),
  autoPlay: PropTypes.bool,
}

BannerCarousel.defaultProps = {
  imageList: [],
  autoPlay: false,
}

export default React.memo(BannerCarousel)
