import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const ServiceRoom = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="serviceRoom"
          d="M28.828 4c-.647 0-1.172.516-1.172 1.152v5.692H24.22c-2.77 0-5.09 1.925-5.652 4.485H7.781C4.593 15.329 2 17.879 2 21.012v6.798c0 .636.525 1.152 1.172 1.152.647 0 1.172-.516 1.172-1.152v-3.38h23.312v3.38c0 .636.525 1.152 1.172 1.152.647 0 1.172-.516 1.172-1.152V5.152C30 4.516 29.475 4 28.828 4zm-1.172 9.149v2.18h-6.65a3.44 3.44 0 0 1 3.213-2.18h3.437zM4.344 22.126v-1.114c0-1.863 1.542-3.38 3.437-3.38h19.875v4.494H4.344z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#serviceRoom" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#serviceRoom"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

ServiceRoom.propTypes = {
  ...propTypesSvg,
}

ServiceRoom.defaultProps = {
  ...defaultPropTypesSvg,
}

export default ServiceRoom
