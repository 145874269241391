import React from 'react'
import PropTypes from 'prop-types'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const expenses = ({ viewBox, color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="expenses"
          d="M23.768 14.242c0 .59-.482 1.067-1.076 1.067h-8.465a1.072 1.072 0 0 1-1.076-1.067c0-.59.482-1.067 1.076-1.067h8.465c.594 0 1.076.477 1.076 1.067zm-1.076 3.13c.594 0 1.076.478 1.076 1.067 0 .59-.482 1.067-1.076 1.067h-8.465a1.072 1.072 0 0 1-1.076-1.067c0-.59.482-1.067 1.076-1.067h8.465zm-4.233 4.198c.595 0 1.077.477 1.077 1.067 0 .589-.482 1.067-1.076 1.067h-4.233a1.072 1.072 0 0 1-1.076-1.067c0-.59.482-1.067 1.076-1.067h4.232zM28 9.546v17.786C28 28.803 26.793 30 25.31 30H11.609c-1.483 0-2.69-1.197-2.69-2.668V9.546c0-1.47 1.207-2.668 2.69-2.668H25.31c1.483 0 2.69 1.197 2.69 2.668zm-2.152 0a.536.536 0 0 0-.538-.533H11.609a.536.536 0 0 0-.538.533v17.786c0 .294.241.534.538.534H25.31c.297 0 .538-.24.538-.534V9.546zm-2.767-4.619H20.93v-.26a.536.536 0 0 0-.538-.533H6.69a.536.536 0 0 0-.538.534v17.786c0 .294.241.533.538.533h.261v2.135H6.69c-1.483 0-2.69-1.197-2.69-2.668V4.668C4 3.197 5.207 2 6.69 2h13.701c1.483 0 2.69 1.197 2.69 2.668v.259z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#expenses" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#expenses"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

expenses.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ...propTypesSvg,
}

expenses.defaultProps = {
  size: 'auto',
  ...defaultPropTypesSvg,
}

export default expenses
