import React from 'react'
import PropTypes from 'prop-types'

import { Button } from 'components'
import { Lock } from 'assets/svgs'

import styles from './ActionButton.module.scss'

const ActionButton = ({
  show,
  callToActionLabel,
  callToActionUrl,
  isConcurrenceOver,
  isAuction,
  handleActionButton,
  isPreActive,
  idButton,
}) => {
  const notFinishedActionLabel = callToActionLabel !== 'Encerrado'
  const sold = callToActionLabel === 'Vendido'
  const auctionWithoutUrl = isAuction && !callToActionUrl
  const isConcurrenceOverOrPreActive = isConcurrenceOver || isPreActive

  const callToActionButtonDisabled =
    ((sold || auctionWithoutUrl) && notFinishedActionLabel) ||
    isConcurrenceOverOrPreActive

  return (
    show && (
      <Button
        id={idButton}
        name="possibleFixedButton"
        className={styles.largeButton}
        type="filled"
        onClick={handleActionButton}
        fontSize="medium"
        disabled={callToActionButtonDisabled}
      >
        {isPreActive && <Lock size={20} color="#FFF" />} {callToActionLabel}
      </Button>
    )
  )
}

ActionButton.propTypes = {
  callToActionLabel: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  callToActionUrl: PropTypes.string,
  isConcurrenceOver: PropTypes.bool.isRequired,
  isAuction: PropTypes.bool.isRequired,
  handleActionButton: PropTypes.func.isRequired,
  isPreActive: PropTypes.bool.isRequired,
  idButton: PropTypes.string,
}

ActionButton.defaultProps = {
  callToActionUrl: '',
  idButton: '',
}

export default React.memo(ActionButton)
