import { useEffect } from 'react'
import isPreRendering from 'utils/isPreRendering'

import { gaPageView } from 'utils/ga'

export const usePageView = () => {
  useEffect(() => {
    if (!isPreRendering()) {
      gaPageView()
    }
  }, [])
}
