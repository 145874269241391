export const defaultFetcher = async (
  storeSetter,
  { error, loading, stateKey },
  request,
  serializer,
  numberOfProperties = 0
) => {
  storeSetter(prev => ({
    ...prev,
    [loading]: true,
  }))

  const [err, result] = await request()
  if (err || !result || !result.data || !result.data.data) {
    storeSetter(prev => ({
      ...prev,
      [loading]: false,
      [error]: true,
    }))

    return false
  }

  let serializedData = serializer(result.data.data)

  if (numberOfProperties) {
    serializedData = serializedData.splice(0, numberOfProperties)
  }

  return storeSetter(prev => ({
    ...prev,
    [loading]: false,
    [stateKey]: serializedData,
  }))
}
