import React from 'react'
import PropTypes from 'prop-types'

const lock = ({ viewBox, color, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 8C10 4.68629 12.6863 2 16 2C19.3137 2 22 4.68629 22 8V11.083C24.8377 11.559 27 14.027 27 17V24C27 27.3137 24.3137 30 21 30H11C7.68629 30 5 27.3137 5 24V17C5 14.027 7.16229 11.559 10 11.083V8ZM20 8V11H12V8C12 5.79086 13.7909 4 16 4C18.2091 4 20 5.79086 20 8ZM11 13C8.79086 13 7 14.7909 7 17V24C7 26.2091 8.79086 28 11 28H21C23.2091 28 25 26.2091 25 24V17C25 14.7909 23.2091 13 21 13H11ZM18 19C18 19.7403 17.5978 20.3866 17 20.7324V24C17 24.5523 16.5523 25 16 25C15.4477 25 15 24.5523 15 24V20.7324C14.4022 20.3866 14 19.7403 14 19C14 17.8954 14.8954 17 16 17C17.1046 17 18 17.8954 18 19Z"
      />
    </svg>
  )
}

lock.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  viewBox: PropTypes.string,
  color: PropTypes.oneOf([
    'primaryColor',
    'darkColor',
    'whiteColor',
    'greenColor',
  ]),
}

lock.defaultProps = {
  size: 'auto',
  viewBox: '0 0 32 32',
  color: 'primaryColor',
}

export default lock
