import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FaFileWord, FaFilePdf } from 'react-icons/fa'
import { Text, Modal, Loading } from 'components'
import objectToQueryString from 'lib/objectToQueryString'
import { isBrowser } from 'utils/isBrowser'
import DisclaimerModal from 'components/DisclaimerModal/DisclaimerModal'
import styles from './DocumentMiniature.module.scss'

const imageTypes = ['jpeg', 'jpg', 'png']

const DocumentMiniature = ({ url, type, fileName, parameters, modalProps }) => {
  const [showDisclaimer, setShowDisclaimer] = useState(true)

  const widthScreen = isBrowser ? window.innerWidth : 1024
  const isMobile = widthScreen < 700
  const isPDF = type === 'pdf'
  const isDocx = type === 'docx'
  const isImage = imageTypes.includes(type)
  const showMiniature = !isMobile && isPDF && isImage

  const queryString = objectToQueryString(parameters)
  const src = parameters ? `${url}#${queryString}` : url

  const closeDisclaimer = () => setShowDisclaimer(!showDisclaimer)

  return (
    <div className={styles.docWrapper}>
      <div className={styles.document}>
        {showMiniature && (
          <>
            <embed
              src={isImage ? url : src}
              height={89}
              width={180}
              className={styles.documentMiniature}
            />
            <button
              type="button"
              className={styles.onClickPanel}
              onClick={() => modalProps.closeModal()}
            />
          </>
        )}
        {isDocx ? (
          <a href={src} download="Imóvel Resale" className={styles.link}>
            <FaFileWord
              className={styles.icon}
              onClick={() => !isDocx && modalProps.closeModal()}
            />
          </a>
        ) : (
          <FaFilePdf
            className={styles.icon}
            onClick={() => !isDocx && modalProps.closeModal()}
          />
        )}
      </div>
      <Text fontSize="medium" color="lightTextColor">
        {fileName}
      </Text>
      {modalProps.opened && (
        <>
          <Modal
            {...modalProps}
            className={classNames(styles.documentMiniatureModal, {
              [styles.imageFileModal]: isImage,
            })}
          >
            {isImage ? (
              <img src={url} alt={`${fileName}`} className={styles.imageFile} />
            ) : (
              <iframe
                id="pdfviewer"
                src={`https://docs.google.com/gview?embedded=true&url=${src}&amp;embedded=true`}
                loading="lazy"
                frameBorder="0"
                width="100%"
                height="100%"
                title="Documentos do imóvel"
                className={styles.iframe}
              />
            )}
            <div className={styles.loading}>
              <Loading size="medium" />
            </div>
          </Modal>

          {showDisclaimer && (
            <DisclaimerModal opened={showDisclaimer} close={closeDisclaimer} />
          )}
        </>
      )}
    </div>
  )
}

DocumentMiniature.propTypes = {
  url: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  parameters: PropTypes.shape({
    page: PropTypes.number, // specifies a number (integer) of the page in the document. It starts on 1.
    zoom: PropTypes.number, // sets the zoom and scroll factors, using float or integer values. A scale value of 100 indicates a zoom value of 100%.
    view: PropTypes.string, // set the view of the displayed page.
    scrollbar: PropTypes.oneOf([0, 1]), // turns scrollbars on or off.
    toolbar: PropTypes.oneOf([0, 1]), // turns the toolbar on or off.
    statusbar: PropTypes.oneOf([0, 1]), // turns the status bar on or off.
    navpanes: PropTypes.oneOf([0, 1]), // turns the navigation panes and tabs on or off.
  }),
  modalProps: PropTypes.shape({
    opened: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
  }).isRequired,
}

DocumentMiniature.defaultProps = {
  parameters: {},
}

export default React.memo(DocumentMiniature)
