const fakePropertyId = '3cf8d3ad-2f86-10bb-582f-5c8be990874f'

const fakeContactInfo = {
  name: '',
  phone: '',
  logo: '',
}

const fakePaymentConditions = [
  {
    content: [
      '<strong>100%</strong> na assinatura do Contrato de Compra e Venda (CCV)',
      'Observação: a comissão de venda está inclusa no valor de sinal acima',
    ],
    title: 'A vista',
  },
]

export { fakePropertyId, fakeContactInfo, fakePaymentConditions }
