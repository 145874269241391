import React, { useEffect, useState } from 'react'
import { Widget } from '@typeform/embed-react'
import { BaseLayout } from 'templates'
import {
  PartnershipButton,
  Title,
  Breadcrumb,
  PartnershipDepoiments,
} from 'components'
import PropTypes from 'prop-types'
import { setUtms } from 'utils/Utms'
import styles from './Partnership.module.scss'
import { partnershipButtons, depoiments } from './partnershipData'

const Partnership = ({ preview, location: { search } }) => {
  const [formId, setFormId] = useState('')

  useEffect(() => {
    setUtms(search)
  }, [search])
  const chosenForm = partnershipButtons.filter(item => item.id === formId)[0]

  const buildBreadcrumb = () => {
    const steps = [
      { name: 'Home', route: '/' },
      {
        name: 'Seja um parceiro',
        route: '/seja-um-parceiro',
        onChange: () => setFormId(''),
      },
    ]

    steps.push({
      name: chosenForm.description,
      route: '/seja-um-parceiro',
    })
    return steps
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const RenderForm = () => {
    if (!formId.includes('https')) {
      return (
        <Widget
          id={formId}
          className={styles.form}
          hidden={{
            source: 'Emgea',
          }}
          hideFooter
          hideHeaders
        />
      )
    }

    return (
      <iframe
        className={styles.iframeForm}
        title={chosenForm.description}
        src={formId}
        allowFullScreen
      />
    )
  }

  return (
    <BaseLayout backgroundColor="white">
      {formId ? (
        <div className={styles.formContainer}>
          {!preview && (
            <div className={styles.breadCrumb}>
              <Breadcrumb steps={buildBreadcrumb()} blockNavigate={preview} />
            </div>
          )}

          <RenderForm />
        </div>
      ) : (
        <main>
          <div className={styles.main} id="partnership">
            <Title type="h1" fontSize="xlarge" className={styles.title}>
              Tenha uma parceria com a Resale para venda dos nossos imóveis!
            </Title>
            <section className={styles.partnershipButtons}>
              {partnershipButtons.map(({ description, iconType, id }) => (
                <PartnershipButton
                  key={id.toString()}
                  description={description}
                  iconType={iconType}
                  onClick={() => {
                    setFormId(id)
                  }}
                />
              ))}
            </section>
          </div>
          <div className={styles.containerDepoiments}>
            <Title
              type="h1"
              fontSize="xlarge"
              className={styles.titleDepoiments}
            >
              Saiba o que nossos parceiros estão falando
            </Title>
            <section className={styles.depoiments}>
              <PartnershipDepoiments data={depoiments} />
            </section>
          </div>
        </main>
      )}
    </BaseLayout>
  )
}
Partnership.propTypes = {
  preview: PropTypes.bool,
  location: {
    search: PropTypes.string,
  },
}

Partnership.defaultProps = {
  preview: false,
  location: {
    search: '',
  },
}

export default Partnership
