const getPage = () => {
  return `${document.location.pathname}${document.location.search}`
}

const getOriginalLocation = () => {
  const originalLocation = window.localStorage.getItem('originalLocation')

  return originalLocation
}

export const gaEvent = ({ category, action, label }) => {
  if (typeof window === 'undefined') {
    return
  }

  const event = {
    hitType: 'event',
  }

  Object.assign(
    event,
    category && { eventCategory: category },
    action && { eventAction: action },
    label && { eventLabel: label }
  )

  window.ga('send', event)
}

export const gaPageView = () => {
  if (typeof window === 'undefined') {
    return
  }

  window.ga('set', 'location', getOriginalLocation())
  window.ga('set', 'page', getPage())
  window.ga('send', 'pageview', window.location.pathname)
}
