import React, { useState } from 'react'
import classNames from 'classnames'
import { Icon } from 'components/'
import Maintenance from '../../assets/images/maintenance.png'
import styles from './MaintenanceNotification.module.scss'

const MaintenanceNotification = () => {
  const [isOpen, setIsopen] = useState(true)
  return (
    <aside
      className={classNames(styles.maintenanceContainer, {
        [styles.closed]: !isOpen,
      })}
    >
      <header className={styles.headerNotification}>
        <img
          loading="lazy"
          alt="Manutenção"
          src={Maintenance}
          className={styles.maintenance}
        />
        <div className={styles.titleContainer}>
          <h3 className={styles.subTitle}>13 de janeiro</h3>
          <h3 className={styles.title}>Manutenção programada</h3>
        </div>
        <span className={styles.divider} />
        <figure className={styles.iconContainer}>
          <button type="button" onClick={() => setIsopen(false)}>
            <Icon color="whiteColor" type="clear" size={26} />
          </button>
        </figure>
      </header>
      <p className={styles.info}>
        Nossa plataforma passará por uma manutenção programada. Por esse motivo,
        alguns serviços podem sofrer oscilações das 8h às 23h do dia 13 de
        janeiro de 2024.
      </p>
      <figure className={styles.iconContainerBig}>
        <button type="button" onClick={() => setIsopen(false)}>
          <Icon color="whiteColor" type="clear" size={26} />
        </button>
      </figure>
    </aside>
  )
}

export default React.memo(MaintenanceNotification)
