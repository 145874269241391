import { useEffect } from 'react'
import { useLocation } from '@reach/router'
import { parse } from 'query-string'
import { useAlert } from 'store'

function useAlertModal() {
  const { setOpenAlertModal } = useAlert()
  const { search } = useLocation()

  const params = parse(search)
  const isVisible = Boolean(params.openAlertModal)

  useEffect(() => {
    if (isVisible) {
      setOpenAlertModal()
    }
    return () => {}
  }, [search, isVisible, setOpenAlertModal])
}

export default useAlertModal
