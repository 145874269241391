import { getBanners } from 'service/campaigns'
import isPreRendering from 'utils/isPreRendering'
import * as providers from './provider'

export default ({ setHome }) => {
  const fetchBanner = async campaigns => {
    const { homeBanners } = getBanners({ campaigns })
    setHome(prev => ({
      ...prev,
      banners: homeBanners,
      isLoadingBanners: false,
    }))
  }

  const fetchBestSellingProperty = async () => {
    if (isPreRendering()) return
    setHome(prev => ({
      ...prev,
      bestSellersLoading: true,
    }))

    const [error, result] = await providers.fetchBestSellingProperty()

    if (error) {
      setHome(prev => ({
        ...prev,
        bestSellersLoading: false,
        bestSellersErr: true,
      }))
      // eslint-disable-next-line no-console
      return console.error('Erro ao buscar imagem do banner', error)
    }

    return setHome(prev => ({
      ...prev,
      bestSellersLoading: false,
      bestSellers:
        (result && result.data && result.data.data && result.data.data) || [],
    }))
  }

  return {
    fetchBanner,
    fetchBestSellingProperty,
  }
}
