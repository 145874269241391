import handleTags from 'utils/tags'
import { SALE_CAMPAIGNS } from 'service/campaigns'

export default ({ $list, isAnActiveCampaign }) => {
  const getListData = () => {
    const { estatesList, pagination } = $list

    const newEstatesList = estatesList.map(property => {
      const initialTags = property.tags || []

      const conditionalTags = [
        isAnActiveCampaign(SALE_CAMPAIGNS.feirao15) &&
          SALE_CAMPAIGNS.feirao15.test(property.coupon) &&
          'FEIRAO15',
        isAnActiveCampaign(SALE_CAMPAIGNS.cupom20) &&
          SALE_CAMPAIGNS.cupom20.test(property.coupon) &&
          'CUPOM20',
      ]

      const arrayTags = [...initialTags, ...conditionalTags].filter(
        tag => !!tag
      )

      return {
        ...property,
        tags: handleTags({ tags: arrayTags, isAnActiveCampaign }),
      }
    })

    return {
      list: newEstatesList,
      pagination,
    }
  }

  const isListLoading = () => $list.loadingList

  return { getListData, isListLoading }
}
