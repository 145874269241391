/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import ContentLoader from 'react-content-loader'
import { useCampaigns, UseParseTag } from 'hooks'
import { Breadcrumb } from 'components'
import { PropertyCardContainer } from 'containers'
import { useFilter, useProperty } from 'store'
import { extractTags } from 'utils/extractTags'
import {
  FunctionalPropertyBanner,
  PublicCompetition,
  PropertyIntroduction,
  JudicialSale,
  Juridical,
  PreSale,
  AuctionRegistration,
  Pendencies,
  Features,
  ImportantRemarks,
  AboutSection,
  Debts,
  Considerations,
  PaymentMethods,
  ValuesAndDiscounts,
  Expenses,
  SaleStage,
  JuridicalProcess,
  DataSection,
  DocumentsSection,
  LegalNotice,
} from './sections'
import styles from './PropertyDataContainer.module.scss'

// eslint-disable-next-line react/prop-types
const PropertyDataContainer = ({ id, preview, walletId }) => {
  const [propertyLock, setPropertyLock] = React.useState(false)
  const {
    getPropertyData,
    getLoadingProperty,
    setCurrentProperty,
    $property: { propertyIsPreActive },
  } = useProperty()

  const {
    filters: {
      arrayFilters: { localities },
    },
  } = useFilter().$filter

  const property = getPropertyData()
  React.useEffect(() => {
    setCurrentProperty(id, preview, walletId)
  }, [id, preview, walletId]) //eslint-disable-line

  React.useEffect(() => {
    if (propertyIsPreActive) {
      setPropertyLock(true)
    } else {
      setPropertyLock(false)
    }
  }, [propertyIsPreActive])

  const sanitizer = DOMPurify.sanitize

  const isPreSale = property && property.saleCategory === 'pre_venda'
  const auctionRegistration =
    property && property.saleCategory === 'averbacoes_leiloes_negativos'
  const isBestProposal =
    property && /melhor( |_)proposta/i.test(property.saleType)

  const buildBreadcrumb = () => {
    const steps = [
      { name: 'Home', route: '/' },
      {
        name: 'Imóveis',
        route: '/busca',
      },
    ]
    const { state, city } = property
    const { options } = localities
    const stateFormat =
      state && options.find(option => option.value === state)?.slug
    const cityFormat =
      city && options.find(option => option.name === `${city} - ${state}`)?.slug
    if (state) {
      const statePush = {
        name: state,
        route: `/busca?order=relevante&search=${stateFormat}`,
      }
      steps.push(statePush)
    }
    if (state && city) {
      const cityPush = {
        name: city,
        route: `/busca?order=relevante&search=${stateFormat},${cityFormat}`,
      }
      steps.push(cityPush)
    }

    steps.push({
      name: property.propertyName,
      route: `/imovel/${id}`,
    })

    return steps
  }

  const placeholder = (
    <ContentLoader height={800} className={styles.contentLoader}>
      <rect
        x="0"
        y="20"
        rx="3"
        ry="3"
        width="100%"
        height="10"
        className={styles.breadcrumb}
      />
      <rect x="0" y="40" rx="3" ry="3" width="100%" height="250" />
      {[4, 5, 6, 7, 8, 9, 10].map(i => {
        return (
          <rect
            key={i}
            x="0"
            y={i * 90 - 60}
            rx="3"
            ry="3"
            width="100%"
            height="80"
          />
        )
      })}
    </ContentLoader>
  )
  const { hasActiveCampaign, isAnActiveCampaign, campaigns } = useCampaigns()

  const propertyStage =
    property && property.isBestProposal
      ? 'propriedade_credor'
      : property && property.stage

  const initialTags = (property && property.tags) || []

  const dontRenderTags = ['Corujão']
  const conditionalTags = []
  const campaignsData = property && property?.campaignsData
  const hasCampaigns = campaignsData && campaignsData.length
  const tagsByApi =
    extractTags({
      campaigns,
      campaignsPropertyData: campaignsData,
      hasCampaignsOnProperty: hasCampaigns,
    }) || []
  const arrayTags = UseParseTag(
    [...tagsByApi, ...conditionalTags, ...initialTags],
    isAnActiveCampaign
  )
    .filter(tag => !!tag)
    .filter(tag => !dontRenderTags.includes(tag))
  const video = property?.videos
  const videoPropertySpecific =
    video && video.find(f => f.property_specific)?.property_specific
  const videoCompetitionTutorial =
    video && video.find(f => f.competition_tutorial)?.competition_tutorial
  return (
    <article className={styles.propertyData}>
      {(getLoadingProperty() || !property) && placeholder}
      {!getLoadingProperty() && property && (
        <>
          <div className={styles.breadcrumb}>
            <Breadcrumb steps={buildBreadcrumb()} blockNavigate={preview} />
          </div>
          <FunctionalPropertyBanner
            propertyData={{
              smallId: property.propertySmallId,
              address: property.address,
              location: property.location,
              map: property.map,
              imgs: property.images,
              video: property.movie || videoPropertySpecific,
              externalTour: property.externalTour,
              internalTour: property.internalTour,
              category: `${property.category}Large`,
            }}
            isPreActive={propertyLock}
          />
          <PublicCompetition
            isPublicCompetition={property.isPublicCompetition}
            auctionInfo={property.auctionInfo}
            video={isBestProposal && videoCompetitionTutorial}
            document={property.document[0] && property.document[0].url}
          />
          <PropertyIntroduction
            propertyData={{
              isJudicialSale: property.isJudicialSale,
              isFriendlySale: property.isFriendlySale,
              tags: arrayTags,
              propertySmallId: property.propertySmallId,
              propertyName: property.propertyName,
              address: property.address,
              preActive: property.preActive,
            }}
            campaigns={campaigns}
            campaignsData={campaignsData}
            coupon={property && property.coupon}
            isAnActiveCampaign={isAnActiveCampaign}
          />
          <div className={styles.card}>
            <PropertyCardContainer preview={preview} />
          </div>
          <PreSale isPreSale={isPreSale} />
          <JudicialSale isJudicialSale={property.isJudicialSale} />
          <Juridical juridical={property.juridical} />
          <AuctionRegistration auctionRegistration={auctionRegistration} />
          <Pendencies pendencies={property.pendencias_categoria} />
          <Features
            features={property.features}
            propertyName={property.propertyName}
          />
          <ImportantRemarks
            importantRemarks={property.importantRemarks}
            sanitizer={sanitizer}
          />
          <AboutSection
            description={property.description}
            sanitizer={sanitizer}
          />
          <Debts debts={property.debts} />
          <Considerations
            considerations={property.considerations}
            sanitizer={sanitizer}
          />
          <PaymentMethods conditions={property.conditions} />
          <ValuesAndDiscounts
            hideConditions={property.hideConditions}
            desagio={property.desagio}
            appraisedPrice={property.appraisedPrice}
            mainValue={property.mainValue}
            campaignInfo={property.campaignInfo}
            tags={property.tags}
            salePrice={property.salePrice}
            mainValueLabel={property.mainValue}
            appraisedLabel={property.appraisedLabel}
            hasActiveCampaign={hasActiveCampaign}
          />
          <Expenses expenses={property.expenses} />
          <SaleStage stage={propertyStage} />
          <JuridicalProcess
            stage={property.stage}
            judicialProcessTimeline={property.juridical.judicialProcessTimeline}
          />
          <DataSection
            rgi={property.rgi}
            cityHallRegistration={property.cityHallRegistration}
            registration={property.registration}
          />
          <DocumentsSection documents={property.documents} />
          <LegalNotice />
        </>
      )}
    </article>
  )
}

PropertyDataContainer.propTypes = {
  id: PropTypes.string.isRequired,
  preview: PropTypes.bool,
  walletId: PropTypes.string,
}

PropertyDataContainer.defaultProps = {
  preview: false,
  walletId: '',
}

export default PropertyDataContainer
