import React from 'react'
import PropTypes from 'prop-types'

import { CountDown, Text } from 'components'
import { formatDate } from '../CallToAction/utils'

import styles from './ActionTime.module.scss'

const ActionTime = ({ show, isBestProposal, auctionInfo }) => {
  if (!show) return null

  const dateFormat =
    auctionInfo[0] &&
    new Date(formatDate(auctionInfo[0].data, auctionInfo[0].hora))

  return (
    <div className={styles.timer}>
      <div className={styles.timerLabel}>
        <Text fontSize="medium">
          Essa{' '}
          {isBestProposal ? (
            <span className={styles.textBold}>Melhor Proposta</span>
          ) : (
            'concorrência'
          )}{' '}
          acaba em:
        </Text>
      </div>
      <div className={styles.countdown}>
        <CountDown endDate={dateFormat} />
      </div>
    </div>
  )
}

ActionTime.propTypes = {
  auctionInfo: PropTypes.objectOf(PropTypes.string).isRequired,
  show: PropTypes.bool,
  isBestProposal: PropTypes.bool,
}

ActionTime.defaultProps = {
  isBestProposal: false,
  show: false,
}

export default React.memo(ActionTime)
