import React from 'react'
import PropTypes from 'prop-types'
import styles from './svg.module.scss'

const SuccessIcon = ({ color }) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="40" cy="40" r="40" fill="none" className={styles[color]} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.8637 48.1785C37.7749 49.2738 36.0104 49.2738 34.9223 48.1785L28.8145 42.0343C27.7285 40.9425 27.7285 39.1647 28.8145 38.0694C29.9033 36.9776 31.6699 36.9776 32.7559 38.0694L36.3962 41.7293C36.67 42.0068 37.116 42.0068 37.3919 41.7293L47.2408 31.8209C48.3296 30.7264 50.0941 30.7264 51.1822 31.8209C51.7045 32.3464 52 33.0592 52 33.8023C52 34.5454 51.7045 35.2576 51.1822 35.783L38.8637 48.1785Z"
        fill="white"
      />
    </svg>
  )
}
SuccessIcon.propTypes = {
  color: PropTypes.oneOf([
    'primaryColor',
    'darkColor',
    'whiteColor',
    'greenColor',
    'grayColor',
  ]),
}

SuccessIcon.defaultProps = {
  color: 'primaryColor',
}

export default React.memo(SuccessIcon)
