/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import PropTypes from 'prop-types'
import { SelectSorting } from 'components'
import Text from 'components/Text/Text'
import classNames from 'classnames'
import styles from './Select.module.scss'

const Select = ({
  value,
  onChange,
  errorMessage,
  options,
  hasError,
  className,
  id,
  onBlur,
  label,
}) => {
  const wrapperClass = classNames(styles.select, className, {
    [styles.error]: hasError,
  })

  return (
    <div className={wrapperClass}>
      <label className={styles.title}>
        <Text fontSize="medium">{label}</Text>
        <SelectSorting
          id={id}
          onChange={onChange}
          value={value || ''}
          color={hasError ? 'error' : 'gray'}
          type="outline"
          iconPosition="right"
          fontSize="small"
          options={options}
          onBlur={onBlur}
        />
      </label>
      {hasError && <span className={styles.span}>{errorMessage}</span>}
    </div>
  )
}

Select.propTypes = {
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string.isRequired,
}

Select.defaultProps = {
  hasError: false,
  errorMessage: 'error',
  className: '',
  onBlur: () => {},
}

export default React.memo(Select)
