export default $providers => {
  const serializeMessagesProviders = messageProviders => {
    const serializedDepoiments = messageProviders.map(
      ({ depoimento, tipo, nome, cidade }) => {
        return {
          depoiment: depoimento,
          type: tipo,
          name: nome,
          subtitle: cidade,
        }
      }
    )
    return serializedDepoiments
  }

  return {
    $providers,
    serializeMessagesProviders,
  }
}
