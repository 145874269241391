/* eslint-disable react/prop-types */
import React from 'react'
import { getUtms } from 'utils/Utms'

import { MessageModal } from 'components'
import { useMessageModal, useProperty } from 'store'
import { fakePropertyId, fakeContactInfo } from 'utils/mockData'
import { setCustomerInfoLocalStorage } from 'utils/customerInfoLocalStorage'
import { normalizeStringAndChangeSpaces } from 'utils/normalizeString'

const MessageModalContainer = ({ locality }) => {
  const [loadingFetch, setloadingFetch] = React.useState(false)

  const {
    getForm,
    getOpenModal,
    sendForm,
    setField,
    setOpenModal,
  } = useMessageModal()

  const {
    getSaleType,
    getPropertyId,
    getContactInfo,
    getPropertySmallId,
    getPropertyData,
    getSaleTypeProperty,
  } = useProperty()
  const propertyId = getPropertyId() || fakePropertyId

  const property = getPropertyData()
  const contactInfo = getContactInfo() || fakeContactInfo

  const values = getForm()
  const handleConfirm = async (checkOffers, checkOffersResale) => {
    const utms = getUtms()

    const buyPartnership = /Compra de imóvel/i.test(values.subject.trim())

    const form = { ...utms }
    form.email = values.email.trim()
    form.message = values.message.trim()
    form.name = values.name.trim()
    form.phone = values.phone.replace(/[^0-9]/g, '')
    form.subject = buyPartnership
      ? `${values.subject?.trim()} - ${values.property_locality?.trim()}`
      : values.subject?.trim()
    form.category = values.category?.trim()

    setCustomerInfoLocalStorage({
      email: form.email,
      name: form.name,
      phone: form.phone,
    })

    const saleType = getSaleType()
    const propertySmallId = getPropertySmallId()
    setloadingFetch(true)
    const success = await sendForm({
      propertyId,
      form,
      saleType,
      propertySmallId,
      send_mail_emgea: checkOffers,
      send_mail_resale: checkOffersResale,
    })
    setloadingFetch(false)

    if (success) {
      setOpenModal()
    }
  }

  const saleTypeProperty = getSaleTypeProperty()

  const checkSaleTypeAndReturnLabel = () => {
    const labels = {
      melhor_proposta: 'fale_conosco_MP_enviado',
      venda_direta: 'fale_conosco_VD_enviado',
      leilao: 'btn_criar_fale_conosco_imovel_leilao',
    }

    return (
      labels[normalizeStringAndChangeSpaces(saleTypeProperty)] ||
      'btn_criar_fale_conosco_imovel'
    )
  }

  return (
    property && (
      <MessageModal
        idButton={checkSaleTypeAndReturnLabel()}
        closeModal={() => setOpenModal()}
        contactInfo={contactInfo}
        opened={getOpenModal()}
        sendForm={handleConfirm}
        setField={setField}
        values={values}
        propertyId={propertyId}
        isLoading={loadingFetch}
        locality={locality}
        property={property}
      />
    )
  )
}

export default MessageModalContainer
