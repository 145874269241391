/* eslint-disable react/no-danger */
import React from 'react'
import { CategoryTitle, Text } from 'components'
import PropTypes from 'prop-types'
import styles from './ImportantRemarks.module.scss'

const ImportantRemarks = ({ importantRemarks, sanitizer }) => {
  if (!importantRemarks) return null

  return (
    <section className={styles.importantRemarks}>
      <CategoryTitle icon="considerations" title="Observações importantes" />
      <Text fontSize="medium" color="lightTextColor">
        <span
          className={styles.strong}
          dangerouslySetInnerHTML={{
            __html: sanitizer(
              `<p>${importantRemarks.replace(/(?:\r\n|\r|\n)/g, '<br />')}</p>`
            ),
          }}
        />
      </Text>
    </section>
  )
}

ImportantRemarks.propTypes = {
  importantRemarks: PropTypes.string.isRequired,
  sanitizer: PropTypes.func.isRequired,
}

export default ImportantRemarks
