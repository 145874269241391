import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  setCustomerInfoLocalStorage,
  getCustomerInfoLocalStorage,
} from 'utils/customerInfoLocalStorage'

import { Text, Button, Input, Optins } from 'components'
import { useMessageForm, useRoot } from 'store'
import { validateEmail } from 'utils/validations'
import { toastError } from 'components/ToastComponent/ToastComponent'
import { getUtms } from 'utils/Utms'
import styles from './MessageFormContainer.module.scss'

const MessageFormContainer = ({ setRegisterState }) => {
  const [checkOffers, setCheckOffers] = useState(true)
  const [checkOffersResale, setCheckOffersResale] = useState(true)
  const { getForm, setField } = useMessageForm()

  const { sendEmail, getSendLeadEmail } = useRoot()
  const { sendLeadEmailLoading, sendLeadEmailError } = getSendLeadEmail()

  const { form } = getForm()

  const [touched, setTouched] = React.useState({})

  const handleErrorMessage = (value, errorMessage) =>
    value === '' ? 'Campo obrigatório' : errorMessage

  const handleBlur = field => setTouched({ ...touched, [field]: true })

  const onChange = e => {
    setTouched({ ...touched, [e.target.id]: false })
    setField(e.target.id, e.target.value)
  }
  const isDisabled = !checkOffers || !validateEmail(form.email)

  const handleConfirm = async () => {
    handleBlur('email')
    if (isDisabled) return

    setCustomerInfoLocalStorage({
      ...getCustomerInfoLocalStorage(),
      email: form.email.trim(),
    })
    const utms = getUtms()
    const newForm = { email: form.email, ...utms }
    await sendEmail(newForm, checkOffers, checkOffersResale, 'home')

    if (sendLeadEmailError) {
      toastError('Não foi possível enviar seu email!')
    } else if (setRegisterState) setRegisterState('form')
  }

  return (
    <div>
      <Text fontSize="medium" className={styles.formText}>
        Deixa que eu vou te ajudar, me diga o que você precisa que te
        encaminharei para nossa equipe.
      </Text>
      <Text bold fontSize="medium" className={styles.formText}>
        Pra começar, qual seu e-mail?
      </Text>
      <div className={styles.main}>
        <Input
          className={styles.inputEmail}
          label="E-mail*"
          labelClassName={styles.labelText}
          id="email"
          type="email"
          onBlur={() => handleBlur('email')}
          value={form.email}
          onChange={onChange}
          hasError={!validateEmail(form.email) && touched.email}
          errorMessage={handleErrorMessage(form.email, 'Email inválido')}
        />
      </div>
      <div className={styles.optins}>
        <Optins
          checkOffers={checkOffers}
          setCheckOffers={setCheckOffers}
          checkOffersResale={checkOffersResale}
          setCheckOffersResale={setCheckOffersResale}
        />
      </div>
      <div className={styles.buttonAlign}>
        <Button
          onClick={handleConfirm}
          className={styles.formButton}
          type="filled"
          textColor="whiteColor"
          fontSize="medium"
          disabled={sendLeadEmailLoading || isDisabled}
          isLoading={sendLeadEmailLoading}
        >
          Prosseguir
        </Button>
      </div>
    </div>
  )
}

MessageFormContainer.propTypes = { setRegisterState: PropTypes.func.isRequired }

export default MessageFormContainer
