import React from 'react'
import styles from './svg.module.scss'
import { propTypesSvg } from './propTypes'

const Video = ({ viewBox, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="video"
          d="M6 6h20a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2zm0 3v14a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm13.812 7.434l-6.493 3.71a.5.5 0 0 1-.748-.434v-7.42a.5.5 0 0 1 .748-.434l6.493 3.71a.5.5 0 0 1 0 .868z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#video" />
        </mask>
        <use
          className={styles[color]}
          // transform="rotate(45 16.04 14.04)"
          xlinkHref="#video"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

Video.propTypes = {
  ...propTypesSvg,
}

Video.defaultProps = {
  viewBox: '0 0 32 32',
  color: 'grayColor',
}

export default Video
