import React from 'react'
import PropTypes from 'prop-types'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const characteristics = ({ viewBox, color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="characteristics"
          d="M29 26.46A2.542 2.542 0 0 1 26.46 29h-1.47a1.016 1.016 0 1 1 0-2.031h1.47a.509.509 0 0 0 .509-.508v-9.394h-7.972a1.016 1.016 0 1 1 0-2.031h7.972V5.539a.508.508 0 0 0-.508-.508H14.988v1.98a1.016 1.016 0 0 1-2.031 0V5.03H5.539a.508.508 0 0 0-.508.508v9.445h7.926v-1.979a1.016 1.016 0 1 1 2.031 0v5.99a1.016 1.016 0 0 1-2.031 0v-1.98H5.03v9.446c0 .28.228.508.508.508h7.418v-1.98a1.016 1.016 0 1 1 2.031 0v1.98h4.007a1.016 1.016 0 1 1 0 2.031H5.539A2.541 2.541 0 0 1 3 26.46V5.54A2.542 2.542 0 0 1 5.54 3h20.92A2.542 2.542 0 0 1 29 5.54v20.92z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#characteristics" />
        </mask>
        <use
          className={styles[color]}
          fillRule="nonzero"
          xlinkHref="#characteristics"
        />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

characteristics.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ...propTypesSvg,
}

characteristics.defaultProps = {
  size: 'auto',
  ...defaultPropTypesSvg,
}

export default characteristics
