import { parseToString } from './parseNumbers'

const applyMask = (currentValue, mask) => {
  const maxAllowedLength = (mask.match(/\*/g) || []).length
  const onlyNumbers = parseToString(currentValue)

  if (onlyNumbers.length > maxAllowedLength) {
    return currentValue.slice(0, currentValue.length - 1)
  }

  const formatedValue = []
  let valueIndex = 0
  let maskIndex = 0
  while (valueIndex < onlyNumbers.length) {
    if (mask[maskIndex] === '*') {
      formatedValue.push(onlyNumbers[valueIndex])
      valueIndex += 1
    } else {
      formatedValue.push(mask[maskIndex])
    }
    maskIndex += 1
  }
  if (
    maxAllowedLength === onlyNumbers.length &&
    mask[mask.length - 1] !== '*'
  ) {
    formatedValue.push(mask[mask.length - 1])
  }
  return formatedValue.join('')
}

export default applyMask
