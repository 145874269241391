import { useState } from 'react'
import useCreateStore from '../../lib/useCreateStore'
import localityModalActions from './actions'
import localityModalSelectors from './selectors'

const initialState = {
  openModal: false,
  clickedBy: '',
}

const LocalityModalStore = useCreateStore(() => {
  const [$localityModal, setLocalityModal] = useState(initialState)

  const actions = localityModalActions(setLocalityModal)
  const selectors = localityModalSelectors($localityModal)

  return { $localityModal, ...actions, ...selectors }
})

export const useLocalityModal = () => LocalityModalStore()
export const LocalityModalContext = LocalityModalStore.Context
export const LocalityModalProvider = LocalityModalStore.Provider
