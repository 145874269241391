import React from 'react'
import PropTypes from 'prop-types'
import { CategoryTitle, Text } from 'components'

const JudicialSale = ({ isJudicialSale }) => {
  if (!isJudicialSale) return null

  return (
    <section>
      <CategoryTitle icon="question" title="Entenda a Venda Judicial" />
      <Text fontSize="medium" color="lightTextColor">
        <p>
          Uma nova modalidade de venda de imóvel na qual é possível se antecipar
          à oportunidade e fazer uma proposta de compra antes mesmo do leilão ou
          do encerramento do processo.
        </p>
        <br />
        <p>
          <b>Como funciona</b>
        </p>
        <br />
        <p>
          1. Você faz uma proposta de compra em venda direta ou pode participar
          de uma disputa digital, quando assim o vendedor determinar.
        </p>
        <p>
          2. No caso de proposta de compra, o primeiro que a completar com todos
          os documentos, terá sua proposta encaminhada para aprovação do
          vendedor do imóvel, mediante o pagamento de caução. No caso de
          disputa, a proposta ganhadora será encaminhada para aprovação do
          vendedor, também após o pagamento de caução.{' '}
        </p>
        <p>
          3. Depois da aprovação do vendedor, a primeira proposta de venda
          direta ou a proposta ganhadora da disputa digital será encaminhada
          pelo vendedor do imóvel para apreciação judicial e trâmites finais da
          liberação do imóvel para venda.{' '}
        </p>
        <p>
          4. Caso a proposta seja aprovada pelo juiz do processo de venda tem
          sequência. Caso negada, o valor da caução será devolvido.
        </p>
      </Text>
    </section>
  )
}

JudicialSale.propTypes = {
  isJudicialSale: PropTypes.bool.isRequired,
}

export default JudicialSale
