import { useState } from 'react'
import { getCustomerInfoLocalStorage } from 'utils/customerInfoLocalStorage'
import useCreateStore from '../../lib/useCreateStore'
import messageFormActions from './actions'
import messageFormSelectors from './selectors'

const initialState = {
  form: {
    name: getCustomerInfoLocalStorage().name || '',
    email: getCustomerInfoLocalStorage().email || '',
    subject: '',
    category: '',
    message: '',
    phone: '',
    property_locality: '',
  },
  sendFormLoading: false,
  sendFormError: false,
}

const MessageFormStore = useCreateStore(() => {
  const [$messageForm, setMessageForm] = useState(initialState)

  const actions = messageFormActions(setMessageForm)
  const selectors = messageFormSelectors($messageForm)

  return { $messageForm, ...actions, ...selectors }
})

export const useMessageForm = () => MessageFormStore()
export const MessageFormContext = MessageFormStore.Context
export const MessageFormProvider = MessageFormStore.Provider
