/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import noImage from 'assets/images/noPhoto.jpg'
import LazyLoad from 'vanilla-lazyload'
import classNames from 'classnames'

import styles from './Image.module.scss'

if (!document.lazyLoadInstance) {
  document.lazyLoadInstance = new LazyLoad({
    elements_selector: '.lazy',
  })
}

const Img = ({
  alt,
  title = alt,
  link,
  sourceMobile,
  source,
  sourceLarge,
  sourceXLarge,
  noPhoto,
  noPhotoLarge,
  noPhotoXLarge,
  noPhotoMobile,
  className,
  onClick,
}) => {
  const newSourceMobile =
    sourceMobile === `${process.env.REACT_APP_CDN}`
      ? noPhotoMobile
      : sourceMobile

  const newSource = source === `${process.env.REACT_APP_CDN}` ? noPhoto : source

  const newSourceLarge =
    sourceLarge === `${process.env.REACT_APP_CDN}` ? noPhotoLarge : sourceLarge

  const newSourceXLarge =
    sourceXLarge === `${process.env.REACT_APP_CDN}`
      ? noPhotoXLarge
      : sourceXLarge

  const Component = () => (
    <picture>
      <source
        className={classNames(styles.image, 'lazy')}
        media="(min-width: 1700px)"
        srcSet={`${newSourceXLarge}`}
      />
      <source
        className={classNames(styles.image, 'lazy')}
        media="(min-width: 1300px)"
        srcSet={`${newSourceLarge}`}
      />

      <source
        className={classNames(styles.image, 'lazy')}
        media="(max-width: 1024px)"
        srcSet={`${newSourceMobile}`}
      />

      <img
        className={classNames(styles.image, 'lazy')}
        loading="lazy"
        sizes="(100vw)"
        src={`${newSource}`}
        alt={alt}
      />
    </picture>
  )

  React.useEffect(() => {
    document.lazyLoadInstance.update()
  }, [])

  const handleLinks = internalLink => {
    if (internalLink.length) {
      if (
        internalLink?.includes('campanha-exclusiva=feirao') ||
        internalLink?.includes(
          'https://resale.com.br/busca?campanha-exclusiva=feirao&ordenacao=relevancia'
        )
      ) {
        return (
          <a href={internalLink} target="_blank" rel="noopener noreferrer">
            <Component />
          </a>
        )
      }
      if (
        internalLink?.includes(
          'https://marketing.resale.com.br/condicoes-blackout-emgea'
        )
      ) {
        return (
          <a href={internalLink} target="_blank" rel="noopener noreferrer">
            <Component />
          </a>
        )
      }
      if (internalLink?.includes('http')) {
        return (
          <a href={internalLink} target="_blank" rel="noopener noreferrer">
            <Component />
          </a>
        )
      }
      return (
        <a href={`/${internalLink}`} title={title} className={className}>
          <Component />
        </a>
      )
    }
    return onClick ? (
      <button type="button" onClick={onClick} className={styles.button}>
        <Component className={className} />
      </button>
    ) : (
      <Component className={className} />
    )
  }

  return <>{handleLinks(link)}</>
}

Img.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string,
  link: PropTypes.string,
  sourceMobile: PropTypes.string,
  source: PropTypes.string.isRequired,
  sourceXLarge: PropTypes.string,
  sourceLarge: PropTypes.string,
  noPhoto: PropTypes.string,
  noPhotoLarge: PropTypes.string,
  noPhotoMobile: PropTypes.string,
  noPhotoXLarge: PropTypes.string,
  className: PropTypes.string,
}

Img.defaultProps = {
  link: '',
  sourceMobile: '',
  sourceXLarge: '',
  sourceLarge: '',
  noPhotoMobile: noImage,
  noPhoto: noImage,
  noPhotoLarge: noImage,
  noPhotoXLarge: noImage,
  title: '',
  className: '',
}

export default React.memo(Img)
