import React, { useState } from 'react'

import { Processometer, Score, SectionCollapse } from 'components'

import PropTypes from 'prop-types'

import styles from './JudicialSaleCard.module.scss'

export default function JudicialSaleCard({ show, juridicalData }) {
  const [showStatus, setShowStatus] = useState(false)
  const [showExplanation, setShowExplanation] = useState(false)

  return (
    show && (
      <div className={styles.container}>
        <Processometer score={juridicalData.scorePoints} />
        <Score
          score={juridicalData.scorePoints}
          updatedAt={juridicalData.updatedAt}
        />
        <hr />
        <SectionCollapse
          title="Andamento do Processo"
          onClick={() => setShowStatus(previous => !previous)}
          toggle={showStatus}
        >
          <span>{juridicalData.processStatus}</span>
        </SectionCollapse>
        <hr />
        <SectionCollapse
          title="Entenda como funciona?"
          onClick={() => setShowExplanation(previous => !previous)}
          toggle={showExplanation}
        >
          <span>
            O processômetro é uma metodologia da Resale para informar, de forma
            lúdica e figurativa, o quão próximo o imóvel está de ser liberado
            para sua transmissão e aquisição definitiva, conforme o andamento do
            processo judicial.
            <br />
            <br />
            Legenda do processômetro
            <br />
            <br />
            1. Início da Ação
            <br />
            2. Citação
            <br /> 3. Indicação do Imóvel
            <br /> 4. Vinculação do Imóvel
            <br /> 5. Avaliação do Imóvel
            <br /> 6. Expropriação do Imóvel
          </span>
        </SectionCollapse>
        <hr />
      </div>
    )
  )
}

JudicialSaleCard.propTypes = {
  show: PropTypes.bool,
  juridicalData: {
    scorePoints: PropTypes.number.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }.isRequired,
}

JudicialSaleCard.defaultProps = {
  show: false,
}
