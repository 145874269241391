/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'
import { CategoryTitle, Text } from 'components'
import styles from './Considerations.module.scss'

const Considerations = ({ considerations, sanitizer }) => {
  if (!considerations) return null

  return (
    <section className={styles.considerations}>
      <CategoryTitle icon="considerations" title="Considerações importantes" />
      <Text fontSize="medium" color="lightTextColor">
        <span
          className={styles.strong}
          dangerouslySetInnerHTML={{
            __html: sanitizer(
              `<p>${considerations.replace(/(?:\r\n|\r|\n)/g, '<br />')}</p>`
            ),
          }}
        />
      </Text>
    </section>
  )
}

Considerations.propTypes = {
  considerations: PropTypes.string.isRequired,
  sanitizer: PropTypes.func.isRequired,
}

export default Considerations
