import React from 'react'
import { Title, FAQCard } from 'components'
import styles from './FAQProviderContainer.module.scss'
import questions from './questions'

const FAQProviderContainer = () => {
  const [toggleStates, setToggle] = React.useState(questions.map(() => false))
  const toggleFaq = index => {
    const newState = toggleStates.map((t, i) => (index === i ? !t : t))
    setToggle(newState)
  }
  return (
    <section className={styles.faqProviderSection}>
      <Title
        className={styles.mainTitle}
        fontSize="medium"
        color="darkBlueColor"
        type="h2"
      >
        Perguntas frequentes
      </Title>
      <div className={styles.container}>
        {questions.map((q, i) => (
          <FAQCard
            key={q.title}
            {...q}
            onClick={() => toggleFaq(i)}
            isActive={toggleStates[i]}
          />
        ))}
      </div>
    </section>
  )
}

export default FAQProviderContainer
