import React from 'react'
import PropTypes from 'prop-types'
import { CategoryTitle, Text } from 'components'

const DataSection = ({ rgi, cityHallRegistration, registration }) => {
  if (!rgi && !cityHallRegistration && !registration) return null

  return (
    <section>
      <CategoryTitle icon="data" title="Dados" />
      {!!registration && (
        <>
          <Text fontSize="medium" color="lightTextColor">
            {`Matrícula: ${registration}`}
          </Text>
          <br />
        </>
      )}
      {!!rgi && (
        <>
          <Text fontSize="medium" color="lightTextColor">
            {`RGI: ${rgi}`}
          </Text>
          <br />
        </>
      )}
      {!!cityHallRegistration && (
        <>
          <Text fontSize="medium" color="lightTextColor">
            {`Inscrição Prefeitura: ${cityHallRegistration}`}
          </Text>
          <br />
        </>
      )}
    </section>
  )
}

DataSection.propTypes = {
  rgi: PropTypes.string.isRequired,
  cityHallRegistration: PropTypes.string.isRequired,
  registration: PropTypes.string.isRequired,
}

export default DataSection
