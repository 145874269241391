import React from 'react'
import PropTypes from 'prop-types'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const debts = ({ viewBox, color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="debts"
          d="M17.094 11.747c0 .604-.49 1.094-1.094 1.094H7.396a1.094 1.094 0 1 1 0-2.188H16c.604 0 1.094.49 1.094 1.094zM16 14.955a1.094 1.094 0 1 1 0 2.188H7.396a1.094 1.094 0 1 1 0-2.188H16zm-4.302 4.302a1.094 1.094 0 1 1 0 2.188H7.396a1.094 1.094 0 0 1 0-2.188h4.302zm9.698-12.323v18.23a2.738 2.738 0 0 1-2.734 2.734H4.734A2.738 2.738 0 0 1 2 25.164V6.934A2.737 2.737 0 0 1 4.734 4.2h13.928a2.737 2.737 0 0 1 2.734 2.734zm-2.188 0a.548.548 0 0 0-.546-.546H4.734a.547.547 0 0 0-.546.546v18.23c0 .301.245.547.546.547h13.928a.548.548 0 0 0 .546-.547V6.934zm8.492 11.53l.748-.55a1.1 1.1 0 0 1 1.304 1.772l-2.482 1.825-.012-.042c.24-.22.403-.559.436-.945a1.096 1.096 0 0 1-.424.987l-.018.014a1.106 1.106 0 0 1-.652.214 1.095 1.095 0 0 1-.67-.228l-2.482-1.825a1.1 1.1 0 0 1 1.304-1.772l.748.55v-5.906c0-.75.493-1.358 1.1-1.358.608 0 1.1.608 1.1 1.358v5.906z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#debts" />
        </mask>
        <use className={styles[color]} fillRule="nonzero" xlinkHref="#debts" />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

debts.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ...propTypesSvg,
}

debts.defaultProps = {
  size: 'auto',
  ...defaultPropTypesSvg,
}

export default debts
