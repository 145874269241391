import React from 'react'
import PropTypes from 'prop-types'
import { CategoryTitle, Stage } from 'components'
import styles from './SaleStage.module.scss'

const SaleStage = ({ stage }) => {
  if (!stage) return null

  return (
    <section className={styles.saleStage}>
      <CategoryTitle icon="stage" title="Estágio de venda" />
      <div className={styles.stage}>
        <Stage stage={stage} />
      </div>
    </section>
  )
}

SaleStage.propTypes = {
  stage: PropTypes.string.isRequired,
}

export default SaleStage
