import { useState } from 'react'
import useCreateStore from 'lib/useCreateStore'
import providersActions from './actions'
import providersSelectors from './selector'

const initialState = {
  messageProvidersErr: false,
  messageProvidersLoading: false,
  messageProviders: [],
}

const ProvidersStore = useCreateStore(() => {
  const [$providers, setProviders] = useState(initialState)

  const actions = providersActions(setProviders)
  const selectors = providersSelectors($providers)

  return { $providers, ...actions, ...selectors }
})

export const useProviders = () => ProvidersStore()
export const ProvidersContext = ProvidersStore.Context
export const ProvidersProvider = ProvidersStore.Provider
