/* eslint-disable react/no-danger  */
import React from 'react'
import PropTypes from 'prop-types'
import { Title } from 'components'
import { boldString } from 'utils/string'
import DOMPurify from 'dompurify'
import styles from './FAQCard.module.scss'

const sanitizer = DOMPurify.sanitize

const FAQCard = ({ title, body, onClick, isActive }) => (
  <article className={`${styles.wrapper} ${isActive ? styles.open : ''}`}>
    <button type="button" className={styles.button} onClick={onClick}>
      <Title type="h2" color="darkBlueColor">
        {title}
      </Title>
      <svg viewBox="0 0 171 171" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="85.5" cy="85.5" r="78.5" strokeWidth="14" />
        {/* se ativo, esconder o traço vertical */}
        {!isActive && <rect x="78" y="40" width="15" height="92" rx="6" />}
        <rect
          x="131.5"
          y="78.5"
          width="15"
          height="92"
          rx="6"
          transform="rotate(90 131.5 78.5)"
        />
      </svg>
    </button>
    <div className={styles.content}>
      {body
        .split('\n')
        .filter(p => p.trim() !== '')
        .map((p, i) => (
          <p
            key={i.toString()}
            dangerouslySetInnerHTML={{
              __html: sanitizer(boldString(p.trim()), {
                ALLOWED_TAGS: ['a', 'b'],
                ALLOWED_ATTR: ['target', 'href', 'rel'],
              }),
            }}
          />
        ))}
    </div>
  </article>
)

FAQCard.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
}

export default React.memo(FAQCard)
