import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Tooltip.module.scss'

const Tooltip = ({ children, content, className, ignoreTooltipTreatment }) => {
  const contentClasses = classNames(styles.text, className)
  const [hasOverflowingChildren, setHasOverflowingChildren] = React.useState(
    false
  )
  const [text, setText] = React.useState(false)

  const updateOverflow = e => {
    const el = e.target

    if (el.scrollWidth > el.clientWidth) {
      setHasOverflowingChildren(true)
      if (el.textContent !== text) {
        setText(el.textContent)
      }
    } else {
      setHasOverflowingChildren(false)
    }
  }

  return (
    <>
      <div className={styles.wrapper}>
        <p
          className={contentClasses}
          onFocus={updateOverflow}
          onMouseOver={updateOverflow}
        >
          {content || children}
        </p>
        {(hasOverflowingChildren || ignoreTooltipTreatment) && (
          <div className={styles.tooltip}>{children}</div>
        )}
      </div>
    </>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.string,
  className: PropTypes.string,
  ignoreTooltipTreatment: PropTypes.bool,
}

Tooltip.defaultProps = {
  className: '',
  content: undefined,
  ignoreTooltipTreatment: false,
}

export default React.memo(Tooltip)
