import React from 'react'
import PropTypes from 'prop-types'
import styles from './svg.module.scss'
import { propTypesSvg, defaultPropTypesSvg } from './propTypes'

const about = ({ viewBox, color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height="100%"
      viewBox={viewBox}
    >
      <defs>
        <path
          id="about"
          d="M5.5 5h21a1.5 1.5 0 0 1 0 3h-21a1.5 1.5 0 0 1 0-3zm0 12h21a1.5 1.5 0 0 1 0 3h-21a1.5 1.5 0 0 1 0-3zm0 6h14a1.5 1.5 0 0 1 0 3h-14a1.5 1.5 0 0 1 0-3zm0-12h6a1.5 1.5 0 0 1 0 3h-6a1.5 1.5 0 0 1 0-3zm11 0h10a1.5 1.5 0 0 1 0 3h-10a1.5 1.5 0 0 1 0-3z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b">
          <use xlinkHref="#about" />
        </mask>
        <use className={styles[color]} fillRule="nonzero" xlinkHref="#about" />
        <g mask="url(#b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

about.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ...propTypesSvg,
}

about.defaultProps = {
  size: 'auto',
  ...defaultPropTypesSvg,
}

export default about
